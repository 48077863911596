import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '../..'

const ContainerGlobal = styled.div`
    background-color: #CFCFD5;
    border-radius: 12px;

    margin-right: 32px;
    margin-top: 24px;
    
`
const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    padding: 24px;
`

const HeaderTitle = styled.div`
font-family: Raleway;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 28px;


color: #292B30;
`

const HeaderBtn = styled.a`
    cursor: pointer;

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;


    color: #292B30;

    margin-top: 6px;
`

const BodyStyled = styled.div`
    padding-top: 20px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 57px;

    background-color: #E1E1E7;

    display: ${props=>props.hide ? `none` : ``};
`

const TextStyled = styled.p`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;

color: #292B30;
`

const FooterStyled = styled.div`
    background-color: #CFCFD5;
    border-radius: 0 0 12px 12px;

    height: 23px;

    
    display: ${props=>props.hide ? `none` : ``};
`

const LinkBody = styled.a`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;
text-decoration-line: underline;

color: #E60000;
`


function CultureAudit(props){
    const lista = ["arrow-down", "arrow-up"]
    const [icone, setIcone] = useState(lista[0]);
    const [texto, setTexto] = useState('Mais Informações')
    const [ativado, setAtivado ] = useState(props.ativado);

    function handleDiv(){
        if(ativado === false){
            setTexto('Mais Informações')
            setAtivado(!ativado)
            setIcone(lista[1])
        }
        else{
            setTexto('Mais Informações')
            setAtivado(!ativado)
            setIcone(lista[0])
        }
        console.log(ativado)
    }

    return <ContainerGlobal>
                <Header>
                    <HeaderTitle>
                        Culture Audit
                    </HeaderTitle>

                    <HeaderBtn onClick={()=>{
                        handleDiv()
                    }}>
                        {texto}
                        <Icon type={icone} />            
                    </HeaderBtn>
                </Header>
                <BodyStyled hide={ativado}>
                    <TextStyled>
                        O Culture Audit © inclui uma série de questões abertas, elaboradas para nos auxiliar a entender quais são as práticas diferenciais na cultura da sua organização. Estamos particularmente interessados no que é especial, único ou não usual.
                    </TextStyled>
                    <br/>
                    <TextStyled>
                        As questões estão agrupadas de acordo com as 9 Práticas Culturais necessárias para criar um Great Workplace.
                    </TextStyled>
                    <br/>
                    <TextStyled>
                        Importante: não há respostas certas ou erradas. Avaliaremos a qualidade e o conteúdo das respostas, não o tamanho ou estilo, portanto, forneça somente as informações necessárias para que possamos entender o propósito e o conteúdo das práticas ou políticas, assim como sua relação com a cultura da empresa.
                    </TextStyled>
                    <br/>
                    <TextStyled>
                        Se sua empresa já preencheu o Culture Audit em anos anteriores, por favor indique com “NOVO!” as novas práticas que está acrescentando em seu documento.
                    </TextStyled>
                    <br/>
                    <TextStyled>
                        <LinkBody href="#">Clique aqui</LinkBody> para conhecer os 5 critérios de avaliação do Culture audit
                    </TextStyled>
                    <br/>
                    <TextStyled>
                        <LinkBody href="#">Clique aqui</LinkBody> para baixar o manual de preenchimento das Práticas Culturais
                    </TextStyled>
                </BodyStyled>
                <FooterStyled hide={ativado}>

                </FooterStyled>
            </ContainerGlobal>
}

export default CultureAudit