import { Icon } from "@chakra-ui/react";

const IconClose = (props) => {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" {...props}>
      <path
        opacity="0.4"
        d="M16 29.3334C23.364 29.3334 29.3333 23.364 29.3333 16C29.3333 8.63602 23.364 2.66669 16 2.66669C8.63596 2.66669 2.66663 8.63602 2.66663 16C2.66663 23.364 8.63596 29.3334 16 29.3334Z"
      />
      <path d="M17.4133 16L20.48 12.9333C20.8666 12.5467 20.8666 11.9067 20.48 11.52C20.2918 11.334 20.0379 11.2297 19.7733 11.2297C19.5087 11.2297 19.2548 11.334 19.0666 11.52L16 14.5867L12.9333 11.52C12.7451 11.334 12.4912 11.2297 12.2266 11.2297C11.9621 11.2297 11.7082 11.334 11.52 11.52C11.1333 11.9067 11.1333 12.5467 11.52 12.9333L14.5866 16L11.52 19.0667C11.1333 19.4533 11.1333 20.0933 11.52 20.48C11.72 20.68 11.9733 20.7733 12.2266 20.7733C12.48 20.7733 12.7333 20.68 12.9333 20.48L16 17.4133L19.0666 20.48C19.2666 20.68 19.52 20.7733 19.7733 20.7733C20.0266 20.7733 20.28 20.68 20.48 20.48C20.8666 20.0933 20.8666 19.4533 20.48 19.0667L17.4133 16Z" />
    </Icon>
  );
};

export default IconClose;
