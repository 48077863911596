import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { researchApi } from '../../../services/api-research';
import { useListAffirmatives } from "../../../helpers/useListAffirmatives";
import {
  Box,
  Grid,
  GridItem,
  useMediaQuery,
  Text,
  Image,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import { useResearch } from "../../../helpers/useResearch";
import { useFormsInfo } from "../../../helpers/useFormsInfo";

import iconReady from "../../../assets/images/icon-ready.svg";
import etapas from "../../../assets/images/etapas.svg";


const Steps = ({ researchId, cr, setCompleted }) => {
  const { data, isLoading } = useFormsInfo(cr);
  const [mobile] = useMediaQuery("(max-width: 1090px)");

  const { data: researchsData } = useResearch(researchId);
  const { data: affirmativeData } = useListAffirmatives(researchId);

  const [initialDate, setInitialDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [urlForm, setUrlForm] = useState('');
  const [customised, setCustomised] = useState(false);

  useEffect(async () => {
    const { data } = await researchApi.get(`/research/form-url/${researchsData.research.extern_researchId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    if (data) {
      setUrlForm(data.split('//')[1]);
    } else {
      setUrlForm('pesquisa.gptw.com.br');
    }

    if (researchsData.research.application_date) {
      const initial = format(parseISO(researchsData.period.from), "dd", {
        locale: ptBR,
      });

      const end = format(parseISO(researchsData.period.to), "dd/MM/yyyy", {
        locale: ptBR,
      });

      setInitialDate(initial);
      setEndDate(end);
    }
  }, [researchsData, researchsData]);

  useEffect(() => {
    if(     
      affirmativeData &&
      affirmativeData[0] !== undefined
    ) {
      setCustomised(true)
    }    
  }, [affirmativeData]);

  useEffect(() => {
    if (data !== undefined) {
      if (
        !!researchsData.employees_sheet &&
        !!researchsData.initial_date &&
        !!researchsData.customised &&
        !!data.cultureBrief.allRequiredAnswered &&
        customised
      ) {
        setCompleted(true);
      }
    }
  }, [researchsData, data]);
  
  return !isLoading ? (
    <Grid
      templateColumns={["repeat(1, 1fr)", "repeat(5, 1fr)"]}
      templateRows={["repeat(6, 1fr)", "repeat(3, 1fr)"]}
      columnGap={[0, 4]}
      rowGap={["8px", 0]}
      h={["auto", "148px"]}
      p={["24px 0 6px 0", "10px 0", "unset"]}
    >
      {!data.err ? (
        <>
          <StepItem >
            {researchsData.employees_sheet ? (
              <Image src={iconReady} alt="finalizado" />
            ) : (
              <Image src={etapas} alt="pendente" />
            )}

            <Text
              fontSize={"14px"}
              lineHeight="20px"
              textAlign={["left", "center"]}
              color="#292B30"
              fontWeight="500"
            >
              Planilha de Funcionários
            </Text>
          </StepItem>

          {researchsData.research.plan?.includes("PREMIUM") ? (            
              <StepItem rowStart={[2, "unset"]}>           
                {customised ? (
                  <Image src={iconReady} alt="finalizado" />
                  ) : (
                  <Image src={etapas} alt="pendente" />
                  )}  
                  <Text
                    fontSize={"14px"}
                    lineHeight="20px"
                    textAlign={["left", "center"]}
                    color="#292B30"
                    fontWeight="500"
                  >                       
                  {mobile
                    ? "Personalização da pesquisa"
                    : "Personalização da Pesquisa de Clima"}
                </Text>                    
              </StepItem>
          ):(
            <></> 
          )}          


          <StepItem rowStart={[3, "unset"]}>
            {researchsData.initial_date ? (
              <Image src={iconReady} alt="finalizado" w="30px" />
            ) : (
              <Image src={etapas} alt="pendente" />
            )}

            <Text
              fontSize={"14px"}
              lineHeight="20px"
              textAlign={["left", "center"]}
              color="#292B30"
              fontWeight="500"
            >
              {mobile
                ? "Data início da pesquisa"
                : "Data Início da Pesquisa de Clima"}
            </Text>
          </StepItem>
          <StepItem rowStart={[4, "unset"]}>
            {!data.err && data.cultureBrief.allRequiredAnswered ? (
              <Image src={iconReady} alt="finalizado" />
            ) : (
              <Image src={etapas} alt="pendente" />
            )}

            <Text
              fontSize={"14px"}
              lineHeight="20px"
              textAlign={["left", "center"]}
              color="#292B30"
              fontWeight="500"
            >
              Questionário de certificação
            </Text>
          </StepItem>

          <Divider as={GridItem} m={0} h="7px" color="#CCCCCC" colSpan={5} />
          <GridItem
            w="100%"
            display={["flex"]}
            flexDir={["column", "row"]}
            justifyContent="space-between"
            px={['unset', '20px', '10px']}
            colSpan={5}
            rowSpan={[1, 1]}
            rowStart={[6, 3]}
            mt={["-15px",'0',"-15px"]}
          >
            <Text fontSize="14px" color="##292B30">
              {mobile ? "Link" : "Link da Pesquisa de Clima:"}

              {researchsData.link !== null &&
              researchsData.link !== "" &&
              researchsData.link !== "Test URL" &&
              researchsData.link !== "string" ? (
                <Text
                  as="span"
                  color="#E60000"
                  fontSize="14px"
                  fontWeight="600"
                  ml="5px"
                >
                  {" "}
                  <a
                    href={`${window.location.protocol}//${urlForm}/${researchsData.link}`}
                    target="_blank"
                    rel="noreferrer"
                  >{`${urlForm}/${researchsData.link}`}</a>
                </Text>
              ) : (
                <Text
                  as="span"
                  color="292B30"
                  fontSize="14px"
                  fontWeight="600"
                  ml="5px"
                >
                  Link não disponivel
                </Text>
              )}
            </Text>
            <Text fontSize="14px" color="##292B30" textAlign={"left"}>
              Período:
              {researchsData.initial_date ? (
                <>
                  <Text as="span" fontSize="14px" fontWeight="600" mx="5px">
                    de {initialDate}
                  </Text>
                  a
                  <Text as="span" fontSize="14px" fontWeight="600" ml="5px">
                    {endDate}
                  </Text>
                </>
              ) : (
                <Text
                  as="span"
                  color="292B30"
                  fontSize="14px"
                  fontWeight="600"
                  ml="5px"
                >
                  Aguardando
                </Text>
              )}
            </Text>
          </GridItem>
        </>
      ) : (
        <></>
      )}
    </Grid>
  ) : (
    <Box w="100%" h="40vh" textAlign="center" pt="20px">
      <Spinner />
    </Box>
  );
};

const StepItem = ({ children, ...rest }) => {
  return (
    <GridItem
      w={["100%", "124px"]}
      colSpan={1}
      rowSpan={1}
      h="100%"
      display={"flex"}
      flexDir={["row", "column"]}
      justifyContent={["start", "center"]}
      alignItems={"center"}
      gap={"10px"}
      {...rest}
    >
      {children}
    </GridItem>
  );
};

export default Steps;
