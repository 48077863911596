import {
  Flex,
  Box,
  Text,
  Button,
  Heading,
  useMediaQuery,
} from "@chakra-ui/react";

import iconBar from "../../../assets/images/icon-bar.svg";

export default function Comunication() {
  const [mobile] = useMediaQuery('(min-width: 1024px)')

  const handleModelDownload = () => {
    fetch(`https://d335luupugsy2.cloudfront.net/cms%2Ffiles%2F2705%2F1722520344Guia_de_Comunicao_Pr-Pesquisa_GPTW.pdf`, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `GUIA_COMUNICACAO.pdf`);

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  const handleModelEmailDownload = () => {
    fetch(`https://d335luupugsy2.cloudfront.net/cms%2Ffiles%2F2705%2F1722520344Guia_de_Comunicao_Pr-Pesquisa_GPTW.pdf`, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `MODELO_EMAIL.pptx`);

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  return (
    <Box
      w={["100%","100%", "50%"]}
      p={["22px", "30px"]}
      bg="white"
      h={["100%"]}
      border="1px solid #CCCCCC"
      borderRadius={["12px", "10px"]}
      position="relative"
    >
      <img src={iconBar} alt="icon bar" />
      <Heading as="h5" fontSize="17px" fontWeight="600" pt="10px">
        Comunicação aos funcionários selecionados
      </Heading>
      <Text fontSize="14px" 
      
      lineHeight='24px'
      mt="8px" mb={["12px", "0"]}>
        A <b>divulgação</b> da pesquisa é de <b>responsabilidade</b> da{" "}
        <b>Empresa. </b>
        <Box display={["none", "none", "none", "none", "block"]} />
        Recomendamos avisar os funcionários sobre a Pesquisa de Clima pelos
        meios abaixo:
      </Text>
      {!mobile ? (
        <Button
          color="#E60000"
          variant="link"
          display="block"
          whiteSpace={"normal"}
          lineHeight="20px"
          fontSize="14px"
          textAlign={"left"}
          fontWeight={"400"}
          mb={["16px", '4px']}
          onClick={handleModelEmailDownload}
        >
          <Text as="span" fontWeight={700}>
            Clique aqui{" "}
          </Text>{" "}
          para baixar um modelo de divulgação por e-mail
        </Button>
      ) : (
        <Flex fontSize="14px" mt={["22px", '20px', '10px']}>
          <Button
            color="#E60000"
            variant="link"
            fontSize="14px"
            borderBottom="2px"
            mb='10px'
            borderRadius="0px"
            fontWeight={"600"}
            mr="5px"
            onClick={handleModelEmailDownload}
          >
            Clique aqui
          </Button>
          para baixar um modelo de divulgação por e-mail
        </Flex>
      )}
      {!mobile ? (
        <Button
          color="#E60000"
          variant="link"
          display="block"
          whiteSpace={"normal"}
          lineHeight="20px"
          fontSize="14px"
          textAlign={"left"}
          fontWeight={"400"}
      
          onClick={handleModelEmailDownload}
        >
          <Text as="span" fontWeight={700} mr="4px">
            Clique aqui{" "}
          </Text>{" "}
          para baixar um guia de comunicação
        </Button>
      ) : (
        <Flex fontSize="14px">
          <Button
            color="#E60000"
            variant="link"
            fontSize="14px"
            borderBottom="2px"
            borderRadius="0px"
            mr="5px"
            onClick={handleModelDownload}
          >
            Clique aqui
          </Button>
          para baixar um guia de comunicação
        </Flex>
      )}
    </Box>
  );
}
