import React from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'

const InputText = styled.input`

 	font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    border: none;
    border: 1px solid #B8CAE8;
    box-sizing: border-box;
    border-radius: 10px;
    outline:  none;
    padding: 16px;
    width: 260px;
    height: 56px;

`

const StyledContainer =styled.div`
  display: flex;
  flex-direction: column;

`

const StyleLabel = styled.label`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;

color: #292B30;
`

function InputPhone(props){
	return  <StyledContainer>
            <StyleLabel>{props.label}</StyleLabel>
            <InputMask
              mask="(99) 9999-9999"
              {...props}
            >
              {(inputProps)=>(<InputText type="tel" {...props} {...inputProps} />)}
            </InputMask>
         </StyledContainer>
}

export default InputPhone
