import axios from "axios";
import { useQuery } from "react-query";
import { queryClient } from "../services/queryCliente";

const fetchData = async (id) => {
  const url_company_info =
    window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
    `/company-register-info?cr=${id}`;

  const response = await axios.get(url_company_info);
  const status = response.data;
  return status;
};

export const useCompanyData = (id) => {
  return useQuery(
    "companyInfo",
    () => fetchData(id),
    {
      // The query will not execute until the userId exists
      enabled: id !== undefined,
    },
    {
      staleTime: 30 * 1000,
      initialData: () => queryClient.getQueryData("companyInfo"),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState("companyInfo")?.dataUpdatedAt,
    }
  );
};
