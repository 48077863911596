/* eslint-disable no-unused-vars */
import apiForms from "../services/api-forms";
import { useQuery } from "react-query";
import { queryClient } from "../services/queryCliente";
import axios from "axios";

const fetchQuestionaries = async (companyRegister) => {
  const response = await axios.get(
    `${window['runConfig'].REACT_APP_URL_FORMS_API}/api/answer/create-and-get/${companyRegister}`,
    {
      headers: {
        auth: "40175c7df50a72ded4117125d8d723f6",
      },
    }
  );
  const forms = response.data?.data.flat();
  return forms;
};

export const useFormsInfo = (companyRegister) => {
  return useQuery(
    ["forms", companyRegister],

    async () => {
      if (companyRegister) {
        const response = await fetchQuestionaries(companyRegister);

        const rankingForm = response.find((d) => d.form === "PD");
        const registraComp = response.find((d) => d.form === "IC");
        const cultureBrief = response.find((d) => d.form === "CB");

        return { rankingForm, registraComp, cultureBrief };
      }
    },
    {
      staleTime: 30 * 10000,
      initialData: () => queryClient.getQueryData("forms"),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState("forms")?.dataUpdatedAt,
    }
  );
};
