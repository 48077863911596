import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  ModalCloseButton,
  Text,
  useToast,
} from "@chakra-ui/react"
import axios from "axios"
import { useState } from "react"
import styled from "styled-components"
import { researchApi } from "../../../../services/api-research"
import apiFinancial from "../../../../services/api-financial";

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  height: auto;
  width: auto;
  max-width: 600px;
  background-color: #fff;
  border-radius: 12px;
  padding: 40px;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  overflow: auto;

  > * + * {
    margin-top: 20px;
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 6px;
  background-color: white;

  h1 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
  }
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-top: auto;
  width: 100%;
  height: auto;

  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    color: white;
  }
`

const TopContainer = styled.div`
  display: block;
  margin-top: auto;
  width: 100%;
  height: 20px;

  button {
    position: relative;
    top: -35px;
    right: -460px;
    margin: 0;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
  }
`

export const UpgradePlan = ({
  close,
  planos,
  oldProductId,
  signatureId,
  faixa,
  cr,
  visible,
  customerId
}) => {
  const [fetching, setFetching] = useState(false)
  const [progress, setProgress] = useState(0)
  const [companyInfo, setcompanyInfo] = useState({})
  const toast = useToast()

  const ModalContainer = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.6s ease;
    visibility: visible;
    opacity: ${visible ? 1 : 0};
  `

  const planTypes = [
    {
      name: "MICRO",
      min: 10,
      max: 29,
    },
    {
      name: "SMALL",
      min: 30,
      max: 99,
    },
    {
      name: "MEDIUM_F1",
      min: 100,
      max: 499,
    },
    {
      name: "MEDIUM",
      min: 500,
      max: 999,
    },
    {
      name: "LARGE",
      min: 1000,
      max: 4999,
    },
    {
      name: "EXTRALARGE",
      min: 5000,
    },
  ]

  const findProductType = (productName) => {
    const productType = planTypes.filter((planType) => {
      console.log(planType.min, parseInt(productName.match(/(\d+)/)[0]))
      console.log(planType.min === parseInt(productName.match(/(\d+)/)[0]))
      return planType.min === parseInt(productName.match(/(\d+)/)[0])
    })
    console.log(productType)
    return productType[0].name
  }

  const handleClick = async (
    oldProductId,
    newProductId,
    productPrice,
    signatureId,
    productName,
    customerId
  ) => {
    setFetching(true);
    setProgress(10);
    let error_phase = "";
    try {
      // Trazendo informações gerais do cliente
      const urlParams = new URLSearchParams(window.location.search);
      const cr = urlParams.get("cr");
      const url_company_info = window["runConfig"].REACT_APP_URL_RESULT_SERVICE + `/company-register-info?cr=${cr}`;
      const crInfo = await axios.get(url_company_info);

      // chamar connect pra trazer o product id necessário pro update no connect
      error_phase = "Não foi possível localizar o plano escolhido no Connect.";

      const url_product_id =
        window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
        `/product/plan-and-type?plan=${faixa}&planType=${findProductType(
          productName
        )}`;
      
      const product_id = await axios.get(url_product_id);

      if (!Object.keys(product_id.data).find((key) => key === "id") || !product_id.data.id) {
        throw new Error("Plano não encontrado no Connect");
      }

      setProgress(30);

      // chamar financial pra atualizar o plano na vindi
      console.log('Atualizando plano na Vindi!')
      error_phase = "não foi possível realizar a troca de plano na Vindi.";

      const url_update_plan =
        window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
        `/vindi/updateSignatureProduct?oldProductId=${oldProductId}&newProductId=${newProductId}&signatureId=${signatureId}&productPrice=${productPrice}&customerId=${customerId}`;
        
      const response = await axios.put(url_update_plan);

      setProgress(65);

      // chamar connect pra atualizar o product id
      error_phase = "Não foi possível alterar o plano no Connect."

      const config = {
        headers: { "Content-type": "application/json" },
        // cancelToken: source.token,
      };
      
      const result_connect = await researchApi.post(
        "/research/update/plan",
        {
          companyRegisterId: cr,
          productId: product_id.data.id,
        },
        config
      );

      // Liberando mais 6 meses para agendamento de pesquisa
      error_phase = "Não foi possível liberar mais 6 meses para agendamento."
      console.log('Liberando mais 6 meses para agendamento de pesquisa!')
      await axios.post(`${window["runConfig"].REACT_APP_URL_RESULT_SERVICE}/research-info/reset-CreationDate?companyRegisterId=${cr}`);

      // Criando uma nova oportunidade no SF
      error_phase = "Não foi possível criar uma oportunidade nova no Salesforcer."
      console.log('Criando uma nova oportunidade no SF!')
      const dataSf = {
        plano: {
          oldProductId,
          newProductId,
          productPrice,
          signatureId,
          productName,
          customerId,
        },
        product: product_id.data,
        companyInfo: crInfo.data,
        finalAmountToPay: response.data.finalAmountToPay
      }
      const responseSf =  await axios.post(`${window["runConfig"].REACT_APP_URL_RESULT_SERVICE}/financial/upgrade-plan-sf`, dataSf);

      if (result_connect.data.statusCode === 200){  
        setProgress(100)
  
        toast({
          title: "Plano Atualizado!",
          description: "Você já pode realizar o envio da sua planilha novamente.",
          status: "success",
          duration: 5000,
          isClosable: true,
        })
  
        setTimeout(() => {
          setFetching(false)
          close()
        }, 500)

      } else {
        throw new Error();
      }

    } catch (error) {
      console.log(error.message)
      console.log(error_phase)
      setFetching(false)
      close()

      toast({
        title: "Falha no envio",
        description: error_phase,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const plans_list = planos
    ? planos.map((plano) => {
        return (
          <Button
            variant="secondary"
            fontSize={["14px", "13px", "16px"]}
            w={["100%"]}
            h="auto"
            padding="4px"
            color={"red.100"}
            borderColor={"red.100"}
            _hover={{
              bg: "#E60000",
              color: "white",
            }}
            onClick={() =>
              handleClick(
                oldProductId,
                plano.plan_items[0].product.id,
                plano.plan_items[0].product.pricing_schema.price,
                signatureId,
                plano.name,
                customerId
              )
            }
          >
            {plano.name}
            <br />
            {plano.plan_items[0].product.pricing_schema.short_format} / mês
          </Button>
        )
      })
    : false

  return (
    <ModalContainer>
      <Modal>
        <InnerContainer>
          {/* <ModalCloseButtonbg="#F1F5FA" rounded={'full'} /> */}
          <TopContainer>
            <h1>Atualize o seu plano!</h1>
            <Button
              onClick={() => close()}
              style={{
                padding: "10px 10px",
                borderRadius: 50,
                alignSelf: "flex-end",
              }}
            >
              x
            </Button>
          </TopContainer>
          <TextContainer>
            Parece que o seu plano não é o ideal para o número de funcionários
            enviados na planilha.
          </TextContainer>
          <TextContainer>
            Caso deseje, você pode clicar em um dos planos disponíveis abaixo
            para realizar um upgrade para um plano de maior capacidade:
          </TextContainer>
          <TextContainer>
            <b>
              ATENÇÃO: Ao fazer o upgrade de plano, o sistema irá criar uma fatura avulsa considerando a diferença entre o valor das mensalidades do plano superior e o valor das mensalidades pagas.
            </b>
          </TextContainer>

          <Flex
            mt={["24px", "unset"]}
            flexDir={["row", "row", "column"]}
            alignItems="center"
            w={["100%", "100%", "100%"]}
            h={["48px", "36px", "auto"]}
            gap={["20px", "12px", "14px"]}
          >
            {!fetching ? (
              plans_list ? (
                plans_list
              ) : (
                "Nenhum plano disponível no momento."
              )
            ) : (
              <>
                <CircularProgress
                  trackColor="pGray.200"
                  value={progress}
                  color="orange.500"
                >
                  <CircularProgressLabel>{progress}%</CircularProgressLabel>
                </CircularProgress>
                <Text as="span" pt={2} textAlign="center">
                  Enviando...
                </Text>
              </>
            )}
          </Flex>
        </InnerContainer>
      </Modal>
    </ModalContainer>
  )
}
