import { useEffect, useState } from "react"
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Heading,
  ModalFooter,
  Box,
  useToast,
  ModalCloseButton,
} from "@chakra-ui/react"
import { researchApi } from "../../../services/api-research"

const ModalConfirmDelete = ({
  onClose,
  isOpen,
  researchId,
  employee,
  total,
  active,
  refresh,
}) => {
  const [collaborator, setCollaborator] = useState({...employee, status: employee?.status === 'Ativo' ? 'sim' : 'não'})
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setCollaborator({...employee, status: employee?.status === 'Ativo' ? 'sim' : 'não'});
  }, [employee])
  

  const toast = useToast();

  const handleSendData = async () => {
    if (collaborator.login || collaborator.status) {

      try {
        setLoading(true)
        await researchApi.delete(
          `/employees?researchId=${
            employee.id_research
          }&total=${total}&active=${active}&id=${employee.id}&status=${
            employee.status === "Ativo" ? "sim" : "não"
          }`
        )
        setLoading(false)
  
        refresh();
        setSuccess(true);
      } catch (err) {
        setLoading(false)
        if (err.response) {
          toast({
            title: "Falha no envio",
            description: err.response.data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        console.log(err.message)
      }

    } else {
      setLoading(false)
      toast({
        title: "Falha no envio",
        description: 'Campos Login e Status são obrigatórios',
        status: "error",
        duration: 5000,
        isClosable: true,
      });

    }
  }

  const handleClose = () => {
    onClose()
        setLoading(false)
    setTimeout(() => {
      setSuccess(false)
    }, 1000)
  }

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          height: "341px",
          width: "552px",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box display={"flex"} flexDir="column" pt="60px">
          <div
            style={{
              margin: "0 auto",
              marginBottom: "20px",
            }}
          >
            {/* <Icon type="check-green" width="100px" height="100px" /> */}
          </div>
          <Heading fontSize="24px" fontWeight="700">
            Exclusão Realizada com sucesso
          </Heading>
        </Box>
        <ModalFooter
          width="100%"
          position={"absolute"}
          p={0}
          bottom={0}
          height="69px"
        >
          <Button
            width="100%"
            color="white"
            background="#E60000"
            height="100%"
            fontWeight="300"
            onClick={handleClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          height: "341px",
          width: "552px",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <ModalCloseButton />
        <Box display={"flex"} flexDir="column" pt="60px">
          <div
            style={{
              margin: "0 auto",
              marginBottom: "20px",
            }}
          >
            {/* <Icon type="check-green" width="100px" height="100px" /> */}
          </div>
          <Heading fontSize="24px" fontWeight="700">
            Deseja mesmo excluir o Colaborador {collaborator.login}?
          </Heading>
        </Box>
        <ModalFooter
          width="100%"
          position={"absolute"}
          p={0}
          bottom={0}
          height="69px"
        >
          <Button
            width="100%"
            color="white"
            background="#E60000"
            height="100%"
            fontWeight="300"
            onClick={handleSendData}
            disabled={loading ? true : false}
          >
            {loading ? (<>Carregando</>) : (<>Confirmar</>) }
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalConfirmDelete
