import { useQuery } from "react-query";
import api from "../api";

const fetchTagsData = async () => {
  const { data } = await api.get(`/tags`);
  return data?.data;
};

const fetchSelectTagsData = async (idAnswer) => {
  const { data } = await api.get(`/answer-tags/${idAnswer}`);
  return data?.data;
};

const fetchAnswerFilesData = async (idAnswer) => {
  const { data } = await api.get(`answer-file/${idAnswer}`);
  return data?.data;
};

// useQuery hooks
export const useAllTagsQuery = () => {
  return useQuery(["pilar-tags"], () => fetchTagsData(), {
    refetchOnWindowFocus: false,
  });
};

export const useSelectTagsQuery = (idAnswer) => {
  return useQuery(
    ["selected-tags", idAnswer],
    () => fetchSelectTagsData(idAnswer),
    {
      enabled: !!idAnswer,
      refetchOnWindowFocus: false,
    }
  );
};

export const useAnswerFilesQuery = (idAnswer) => {
  return useQuery(
    ["answer-files", idAnswer],
    () => fetchAnswerFilesData(idAnswer),
    {
      enabled: !!idAnswer,
      refetchOnWindowFocus: false,
    }
  );
};
