/* eslint-disable no-unused-vars */
import {
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useMediaQuery,
} from "@chakra-ui/react";

const QuestionCard = ({ question, children }) => {
  const [mobile] = useMediaQuery("(min-width: 1024px)");

  return !mobile ? (
    <Accordion
      defaultIndex={[0]}
      allowMultiple
      bg="white"
      order={question.position}
    >
      <AccordionItem mb="16px" p={0} pb="32px" bg="white" border="none">
        <Stack spacing="12px" p={"42px 24px 24px 24px"} position="relative">
          <Text fontSize={["15px", "16px"]}>{question.text}</Text>
          {!!question.required === true && (
            <Text color="#D50000" fontWeight="500" fontSize={["13px", "14px"]}>
              * Questão Obrigatória
            </Text>
          )}

          {children}

          <AccordionButton
            bg="white"
            p={0}
            position={"absolute"}
            bottom={"-10px"}
            w="32px"
            right="40px"
          >
            <Text fontSize="13px">Dica</Text>

            <AccordionIcon bg="white" w="100%" />
          </AccordionButton>
        </Stack>

        <AccordionPanel>
          {!!question?.help && question?.help?.length > 2 ? (
            <Text fontSize="13px" mt="10px" lineHeight="20px">
              {question.help}
            </Text>
          ) : null}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : (
    <Stack
      p="32px 24px 24px 24px"
      bg="white"
      borderRadius="12px"
      position={"relative"}
      order={question.position}
      spacing="12px"
      mb="20px"
    >
      <Text fontSize={["15px", "16px"]}>{question.text}</Text>
      {!!question.required === true && (
        <Text color="#D50000" fontWeight="500" fontSize={["13px", "14px"]}>
          *Questão Obrigatória
        </Text>
      )}
      {children}
    </Stack>
  );
};

export default QuestionCard;
