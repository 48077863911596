import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Btn = styled(Link)`



width: ${props=>props.width ? props.width: '443px'};
height: ${props=>props.height ? props.height : '56px'};


display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    word-wrap: wrap;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    border: none;
    
    
    &:hover{
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    background-color: ${props => props.disabled ? "#292B30": "#E60000"};
    color: #fff;
    border: 1px solid black;
    border-color: ${props => props.disabled ? "#292B30": "#E60000"};
    border-radius: 10px;
    ${props => props.disabled ? "opacity: 0.5;": ""}
    ${props => props.disabled ? "filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));": ""}

   ${props=>{
     if(props.type === "outline"){
        return `
          background-color: #fff;
          color: #E60000;
        `
     }
     if(props.width){
        return `
          width: ${props=>props.width};
        `
     }
     if(props.height){
        return `
          height: ${props=>props.height};
        `
     }
   }}

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

`
const Text = styled.span`
   
`

function ButtonLink(props){
    return  <Btn {...props}>
              {props.label}
            </Btn>
}

export default ButtonLink