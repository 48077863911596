/* eslint-disable no-unused-vars */
import React, { createElement, useEffect, useState } from "react";
import { reset } from "../../services/authServices";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input as ChakraInput,
  Box,
  Flex,
  Grid,
  Image,
  Icon,
  Spinner
} from "@chakra-ui/react";

import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import iconEye from "../../assets/images/icon-eye.svg";
import iconEyeClosed from "../../assets/images/icon-eyeclosed.svg";
import iconError from "../../assets/images/icon-pending-red.svg";
import iconCheck from "../../assets/images/icon-check.svg";
import styled from "styled-components";
import successImage from "../../assets/images/success.jpg";
import failedImage from "../../assets/images/attention.jpg"
import { MdSettingsInputComponent } from "react-icons/md";
import { Input } from "../components/input/index";

const schema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  passwordConfirmation: Yup.string().required("Password is required"),
});

function ModalReset({ isOpen, requestKey, setModalUpdatePassword, onClose }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [match, setMatch] = useState(false);
  const [active, setIsActive] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      !!charNumberValid &&
      !!specialCharValid &&
      !!uppercaseValid &&
      !!numberValid &&
      !!match
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [
    match,
    setIsActive,
    charNumberValid,
    specialCharValid,
    uppercaseValid,
    numberValid,
  ]);
  // Check the length of the input
  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      setCharNumberValid(true);
    } else {
      setCharNumberValid(false);
    }
  };

  // Check for special characters
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);

    checkPasswordLength(event.target.value);
    checkSpecialCharacters(event.target.value);
    checkUppercase(event.target.value);
    checkNumber(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  useEffect(() => {
    if (password === confirmPassword) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }, [password, confirmPassword]);

  async function onSubmit(data) {
    console.log(data);
  }

  async function resetSenha() {
    if (!active) return;
    setIsLoading(true)
    await reset(requestKey, confirmPassword)
    .then(res => {
      if (res.data === true) {
        setShowSuccess(true)
      } else {
        setShowFailure(true)
      }
    })
    setIsLoading(false)
  }

  const handleClose = () => {
    if (showFailure) {
      onClose(true)
    } else {
      onClose();
    }
    setShowSuccess(false);
    setShowFailure(false)
  };

  function showPass (event) {
    let img = event.target.offsetParent.offsetParent.childNodes[0].firstChild
    let input = event.target.offsetParent.offsetParent.childNodes[1]
    if (input.type === "password") {
      input.type = "text"
      img.src = iconEyeClosed
    } else {
      input.type = "password"
      img.src = iconEye 
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="9xl">
      <ModalOverlay />
      {(!showSuccess && !showFailure) ? <ModalContent
        style={{ borderRadius: "30px", width: "500px", padding: "40px" }}
      >
        <ModalHeader>
          <Heading fontSize="28px">Alterar senha</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text fontSize="16px">Redefina a sua senha:</Text>
          <FormControl mt="20px">
            <FormLabel
              id={password}
              htmlFor={password}
              fontSize="xs"
              color="#292B30"
              fontWeight="normal"
              mb="7px"
            >
              Senha
            </FormLabel>
            <Input
              placeholder="Digite a nova senha"
              color="#292B30"
              display="flex"
              alignItems={"center"}
              px="16px"
              fontSize="14px"
              h="56px"
              fontWeight="600"
              borderWidth="1.5px"
              borderRadius="10px"
              marginTop="0px"
              borderColor="#B8CAE8"
              bgColor="white"
              iconRight={<img onClick={(e) => showPass(e)} src={iconEye} alt="icon eye" />}
              _hover={{
                borderWidth: "2px",
              }}
              w="100%"
              type="password"
              value={password}
              onChange={(event) => handlePasswordChange(event)}
            />

            <Box mb="20px" mt="10px">
              <div className="validation">
                <p
                  className={charNumberValid && specialCharValid && uppercaseValid && numberValid ? "success-msg" : "error-msg"}
                > 
                 <b>Sua senha deve conter no mínimo:</b>  
                </p>

                <Grid wrap="wrap">
                  <div
                    className={charNumberValid ? "success-msg" : "error-msg"}
                  >
                    <img src={charNumberValid ? iconCheck : iconError} alt={charNumberValid ? "checado" : "erro"} width="12px"/>
                    <p className="validation-item">
                      8 caracteres 
                    </p>
                  </div>
                  <div
                    className={specialCharValid ? "success-msg" : "error-msg"}
                  >
                    <img src={specialCharValid ? iconCheck : iconError} alt={charNumberValid ? "checado" : "erro"} width="12px"/>
                    <p className="validation-item">
                      1 caractere especial
                    </p>
                  </div>

                  <div
                    className={uppercaseValid ? "success-msg" : "error-msg"}
                  >
                    <img src={uppercaseValid ? iconCheck : iconError} alt={charNumberValid ? "checado" : "erro"} width="12px"/>
                    <p className="validation-item">
                      1 letra maiúscula
                    </p>
                  </div>
                  <div className={numberValid ? "success-msg" : "error-msg"}>
                    <img src={numberValid ? iconCheck : iconError} alt={charNumberValid ? "checado" : "erro"} width="12px"/>
                    <p className="validation-item">
                      1 número
                    </p>
                  </div>
                </Grid>
              </div>
            </Box>

            <FormLabel
              id={confirmPassword}
              htmlFor={confirmPassword}
              fontSize="xs"
              color="#292B30"
              fontWeight="normal"
              mb="7px"
            >
              Confirmar senha
            </FormLabel>

            <Input
              placeholder="Confirmar a nova senha"
              color="#292B30"
              display="flex"
              alignItems={"center"}
              px="16px"
              fontSize="14px"
              h="56px"
              fontWeight="600"
              borderWidth="1.5px"
              borderRadius="10px"
              marginTop="0px"
              borderColor="#B8CAE8"
              bgColor="white"
              iconRight={<img onClick={(e) => showPass(e)} src={iconEye} alt="icon eye" />}
              _hover={{
                // bgColor: "whiteAlpha.100",
                borderWidth: "2px",
              }}
              w="100%"
              type="password"
              value={confirmPassword}
              onChange={(event) => handleConfirmPasswordChange(event)}
            />
            <Box mt="10px">
                {(password !== "" && confirmPassword !== "") && <p
                  className={match ? "success-msg" : "error-msg"}
                > 
                 <b>{match ? "Agora é só confirmar!" : "As senhas não correspondem!"}</b>  
                </p>}
            </Box>
            <Button
              type="submit"
              w="100%"
              height="56px"
              mt="30px"
              color="white"
              bg="#E60000"
              isActive={!active ? true : false}
              onClick={(e) => { resetSenha()}}
            >
              {isLoading ? 
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="gray.500"
                  size="xl"
                />
                : "Confirmar"}
            </Button>
          </FormControl>
        </ModalBody>
      </ModalContent> : 
        
        <ModalContent
        style={{
          borderRadius: "30px",
          height: "380px",
          width: "100%",
          maxWidth: "450px",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box display={"flex"} flexDir="column" p="40px">
          <div
            style={{
              margin: "0 auto",
              marginBottom: "20px",
            }}
          >
            <Image src={showSuccess ? successImage : failedImage} alt={showSuccess ? "success" : "failed"} />
            {/* <Icon type="check-green" width="100px" height="100px" /> */}
          </div>
          <Heading fontSize="28px">{showSuccess ? "Senha alterada com sucesso!" : "Erro!"}</Heading>
          <Text as="p" fontSize={"16px"} mt="30px">
            {showSuccess ? "Agora é só você acessar pela tela de login." : "Algo de errado ocorreu, pedimos que tente novamente."}
          </Text>
        </Box>
        <ModalFooter width="100%" p={0}>
          <Button
            width="100%"
            color="white"
            background="#E60000"
            height="69px"
            position="absolute"
            bottom={0}
            onClick={() => handleClose()}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>}
    </Modal>
  );
}

export { ModalReset };
