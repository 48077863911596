import React, { Component } from "react";
import Chart from "react-apexcharts";

class Charts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 6,
            columnWidth: "20px",
            barHeight: "0%",
            distributed: false,
            rangeBarOverlap: true,
            rangeBarGroupRows: false,
            dataLabels: {
              enabled: false,
            },
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: "white",
          width: 6,
          dashArray: 0,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          // width: '70%',
          fontSize: "13px",
          fontFamily: "Raleway, sans-serif",
          fontWeight: 500,
          offsetX: 0,
          offsetY: 10,
          labels: {
            colors: ["#D50000", "#174BD6", "#EA42AE"],
            useSeriesColors: false,
          },
          markers: {
            width: 8,
            height: 8,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: ["#D50000", "#174BD6", "#EA42AE"],
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 10,
          },
        },
        grid: {
          show: true,
          borderColor: "#90A4AE",
          strokeDashArray: 0,
          position: "back",

          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.9,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: "13px",
              fontFamily: "Raleway, sans-serif",
              fontWeight: 500,
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => {
              return value + "%";
            },
          },
        },
        xaxis: {
          labels: {
            show: true,
            enabled: false,
            style: {
              fontSize: "13px",
              fontFamily: "Raleway, sans-serif",
              fontWeight: 500,
            },
          },
          categories: [
            "Credibilidade",
            "Respeito",
            "Imparcialidade",
            "Orgulho",
            "Camaradagem",
            "Loren ipson",
          ],
        },
        fill: {
          colors: ["#D50000", "#174BD6", "#EA42AE"],
        },
      },
      series: [
        {
          name: "Visão da empresa em 2021",
          data: [80, 80, 80, 80, 80, 80],
        },
        {
          name: "Visão da área em 2021",
          data: [82, 82, 82, 82, 82, 82],
        },
        {
          name: "150 GPTW Nacional 2020",
          data: [84, 84, 84, 84, 84, 84],
        },
      ],
    };
  }

  render() {
    return (
      <>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={"250px"}
          width="100%"
        />
      </>
    );
  }
}
export { Charts };
