import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useRef } from "react";

function ModalWithoutClose({
  title,
  isOpen,
  onClose,
  handleSubmit,
  children,
  titleSubmit,
  isLoading,
}) {
  const initialRef = useRef(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef}>
      <ModalOverlay />

      <ModalContent
        borderRadius="none"
        maxWidth={["348px", "348px", "348px", "614px"]}
      >
        <ModalBody
          p={["32px 16px", "32px 16px", "32px 16px", "32px"]}
          gap="24px"
          display="flex"
          flexDirection="column"
        >
          <Stack spacing={"12px"}>
            <Flex bg="#FF1628" width="20px" h="5px" borderRadius="none" />
            <Heading fontSize="24px" fontWeight={600} lineHeight="28px">
              {title}
            </Heading>
          </Stack>

          {children}
          <Flex gap={["16px", "32px"]} justify="space-between">
            <Button variant="invisible" onClick={onClose}>
              Voltar
            </Button>
            <Button
              variant="red"
              ref={initialRef}
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              {titleSubmit}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ModalWithoutClose;
