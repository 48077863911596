/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  Button,
  Grid,
  GridItem,
  Container,
  Flex,
  Text,
  Heading,
  Box,
  Spinner,
  Image,
  Input as ChakraInput,
  FormLabel as Label,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Select,
  useToast,
} from "@chakra-ui/react";

import styled from "styled-components";
import InputMask from "react-input-mask";
import Cookies from 'js-cookie';
import { Icon } from "../../../style";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import apiForms from "../../../services/api-forms";
import getUrlParams from "../../../services/getUrlParams";
import QuestionCard from "./QuestionCard";
import IBGE from "../../../helpers/getIBGEInfo";
import CustomInputImage from "../../components/customInputImage";
import { convertToNumber } from "../../../helpers/number";
import { fetchExportQuestions } from "../../../hooks/Admin/useCompanyRegisterList";
import axios from "axios"

//Ajuste para merge
const Input = styled.input`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border: none;
  border: 1px solid #b8cae8;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  padding: 16px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

function InfoOrganizacao(props) {
  let dispatch = useDispatch();
  const toast = useToast();
  const [imagesUploading, setImagesUploading] = React.useState(0);

  function filterOptions(answerOptions, idQuestionOption) {
    const options = answerOptions.filter(
      (answerOption) => answerOption.idQuestionOption === idQuestionOption
    );
    return options;
  }

  const [imagesLoading, setImagesLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [generalQuestionsTrue, setGeneralQuestions] = useState({});
  const [answerOptions, setAnswerOptions] = useState({});
  const [responseData, setResponseData] = useState({});
  const [responseForms, setResponseForms] = useState({});
  let [answers, setAnswers] = useState([]);
  const [answersNumber, setAnswersNumber] = useState(0);
  const [requiredNumber, setRequiredNumber] = useState(0);
  const [requiredAnswered, setRequiredAnswered] = useState(0);
  const [canEdit, setCanEdit] = useState(null);
  const [doc, setDocument] = useState(null);
  const [totalFemEmployees, setTotalFemEmployess] = useState(0);
  const [totalMascEmployees, setTotalMascEmployees] = useState(0);
  const [totalNonBinaryEmployees, setNonBinaryEmployees] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [UFsIBGE, setUFsIBGE] = useState({});
  const [UFCities, setUFCities] = useState({});
  
  const [updatedAt, setUpdatedAt] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [showBtnDownloadAnswers, setShowBtnDownloadAnswers] = useState(true);

  let getAnswers = [];
  const [initialAnswers, setInitialAnswers] = useState([]);

  function countQuestions() {
    let requiredAnsweredCounter = 0;

    answers
      .filter((ans) => ans.answered === true)
      .map((ansValue) =>
        generalQuestionsTrue.find(
          (generalQuestion) => ansValue.idAnswer === generalQuestion.idAnswer
        ).required === true
          ? requiredAnsweredCounter++
          : (requiredAnsweredCounter = requiredAnsweredCounter)
      );

    setAnswersNumber(answers.filter((ans) => ans.answered === true).length);
    setRequiredAnswered(requiredAnsweredCounter);
  }

  function sendAlert(help) {
    alert(help);
  }

  async function save(file, companyRegister, isLogo = false) {
    const AWS = require("aws-sdk");
    //TODO: ver se existe uma abordagem melhor

    AWS.config.update({
      accessKeyId: window["runConfig"].AWS_ACCESS_KEY_ID,
      secretAccessKey: window["runConfig"].AWS_SECRET_ACCESS_KEY,
    });

    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      // eslint-disable-next-line no-undef
      region: window["runConfig"].AWS_REGION,
    });

    const params = {
      // eslint-disable-next-line no-undef
      Bucket: `${window["runConfig"].AWS_S3_BUCKET}/CompanyPics/${companyRegister}`,
      Key: isLogo ? `Logo.${file.name.split(".").pop()}` : file.name,
      Body: file,
      ACL: "public-read",
    };

    return await s3.upload(params).promise();
  }

  async function setAware(event) {
    if (event.target.checked) {
      const current = new Date().toLocaleString();
      answers.find(
        (answer) => answer.idAnswer === event.target.name
      ).acceptConfidential = current;
    } else {
      answers.find(
        (answer) => answer.idAnswer === event.target.name
      ).acceptConfidential = "";
    }
  }

  async function createAnswers(event) {
    if (answers.find((answer) => answer.idAnswer === event.target.name)) {
      if (event.target.type === "checkbox") {
        const { value, checked } = event.target;
        let arrayOrString = answers.find(
          (answer) => answer.idAnswer === event.target.name
        ).answer;

        if (
          !Array.isArray(arrayOrString) &&
          arrayOrString !== "" &&
          arrayOrString !== null
        ) {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = answers
            .find((answer) => answer.idAnswer === event.target.name)
            .answer.split(",");
        } else if (
          arrayOrString === undefined ||
          arrayOrString === "" ||
          arrayOrString === null
        ) {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = [];
        }

        if (checked) {
          answers
            .find((answer) => answer.idAnswer === event.target.name)
            .answer.push(value);
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = true;
          arrayOrString = answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer;
        } else {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = answers
            .find((answer) => answer.idAnswer === event.target.name)
            .answer.filter((element) => element !== value);
          arrayOrString = answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer;
        }

        if (arrayOrString.length === 0) {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = null;
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = false;
        }
      } else if (event.target.type === "file") {
        if (event.target.multiple) {
          // const links = [];
          // Object.keys(event.target.files).forEach(async (index) => {
          //   if (event.target.files[index].size > 5 * 1024 * 1024) {
          //     alert(
          //       `O arquivo ${event.target.files[index].name} não pode ser carregado por conta do tamanho. Diminua o arquivo e tente novamente. Limite de 5MB.`
          //     );
          //   } else {
          //     let link = await save(event.target.files[index], doc);
          //     links.push(link.Location);
          //     answers.find(
          //       (answer) => answer.idAnswer === event.target.name
          //     ).answer = links;
          //     answers.find(
          //       (answer) => answer.idAnswer === event.target.name
          //     ).answered = true;
          //   }
          //   // setImagesUrl(links);
          // });
        } else {
          if (event.target.files[0].size > 5 * 1024 * 1024) {
            alert(
              `O arquivo ${event.target.files[0].name} não pode ser carregado por conta do tamanho. Diminua o arquivo e tente novamente. Limite de 5MB.`
            );
          } else {
            setImageLoading(true);

            setTimeout(() => {
              setImageLoading(false);
            }, 1000);
            let link = await save(event.target.files[0], doc, true);
            answers.find(
              (answer) => answer.idAnswer === event.target.name
            ).answer = link.Location;
            answers.find(
              (answer) => answer.idAnswer === event.target.name
            ).answered = true;
          }
        }
      } else if (event.target.type === "radio") {
        if (
          event.target.parentElement.parentElement.parentElement.firstChild
            .innerText === "Estado da sede da organização no Brasil."
        ) {
          const UF = event.target.value.split("(")[1].split(")")[0];
          const UFId = UFsIBGE.find((item) => item.sigla === UF).id;
          IBGE.getCities(UFId).then((response) => setUFCities(response));
        }

        if (event.target.value === "") {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = null;
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = false;
        } else {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = event.target.value;
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = true;
        }
      } else {
        let totalOfEmployeesAccount = countTotalEmployees(
          event.target.parentNode.parentNode.firstChild.innerText,
          event
        );

        if (totalOfEmployeesAccount) {
          const totalEmployeesQuestion = generalQuestionsTrue.find(
            (question) =>
              question.text ===
              "Número total de funcionários e funcionárias da organização no Brasil."
          );
          if (totalEmployeesQuestion.answer === 0) {
            answers.find(
              (answer) => answer.idAnswer === totalEmployeesQuestion.idAnswer
            ).answer = null;
            answers.find(
              (answer) => answer.idAnswer === totalEmployeesQuestion.idAnswer
            ).answered = false;
          } else {
            answers.find(
              (answer) => answer.idAnswer === totalEmployeesQuestion.idAnswer
            ).answer = totalEmployees.toString();
            answers.find(
              (answer) => answer.idAnswer === totalEmployeesQuestion.idAnswer
            ).answered = true;
          }
        }

        if (event.target.value === "") {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = null;
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = false;
        } else {
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answer = event.target.value;
          answers.find(
            (answer) => answer.idAnswer === event.target.name
          ).answered = true;
        }
      }
    }

    countQuestions();
  }

  useEffect(() => {
    console.log(imagesUploading);
    if (imagesUploading === 0) {
      console.log("toast close");
      toast.closeAll();
    }
  }, [imagesUploading]);
  /**
   * @param {{type: string; value: string | File}[]} data
   * @param {string} idAnswer
   */
  async function createAnswersOfFiles(data, idAnswer) {
    const urls = data
      .filter((image) => image.type === "url")
      .map((image) => image.value);

    const files = data
      .filter((image) => image.type === "file")
      .map((image) => image.value);

    const links = [];
    links.push(...urls);

    for await (const file of files) {
      if (imagesUploading === 0) {
        toast({
          title: "Enviando imagens...",
          description: `Aguarde enquanto seus arquivos são enviados.`,
          status: "info",
          duration: null,
          isClosable: false,
        });
      }

      setImagesUploading((prev) => prev + 1);
      if (file.size > 5 * 1024 * 1024) {
        const message = `O arquivo ${file.name} não pode ser carregado por conta do tamanho. Diminua o arquivo e tente novamente. Limite de 5MB.`;
        alert(message);
      } else {
        let link = await save(file, doc);
        links.push(link.Location);
      }

      setImagesUploading((prev) => prev - 1);
    }
    const answered = !!links.length;
    answers.find((answer) => answer.idAnswer === idAnswer).answer = links;
    answers.find((answer) => answer.idAnswer === idAnswer).answered = answered;
    countQuestions();
  }

  function sendInfo(url) {
    if (isSubmitting) return;
    setSubmitting(true);

    const data = answers
      // .filter((answer) => answer.answer !== null)
      .map((answer) => ({
        ...answer,
        answer: Array.isArray(answer?.answer)
          ? answer?.answer.toString()
          : answer?.answer,
      }));

    apiForms
      .put("/api/answer/", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then(() => window.location.replace(url))
      .finally(() => setSubmitting(false));
  }

  function checkConfidential(answers) {
    if (answers.isConfidential === true) {
      return (
        <div
          style={{ display: "inline-flex", alignItems: "center", gap: "5px", marginTop: "15px", backgroundColor: "#eee", padding:"10px"}}
        >
          <input
            border="none"
            cursor="pointer"
            type="checkbox"
            name={answers.idAnswer}
            disabled={canEdit === "false" ? true : false}
            defaultChecked={answers.acceptConfidential}
            onClick={(e) => setAware(e)}
          />
          <label>Eu estou de acordo em compartilhar essas informações.</label>
        </div>
      );
    }
  }

  function countTotalEmployees(questionText, event) {
    if (
      questionText ===
      "Número de funcionárias do gênero feminino na organização no Brasil."
    ) {
      setTotalFemEmployess(Number(event.target.value));
      setTotalEmployees(
        totalFemEmployees + totalMascEmployees + totalNonBinaryEmployees
      );
      return true;
    } else if (
      questionText ===
      "Número de funcionários no gênero masculino na organização no Brasil."
    ) {
      setTotalMascEmployees(Number(event.target.value));
      setTotalEmployees(
        totalFemEmployees + totalMascEmployees + totalNonBinaryEmployees
      );
      return true;
    } else if (
      questionText ===
        "Número de funcionários e funcionárias de outros gênero na organização no Brasil." ||
      questionText ===
        "Número de funcionários e funcionárias de outros gêneros, que não se identificam com feminino ou masculino, na organização no Brasil."
    ) {
      setNonBinaryEmployees(Number(event.target.value));
      setTotalEmployees(
        totalFemEmployees + totalMascEmployees + totalNonBinaryEmployees
      );
      return true;
    }

    return false;
  }

  useEffect(() => {
    setTotalEmployees(
      totalFemEmployees + totalMascEmployees + totalNonBinaryEmployees
    );
  }, [totalFemEmployees, totalMascEmployees, totalNonBinaryEmployees]);

  useEffect(async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const formType = urlParams.get("form");
    const { document, key } = getUrlParams("not");
    setDocument(document);
    setCanEdit(key[0]);

    let mounted = true;
    setIsLoading(true);

    await apiForms
      .get(`/api/form-questions`)
      .then(async (resData) => {

        let isDiversityGlobal;

        resData.data.data.forEach(obj => {
            if (obj.type === formType) {
                isDiversityGlobal = obj.isDiversity;
            }
        });

        setShowBtnDownloadAnswers(!isDiversityGlobal);

        if (mounted) {
          setResponseData(
            resData.data.data.filter((data) => formType === data.type)[0]
          );
          apiForms
            .get(`/api/answer/create-and-get/${document}`)
            .then((res) => {
              if (mounted) {
                setResponseForms(
                  res.data.data.filter(
                    (data) => formType === data[0].form
                  )[0][0]
                );
              }
            })
            .catch((err) => {
              console.error("Erro no getFormsData, problema: ", err);
            });
        }
      })
      .catch((err) => {
        console.error("Erro no getQuestions, problema: ", err);
      });

    await apiForms.get(`/api/question-option`).then((res) => {

      const sortedData = res.data.data.sort((a, b) => {
        return parseInt(a.position) - parseInt(b.position);
      });

      setAnswerOptions(sortedData);
      dispatch({
        type: "FETCH_QUESTIONS_OPTIONS",
        questionsOptions: answerOptions,
      });
    });

    await apiForms
      .get(`/api/answer?idCompanyRegister=${document}`)
      .then(async (res) => {
        if (mounted) {
          const filteredQuestions = res.data.data.filter(
            (data) => data.form === formType
          );
          setRequiredNumber(
            filteredQuestions[0].result.filter(
              (question) => question.required === true
            ).length
          );
          setGeneralQuestions(
            filteredQuestions[0].result.sort(function (a, b) {
              return a.position - b.position;
            })
          );
          dispatch({
            type: "FETCH_QUESTIONS",
            questions: generalQuestionsTrue,
          });
        }
      })
      .catch((err) => {
        console.error("Erro no getQuestions, problema: ", err);
      })
      .finally(setIsLoading(false));

    return () => (mounted = false);
  }, []);
  

  useEffect(()=>{
    if (doc && responseData?.idFormQuestion) {
    apiForms
        .get(`/api/answer/last-update/${doc}/${responseData.idFormQuestion}`)
        .then((res) => {
          setUpdatedAt(res.data.data.updatedAt);
          setUpdatedBy(res.data.data.updatedBy);
        })
        .catch((err) => {
          console.error("Erro ao obter última atualização: ", err);
        });
    }
  } , [doc, responseData]
  )

  useEffect(() => {
    IBGE.getUFs().then((response) => setUFsIBGE(response));

    Object.keys(generalQuestionsTrue).forEach((key) => {
      getAnswers.push({
        idAnswer: generalQuestionsTrue[key].idAnswer,
        answer: generalQuestionsTrue[key].answer,
        answered: generalQuestionsTrue[key].answered,
        acceptConfidential: generalQuestionsTrue[key].acceptConfidential,
        isConfidential: generalQuestionsTrue[key].isConfidential,
      });
    });

    setAnswers(getAnswers);
    setInitialAnswers(getAnswers.map((answer) => ({ ...answer })));
    countQuestions();
  }, [generalQuestionsTrue]);

  useEffect(() => {
    if (generalQuestionsTrue.length > 0) {
      const femQuestion = generalQuestionsTrue.find(
        (question) =>
          question.text ===
          "Número de funcionárias do gênero feminino na organização no Brasil."
      );
      const mascQuestion = generalQuestionsTrue.find(
        (question) =>
          question.text ===
          "Número de funcionários no gênero masculino na organização no Brasil."
      );
      const nonBinaryQuestion = generalQuestionsTrue.find((question) => {
        return (
          question.text ===
            "Número de funcionários e funcionárias de outros gênero na organização no Brasil." ||
          question.text ===
            "Número de funcionários e funcionárias de outros gêneros, que não se identificam com feminino ou masculino, na organização no Brasil."
        );
      });
      if (femQuestion && mascQuestion && nonBinaryQuestion) {
        setTotalFemEmployess(Number(femQuestion.answer));
        setTotalMascEmployees(Number(mascQuestion.answer));
        setNonBinaryEmployees(Number(nonBinaryQuestion.answer));
      }

      const estadoSedeQuestion = generalQuestionsTrue.find(
        (question) =>
          question.text === "Estado da sede da organização no Brasil."
      );

      if (estadoSedeQuestion && estadoSedeQuestion.answer) {
        const UF = estadoSedeQuestion.answer.split("(")[1].split(")")[0];
        const UFId = UFsIBGE.find((item) => item.sigla === UF).id;
        IBGE.getCities(UFId)
          .then((response) => setUFCities(response))
          .finally(console.log("ufcities -> ", UFCities));
      }
    }
  }, [generalQuestionsTrue]);

  useEffect(() => {
    countQuestions();
  });

  function checkChanges() {
    for (let i = 0; i < answers.length; i++) {
      if (answers[i].answer !== initialAnswers[i].answer) {
        return true;
      }
    }
    return false;
  }

  // eslint-disable-next-line no-redeclare
  async function sendInfo(url) {
    if (isSubmitting) return;
    setSubmitting(true);

    const data = answers
      // .filter((answer) => answer.answer !== null)
      .map((answer) => ({
        ...answer,
        answer: Array.isArray(answer?.answer)
          ? answer?.answer.toString()
          : answer?.answer,
      }));

    try {
      await apiForms.put("/api/answer/", data, {
        headers: { "Content-Type": "application/json" },
      });
      const name = Cookies.get('user_email'); // Para ambiente de prod
      // const name = "criador" // teste envio
      // const name = "atualizador" // teste atualizacao
      // const name = Cookies.get('_ga'); // Para teste em ambiente dev
      if (checkChanges()) {
        await apiForms.post("/api/answer/add-form-status", {
          idCompanyRegister: doc,
          idFormQuestion: responseData.idFormQuestion,
          updatedBy: name,
          createdBy: name
          // createdBy: _ga, // Para teste em ambiente dev

        });
      }

      window.location.replace(url);
    } catch (error) {
      console.error("Erro ao enviar informações: ", error);
    } finally {
      setSubmitting(false);
    }
  }

  // Download Respostas
  const handleDownloadResponsesButtonClick = async () => {
    
    const params = new URLSearchParams(window.location.search);
    const url_company_info =
    window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
    `/company-register-info?cr=${params.get("cr")}`

    var participationCode = '';

    try {
      const company_info = await axios.get(url_company_info);
      
      participationCode = company_info.data.company.salesforce;
    } catch (error) {
      console.log('Erro ao buscar informações da empresa')
      return
    }

    console.log('Company: ' + participationCode)
    try {

      setIsLoading(true);
      await fetchExportQuestions(participationCode,true);

      toast({
        title: "Geração das respostas dos quetionários ocorreu com sucesso!",
        description: "O Download já foi iniciado.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Ocorreu um erro inesperado!",
        description:
          "Tente novamente mais tarde ou entre em contato com o suporte",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Accordion
        defaultIndex={[0]}
        allowMultiple
        display={["initial", "none"]}
        bg="white"
      >
        <AccordionItem p={0} borderBottom="1px solid #CCCCCC" bg="white">
          <Stack
            w="100%"
            position={"relative"}
            p={"20px 24px 24px 24px"}
            h="170px"
            align="flex-start"
            spacing="12px"
          >
            <Heading
              as="h2"
              fontSize={["20px", "24px"]}
              lineHeight={["34px", "54px"]}
            >
              {`${responseData.nameVersion}`}
            </Heading>

            {!!responseForms.totalQuestions && (
              <Text
                fontSize={"14px"}
                fontWeight={500}
                color="#5C6066"
              >{`${responseForms.totalQuestions} perguntas`}</Text>
            )}

            <Flex align={"center"} gap="12px" w="70%" ml="auto">
              <Button
                w="50%"
                color="white"
                h="40px"
                fontSize="14px"
                variant="primary"
                disabled={imagesUploading > 0 ? true : false}
                onClick={() =>
                  imagesUploading === 0
                    ? sendInfo(`/registration/profile?cr=${doc}&st=${canEdit}`)
                    : null
                }
              >
                {isSubmitting ? "Salvando..." : "Salvar"}
                {isSubmitting && <Spinner ml={2} />}
              </Button>
              <Button
                w="50%"
                h="40px"
                fontSize="14px"
                as="a"
                variant="secondary"
                href={`/registration/profile?cr=${doc}&st=${canEdit}`}
              >
                Voltar
              </Button>
            </Flex>
            <AccordionButton
              bg="none"
              p={0}
              position={"absolute"}
              bottom={"20px"}
              w="32px"
              right="16px"
            >
              <AccordionIcon bg="white" w="100%" />
            </AccordionButton>
          </Stack>

          <AccordionPanel pb={4}>
            <Stack spacing={"10px"}>
              <Flex h="15px" align="center" flexDir="row" gap={"13px"}>
                <Text
                  fontSize={"13px"}
                  fontWeight={500}
                  whiteSpace="nowrap"
                  w="25%"
                >
                  Respondidas
                </Text>
                <Box w="50%">
                  <ProgressBar
                    bgColor="#174BD6"
                    height="8px"
                    completed={`${answersNumber}`}
                    baseBgColor="#174BD6"
                    isLabelVisible={false}
                  />
                </Box>
                <Text fontSize="13px" fontWeight={600}>
                  {answersNumber}/
                  {responseForms.totalQuestions
                    ? responseForms.totalQuestions
                    : 0}
                </Text>
              </Flex>
              <Flex h="15px" align="center" flexDir="row" gap={"13px"}>
                <Text fontSize={"13px"} fontWeight={500} w="25%">
                  Obrigatórias
                </Text>
                <Box w="50%">
                  <ProgressBar
                    bgColor="#D50000"
                    height="8px"
                    completed={`${answersNumber}`}
                    baseBgColor="#D50000"
                    isLabelVisible={false}
                  />
                </Box>
                <Text fontSize={"13px"} fontWeight={600}>
                  {requiredAnswered}/{requiredNumber}
                </Text>
              </Flex>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Container
        maxW="1440px"
        background="#FFFFFF"
        p="30px"
        h="200px"
        display={["none", "block"]}
        style={{ position: "sticky", top: "0", zIndex: "9999" }}
      >
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          <GridItem colSpan={1}>
            <Heading
              as="h2"
              fontSize={["20px", "22px", "24px"]}
              lineHeight={["34px", "34px", "34px", "54px"]}
              borderBottom="1px"
              borderColor="#E5E5E5"
            >
              {`${responseData.nameVersion}`}
            </Heading>
            <Text
              fontSize={"14px"}
            >{`${responseForms.totalQuestions} perguntas`}</Text>
              {updatedAt && updatedBy && (
              <Text mt={4} fontSize="14px" color="gray.500" position="absolute" bottom={5} >
                Última atualização em {new Date(updatedAt).toLocaleString()} por {updatedBy}
              </Text>
            )}
          </GridItem>

          <GridItem colSpan={1}>
            <Flex
              width="100%"
              justify="center"
              gap={["40px", "40px", "80px"]}
              alignItems="center"
            >
              <Stack w="84px" h="95px" spacing="8px">
                <CircularProgressbar
                  value={`${answersNumber}`}
                  minValue={0}
                  strokeWidth={10}
                  maxValue={`${responseForms.totalQuestions}`}
                  text={
                    `${answersNumber}` +
                    "/" +
                    `${
                      responseForms.totalQuestions
                        ? responseForms.totalQuestions
                        : 0
                    }`
                  }
                  styles={buildStyles({
                    textSize: "font-size: 16px",
                    width: "71px",
                    height: "71px",
                    pathColor: `#174BD6`,
                    textColor: "#5C6066",
                    trailColor: "#C4C4C4",
                  })}
                />
                <Text whiteSpace="nowrap" fontSize="14px" lineHeight="16px">
                  Respondidas
                </Text>
              </Stack>
              <Stack w="84px" h="95px" spacing="8px">
                <CircularProgressbar
                  value={`${requiredAnswered}`}
                  minValue={0}
                  strokeWidth={10}
                  maxValue={`${requiredNumber}`}
                  text={`${requiredAnswered}` + "/" + `${requiredNumber}`}
                  styles={buildStyles({
                    textSize: "font-size: 16px",
                    width: "71px",
                    height: "71px",
                    pathColor: `#D50000`,
                    textColor: "#5C6066",
                    trailColor: "#C4C4C4",
                  })}
                />
                <Text whiteSpace="nowrap" fontSize="14px" lineHeight="16px">
                  Obrigatórias
                </Text>
              </Stack>
            </Flex>
          </GridItem>
          <GridItem colSpan={1}>
            <Flex
              flexDir="column"
              alignItems="flex-end"
              gap="12px"
              w="100%"
              justify="flex-end"
            >
              <Button
                w={["100%", "220px", "265px"]}
                color="white"
                lineHeight={"19px"}
                borderRadius="10px"
                backgroundColor="#E60000"
                disabled={imagesUploading > 0 ? true : false}
                onClick={() =>
                  imagesUploading === 0
                    ? sendInfo(`/registration/profile?cr=${doc}&st=${canEdit}`)
                    : null
                }
              >
                {isSubmitting ? "Salvando..." : "Salvar"}
                {isSubmitting && <Spinner ml={2} />}
              </Button>
              <Button
                w={["100%", "220px", "265px"]}
                as="a"
                color="#E60000"
                variant="outline"
                borderRadius={"12px"}
                href={`/registration/profile?cr=${doc}&st=${canEdit}`}
              >
                Voltar
              </Button>
              { (answersNumber > 0 && showBtnDownloadAnswers) && (
              <Button
                w={["100%", "220px", "265px"]}
                as="a"
                color="#E60000"
                variant="outline"
                borderRadius={"12px"}
                onClick={() => handleDownloadResponsesButtonClick()}
                isDisabled={isLoading}
              >
                 {isLoading ? 'Preparando Download...' : 'Download Respostas'}
              </Button>
              )}
            </Flex>
          </GridItem>
        </Grid>
      </Container>

      <Container
        maxW={[
          "container.xl",
          "container.xl",
          "container.xl",
          "80%",
          "container.lg",
        ]}
        mt={["14px", "30px"]}
        p={[0, "unset"]}
      >
        {generalQuestionsTrue.length
          ? generalQuestionsTrue.map((question, index) => (
              <QuestionCard key={index + question.type} question={question}>
                {question.typeForm === "CHECKBOX" && (
                  <Grid
                    style={{
                      marginTop: "20px",
                      display: "grid",
                    }}
                  >
                    {filterOptions(
                      answerOptions,
                      question.idQuestionOption
                    ).map((option, i) => {
                      return (
                        <span key={i} style={{ marginRight: "10px" }}>
                          <input
                            key={question.idAnswer}
                            name={question.idAnswer}
                            value={option.text}
                            type="checkbox"
                            onInput={(e) => createAnswers(e)}
                            defaultChecked={
                              question.answer &&
                              question.answer.includes(option.text)
                            }
                            disabled={canEdit === "false" ? true : false}
                          />
                          <label> {option.text}</label>
                        </span>
                      );
                    })}

                    <HelpText help={question?.help} />
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "RADIO" && (
                  <Grid
                    row
                    style={{
                      marginTop: "20px",
                      display: "grid",
                    }}
                  >
                    {filterOptions(
                      answerOptions,
                      question.idQuestionOption
                    ).map((option, i) => {
                      return (
                        <span key={i} style={{ marginRight: "10px" }}>
                          <input
                            key={question.idAnswer}
                            name={question.idAnswer}
                            value={option.text}
                            type="radio"
                            onInput={(e) => createAnswers(e)}
                            defaultChecked={
                              question.answer &&
                              question.answer.includes(option.text)
                            }
                            disabled={canEdit === "false" ? true : false}
                          />
                          <label> {option.text} </label>
                        </span>
                      );
                    })}
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "CITYSELECT" && (
                  <Grid
                    row
                    style={{
                      marginTop: "20px",
                      display: "grid",
                    }}
                  >
                    <span style={{ marginRight: "10px" }}>
                      <Select
                        key={question.idAnswer}
                        name={question.idAnswer}
                        type="input"
                        onChange={(e) => createAnswers(e)}
                        bg="white"
                        borderColor="#b8cae8"
                        color="black"
                      >
                        <option value="">...</option>
                        {UFCities.length > 0
                          ? UFCities.map((city) => {
                              return (
                                <option
                                  value={city.nome}
                                  selected={
                                    city.nome === question.answer ? true : false
                                  }
                                >
                                  {city.nome}
                                </option>
                              );
                            })
                          : ""}
                      </Select>
                    </span>
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "TEXT" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <Input
                      key={question.idAnswer}
                      name={question.idAnswer}
                      width={question.width}
                      placeholder={question.placeholder}
                      height={question.height}
                      onInput={(e) => createAnswers(e)}
                      defaultValue={question.answer}
                      readOnly={canEdit === "false" ? true : false}
                    />
                    <HelpText help={question?.help} />
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "CURRENCYBRL" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <InputMask
                      mask="R$9999999999999999999999999999"
                      maskChar=" "
                      formatChars={{
                        9: "[0-9,]",
                      }}
                      key={question.idAnswer}
                      name={question.idAnswer}
                      width={question.width}
                      placeholder={question.placeholder}
                      height={question.height}
                      onInput={(e) => createAnswers(e)}
                      defaultValue={question.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={question.placeholder}
                        />
                      )}
                    </InputMask>
                    <HelpText help={question?.help} />
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "CURRENCYUSA" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <InputMask
                      mask="$9999999999999999999999999999"
                      maskChar=" "
                      formatChars={{
                        9: "[0-9,]",
                      }}
                      key={question.idAnswer}
                      name={question.idAnswer}
                      width={question.width}
                      placeholder={question.placeholder}
                      height={question.height}
                      onInput={(e) => createAnswers(e)}
                      defaultValue={question.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={question.placeholder}
                        />
                      )}
                    </InputMask>
                    <HelpText help={question?.help} />
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "NUMBER" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <InputMask
                      mask="9999999999"
                      maskChar=" "
                      formatChars={{
                        9: "[0-9]",
                      }}
                      key={question.idAnswer}
                      name={question.idAnswer}
                      width={question.width}
                      placeholder={question.placeholder}
                      height={question.height}
                      onInput={(e) => createAnswers(e)}
                      defaultValue={question.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={question.placeholder}
                        />
                      )}
                    </InputMask>
                    <HelpText help={question?.help} />
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "CNPJ" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <InputMask
                      mask="99.999.999/9999-99"
                      {...props}
                      onInput={(e) => createAnswers(e)}
                      name={question.idAnswer}
                      key={question.idAnswer}
                      defaultValue={question.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={question.placeholder}
                        />
                      )}
                    </InputMask>
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "SITE" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <InputMask
                      mask="https://aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
                      maskChar=" "
                      formatChars={{
                        a: "[a-z0-9-./]",
                      }}
                      {...props}
                      onInput={(e) => createAnswers(e)}
                      name={question.idAnswer}
                      key={question.idAnswer}
                      defaultValue={question.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={question.placeholder}
                        />
                      )}
                    </InputMask>
                    <HelpText help={question?.help} />
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "EMAIL" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <InputMask
                      {...props}
                      onInput={(e) => createAnswers(e)}
                      name={question.idAnswer}
                      key={question.idAnswer}
                      defaultValue={question.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={question.placeholder}
                        />
                      )}
                    </InputMask>
                    <HelpText help={question?.help} />
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "PHONE" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <InputMask
                      {...props}
                      mask="+99(99)99999-9999"
                      maskChar=" "
                      onInput={(e) => createAnswers(e)}
                      name={question.idAnswer}
                      key={question.idAnswer}
                      defaultValue={question.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={question.placeholder}
                        />
                      )}
                    </InputMask>
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "DATA" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <InputMask
                      mask="99/99/9999"
                      {...props}
                      onInput={(e) => createAnswers(e)}
                      name={question.idAnswer}
                      key={question.idAnswer}
                      defaultValue={question.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={question.placeholder}
                        />
                      )}
                    </InputMask>
                    <HelpText help={question?.help} />
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "YEAR" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <InputMask
                      mask="9999"
                      {...props}
                      onInput={(e) => createAnswers(e)}
                      name={question.idAnswer}
                      key={question.idAnswer}
                      defaultValue={question.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={question.placeholder}
                        />
                      )}
                    </InputMask>
                    <HelpText help={question?.help} />
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "PERCENTAGE" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <InputMask
                      mask="999999"
                      maskChar=" "
                      formatChars={{
                        9: "[0-9,]",
                      }}
                      {...props}
                      onInput={(e) => createAnswers(e)}
                      name={question.idAnswer}
                      key={question.idAnswer}
                      defaultValue={question.answer}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={question.placeholder}
                        />
                      )}
                    </InputMask>
                    <HelpText help={question?.help} />
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "SOMAFUNCIONARIOS" && (
                  <Grid column>
                    <Label fontSize="14px">{question.label}</Label>
                    <InputMask
                      // mask="9999999"
                      {...props}
                      onInput={(e) => createAnswers(e)}
                      name={question.idAnswer}
                      key={question.idAnswer}
                      value={totalEmployees}
                      readOnly={canEdit === "false" ? true : false}
                    >
                      {(inputProps) => (
                        <Input
                          {...props}
                          {...inputProps}
                          placeholder={question.placeholder}
                          value={totalEmployees}
                        />
                      )}
                    </InputMask>
                    <HelpText help={question?.help} />
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "FILE" && (
                  <Grid column>
                    <label
                      htmlFor={question.idAnswer}
                      style={{ display: "none" }}
                    >
                      {index + "FILE"}
                    </label>
                    <ChakraInput
                      border="none"
                      mt="10px"
                      fontSize="15px"
                      key={question.idAnswer}
                      name={question.idAnswer}
                      type="file"
                      accept=".jpeg, .png, .jpg"
                      onInput={(e) => createAnswers(e)}
                      readOnly={canEdit === "false" ? true : false}
                    />

                    {imageLoading && (
                      <>
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                        />
                      </>
                    )}

                    {question.answered && (
                      <Box maxW="200px" mt="20px">
                        <Image src={question.answer} alt="load" />
                      </Box>
                    )}
                    {/* 
                    {imageUrl.length > 2 && (
                      <Box maxW="200px" mt="20px">
                        <Image src={imageUrl} alt="load" />                        
                      </Box>
                    )} */}
                    {checkConfidential(question)}
                  </Grid>
                )}

                {question.typeForm === "FILES" && (
                  <Grid column>
                    <label
                      htmlFor={question.idAnswer}
                      style={{ display: "none" }}
                    >
                      {index + "FILE"}
                    </label>
                    <CustomInputImage
                      mt="10px"
                      multiple
                      key={question.idAnswer}
                      name={question.idAnswer}
                      readOnly={canEdit === "false" ? true : false}
                      values={question.answer ? question.answer.split(",") : ""}
                      onChange={(data) =>
                        createAnswersOfFiles(data, question.idAnswer)
                      }
                    />

                    {checkConfidential(question)}
                  </Grid>
                )}
                {!!question?.help ? (
                  <Box
                    as="button"
                    position="absolute"
                    top="16px"
                    right="16px"
                    onClick={() => sendAlert(question?.help)}
                  >
                    <Icon type="alert-gray" />
                  </Box>
                ) : null}
              </QuestionCard>
            ))
          : null}
      </Container>
    </>
  );
}

const HelpText = ({ help }) => {
  console.log("🚀 ~ help:", help);

  const hasHelpText = !!help && help?.length > 2;

  return hasHelpText ? (
    <Text
      display={["none", "inherit"]}
      fontSize="13px"
      mt="10px"
      lineHeight="20px"
    >
      Dica: {help}
    </Text>
  ) : null;
};

export default InfoOrganizacao;
