import React, { useState } from "react";
import { Button, Image, useToast } from "@chakra-ui/react";
import { IconXLSX } from "../../../assets";
import { downloadExcelFile } from "../helper/functionConvertEndDownload";
import { fetchExportStatus } from "../../../../../hooks/Admin/useCompanyRegisterList";

const ButtonExportXLSX = ({ meta, ...props }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const handleDownload = async () => {
    try {
      setIsLoading(!isLoading);
      const { data } = await fetchExportStatus(meta);
      downloadExcelFile(data, "Status dos Clientes");
      toast({
        title: "Exportar os Status para XLSX ocorreu com sucesso!",
        description: "O Download já foi iniciado.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Ocorreu um erro inesperado!",
        description:
          "Tente novamente mais tarde ou entre em contato com o suporte",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      onClick={handleDownload}
      variant="outline"
      fontWeight={700}
      p="8px 16px"
      minH="48px"
      minW="100%"
      isLoading={isLoading}
      leftIcon={
        <Image src={IconXLSX} alt="icons XLSX" w="32px" h="32px" mr="10px" />
      }
      color="#107C41"
      border="1px solid #DEE5E4"
      background="#F5F8F6"
      borderRadius="none"
      _hover={{
        border: "1px solid #A6AEB2",
        background: "#DEE5E4",
      }}
      sx={{
        span: {
          margin: 0,
        },
      }}
      {...props}
    >
      Exportar XLSX
    </Button>
  );
};

export default ButtonExportXLSX;
