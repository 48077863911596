import styled from "styled-components";

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  height: 200px;
  width: 200px;
  margin-top: 10px;
  padding:6px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 6px;
  background-color: white;

  h1 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: auto;

  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    color: white;
  }
`