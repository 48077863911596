export function CNPJconverter(cnpj) {
  // Remove caracteres não numéricos
  const cnpjFormatado = !!cnpj ? cnpj.replace(/\D/g, "") : "00";

  if (cnpjFormatado?.length === 14) {
    // Se o CNPJ tem 14 dígitos, converte para o formato com pontuações
    return `${cnpjFormatado.slice(0, 2)}.${cnpjFormatado.slice(
      2,
      5
    )}.${cnpjFormatado.slice(5, 8)}/${cnpjFormatado.slice(
      8,
      12
    )}-${cnpjFormatado.slice(12)}`;
  } else if (cnpjFormatado.length === 18) {
    // Se o CNPJ tem 18 caracteres, converte para o formato sem pontuações
    return cnpjFormatado.replace(/\D/g, "");
  } else {
    // Caso contrário, o formato do CNPJ é inválido
    console.error(
      "Formato de CNPJ inválido. Deve conter 14 dígitos ou 18 caracteres."
    );
    return null; // Retorna null se o formato do CNPJ for inválido
  }
}
