import {
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Button,
  Tr,
  Th,
  Td,
  TableContainer,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import { TbDotsVertical } from "react-icons/tb";
import Loading from "../components/loading";
import React,{useState} from "react";
import SimpleModal from "../components/modal/SimpleModal";

const RankingSummariesTable = ({ summaries }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => {
    setIsOpen(false)
  }
  return summaries.length > 0 ? (
    <TableContainer>
      <SimpleModal
        title="Opção não disponível para esse plano"
        text="Você pode visualizar os resultados pela opção Trust Index."
        isOpen={isOpen}
        onClose={onClose}
      />
      {isLoading ? <Loading/> : 
      <Table
        borderRadius={"2px"}
        variant="simple"
        color="#292B30"
        fontFamily="Raleway"
        border="1px"
        borderColor="#F4F4F4"
        bg="white"
      >
        <Thead
          h="46px"
          background="rgba(0, 0, 0, 0.1)"
          color="#292B30"
          boxShadow="0px 1px 4px rgba(0, 0, 0, 0.1)"
        >
          <Tr className="custom-th" fontWeight={700} borderColor="#F4F4F4">
            <Th w="20px"color="rgba(41, 43, 48, 1)">Nome Ranking</Th>
            <Th w="20px"color="rgba(41, 43, 48, 1)">Download</Th>
          </Tr>
        </Thead>
        <Tbody>          
          { summaries.map((summary, index) => { 
            return ( <TableRowCustom
              key={index}
              columnName={summary.split("/")[6].split(".")[0].replace(/["_"]/g, " ")}
              certified={true}
              link={summary}
              checkIsLoading={setIsLoading}
              setIsOpen={setIsOpen}
            /> )
          })}
        </Tbody>
      </Table>}
    </TableContainer>
  ) : (
    <Flex h="60px" align="center" justify="center">
      <Text fontWeight={600}>Nenhuma pesquisa com resultados disponíveis foram encontradas</Text>
    </Flex>
  );
};

const TableRowCustom = ({ columnName, link, checkIsLoading, setIsOpen }) => {

  return (
    <Tr fontSize="12px" maxH="46px">
      <Td 
        pl="32px"
        pr="10px"
        whiteSpace={"nowrap"}
        maxW="200px"
        color="rgba(41, 43, 48, 1)"
        fontWeight={600}
        fontSize="13px"
        lineHeight={"15px"}
        textDecor="underline"
      >
        {columnName}
      </Td>

      <Td 
        w="13px"
        pl="32px"
        pr="10px"
        whiteSpace={"nowrap"}
        maxW="200px"
        color="rgba(41, 43, 48, 1)"
        fontWeight={600}
        fontSize="13px"
        lineHeight={"15px"}  
      >
        <Button
            w={["100%", "100px"]}
            color="#E60000"
            border="1px"
            borderColor="red.100"
            borderRadius="10px"
            variant="ghost"
            _hover={{
              bg: "#E60000",
              color: "white",
            }}
            fontSize="13px"
            aria-label="Clique Aqui"
            onClick={()=> window.open(link, "_blank")}
        >Clique Aqui</Button>
      </Td>

    </Tr>

  );
};

export default RankingSummariesTable;