import {
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Button,
  Tr,
  Th,
  Td,
  TableContainer,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import { TbDotsVertical } from "react-icons/tb";
import { downloadComments } from "../../services/downloadComments";
import { downloadReport } from "../../services/downloadReport";
import { downloadTrustIndex} from "../../services/downloadTrustIndex";
import { downloadAffirmatives} from "../../services/downloadAffirmatives";
import Loading from "../../app/components/loading";
import React,{useState} from "react";
import SimpleModal from "../components/modal/SimpleModal";

const ResultsTable = ({ researchsList }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => {
    setIsOpen(false)
  }
  return researchsList.length > 0 ? (
    <TableContainer>
      <SimpleModal
        title="Opção não disponível para esse plano"
        text="Você pode visualizar os resultados pela opção Trust Index."
        isOpen={isOpen}
        onClose={onClose}
      />
      {isLoading ? <Loading/> : 
      <Table
        borderRadius={"2px"}
        variant="simple"
        color="#292B30"
        fontFamily="Raleway"
        border="1px"
        borderColor="#F4F4F4"
        bg="white"
      >
        <Thead
          h="46px"
          background="rgba(0, 0, 0, 0.1)"
          color="#292B30"
          boxShadow="0px 1px 4px rgba(0, 0, 0, 0.1)"
        >
          <Tr className="custom-th" fontWeight={700} borderColor="#F4F4F4">
            <Th w="20px"color="rgba(41, 43, 48, 1)">Código da Participação</Th>
            <Th>Período da pesquisa</Th>
            <Th w="14px">Nota</Th>
            <Th>Resultado</Th>
            <Th>Expira em</Th>
            <Th>Arquivos</Th>
          </Tr>
        </Thead>
        <Tbody>          
          {researchsList.map((research, index) => (            
            <TableRowCustom
              key={index}
              columnName={research.participationCode}
              certified={true}
              research={research}
              checkIsLoading={setIsLoading}
              setIsOpen={setIsOpen}
            />
          ))}
        </Tbody>
      </Table>}
    </TableContainer>
  ) : (
    <Flex h="60px" align="center" justify="center">
      <Text fontWeight={600}>Nenhuma pesquisa com resultados disponíveis foram encontradas</Text>
    </Flex>
  );
};

const TableRowCustom = ({ columnName, certified, research, checkIsLoading, setIsOpen }) => {
  const endDate = new Date(research.surveyEndDate.split('-').reverse().join('-')).getTime() + 86399999;
  
  return (
    <>
      {(endDate < Date.now()) && (Date.now() < endDate + 172800000) && (research.status !== 'APPROVED') ? (<></>) : research.status === 'AUDITING' ? (<></>) : (
        <Tr fontSize="12px" maxH="46px">
          <Td 
            pl="32px"
            pr="10px"
            whiteSpace={"nowrap"}
            maxW="200px"
            color="rgba(41, 43, 48, 1)"
            fontWeight={600}
            fontSize="13px"
            lineHeight={"15px"}
            textDecor="underline"
          >
            {columnName}
          </Td>
    
          <Td w="75px">
            {research.surveyStartDate === null ? (
              <>-- a --</>
            ) : (
              <Flex gap="4px">
                {research.surveyStartDate}
                <Text>a</Text>
    
                {research.surveyEndDate}
              </Flex>
            )}
          </Td>
    
          <Td w="13px">
            {!!research ? research.ti : "--"}
          </Td>
    
          <Td w="75px" >
            {research.ti >= 70 && research.minimumSample === "true"  ? (
              <Tag
                fontSize="13px"
                bg="#40BF91"
                color="white"
                borderRadius={"6px"}
                fontWeight={600}
                py="4px"
                px="8px"
              >
                Certificado
              </Tag>
            ) : (
              <Tag
                fontSize="13px"
                bg="red.100"
                color="white"
                borderRadius={"6px"}
                fontWeight={600}
                py="4px"
                px="8px"
              >
                Não certificado
              </Tag>
            )}
          </Td>
    
          <Td w="13px">
            {research.expirationDate}
          </Td>
    
          <Td textAlign={"center"} w="51px">
            <DoprodownButton research={research} checkIsLoading={checkIsLoading} setIsOpen={setIsOpen}  />
          </Td>
    
        </Tr>

      )}
    </>

  );
};

export default ResultsTable;

const DoprodownButton = ({research, checkIsLoading, setIsOpen}) => {  
  return (
    <Menu>
      <MenuButton
        as={Button}
        py="3.75px"
        bg="#E5E5E5"
        w="20px"
        minW={0}
        maxW="20px"
        h="20px"
        p={0}
      >
        <TbDotsVertical color="black" />
      </MenuButton>
      <MenuList
        w="155px"
        maxW="155px"
        h="100%"
        borderRadius={"8px"}
        background="white"
        border="1px solid #F4F4F4"
      >
        <MenuItem py="10px" onClick={() => {
          checkIsLoading(true)
          downloadReport(research, 'pt_BR').then(() => {
          checkIsLoading(false)
        })}}>Relatório da Pesquisa (Pt-Br)</MenuItem>
        <MenuItem py="10px" onClick={() => {
          checkIsLoading(true)
          downloadReport(research, 'en').then(() => {
          checkIsLoading(false)
        })}}>Relatório da Pesquisa (ENG)</MenuItem>
        <MenuItem py="10px" onClick={() => {
          checkIsLoading(true)
          downloadReport(research, 'es').then(() => {
          checkIsLoading(false)
        })}}>Relatório da Pesquisa (ES)</MenuItem>
        {!(research.plan.search('PREMIUM') > -1) ? (<MenuItem py="10px" onClick={() => {
          checkIsLoading(true)
          downloadAffirmatives(research, setIsOpen).then(() => {
          checkIsLoading(false)
        })}}>Notas Gerais da Pesquisa </MenuItem>) : ''}
        <MenuItem py="10px" onClick={() => {
          checkIsLoading(true)
          downloadComments(research).then(() => {
          checkIsLoading(false)
        })}}>Caderno de comentários</MenuItem>
        <MenuItem py="10px" onClick={() => {
          checkIsLoading(true)
          downloadTrustIndex(research).then(() => {
          checkIsLoading(false)
        })}}>Trust Index</MenuItem>
      </MenuList>
    </Menu>
  );
};