import { Stack, Text, Flex, Image, useMediaQuery, Link } from "@chakra-ui/react";
import welcomeCard from "../../assets/images/card-dashboard.svg";
import whiteImage from "../../assets/images/people.svg";

export default function WelcomeCard({ currentUser, backgroundActive }) {
  const isLargerThan1280 = useMediaQuery("(min-width: 1280px)");
  console.log(isLargerThan1280[0]);

  return (
    <Flex
      w={"100%"}
      h={["107px", "107px", "136px"]}
      justify={"space-between"}
      align="center"
      pos="relative"
      borderRadius={"12px"}
      bgColor={backgroundActive ? "white" : "#e2e1e7"}
    >
      <Stack
        color="#292B30"
        spacing={["4px", "4px", "6px", "16px"]}
        p={["16px", "16px", "16px", "24px"]}
      >
        <Text
          fontSize={["12px", "18px", "24px"]}
          lineHeight={["30px", "28px"]}
          fontWeight={700}
        >
          Seja bem-vindo(a) ao Connect!
        </Text>

        <Text
          fontSize={["11px", "12px", "13px", "14px"]}
          lineHeight={["25px", "22px"]}
        >
          Participe do <b>Encontro de Onboarding</b> e <b>Plantão de Dúvidas</b> na sexta-feira às 10h, ao vivo. 
          <Text lineHeight="28px">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://conteudo.gptw.com.br/plantao-de-duvidas-gptw"
            >
              <Text
                as="span"
                color="blue"
                textDecor="underline"
                fontWeight={600}
              >
                Clique aqui
              </Text>{" "}
              </a>{" "}
              e acesse o link.
          </Text>        
        </Text>

      </Stack>

      <Image
        src={[isLargerThan1280[0] ? whiteImage : welcomeCard]}
        alt=""
        bottom={0}
        h={["100px", "100px", "136px", "136px"]}
        mt={["6px", "6px", "0px"]}
        mr={["10px", "14px", "16px"]}
      />
    </Flex>
  );
}
