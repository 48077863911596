import { useQuery } from "react-query";
import { researchApi } from "../services/api-research";

const fetchResearchInfoData = async (companyId) => {
  const { data } = await researchApi.get(`/research/researchInfo/${companyId}`);
  return data;
};

export const useResearchInfoDataQuery = (companyId) => {
  return useQuery(
    ["research-info", companyId],
    () => fetchResearchInfoData(companyId),
    {
      enabled: !!companyId,
      refetchOnWindowFocus: false,
    }
  );
};
