import { extendTheme } from "@chakra-ui/react";

const Menu = {
  baseStyle: {
    item: {
      paddingBlock: "10px",
      fontWeight: 500,
      fontSize: "14px",
      color: "black.100",
      lineHeight: "16px",
      _hover: {
        bg: "none",
      },
    },
  },
};

export const theme = extendTheme({
  components: {
    Menu,
    FormLabel: {
      baseStyle: {
        fontWeight: "400",
        margin: 0,
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 700,
      },
      variants: {
        primary: {
          bg: "red.100",
          color: "white",
          borderRadius: "10px",
          _disabled: {
            bg: "rgba(41, 43, 48, 0.8)",
            cursor: "not-allowed",
          },
        },
        secondary: {
          bg: "none",
          color: "red.100",
          border: "1px",
          borderRadius: "10px",
        },
        naked: {
          bg: "none",
          color: "#E60000",
          borderRadius: 0,
          fontWeight: 600,
        },
        navlink: {
          padding: 0,
          color: "#E60000",
          fontSize: "12px",
          fontWeight: 600,
          textDecor: "underline",
        },
        red: {
          bg: "#E60000",
          color: "#fff",
          border: "none",
          borderRadius: "none",
          lineHeight: "11px",
          fontWeight: 700,
          minH: "48px",
          minW: "fit-content",
          p: "18.5px 24px",
          transitions: "all 0.5",
          _hover: {
            transitions: "all 0.5",
            opacity: 0.8,
          },
        },
        outlineRed: {
          bg: "#fff",
          color: "#E60000",
          border: "1.5px solid #E60000",
          borderRadius: "none",
          lineHeight: "11px",
          fontWeight: 700,
          minH: "48px",
          minW: "fit-content",
          p: "18.5px 24px",
          transitions: "all 0.5",
          _hover: {
            transitions: "all 0.5",
            opacity: 0.8,
            bg: "#F5F8F6",
          },
        },
        invisible: {
          bg: "#fff",
          color: "#11131C",
          border: "none",
          borderRadius: "none",
          lineHeight: "11px",
          fontWeight: 700,
          minH: "48px",
          minW: "fit-content",
          p: "18.5px 24px",
          transitions: "all 0.5",
          _hover: {
            color: "#E60000",
            transitions: "all 0.5",
          },
        },
      },
    },
  },
  colors: {
    red: {
      100: "#E60000",
    },
    black: {
      100: "#292B30",
      200: "#011F3B",
    },
    gray: {
      500: "#5C6066",
      400: "#E5E5E5",
      300: "#9699B0",
      200: "#B3B5C6",
      150: "#ced4da",
      100: "#F5F5FB",
      50: "#EEEEF2",
      10: "#F9F9FA",
    },
    white: {
      100: "#ffffff",
    },
    green: {
      100: "#70e2cb",
      200: "#40BF91",
      900: "#445D72",
    },
    teal: {
      200: "#718F94",
    },
    blue: {
      400: "#8AA2A9",
      500: "#174BD6",
    },
    purple: {
      100: "#F6E4F6",
    },
    orange: {
      100: "#FF6700",
    },
  },
  fonts: {
    heading: "Raleway",
    body: "Raleway",
  },
  styles: {
    global: {
      body: {
        bg: "#E5E5E5",
        color: "#292B30",
      },
    },
  },
});
