/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext } from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Tag,
  Text,
  Button,
  Image,
  useMediaQuery,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useLanguage } from "../../../helpers/useLanguageInfo";
import { CustomContext } from "../../../context/CustomContext";
import WaitImportRespondentModal from "./Modals/WaitImportRespondentModal";

import managers from "../../../assets/images/managers.svg";
import iconReady from "../../../assets/images/icon-ready.svg";
import colaboradores from "../../../assets/images/colaboradores.svg";
import companyName from "../../../assets/images/company-name.svg";

import LanguageModal from "./Modals/LanguageModal";

const LanguageData = ({ researchId, importRespondentIsRunning, totalRespondents, extern_researchId }) => {
  const { custom } = useContext(CustomContext);
  const [mobile] = useMediaQuery('(min-width: 1024px)')

  const [modal, setModal] = useState(false);
  const [languageData, setLanguageData] = useState(false);
  const [info, setInfo] = useState({});
  const { data } = useLanguage(researchId);
  const [isOpenWaitImportModal, setIsOpenWaitImportModal] = useState(false);

  useEffect(() => {
    if (data instanceof Array && data.length > 0) {
      setLanguageData(true);
      setInfo(data[0]);
    }
  }, [data]);

  const handleLanguageModal = () => {
    if (custom) {
      if(importRespondentIsRunning && (totalRespondents>0 || extern_researchId!=null)){
        setIsOpenWaitImportModal(true);
      } else {
        setModal(true);
      }
    } else return;
  };

  const handleCloseModalWaitImport = () => {
    setIsOpenWaitImportModal(false);
  };

  const handleCloseLanguageModal = () => {
    setModal(false);
  };

  return !mobile ? (
    <Accordion
      defaultIndex={[1]}
      allowMultiple
      maxW="100%"
   
      p={["26px 22px 32px 22px", 6]}
      borderBlock="1px solid #E5E5E5"
    >
     <LanguageModal
        data={data}
        researchId={researchId}
        isOpen={modal}
        onClose={handleCloseLanguageModal}
      />
      <WaitImportRespondentModal isOpen={isOpenWaitImportModal} onClose={handleCloseModalWaitImport}  text=''/>
   <AccordionItem border="none" position={"relative"}>
        <Tag
          fontSize="16px"
          bg="#e5ebec"
          color="#002070"
          borderRadius="20px"
          h="23px"
          px="12px"
          fontWeight="600"
        >
          Linguagem organizacional
        </Tag>
        <Text fontSize="13px" lineHeight={"24px"} pt="8px" maxW="85%">
          É possível <b> adequar alguns dos termos </b>
          que serão usados na Pesquisa de Clima para se adaptar melhor a
          realidade das <b>terminologias</b> utilizadas na sua empresa.
        </Text>
        <AccordionButton
          bg="gray.400"
          w="32px"
          right={0}
          top="40px"
          p={0}
          justifyContent="center"
          h="32px"
          rounded={"full"}
          position={"absolute"}
        >
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p={0}>
          <Items languageData={languageData} info={info} />
        </AccordionPanel>
      </AccordionItem>
      <Button
        w="100%"
        mt="24px"
        h="48px"
        variant="secondary"
        color={!custom ? "#292B30" : "red.100"}
        disabled={!custom}
        borderColor={!custom ? "#292B30" : "red.100"}
        onClick={handleLanguageModal}
      >
        {languageData ? "Editar" : "Configurar"}
      </Button>
    </Accordion>
  ) : (
    <GridItem
      w="100%"
      h='auto'
      border="1px solid #CCCCCC"
      borderRadius="20px"
      p="20px"
    >
      <LanguageModal
        data={data}
        researchId={researchId}
        isOpen={modal}
        onClose={handleCloseLanguageModal}
      />
      <WaitImportRespondentModal isOpen={isOpenWaitImportModal} onClose={handleCloseModalWaitImport} text='' />
      <Flex justify={"space-between"} align="center">
        <Box>
          <Tag
            bg="#e5ebec"
            color="#002070"
            borderRadius="20px"
            fontSize="16px"
            h="23px"
            px="15px"
            fontWeight="600"
          >
            Linguagem organizacional
          </Tag>
          <Text fontSize="14px" pt={1} mt="8px">
            É possível <b> adequar alguns dos termos </b>
            que serão usados na Pesquisa de Clima para se adaptar melhor a
            realidade
            <br />
            das <b>terminologias</b> utilizadas na sua empresa.
          </Text>
        </Box>
        <Button
          w="175px"
          variant="secondary"
          color={!custom ? "#292B30" : "red.100"}
          disabled={!custom}
          borderColor={!custom ? "#292B30" : "red.100"}
          onClick={handleLanguageModal}
        >
          {languageData ? "Editar" : "Configurar"}
        </Button>
      </Flex>

      <Items languageData={languageData} info={info} />
    </GridItem>
  );
};

const Items = ({ languageData, info, mobile }) => {
  return (
    <Grid
      templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(3, 1fr)"]}
      gap={["12px", 6]}
      mt="25px"
    >
      {languageData ? (
        <GridItem
          w="100%"
          h={["95px", "140px"]}
          border="1px"
          py="30px"
          px="20px"
          borderColor="#40BF91"
          borderRadius="12px"
        >
          <Image src={iconReady} alt="true" h="20px" w="20px" mb="5px" />
          <Text fontSize="18px" color="#292B30" fontWeight="700">
            {info.manager}
          </Text>
          <Box h="2px" w="25px" bg="red.100" borderRadius="5px" />
          <Text fontSize="14px" color="#5C6066" mt="8px">
            São os nossos
            <Text as="span" fontWeight="600" ml="5px">
              gestores
            </Text>
          </Text>
        </GridItem>
      ) : (
        <GridItem
          w="100%"
          h={["95px", "140px"]}
          border="1px dashed #5C6066"
          borderColor="#5C6066"
          fontSize={["13px", "14px"]}
          borderRadius={["10px", "12px"]}
        >
          <Flex alignItems="center" pl="20px" h="100%">
            <Box flex={2} alignItems="center">
              {mobile ? (
                <Text>
                  Configure como são chamados os <b> gestores </b>
                  da sua empresa
                </Text>
              ) : (
                <Text lineHeight={"20px"}>
                  Configure como são
                  <br />
                  chamados os <b> gestores </b>
                  <br />
                  da sua empresa
                </Text>
              )}
            </Box>
            <Flex flex={1}>
              <Image w={["79px", "auto"]} src={managers} alt="character" />
            </Flex>
          </Flex>
        </GridItem>
      )}

      {languageData ? (
        <GridItem
          w="100%"
          h={["95px", "140px"]}
          border="1px"
          py="30px"
          px="20px"
          borderColor="#40BF91"
          borderRadius="12px"
        >
          <Image src={iconReady} alt="true" h="20px" w="20px" mb="5px" />
          <Text fontSize="18px" color="#292B30" fontWeight="700">
            {info.collaborator}
          </Text>
          <Box h="2px" w="25px" bg="red.100" borderRadius="5px" />
          <Text fontSize="14px" color="#5C6066" mt="8px">
            São as nossas
            <Text as="span" fontWeight="600" ml="5px">
              pessoas
            </Text>
          </Text>
        </GridItem>
      ) : (
        <GridItem
          w="100%"
          h={["95px", "140px"]}
          border="1px"
          borderColor="#5C6066"
          fontSize={["13px", "14px"]}
          borderRadius={["10px", "12px"]}
          borderStyle="dashed"
        >
          <Flex alignItems="center" pl="20px" h="100%">
            <Box flex={2}>
              {mobile ? (
                <Text lineHeight={"20px"}>
                  Configure como são chamados os <b> colaboradores </b> da sua
                  empresa
                </Text>
              ) : (
                <Text lineHeight={"20px"}>
                  Configure como são
                  <br />
                  chamados os <b> colaboradores </b>
                  <br />
                  da sua empresa
                </Text>
              )}
            </Box>
            <Flex flex={1} pb="0px">
              <Image w={["81px", "auto"]} src={colaboradores} alt="character" />
            </Flex>
          </Flex>
        </GridItem>
      )}

      {languageData ? (
        <GridItem
          w="100%"
          h="140px"
          border="1px"
          py="30px"
          px="20px"
          borderColor="#40BF91"
          borderRadius="12px"
        >
          <Image src={iconReady} alt="true" h="20px" w="20px" mb="5px" />
          <Text fontSize="18px" color="#292B30" fontWeight="700">
            {info.company}
          </Text>
          <Box h="2px" w="25px" bg="red.100" borderRadius="5px" />
          <Text fontSize="14px" color="#5C6066" mt="8px">
            É a nossa
            <Text as="span" fontWeight="600" ml="5px">
              empresa
            </Text>
          </Text>
        </GridItem>
      ) : (
        <GridItem
          w="100%"
          h={["95px", "140px"]}
          border="1px"
          borderColor="#5C6066"
          fontSize={["13px", "14px"]}
          borderRadius={["10px", "12px"]}
          borderStyle="dashed"
        >
          <Flex alignItems="center" pl="20px" h="100%">
            <Box flex={2}>
              {mobile ? (
                <Text>
                  Configure como é chamada
                  <br />a sua empresa <b> empresa </b>
                </Text>
              ) : (
                <Text>
                  Configure como é chamada
                  <br />a sua empresa <b> empresa </b>
                </Text>
              )}
            </Box>
            <Flex flex={1}>
              <Image w={["78px", "auto"]} src={companyName} alt="character" />
            </Flex>
          </Flex>
        </GridItem>
      )}
    </Grid>
  );
};
export default LanguageData;
