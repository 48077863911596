/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext } from "react";
import {
  Box,
  Flex,
  GridItem,
  Tag,
  Text,
  Button,
  Input,
  Image,
  Heading,
  useMediaQuery,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import iconSuccess from "../../../assets/images/icon-success.svg";
import ResearchCustomModal from "./Modals/ResearchCustom";
import { useListAffirmatives } from "../../../helpers/useListAffirmatives";
import { CustomContext } from "../../../context/CustomContext";
import WaitImportRespondentModal from "./Modals/WaitImportRespondentModal";

const Affirmatives = ({ researchId , importRespondentIsRunning, totalRespondents, extern_researchId}) => {
  const { custom } = useContext(CustomContext);
  const [modal, setModal] = useState(false);
  const [mobile] = useMediaQuery("(min-width: 1024px)");

  const { data: affirmativeData } = useListAffirmatives(researchId);
  const [options, setOptions] = useState([]);
  const [success, setSuccess] = useState(false);
  const [isOpenWaitImportModal, setIsOpenWaitImportModal] = useState(false);

  useEffect(() => {
    if (affirmativeData !== undefined && affirmativeData.length) {
      const findOptions = affirmativeData.filter(
        (af) =>
          af.id_research.id === researchId || af.id_research === researchId
      );
      const order = findOptions.sort(function compareFn(a, b) {
        return a.position - b.position;
      });

      setSuccess(true);
      setOptions(order);
    }
  }, [affirmativeData, researchId]);

  const handleModalResearch = () => {
    if (custom) {
      if(importRespondentIsRunning && extern_researchId!=null){
        setIsOpenWaitImportModal(true);
      } else {
        setModal(true);
      }
    } else return;
  };

  const handleCloseModalWaitImport = () => {
    setIsOpenWaitImportModal(false);
  };

  const handleCloseModalResearch = () => {
    setModal(false);
  };

  return !mobile ? (
    <Accordion
      defaultIndex={[1]}
      allowMultiple
      w="100%"
      maxW="100%"
      p={["26px 22px 32px 22px", 6]}
    >
      <WaitImportRespondentModal isOpen={isOpenWaitImportModal} onClose={handleCloseModalWaitImport}  text=''/>
      <ResearchCustomModal
        previousData={options}
        isOpen={modal}
        onClose={handleCloseModalResearch}
        researchId={researchId}
      />
      <AccordionItem border="none" position={"relative"}>
        <Tag
          fontSize="16px"
          bg="#e5ebec"
          color="#002070"
          borderRadius="20px"
          h="23px"
          px="12px"
          fontWeight="600"
        >
          Afirmativas
        </Tag>
        <Text fontSize="13px" lineHeight={"24px"} pt="8px" maxW="85%">
          É possível adicionar até <b>3 afirmativas </b> pré-determinadas a sua
          pesquisa de clima.
        </Text>
        <AccordionButton
          bg="gray.400"
          w="32px"
          right={0}
          top="24px"
          p={0}
          justifyContent="center"
          h="32px"
          rounded={"full"}
          position={"absolute"}
        >
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p={0}>
          <Stack spacing={["12px", 1]} mt="18px">
            {[0, 1, 2].map((i, index) => {
              return options.length > i ? (
                <Flex
                  key={i}
                  px="16px"
                  py={['18px', 'unset']}
                  gap="20px"
                  alignItems="center"
                  h={['auto',"54px"]}
                  fontSize="14px"
                  border="1px solid #40BF91"
                  borderRadius="12px"
                >
                  <Image src={iconSuccess} alt="success" w="16px" h="16px" />
                  <Text>{options[i].description}</Text>
                </Flex>
              ) : (
                <Input
                  key={i}
                  color="gray.500"
                  placeholder="Configure uma afirmativa"
                  w="100%"
                  h="54px"
                  fontSize="14px"
                  border="1px dashed #5C6066"
                  borderRadius={["12px", "10px"]}
                />
              );
            })}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
      <Button
        w="100%"
        mt="24px"
        h="48px"
        variant="outline"
        color={!custom ? "#292B30" : "red.100"}
        border="1px"
        disabled={!custom}
        borderColor={!custom ? "#292B30" : "red.100"}
        borderRadius="10px"
        onClick={handleModalResearch}
      >
        {!!options.length ? "Editar" : "Configurar"}
      </Button>
    </Accordion>
  ) : (
    <Stack
      w="100%"
      h={["auto", "277px"]}
      border={"1px solid #CCCCCC"}
      borderRadius="20px"
      p={["0", "20px"]}
    >
      <ResearchCustomModal
        previousData={options}
        isOpen={modal}
        onClose={handleCloseModalResearch}
        researchId={researchId}
      />
      <WaitImportRespondentModal isOpen={isOpenWaitImportModal} onClose={handleCloseModalWaitImport} text=''/>
      <Flex justify={"space-between"} align="center">
        <Box>
          <Tag
            bg="#e5ebec"
            color="#002070"
            fontSize="16px"
            borderRadius="20px"
            h="23px"
            px="15px"
            fontWeight="600"
          >
            Afirmativas
          </Tag>
          <Text fontSize="14px" lineHeight={"24px"} pt={1} mt="8px">
            É possível adicionar até <b>3 afirmativas </b> pré-determinadas a
            sua Pesquisa de Clima.
          </Text>
        </Box>
        <Button
          w="175px"
          variant="outline"
          color={!custom ? "#292B30" : "red.100"}
          border="1px"
          disabled={!custom}
          borderColor={!custom ? "#292B30" : "red.100"}
          borderRadius="10px"
          onClick={handleModalResearch}
        >
          {!!options.length ? "Editar" : "Configurar"}
        </Button>
      </Flex>
      <Stack spacing={["12px", 1]} align="stretch" mt="18px">
        {[0, 1, 2].map((i, index) => {
          return options.length > i ? (
            <Flex
              key={i}
              w="100%"
              px="16px"
              gap="20px"
              alignItems="center"
              h="54px"
              fontSize="14px"
              border="1px"
              borderColor="#40BF91"
              borderRadius="12px"
            >
              <Image src={iconSuccess} alt="success" w="16px" h="16px" />
              <Text>{options[i].description}</Text>
            </Flex>
          ) : (
            <Input
              key={i}
              placeholder="Configure uma afirmativa"
              w="100%"
              h="54px"
              readOnly
              color="black"
              fontSize="14px"
              borderColor="#5C6066"
              borderRadius="10px"
              borderStyle="dashed"
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default Affirmatives;
