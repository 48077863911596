import React from "react";
import { Input as ChakraInput, InputGroup } from "@chakra-ui/react";

const Input = ({ value, setTextSearch, ...props }) => {
  return (
    <InputGroup borderRadius="0">
      <ChakraInput
        borderColor="#DEE5E4"
        fontSize={"14px"}
        _placeholder={{
          color: "#444C54",
          fontWeight: 400,
          fontSize: "14px",
        }}
        minH="48px"
        borderRadius="0"
        whiteSpace={"nowrap"}
        overflow="hidden"
        textOverflow="ellipsis"
        {...props}
      />
    </InputGroup>
  );
};

export default Input;
