import { Flex, Stack, Text, FormLabel } from "@chakra-ui/react";
import { Badge } from "../../../components/badge";

const PilaresTags = ({
  register,
  tagsData,
  participationData,
  isThirtyDaysPassed,
}) => (
  <>
    <Text fontSize={["sm", "md"]} fontWeight={700} lineHeight="32px">
      Selecione tags que descrevem sua resposta
    </Text>

    <Flex gap="12px" flexWrap="wrap" className="pilares">
      {!!tagsData &&
        tagsData.map((item) => (
          <Flex
            key={item.idTag}
            height="37px"
            align="center"
            px="12px"
            borderRadius="50px"
            overflowX="hidden"
            pos="relative"
            opacity={
              participationData?.finished || isThirtyDaysPassed ? 0.5 : 1
            }
            style={{
              cursor:
                participationData?.finished || isThirtyDaysPassed
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            <input
              type="checkbox"
              name={item.text}
              id={item.text}
              value={item.idTag}
              {...register("tags")}
              disabled={participationData?.finished || isThirtyDaysPassed}
              className="checkbox-pilar"
            />
            <FormLabel
              fontSize="sm"
              htmlFor={item.text}
              pos="relative"
              lineHeight="32px"
              zIndex={4}
              pointerEvents="none"
            >
              {item.text}
            </FormLabel>
          </Flex>
        ))}
    </Flex>
  </>
);

const PerguntaObrigatoria = () => (
  <Stack
    px={{ base: "0px", xl: "100px", "2xl": "150px" }}
    mt={[0, "8px"]}
    mb={["16px", "24px"]}
  >
    <Badge>
      <Text>
        Você ainda tem{" "}
        <Text as="span" fontWeight={700}>
          1 pergunta obrigatória
        </Text>{" "}
        para responder.
      </Text>
    </Badge>
  </Stack>
);

const FeedbackMessage = ({ text, color }) => (
  <Stack
    px={{ base: "0px", xl: "100px", "2xl": "150px" }}
    mt={[0, "8px"]}
    mb={["16px", "24px"]}
  >
    <Badge>
      <Text fontSize="15px" fontWeight={500} color={color}>
        {text}
      </Text>
    </Badge>
  </Stack>
);

const CulturaAuditFeedback = ({
  data,
  participationData,
  isThirtyDaysPassed,
}) => {
  if (participationData?.finished || isThirtyDaysPassed) {
    if (participationData?.finished) {
      return (
        <FeedbackMessage
          text="Culture Audit já enviado para avaliação."
          color="#D50000"
        />
      );
    } else {
      return (
        <FeedbackMessage
          text="O prazo de preenchimento já terminou."
          color="#D50000"
        />
      );
    }
  } else if (!participationData?.finished && isThirtyDaysPassed) {
    return (
      <FeedbackMessage
        text="O prazo de preenchimento já terminou."
        color="#D50000"
      />
    );
  } else if (
    !participationData?.finished &&
    !data?.answered &&
    !isThirtyDaysPassed
  ) {
    return <PerguntaObrigatoria />;
  } else {
    return null;
  }
};

export { PilaresTags, CulturaAuditFeedback };
