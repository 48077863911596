/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

import { addDays } from "date-fns";
import {
  Container,
  Grid,
  GridItem,
  Heading,
  Tag,
  Text,
  Image,
  Flex,
  Box,
  Spinner,
} from "@chakra-ui/react";
import SimpleSidebar from "../components/nav/Sidebar";
import Steps from "./certficacao/Steps";

import Card from "../components/card/index";
import certificationLogo from "../../assets/images/icon-logo-certificacao.svg";
import backBlue from "../../assets/images/back-blue.svg";
import backPurple from "../../assets/images/back-purple.svg";

import getUrlParams from "../../services/getUrlParams";
import { useResearch } from "../../helpers/useResearch";
import { researchApi } from "../../services/api-research";

const Layout = styled.div`
  margin-left: 32px;
  background: #f6f5fb;
`;

const Pesquisas = () => {
  const { document, key } = getUrlParams("");

  const [researchLink, setResearchLink] = useState("");
  const [researchId, setResearchId] = useState("");

  const [creatingRegistration, setCreatingRegistration] = useState(false);
  const [researchData, setResearchData] = useState({});
  const companyRegister = document[0];

  useEffect(() => {
    async function getResearches() {
      try {
        const response = await researchApi.get(`/research`);

        const researchExists = response.data.find(
          (d) => d.id_company_register === companyRegister
        );

        if (!!researchExists) {
          setResearchId(researchExists.id);
        

      } else {
        setCreatingRegistration(true);
      }

        console.log("researchExists", researchExists);
      } catch (error) {
        console.log("Error", error);
      }
    }
    getResearches();
  }, []);

  return (
    <Layout>      
      <SimpleSidebar>
        {/* {data === undefined && creatingRegistration === false ? (
          <Box w="100%" textAlign="center" h="30vh" pt="20px">
            <Spinner />
          </Box>
        ) : creatingRegistration === true ? (
          <Box w="100%" textAlign="center" h="30vh" pt="20px">
            <Text fontWeight={500}> Estamos cadastrando sua pesquisa</Text>
            <Spinner />
          </Box>
        ) : (
          <Container maxW="container.xl">
            <Card
              title="Começando o processo de certificação"
              text="As pŕoximas etapas ajudarão a iniciar seu processo de certificação."
              hasCloseButton
            />

            <Heading as="h4" fontSize={"18px"} mt="30px" mb="30px">
              Pesquisas
            </Heading>

            <Grid
              templateColumns="repeat(4, 1fr)"
              gap={4}
              maxW="100%"
              h="148px"
              bg="white"
              borderRadius={"12px"}
              overflow="hidden"
              mb="20px"
              border="1px"
              borderColor="#CCCCCC"
            >
              <GridItem
                colSpan={1}
                w="350px"
                backgroundSize="fill"
                backgroundRepeat={"no-repeat"}
                h="100%"
                p="32px"
                backgroundImage={backBlue}
              >
                <Tag
                  bg="#40BF91"
                  color="white"
                  fontSize="14px"
                  lineHeight="16px"
                  h="24px"
                  w="96px"
                >
                  Certificação
                </Tag>
                <Heading
                  as="h3"
                  color="white"
                  fontSize="18px"
                  lineHeight="21px"
                  mt="10px"
                  mb="10px"
                >
                  Rumo a certificação!
                </Heading>

                <RouterLink
                  // to={researchLink}
                  style={{
                    fontWeight: "300",
                    fontSize: "13px",
                    color: "white",
                    textDecoration: "underline",
                  }}
                >
                  Configurar pesquisa
                </RouterLink>
              </GridItem>
              <GridItem colSpan={3} h="100%" p="20px">
                {data.research.status !== "Aprovada" ? (
                  <Steps researchId={researchId} />
                ) : (
                  <Flex gap="36px">
                    <Image
                      src={certificationLogo}
                      alt="Logo certificação"
                      maxH="116px"
                    />
                    <Box>
                      <Text fontSize="15px" lineHeight="22.4px">
                        Parabéns!
                      </Text>
                      <Text
                        fontSize="15px"
                        fontWeight="600"
                        lineHeight="20.4px"
                        mt="5px"
                      >
                        Sua empresa
                        <br />
                        foi certificada!
                      </Text>

                      <Tag
                        fontSize="14px"
                        mt="10px"
                        bg="rgba(0, 32, 112, 0.1)"
                        borderRadius="6px"
                        color="#002070"
                        h="16px"
                        fontWeight="600"
                      >
                        Nota: 92
                      </Tag>
                    </Box>

                    <Box fontSize="13px" w="250px" color="#292B30" ml="20px">
                      <Text mb="10px" fontSize="13px" lineHeight="18.2px">
                        Agora que você conquistou a
                        <br />
                        <b>
                          Certificação GPTW, é hora de concorrer aos nossos
                          rankings!
                        </b>
                      </Text>
                      <Text fontSize="13px" lineHeight="18.2px">
                        Confira os
                        <Text
                          as="span"
                          color="red.100"
                          fontWeight="600"
                          textDecor="underline"
                          ml="4px"
                        >
                          Rankings disponíveis aqui
                        </Text>
                      </Text>
                    </Box>
                  </Flex>
                )}
              </GridItem>
            </Grid>

            <Grid
              templateColumns="repeat(4, 1fr)"
              gap={4}
              h="148px"
              bg="white"
              borderRadius={"12px"}
              overflow="hidden"
            >
              <GridItem
                colSpan={1}
                w="350px"
                backgroundImage={backPurple}
                backgroundSize="fill"
                backgroundRepeat={"no-repeat"}
                h="100%"
                p="32px"
              >
          
                <Heading
                  as="h3"
                  color="white"
                  fontSize="18px"
                  lineHeight="21px"
                  mt="10px"
                  mb="10px"
                >
                  Customize sua pesquisa
                </Heading>
                <RouterLink
                  // to={researchLink}
                  style={{
                    fontWeight: "300",
                    fontSize: "13px",
                    color: "white",
                    textDecoration: "underline",
                  }}
                >
                  Configurar pesquisa
                </RouterLink>
              </GridItem>
              <GridItem
                colSpan={3}
                h="100%"
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
              >
                <Text fontSize={"14px"} fontWeight="600">
                  PRECISAMOS DAS INFORMAÇÕES SOBRE ESSA PESQUISA
                </Text>
              </GridItem>
            </Grid>
          </Container>
        )} */}
      </SimpleSidebar>
    </Layout>
  );
};

export default Pesquisas;
