import axios from "axios";
import apiNotification from "../services/api-notification";
import apiFinancial from "../services/api-financial";
import apiLegacy from "../services/api-legacy";



const getReasons = (data) => {
    const { reasonsToCancel, reasonsPositive, comment } = data;

    let responseToCancel = '';

    reasonsToCancel.forEach(reason => {
       if (responseToCancel == '') {
        responseToCancel += `${reason}`
       } else {
        responseToCancel += `, ${reason}`
       }
    });

    let responsePositive = '';

    reasonsPositive.forEach(reason => {
       if (responsePositive == '') {
        responsePositive += `${reason}`
       } else {
        responsePositive += `, ${reason}`
       }
    });

    const log = `Razões de cancelamento: ${responseToCancel}; Pontos posivos: ${reasonsPositive}; Comentario: ${comment}`;
    const obj = {
        reasonsToCancel,
        reasonsPositive,
        comment,
    }
    return {log, obj};
};

const formatReasonsArraysToHTML = (obj) => {
    let reasonsToCancelHTML = "";
    let reasonsPositiveHTML = "";

    obj.reasonsToCancel.forEach((reason) => {
        reasonsToCancelHTML += `<li>${reason}</li>`;
    });

    obj.reasonsPositive.forEach((reason) => {
        reasonsPositiveHTML += `<li>${reason}</li>`;
    });

    return { reasonsToCancelHTML, reasonsPositiveHTML };
};

const formatResponsiblesArraysToHTML = (responsibles) => {
    let formattedHTML = "<ul>";

    responsibles.forEach((responsibles) => {
        formattedHTML += `<li>${responsibles.name} - ${responsibles.email} - ${responsibles.phone}</li>`;
    });

    formattedHTML += "</ul>";
    return formattedHTML;
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
    return formattedDate;
};

const buildBodyEmail = (companyInfo, date, obj) => {
    const { company } = companyInfo;

    const responsibles = formatResponsiblesArraysToHTML(company.responsibles);
    const { reasonsToCancelHTML, reasonsPositiveHTML } = formatReasonsArraysToHTML(obj)

    let body = template.replace("_nome", company.name);
    body = body.replace("_nomeFantasia", company.fantasyName);
    body = body.replace("_cnpj", company.cnpj);
    body = body.replace("_responsavelInterno", companyInfo.consultant);
    body = body.replace("_comentario", obj.comment);
    body = body.replace("_contatoEmpresa", responsibles);
    body = body.replace("_data", formatDate(date));
    body = body.replace("_razoesCancelamento", reasonsToCancelHTML);
    body = body.replace("_razoespositivas", reasonsPositiveHTML);
    body = body.replace("_local", company.city);

    return body;
}

const cancellationNotification = async (companyInfo, date, obj) => {
    try {
        const emails = [];
        const receiverEmails = window['runConfig'].EMAILS_RECEIVER_CANCEL_NOTIFICATION;
        const receiverCcEmails = window['runConfig'].EMAILS_RECEIVER_CC_CANCEL_NOTIFICATION;
        const body = buildBodyEmail(companyInfo, date, obj);

        const objEmail = {
            body,
            subject: "Notificação de Cancelamento de Jornada",
            to: ["certificacao@gptw.com.br"], // receiverEmails,
            cc: ["deyse.alves@greatpeople.com.br", "lucas.madio@greatpeople.com.br"], // receiverCcEmails,
            from: "produtos_gptw@gptwbrasil.com.br",
            replyTo: ""
        }

        emails.push(objEmail); 

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${window['runConfig'].REACT_APP_MESSAGE_URL}/send-email`,
            headers: { 
              'auth': window['runConfig'].REACT_APP_MESSAGE_AUTH, 
              'Content-Type': 'application/json'
            },
            data : JSON.stringify(emails)
        };

        await axios.request(config)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
        
        
    } catch (erro) {
        console.error(erro)
    }

}

export const subscription = {
    async unsubscribe(data, amountFine, document, companyInfo) {
        console.log('Iniciando processo de cancelamento!');
        try {
            const { company } = companyInfo;
            const date = new Date();
            const subscriptionInformation = await apiFinancial.get(`/signature?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}&document=${document}`);
            const subscription = subscriptionInformation.data.data.message[0].subscription;
            const customer = subscriptionInformation.data.data.message[0].customer;;
            const { log, obj} = getReasons(data);
            
            // Notificando o cancelamento
            await cancellationNotification(companyInfo, date, obj)

            // Criando registro de cancelamento Status- solicitação
            const objFinalResponse = {
                cnpj: document,
                origin_system: 'Connect-app',
                system_customer_id: document,
                log_type: "Cancelamento solicitado e em andamento",
                status: "OK",
                log: log,
                receiver_address: company.responsibles[0].email,
                creation_date_time: date
            }

            await apiNotification.post('/log-cancel-journey', objFinalResponse);
            
            // CANCELAMENTO ASSINATURA VINDI
            console.log('Realizando cancelamento na Vindi!');
            const responseCancelVindi = await apiFinancial.delete(
                `/vindi/cancel-subscription/${subscription.id}?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}`, 
                log
            );
            if(responseCancelVindi.status !== 201) {
                const obj = {
                    cnpj: document,
                    origin_system: 'Vindi',
                    system_customer_id: customer.id || document,
                    log_type: "Erro na requisição de cancelamento Vindi",
                    status: `Erro`,
                    log: responseCancelVindi,
                    receiver_address: "",
                    creation_date_time: date
                }
                await apiNotification.post('/log-cancel-journey', obj);
                console.error(`Erro no cancelamento de assinatura Vindi: ${responseCancelVindi}`)
            };

            // EMITINDO FATURA DE MULTA
            console.log('Emitindo fatura de multa!');
            const objFine = {
                customer_id: customer.id,
                payment_method_code: "bank_slip",
                bill_items: [
                    {
                        "product_id": 1357159,
                        "amount": amountFine
                    }
                ]
            };
            const responseAplyFineVindi = await apiFinancial.post(
                `/vindi/createBill?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}`, 
                objFine
            );
            if(responseAplyFineVindi.status !== 201) {
                const obj = {
                    cnpj: document,
                    origin_system: 'Vindi',
                    system_customer_id: customer.id || document,
                    log_type: "Erro na requisição de criar fatura da multa",
                    status: `Erro`,
                    log: responseAplyFineVindi,
                    receiver_address: "",
                    creation_date_time: date
                }
                await apiNotification.post('/log-cancel-journey', obj);
                console.error(`Erro para criar fatura Vindi: ${responseAplyFineVindi}`);
            };

            // CANCELANDO PARTICIPAÇÃO NO CONNECT
            console.log('Cancelando participação atual no Connect!');
            const responseConnect =  await axios.post(`${window["runConfig"].REACT_APP_URL_RESULT_SERVICE}/participation/cancel-participation?companyId=${company.id}`);
            let responseConnectBuffer = Buffer.from(responseConnect.data);
            responseConnectBuffer = JSON.parse(responseConnectBuffer.toString());

            if(responseConnectBuffer.hasError) {
                const obj = {
                    cnpj: document,
                    origin_system: 'Connect',
                    system_customer_id: company.id || document,
                    log_type: "Erro na requisição para cancelar participação no connect",
                    status: `Erro`,
                    log: responseConnect,
                    receiver_address: "",
                    creation_date_time: date
                }
                await apiNotification.post('/log-cancel-journey', obj);
                console.error(`Erro no cancelamento da participação Connect: ${responseConnect}`);
            };

            // ALTERANDO STATUS DA OPORTUNIDADE NO SF
            console.log('Cancelando oportunidade no Salesfocer');
            
            let idOpportunity;
            await apiFinancial.get(`/salesforce/salesforceVoucherVerify?salesforceVoucher=${company.voucher}&secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}`)
            .then((res) => {
                idOpportunity = res.data.data.message.records[0]['Id'];
            }).catch(async (erro) => {
                const obj = {
                    cnpj: document,
                    origin_system: 'Connect',
                    system_customer_id: company.id || document,
                    log_type: "Erro para obter informação",
                    status: `Erro para obter o id da oportunidade no Salesforce`,
                    log: erro,
                    receiver_address: "",
                    creation_date_time: date
                }
                await apiNotification.post('/log-cancel-journey', obj);
                console.error(`Erro para obter o id da oportunidade no salesforce: ${erro}`);
            });

            const salesforceResponse = await apiFinancial.post(`/salesforce/update-status-opp?opportunityId=${idOpportunity}&secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}`);

            if(salesforceResponse.hasError) {
                const obj = {
                    cnpj: document,
                    origin_system: 'Salesforce',
                    system_customer_id: company.voucher || document,
                    log_type: "Erro na requisição para mudar estágio da oportunidade no Salesforce",
                    status: `Error`,
                    log: salesforceResponse,
                    receiver_address: "",
                    creation_date_time: date
                }
                await apiNotification.post('/log-cancel-journey', obj);
                console.error(`Erro na requisição para mudar estágio da oportunidade no Salesforce: ${salesforceResponse}`);
            };

            // Criando registro de cancelamento Status- solicitação
            const objFinalResponseCompleted= {
                cnpj: document,
                origin_system: 'Connect-app',
                system_customer_id: document,
                log_type: "Cancelamento realizado com sucesso",
                status: "OK",
                log: log,
                receiver_address: company.responsibles[0].email,
                creation_date_time: date
            }

            await apiNotification.post('/log-cancel-journey', objFinalResponseCompleted);

            // Enviando notificação de confirmação para o cliente.
            const emailsArray = company.responsibles.map((responsible) => responsible.email);
            const objEmail = {
                "templateCode": "EMAIL_CANCELAMENTO",
                "emailTo": emailsArray,
                "sendMail": true,
                "company": company
            }
            await apiNotification.post('/notification/send-cancel-notification', objEmail);
            console.info('Cancelamento finalizado com sucesso!');

            return true;
        } catch (erro) {
            const date = new Date();

            const obj = {
                cnpj: document,
                origin_system: 'Connect-app',
                system_customer_id: document,
                log_type: "Erro no cancelamento da assinatura",
                status: "Error",
                log: `Erro interno de código: ${JSON.stringify(erro)}`,
                receiver_address: "",
                creation_date_time: date
            }
            await apiNotification.post('/log-cancel-journey', obj);
            console.error(`Erro no cancelamento da participação Connect: ${erro}`);
            return false;
        }
    },
    
    checkPendingBills(data) {
        try {
            if (data.message == undefined) throw new Error('Não foi possível acessar as faturas do cliente!');
            const { message: bills } = data;
            const billsPending = bills.filter((bill) => bill.status === 'pending');

            return billsPending;
        } catch(erro) {
            throw new Error(erro);
        }
    },

    reasons : [
        'Mau Atendimento',
        'Empresa Vendida/Aquisição',
        'Reorganização de Processos Internos',
        'Aguardando Resultado de Ranking',
        'Fechamento de Ciclo',
        'Não trouxe os benefícios esperados',
        'Sistema/Plataforma Lenta',
        'Aquisição de Novo Plano (Contratação)',
        'Preço alto',
        'Falta de Clareza nos Processos',
        'Mudança no Quadro de Colaboradores/Qtd Inferior a 29 colaboradores',
        'Sem Tempo para Implementação',
        'Mudança de Empresa Fornecedora',
        'Redução de Despesas',
        'Aguardando Aprovação Interna'
    ],
    positiveReasons : [
        'Sistema/Plataforma',
        'Comunicação (e-mails, connect, newsletter)',
        'Processo/Experiência da Jornada',
        'Conteúdo: Site GPTW e Mídias Sociais (Instagram, Facebook, LinkedIn)',
        'Atendimento',
        'Resultados',
        'Metodologia GPTW (Credibilidade/Reconhecimento)',
    ]
}

const template = "<html lang=\"pr_Br\"><head><style>main {width: 80%;margin: 0 auto;}table {width: 100%;border-collapse: collapse;}th, td {padding: 8px;text-align: left;border-bottom: 1px solid #ddd;}th {background-color: #f2f2f2;}h1 {text-align: center;}div {width: 80%;margin: 0 auto;}</style></head><body><main><h1>Notificação de Cancelamento</h1><div><p>Informamos que houve um cancelamento realizado no sistema Connect. Segue abaixo as informações:</p><table><tr><th>Campo</th><th>Valor</th></tr><tr><td>Nome da Empresa</td><td>_nome</td></tr><tr><td>Nome Fantasia</td><td>_nomeFantasia</td></tr><tr><td>CNPJ</td><td>_cnpj</td></tr><tr><td>Cidade</td><td>_local</td></tr><tr><td>Responsável pelo Cliente</td><td>_responsavelInterno</td></tr><tr><td>Razões do Cancelamento</td><td><ul>_razoesCancelamento</ul></td></tr><tr><td>Pontos Positivos</td><td><ul>_razoespositivas</ul></td></tr><tr><td>Comentário</td><td>_comentario</td></tr><tr><td>Contato da Empresa</td><td>_contatoEmpresa</td></tr><tr><td>Data e Hora</td><td>_data</td></tr></table></div></main></body></html>"

export default subscription;
