import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const downloadExcelFile = (data, fileName) => {
  const rows = data.split("\n").map((row) => row.split(";"));
  const ws = XLSX.utils.aoa_to_sheet(rows);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Convert the workbook to a binary string
  const binaryString = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  // Create a Blob with the binary string
  const blob = new Blob([convertStringToArrayBuffer(binaryString)], {
    type: "application/octet-stream",
  });
  let d = new Date();
  const dateToFile = d.getTime();
  // Save the blob as a file using file-saver library
  saveAs(blob, `${fileName}_${dateToFile}.xlsx`);
};

// Convert string to ArrayBuffer
const convertStringToArrayBuffer = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export { downloadExcelFile };
