import React from "react";
import { Box, Grid, Spinner } from "@chakra-ui/react";

const Loading = () => {
  return (
    <>  
        <Grid 
            backgroundColor="rgba(0, 0, 0, 0.2)"
            alignContent="center"
            justifyContent="center"
            width="100%"
            height="100%"
            position="fixed"
            top='0'
            left='0'
            zIndex="999999" >
          <Box position="relative" h="151px">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='gray.500'
            size='xl'
          />
          </Box>
        </Grid>
    </>
  );
};

export default Loading;