import React from "react";
import { Icon } from "../../../style";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Text, Flex, Stack, Image, Box, Heading, Link, Tooltip} from "@chakra-ui/react";
import moment from 'moment';
import {
  successIcon,
  iconQuestionPilares,
  cadastrosBgNovo,
} from "../../../assets/images";

export default function QuestionnaireCard({
  title,
  form,
  isThirtyDaysPassed,
  pillarsFinished,
  progressLabel,
  iconColor,
  bg,
  smallSizes,
  hideProgressBar,
  isDiversity,
  responseEnableOnAt,
  dataMessage, 
  ...rest
}) {
  const {
    answered,
    totalAnswered,
    totalQuestions,
    allRequiredAnswered,
    formUrl,
    order,
  } = form;

  const isResponseDiversityIsEnabled = responseEnableOnAt ? moment(responseEnableOnAt).isAfter(moment()) : false;
  const formattedDateFinishDiversity = moment(responseEnableOnAt).format('DD/MM/YYYY');

  return (
    <Stack
      width="100%"
      height="203px"
      borderRadius="12px"
      overflow="hidden !important"
      justify="space-between"
      spacing={0}
      pos="relative"
      order={order}
      {...rest}
    >
      <Image
        borderRadius="12px"
        src={bg ? bg : cadastrosBgNovo}
        alt=""
        pos="absolute"
        filter="brightness(0.3)"
        zIndex={0}
        left={0}
        top={0}
      />

      <Stack
        p={["24px", smallSizes ? "25px" : "32px 32px 24px"]}
        direction={"row"}
        pos="relative"
        h="100%"
      >
        <Image
          w={"22px"}
          pos="absolute"
          right="20px"
          top="20px"
          zIndex={2}
          src={answered ? successIcon : iconQuestionPilares}
          alt=""
        />

        <Flex
          flex={2}
          pos="relative"
          zIndex={1}
          flexDir={"column"}
          justify={isThirtyDaysPassed ? "space-between" : "start"}
          gap={isThirtyDaysPassed ? "auto" : "28px"}
          m={"0 !important"}
        >
          <Stack spacing="1px">
            <Box w="20px" h="5px" borderRadius="6px" bg={iconColor} />
            <Heading
              as="h3"
              color="white"
              fontSize={smallSizes ? "18px" : " 20px"}
              fontWeight={600}
            >
              {title}
            </Heading>
          </Stack>
          {dataMessage && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Tooltip label="Essa data pode divergir para questionários concluídos antes de 23/07/2024">
              <span>
                <Icon type="alert-white" />
              </span>
            </Tooltip>
            <Text fontSize="14px" color="white">
              {dataMessage}
            </Text>
          </div>
          )}

          {isDiversity ? (
            !isResponseDiversityIsEnabled && (
            <Stack color="white" spacing="10px" align="start">
              <Flex height="30px" align="center" gap="8px">
                <Icon type="alert-orange" />
                {hideProgressBar ? (
                  <b>
                    <Text fontSize="15px">
                      Preenchimento opcional para empresas até 99 colaboradores
                    </Text>
                  </b>
                ) : (
                  <Text fontSize="15px">
                    Prazo de preenchimento encerrado!
                  </Text>
                )}
              </Flex>
              <Link
                href={formUrl}
                textDecor="underline"
                _pressed={{
                  border: "none",
                }}
              >
                Visualizar
              </Link>
            </Stack>
            )
          ) : (
            isThirtyDaysPassed && (
              <Stack color="white" spacing="10px" align="start">
                <Flex height="30px" align="center" gap="8px">
                  <Icon type="alert-orange" />
                  {hideProgressBar ? (
                    <b>
                      <Text fontSize="15px">
                        Preenchimento opcional para empresas até 99 colaboradores
                      </Text>
                    </b>
                  ) : (
                    <Text fontSize="15px">
                      Prazo de preenchimento encerrado!
                    </Text>
                  )}
                </Flex>
                <Link
                  href={formUrl}
                  textDecor="underline"
                  _pressed={{
                    border: "none",
                  }}
                >
                  Visualizar
                </Link>
              </Stack>
            )
          )}


          {
            isDiversity ? (
              isResponseDiversityIsEnabled && (
                hideProgressBar ? (
                  <Stack color="white" spacing="10px" align="start">
                    <Flex height="15px" align="center" gap="8px">
                      <Icon type="alert-orange" />
                      <b>
                        <Text fontSize="15px">
                          Preenchimento opcional para empresas até 99 colaboradores
                        </Text>
                      </b>
                    </Flex>
                    isResponseDiversityIsEnabled ? (
                    <Link
                      href={formUrl}
                      fontSize="15px"
                      textDecor="underline"
                      _pressed={{
                        border: "none",
                      }}
                    >
                      Responder - Prazo final: {formattedDateFinishDiversity}
                    </Link>
                    ) : (
                      <Link
                        href="#"
                        fontSize="15px"
                        color="white"
                        textDecor="underline"
                        _pressed={{
                          border: "none",
                        }}
                      >
                        Prazo encerrado
                      </Link>
                    )
                  </Stack>
                ) : (

                  isResponseDiversityIsEnabled ? (
                  <Link
                    href={formUrl}
                    fontSize="15px"
                    color="white"
                    textDecor="underline"
                    _pressed={{
                      border: "none",
                    }}
                  >
                    {allRequiredAnswered ? "Visualizar" : `Responder - Prazo final: ${formattedDateFinishDiversity}`}
                  </Link>
                  ) : (
                    <Link
                    href="#"
                    fontSize="15px"
                    color="white"
                    textDecor="underline"
                    _pressed={{
                      border: "none",
                    }}
                  >
                    Prazo encerrado
                  </Link>
                  )
                )
              )
            ) : (
              !isThirtyDaysPassed && (
                hideProgressBar ? (
                  <Stack color="white" spacing="10px" align="start">
                    <Flex height="15px" align="center" gap="8px">
                      <Icon type="alert-orange" />
                      <b>
                        <Text fontSize="15px">
                          Preenchimento opcional para empresas até 99 colaboradores
                        </Text>
                      </b>
                    </Flex>
                    <Link
                      href={formUrl}
                      fontSize="15px"
                      textDecor="underline"
                      _pressed={{
                        border: "none",
                      }}
                    >
                      Responder
                    </Link>
                  </Stack>
                ) : (
                  <Link
                    href={formUrl}
                    fontSize="15px"
                    color="white"
                    textDecor="underline"
                    _pressed={{
                      border: "none",
                    }}
                  >
                    {allRequiredAnswered ? "Visualizar" : "Responder"}
                  </Link>
                )
              )
            )
          }


        </Flex>

        <Flex
          flex={1}
          maxW="70px"
          mt="auto !important"
          display={hideProgressBar ? "none" : "flex"}
        >
          <CircularProgressbar
            value={totalAnswered}
            minValue={0}
            maxValue={totalQuestions}
            strokeWidth={7}
            text={progressLabel}
            styles={buildStyles({
              textSize: "font-size: 16px",
              width: "71px",
              height: "71px",
              pathColor: `#40BF91`,
              textColor: "#ffff",
              trailColor: "#E5E5E5",
            })}
          />
        </Flex>
      </Stack>

      {!isThirtyDaysPassed && (
        <Flex
          height="32px"
          bg={
            hideProgressBar
              ? "#008000"
              : allRequiredAnswered
              ? "#008000"
              : "red.100"
          }
          color="#ffffff"
          justify="center"
          align="center"
          gap={["4px"]}
          pos="relative"
        >
          {allRequiredAnswered ? (
            <Text
              textAlign="center"
              fontSize={["12px", smallSizes ? "13px" : "14px"]}
              lineHeight={["14px"]}
            >
              {form?.type === "CA" ? (
                <>
                  {pillarsFinished ? (
                    <Text as="span" fontSize="sm">
                      Parabéns! Seu questionário FOI <b>ENVIADO</b> com sucesso!
                    </Text>
                  ) : (
                    <Text as="span">
                      Seu questionário ainda <b>NÃO</b> foi <b>ENVIADO!</b>
                    </Text>
                  )}
                </>
              ) : form?.type === "QT" ? (
                <Text as="span"></Text>
              ) : (
                <Text as="span">
                  Parabéns! Você <b>respondeu</b> todas as questões
                  obrigatórias.
                </Text>
              )}
            </Text>
          ) : isThirtyDaysPassed ? (
            <Text
              textAlign="center"
              fontSize={["12px", smallSizes ? "13px" : "14px"]}
              lineHeight={["14px"]}
            >
              Prazo de preenchimento encerrado!
            </Text>
          ) : hideProgressBar ? (
            <Text as="span" fontSize="sm">
              Preenchimento opcional.{" "}
              <b>
                <Link href="https://portal-ca.gptw.com.br/quais-qualidades-essenciais-fazem-da-sua-organizacao-excelente-lugar-para-trabalhar-como-voces-criam-este-ambiente-unico/?wpca=83D04B9E11C6DE49847D8981FBF7E279&company_register=870e9b12-d3b5-4793-a1eb-7bbf1f058005">
                  Clique aqui
                </Link>
              </b>{" "}
              e veja as práticas das empresas.
            </Text>
          ) : (
            <Text
              textAlign="center"
              fontSize={["12px", "13px"]}
              lineHeight={["14px"]}
            >
              Você ainda tem <b>questões</b> que precisam ser <b>preenchidas</b>
              .
            </Text>
          )}
        </Flex>
      )}

      {isThirtyDaysPassed && form.type === "CA" && (
        <Flex
          height="32px"
          bg={
            hideProgressBar
              ? "#008000"
              : allRequiredAnswered
              ? "#008000"
              : "red.100"
          }
          color="#ffffff"
          justify="center"
          align="center"
          gap={["4px"]}
          pos="relative"
        >
          {pillarsFinished ? (
            <Text as="span" fontSize="sm">
              Parabéns! Seu questionário <b>FOI ENVIADO</b> com sucesso!
            </Text>
          ) : hideProgressBar ? (
            <Text as="span" fontSize="sm">
              Preenchimento opcional.{" "}
              <b>
                <Link href="https://portal-ca.gptw.com.br/quais-qualidades-essenciais-fazem-da-sua-organizacao-excelente-lugar-para-trabalhar-como-voces-criam-este-ambiente-unico/?wpca=83D04B9E11C6DE49847D8981FBF7E279&company_register=870e9b12-d3b5-4793-a1eb-7bbf1f058005">
                  Clique aqui
                </Link>
              </b>{" "}
              e veja as práticas das empresas.
            </Text>
          ) : (
            <Text as="span" fontSize="sm">
              Seu questionário ainda <b>NÃO FOI enviado!</b>
            </Text>
          )}
        </Flex>
      )}
    </Stack>
  );
}
