/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Spinner,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import getUrlParams from "../../../services/getUrlParams";

import cardBackground from "../../../assets/images/card-background.svg";
import pending from "../../../assets/images/icon-pendency-white.svg";
import iconPlay from "../../../assets/images/icon-play.svg";
import iconBar from "../../../assets/images/icon-bar.svg";

import { useFormsInfo } from "../../../helpers/useFormsInfo";

const CardForm = ({ cr, researchId }) => {
  const { document: doc, key } = getUrlParams("");
  const companyRegister = doc[0];
  const [registLink, setRegistLink] = useState("");
  const token = btoa(cr);

  const { data, isLoading, isFetching } = useFormsInfo(companyRegister);

  useEffect(() => {
    const link = `https://connect.gptw.info/registration/profile?cr=${cr}&st=true`;
    setRegistLink(link);
  }, [cr, token]);

  return isLoading ? (
    <Box
      h="100%"
      w={["100%", "50%", "360px"]}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Box>
  ) : data.err ? (
    <Text>Registro não encontrado</Text>
  ) : (
    <Box
      h="100%"
      w={["100%", "50%", "360px"]}
      maxH="206px"
      minW={["100%","50%", "360px"]}
      maxW={["100%","50%", "360px"]}
      backgroundImage={cardBackground}
      backgroundColor="#292B30"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="12px"
    >
      <VStack spacing={2} align="stretch" p="22px">
        <Flex justify={"space-between"}>
          <img src={iconBar} alt="icon bar" />
          <img src={pending} alt="icon pendency" />
        </Flex>
        <Box>
          <Heading
            as="h5"
            fontSize="18px"
            fontFamily="Raleway"
            color="white"
            fontWeight={"600"}
          >
            Questionário de Certificação
          </Heading>
          <Box mt="10px">
            <Flex gap="10px" flexDir={"column"}>
              <Flex alignItems="center">
                <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                  3 concluídos
                </span>
              </Flex>
              <Flex alignItems="center">
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "10px",
                    textShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                  }}
                >
                  2 pendentes
                </span>
              </Flex>
            </Flex>
          </Box>
        </Box>
        <Box>
          {isLoading ? (
            <Text fontSize="30" fontWeight={700} color="white"></Text>
          ) : (
            <Flex h="40px" alignItems="center">
              <img src={iconPlay} alt="icon play" />

              {data.cultureBrief.allRequiredAnswered ? (
                <Text
                  textDecoration="underline"
                  fontSize="13px"
                  color="#ffffff"
                  ml="20px"
                >
                  <a href={registLink} target="_blank" rel="noreferrer">
                    <b>Editar </b> cadastro
                  </a>
                </Text>
              ) : data.cultureBrief.totalAnswered === 0 ? (
                <Text
                  textDecoration="underline"
                  fontSize="13px"
                  color="#ffffff"
                  ml="20px"
                >
                  <a href={registLink} target="_blank" rel="noreferrer">
                    <b>Iniciar </b> cadastro
                  </a>
                </Text>
              ) : (
                <Text
                  textDecoration="underline"
                  fontSize="13px"
                  color="#ffffff"
                  ml="20px"
                >
                  <a href={registLink} target="_blank" rel="noreferrer">
                    <b>Continuar </b> cadastro
                  </a>
                </Text>
              )}
            </Flex>
          )}
        </Box>
      </VStack>
    </Box>
  );
};

export default CardForm;
