import React from "react";
import { Flex, Heading, Text } from "@chakra-ui/react";

const Header = ({ title, total }) => {
  return (
    <Flex
      direction={["row", "row", "row", "column"]}
      gap={["8px", "8px", "8px", "12px"]}
      justify="space-between"
      w="100%"
      align={["center", "center", "center", "start"]}
      h="fit-content"
    >
      <Heading
        fontWeight={600}
        fontSize={["24px", "24px", "24px", "24px"]}
        lineHeight={["24px", "24px", "24px", "24px"]}
        color="#22262D"
        whiteSpace={"nowrap"}
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {title}
      </Heading>
      {total ? (
        <Text
          fontWeight={400}
          fontSize={["14px", "14px", "14px", "14px"]}
          lineHeight={["14px", "14px", "14px", "14px"]}
          color="#555E66"
          whiteSpace={"nowrap"}
          sx={{
            span: {
              color: "#444C54",
              fontWeight: 700,
            },
          }}
        >
          {/* Cadastros - <span>{total}</span> */}
          <span>{total}</span> - Empresas cadastradas
        </Text>
      ) : null}
    </Flex>
  );
};

export default Header;
