import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Container, Spinner, Textarea, Flex } from "@chakra-ui/react";
import {
  HeaderColaboradores,
  TableCollabs,
  Grid,
  Breadcumbs,
  Button,
  Select,
  Icon,
  HeaderGray,
} from "../../style";
import styled from "styled-components";
import Modal from "react-modal";
import getFaturaService from "../../services/getFaturaService";
import updateInvoiceDueDateService from "../../services/updateInvoiceDueDateService";
import FORMAT from "../../helpers/formatDateHelper";
import OPTIONSDAYS from "../../helpers/expirationDaysHelper";
import getURLParams from "../../services/getUrlParams";
import { useDispatch } from "react-redux";
import { apiFinancial } from "../../services/api-financial";
import subscription from "../../helpers/cancelSubscription";
import SimpleModal from "../../app/components/modal/SimpleModal";
import { InnerContainer, ModalUnsubscribe, ModalContainer } from "./styles";
import axios from "axios";
import ReactGA from "react-ga";
import { CustomContext } from "../../context/CustomContext";

import SimpleSidebar from "../components/nav/Sidebar";
import ModalCancellationHold from "./ModalCancellationHold";

const ModalStyled1 = styled(Modal)`
  z-index: 1;
  margin-top: 15%;
  margin-left: 35%;
  width: 600px;
  height: 330px;
  background-color: #fff;
  position: relative;
  border-radius: 20px;
  border: 1px solid #ccc;
`;
const ModalStyled2 = styled(Modal)`
  z-index: 1;
  margin-top: 15%;
  margin-left: 35%;
  width: 581px;
  height: 341px;
  background-color: #fff;
  position: relative;
  border-radius: 20px;
  border: 1px solid #ccc;
`;

const Title = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #292b30;
`;

const TagStyled = styled.span`
  padding: 1px;
  width: 20px;
  height: 5px;
  border-radius: 6px;
  background-color: #40bf91;
  margin-bottom: 8px;
`;

const ButtonFooter = styled.button`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  padding-top: 19px;
  padding-bottom: 19px;
  border-radius: 0 0 20px 20px;
  border: 0px solid black;
  background-color: #e60000;
  width: 581px;
  position: absolute;
  bottom: 0;
  left: 0;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
`;

const MyForm = ({ fine, setModal, companyInfo }) => {
  if (fine.message === undefined)
    throw new Error("Não foi possível acessar as faturas do cliente!");

  const formRef = useRef(null);

  const [canCancel, setCanCancel] = useState(false);

  const handleCanCancel = useCallback(
    (event) => {
      if (event.target.value.toLowerCase() !== "cancelar") {
        if (canCancel) {
          setCanCancel(false);
        }

        return;
      }
      setCanCancel(true);
    },
    [canCancel]
  );

  const getAllDataToCancel = useCallback(() => {
    if (typeof formRef.current == "undefined") {
      return;
    }

    const form = formRef.current;

    const reasonsToCancel = [
      ...form.querySelectorAll(
        'input[type="checkbox"].reasons-to-cancel:checked'
      ),
    ].map((item) => item.value);
    const reasonsPositive = [
      ...form.querySelectorAll(
        'input[type="checkbox"].reasons-positives:checked'
      ),
    ].map((item) => item.value);
    const comment = form.querySelector("#comment").value;

    const obj = {
      reasonsToCancel,
      reasonsPositive,
      comment,
    };

    return obj;
  }, []);

  let { message: bills } = fine;
  bills = bills.filter((bill) => bill.status !== "canceled");
  const invoiceAmount = bills.length;
  let showMulta = invoiceAmount <= 12;
  let amountFine = 0;
  let value = 0;
  let remainingInvoices = 0;

  if (showMulta) {
    value = parseFloat(bills[0].amount);
    remainingInvoices = (invoiceAmount - 12) * -1; // Subtração sempre resultara em um valor negativo.
    amountFine = (remainingInvoices * value * 1.4).toFixed(2);
  }

  return (
    <form ref={formRef}>
      <div>
        <div>
          <div>
            <b>
              1) Qual o motivo do cancelamento? (Você pode marcar mais de uma
              opção.)
            </b>
          </div>
          <ul style={{ "list-style": "none" }}>
            {subscription.reasons.map((reason) => {
              return (
                <li>
                  <label>
                    <input
                      className="reasons-to-cancel"
                      type="checkbox"
                      name={reason}
                      value={reason}
                    />
                    &nbsp;{reason}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>

        <div>
          <div>
            <b>
              2) Com base na sua resposta anterior, o que poderíamos ter feito
              para você manter o Selo GPTW vigente na sua empresa?
            </b>
          </div>
          <Textarea id="comment" type="text" name="feedBack" />
        </div>

        <div>
          <div>
            <b>
              3) Durante sua Jornada de Certificação & Ranking, quais foram os
              pontos positivos que identificou? Escolha quantas opções desejar.
            </b>
          </div>
          <ul style={{ "list-style": "none" }}>
            {subscription.positiveReasons.map((reason) => {
              return (
                <li>
                  <label>
                    <input
                      className="reasons-positives"
                      type="checkbox"
                      name={reason}
                      value={reason}
                    />
                    &nbsp;{reason}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>

        {!showMulta ? (
          <p></p>
        ) : (
          <div style={{ width: "100%", border: "1px solid #ccc" }}>
            <div
              style={{ width: "100%", marginTop: "0 auto", padding: "20px" }}
            >
              <h2 style={{ textAlign: "center" }}>Multa por cancelamento</h2>
              <div
                style={{
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "end",
                  "margin-bottom": "10px",
                }}
              >
                <span>Valor da mensalidade:</span>
                <span>
                  <b>R$ {value.toFixed(2)}</b>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "end",
                  "margin-bottom": "10px",
                }}
              >
                <span>Total de mensalidades restantes:</span>
                <span>
                  <b>{remainingInvoices}</b>
                </span>
              </div>
              <div style={{ display: "flex", "flex-direction": "column" }}>
                <span style={{ "margin-bottom": "-20px" }}>
                  Fórmula para o cálculo:
                </span>
                <span style={{ "font-size": "12px" }}>
                  <b>
                    (Valor da Mensalidade x Número de Parcelas Restantes) x
                    Percentual de multa = Multa
                  </b>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "end",
                  "margin-bottom": "10px",
                }}
              >
                <span>Total:</span>
                <span>
                  (R$ {value.toFixed(2)} x {remainingInvoices}) x 1.4 ={" "}
                  <b>R$ {amountFine}</b>
                </span>
              </div>
            </div>
          </div>
        )}
        <div>
          <p>Digite abaixo "cancelar" para confirmar seu cancelamento:</p>
          <input
            id="confirm-input"
            style={{
              width: "100%",
              padding: "5px",
              "margin-bottom": "10px",
              color: "red",
              border: "1px solid black",
              "border-radius": "7px",
            }}
            type="text"
            placeholder="Digite 'cancelar'"
            onChange={handleCanCancel}
          />
        </div>
        <div
          style={{
            display: "flex",
            "justify-content": "center",
          }}
          id="div-cancel"
        >
          <Button
            id="bt-cancel"
            class="butCancelSing"
            label="Cancelar Assinatura"
            type="button"
            bg="black"
            pode
            disabled={!canCancel}
            style={{
              "align-self": "center",
              width: "200px",
              height: "30%",
              marginRight: "10%",
            }}
            onClick={async (event) => {
              event.target.disabled = true;
              const data = getAllDataToCancel();
              const { document, key } = getURLParams("bill");
              setModal(
                <ModalContainer>
                  <ModalUnsubscribe>
                    <InnerContainer>
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="gray.500"
                        size="xl"
                        style={{ "margin-top": "17vw" }}
                      />
                    </InnerContainer>
                  </ModalUnsubscribe>
                </ModalContainer>
              );
              const res = await subscription.unsubscribe(
                data,
                amountFine,
                document,
                companyInfo
              );

              if (res) {
                setModal(
                  <SimpleModal
                    title="Cancelamento em Processamento!"
                    text={`O seu cancelamento está em processamento e logo você receberá um e-mail de confirmação, por favor aguarde!`}
                    isOpen={true}
                    onClose={() => setModal(null)}
                  />
                );
              } else {
                setModal(
                  <SimpleModal
                    title="Cancelamento não concluído!"
                    text={`Não foi possível concluir o cancelamento, por favor entre em contato com o atendimento@gptwbrasil.com.br e informe o problema!`}
                    isOpen={true}
                    onClose={() => setModal(null)}
                  />
                );
              }
            }}
          />
          <Button
            class="butCancelSing"
            label="Voltar"
            style={{
              "align-self": "center",
              width: "200px",
              height: "30%",
              "margin-top": "0",
            }}
            onClick={() => setModal(null)}
          />
        </div>
      </div>
    </form>
  );
};

function Faturas(props) {
  let dispatch = useDispatch();
  const [listInvoice, setListInvoice] = useState([]);
  const [infokey, setInfoKey] = useState(null);
  const [document, setDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuscription, setIsSuscription] = useState(false);
  const [modal, setModal] = useState(null);
  const [alertModal, setAlertModal] = useState(null);
  const [dataCancel, setDataCancel] = useState({});
  const [alertConfirmCancel, setAlertConfirmCancel] = useState(null);
  const [companyInfo, setcompanyInfo] = useState({});
  const { plan } = useContext(CustomContext);
  const { consultant } = useContext(CustomContext);
  const [openCancellationHold, setOpenCancellationHold] = useState(false);

  const getInitialState = () => {
    const value = "";
    return value;
  };

  const [value, setValue] = useState(getInitialState);
  const [customerSubscription, setcustomerSubscription] = useState(null);

  useEffect(() => {
    ReactGA.pageview(
      window["runConfig"].REACT_APP_CONNECT_BASE_URL + window.location.search
    );
  }, []);

  useEffect(async () => {
    let mounted = true;
    const { document, key } = getURLParams("bill");
    setInfoKey(key);
    setDocument(document);
    apiFinancial
      .get(
        `/signature?secret=${window["runConfig"].REACT_APP_FINANCIAL_SECRET}&document=${document}`
      )
      .then((response) => {
        const { data } = response;
        if (response.status == "200") {
          if (data["data"] != undefined) {
            setcustomerSubscription(data);
            setIsSuscription(true);
          }
        }
      });

    setIsLoading(true);
    getFaturaService(document, key).then((items) => {
      if (mounted) {
        setListInvoice(items);
        setIsLoading(false);

        let usuarioObj = {
          email: items.length > 0 ? items[0].customerEmail : "",
          nome: items.length > 0 ? items[0].customerName : "",
        };
        dispatch({ type: "CLICK_ADD_USUARIO", usuario: usuarioObj });
      }
    });

    const urlParams = new URLSearchParams(window.location.search);
    const cr = urlParams.get("cr");
    const url_company_info =
      window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
      `/company-register-info?cr=${cr}`;
    await axios.get(url_company_info).then((response) => {
      setcompanyInfo(response.data);
    });

    return () => (mounted = false);
  }, []);

  if (listInvoice.length > 0) {
  }

  let breadcumbs = [
    { nome: "Faturas", tipo: "link", link: "/bills", icone: "", ativado: true },
  ];

  let listaHeader = [
    {
      titulo: "Forma de Pagamento",
      text: listInvoice.length > 0 ? listInvoice[0].paymentMethod : " ",
      icon: "collabs-blue",
      number: 1000,
      corFundo: "#D1DBF7",
      corTexto: "#174BD6",
    },

    {
      titulo: "Plano",
      text: listInvoice.length > 0 ? listInvoice[0].plan : " ",
      icon: "collabs-green",
      number: 1000,
      corFundo: "#D9F2E9",
      corTexto: "#40BF91",
    },
  ];

  const options = OPTIONSDAYS.expirationDays();

  let tabelaCabecalho = [
    { item: "#", icone: false },
    { item: "Emissão", icone: false },
    { item: "Vencimento", icone: false },
    { item: "Ciclo", icone: false },
    { item: "Valor", icone: false },
    { item: "Status", icone: true },
    { item: "Ações", icone: false },
  ];

  let tabelaBody = [];
  if (listInvoice.length > 0) {
    for (const item in listInvoice) {
      const invItem = {
        id: listInvoice[item].id,
        emissao: FORMAT.convertDateUTCToBR(listInvoice[item].creationDate),
        vencimento:
          listInvoice[item].dueAt !== null
            ? FORMAT.convertDateUTCToBR(listInvoice[item].dueAt)
            : FORMAT.convertDateUTCToBR(listInvoice[item].creationDate),
        ciclo: listInvoice[item].cycle,
        valor: listInvoice[item].amount,
        status: listInvoice[item].status,
        selecionado: true,
        icones: true,
        url: listInvoice[item].url,
      };
      tabelaBody.push(invItem);
    }
  }

  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const handleModal1 = () => setModal1(!modal1);
  const handleModal2 = () => setModal2(!modal2);

  async function updateDueDate(newDate, document) {
    if (
      newDate === "" ||
      newDate === null ||
      document === "" ||
      document === null
    ) {
      alert("Escolha uma data para o novo vencimento.");
      return;
    }

    alert(
      "ATENÇÃO: A alteração do vencimento só produzirá efeito nas mensalidades que ainda não foram faturadas."
    );
    await updateInvoiceDueDateService(newDate, document[0]);
    handleModal2();
  }

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const openModal = useCallback(
    (fine, companyInfo) => {
      companyInfo.plan = plan;
      companyInfo.consultant = consultant;

      setModal(
        <ModalContainer>
          <ModalUnsubscribe>
            <InnerContainer>
              <h1>Cancelamento de Jornada de Certificação e Ranking - GPTW</h1>
              <MyForm
                fine={fine}
                setModal={setModal}
                companyInfo={companyInfo}
              />
            </InnerContainer>
          </ModalUnsubscribe>
        </ModalContainer>
      );
    },
    [setModal, plan, consultant]
  );

  const openAlertModal = (numberBills) => {
    setAlertModal(
      <SimpleModal
        title="Cancelamento indisponível!"
        text={`Para fazer o cancelamento não deve haver faturas pendentes. Você tem ${numberBills} ${
          numberBills == 1 ? "fatura pedente" : "faturas pendentes"
        }.`}
        isOpen={true}
        onClose={() => setAlertModal(null)}
      />
    );
  };

  const handleOpenModals = () => {
    setOpenCancellationHold(!openCancellationHold);
    const billsPending = subscription.checkPendingBills(
      customerSubscription.data
    );
    if (billsPending.length > 0) {
      // Alert informando que existem faturas pendentes.
      openAlertModal(billsPending.length);
    } else {
      // Modal para informar motivos de cancelamento e valor de multo(caso de aplique).
      openModal(customerSubscription.data, companyInfo);
    }
  };

  const handleOpenCancellationHold = () => {
    setOpenCancellationHold(!openCancellationHold);
  };

  return (
    <SimpleSidebar>
      <Container maxW="100%" ml={["unset", "auto"]} p={["0", "24px"]}>
        <Grid row>
          <Grid
            column
            style={{ width: "100%", marginTop: "0px", marginLeft: "10px" }}
          >
            <Grid row style={{ width: "100%", marginTop: "32px" }}>
              <Breadcumbs
                lista={breadcumbs}
                spacing="space-between"
                style={{ width: "100%" }}
              />
            </Grid>

            {tabelaBody.length > 0 ? (
              <Grid>
                {modal}
                {alertModal}
                {alertConfirmCancel}
                {/* <NavBarHorizontal /> */}
                <Grid row style={{ marginLeft: "32px", marginRight: "32px" }}>
                  {listaHeader.map((chave, valor) => {
                    return (
                      <HeaderColaboradores
                        title={chave.titulo}
                        text={chave.text}
                        icon={chave.icon}
                        number={chave.number}
                        background={chave.corFundo}
                        color={chave.corTexto}
                      />
                    );
                  })}
                </Grid>

                <Grid
                  row
                  style={{
                    width: "100%",
                  }}
                >
                  <Flex w="100%" justify="end" gap="24px">
                    {tabelaBody.length > 0 &&
                    listInvoice[0].paymentMethod === "Boleto Bancário" ? (
                      <Button
                        type="outline"
                        width="265px"
                        height="40px"
                        label="Alterar Vencimento"
                        onClick={handleModal1}
                      />
                    ) : null}

                    {isSuscription ? (
                      <Button
                        type="outline"
                        width="265px"
                        height="40px"
                        label="Cancelar Assinatura"
                        onClick={handleOpenCancellationHold}
                      />
                    ) : null}
                  </Flex>
                  <ModalCancellationHold
                    isOpen={openCancellationHold}
                    onClose={() =>
                      setOpenCancellationHold(!openCancellationHold)
                    }
                    plan={listInvoice.length > 0 ? listInvoice[0].plan : " "}
                    handleSubmit={handleOpenModals}
                    titleSubmit={"Continuar cancelamento"}
                  />
                </Grid>

                <TableCollabs header={tabelaCabecalho} body={tabelaBody} />
              </Grid>
            ) : (
              <Grid
                row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "95%",
                }}
              >
                {isLoading === false ? (
                  <HeaderGray
                    title="A sua modalidade não é assinatura!"
                    text={
                      <p>
                        Não foram encontrados dados com as informações
                        fornecidas. <br></br>Este acesso é para clientes com
                        assinaturas ativas.
                      </p>
                    }
                    icon={false}
                  />
                ) : null}
              </Grid>
            )}
          </Grid>

          <ModalStyled1 isOpen={modal1} onRequestClose={handleModal1}>
            <Grid
              column
              spacing="space-between"
              style={{
                position: "absolute",
                top: "32px",
                left: "32px",
              }}
            >
              <TagStyled />
              <Title style={{ marginBottom: "29px" }}>
                Alterar data de vencimento
              </Title>
              <Grid row>
                <Select
                  width="250px"
                  background="#F5F5FB"
                  options={options}
                  onChange={handleChange}
                />
              </Grid>
              <Grid row spacing="space-between" style={{ marginTop: "60px" }}>
                <span style={{ marginRight: "35px" }}>
                  <Button
                    label="Cancelar"
                    type="outline"
                    width="265px"
                    height="56px"
                    onClick={handleModal1}
                  />
                </span>
                <Button
                  label="Confirmar"
                  width="245px"
                  height="56px"
                  onClick={(e) => {
                    updateDueDate(value, document);
                  }}
                />
              </Grid>
            </Grid>
          </ModalStyled1>

          <ModalStyled2 isOpen={modal2} onRequestClose={handleModal2}>
            <Grid
              column
              style={{
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "32px",
                left: "0px",
                width: "100%",
              }}
            >
              <div style={{ marginBottom: "24px", marginTop: "30px" }}>
                <Icon type="check-green" width="100px" height="100px" />
              </div>
              <Title>Alteração realizada com sucesso</Title>
            </Grid>

            <ButtonFooter
              onClick={() => {
                handleModal1();
                handleModal2();
              }}
            >
              Fechar
            </ButtonFooter>
          </ModalStyled2>
        </Grid>
      </Container>
    </SimpleSidebar>
  );
}

export default Faturas;
