import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  Image,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  Flex,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { getCookie } from "../common/cookieManager";
import iconAnchor from "../../assets/images/button-anchor.svg";

export function TermsAgreementModal({ isOpen, onClose, content, company }) {
  const toast = useToast();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userIp, setUserIp] = useState();

  async function getCurrentUserIp() {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      if (res && res.data) {
        setUserIp(res.data.IPv4);
      }
    } catch (err) {
      console.log("Erro na busca pelo ip");
    }
  }

  async function handleAcceptButton() {
    setLoading(true);
    const email = getCookie("user_email");

    const cnpj = company?.cnpj
      .replace(/\./g, "")
      .replace(/-/g, "")
      .replace(/\//, "");
    try {
      const params = new URLSearchParams();

      params.append("cnpj", cnpj);
      params.append("origem", "CONNECT");
      params.append("user", email);
      params.append("idOrigem", userIp);
      const res = await axios.post(
        `${window["runConfig"].REACT_APP_URL_RESULT_SERVICE}/participation/save-accept-terms`,
        params
      );
      if (res && res.data) {
        setSuccess(true);
      }
    } catch (err) {
      console.log("Err", err);
      toast({
        title: "Ocorreu um erro",
        description: "Não foi possível salvar a sua resposta",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  const logout = () => {
    window.location.replace("/login");
  };

  useEffect(() => {
    getCurrentUserIp();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={logout} size={success ? "4xl" : "full"}>
      <ModalOverlay />
      <ModalContent
        color="#292B30"
        w={success ? "50vw" : "80vw"}
        mt={["8vdh", "8vh"]}
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          w={"100%"}
          p="40px 60px"
          h="140px"
          maxH="140px"
        >
          <Heading as="h2" fontSize="24px" textAlign="left">
            Regras de participação
          </Heading>
          {!success && (
            <GenericPdfDownloader
              downloadFileName="CustomPdf"
              rootElementId="testId"
            />
          )}
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody
          bg="#f6f5fb"
          p="40px 60px"
          color="#000000"
          lineHeight="19.6px"
        >
          {success ? (
            <Flex h="40vh" align="center" justify={"center"}>
              <Text fontWeight={600}>Resposta salva com sucesso</Text>
            </Flex>
          ) : (
            <div id="testId" dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </ModalBody>

        <ModalFooter>
          {success ? (
            <Button
              variant="primary"
              w="242px"
              h="52px"
              boxShadow="0px 5px 20px rgba(230, 0, 0, 0.1)"
              onClick={onClose}
            >
              Fechar
            </Button>
          ) : (
            <Flex gap="14px" direction={["column", "row"]}>
              <Button
                bg="white"
                color="red.100"
                border="1px solid #E60000"
                w="242px"
                h="52px"
                onClick={handleAcceptButton}
                mr="14px"
                isLoading={loading}
              >
                Aceitar
              </Button>

              <Button
                variant="primary"
                w="242px"
                h="52px"
                boxShadow="0px 5px 20px rgba(230, 0, 0, 0.1)"
                onClick={logout}
              >
                Recusar
              </Button>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const GenericPdfDownloader = ({ rootElementId }) => {
  const toast = useToast();

  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId);
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "JPEG", 0, 0);
        pdf.save("Termos-de-uso.pdf");
      })
      .catch((err) => {
        toast({
          title: "",
          description: "Não foi possível fazer o download do documento",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Button
      variant="naked"
      w="265px"
      h="24px"
      gap="10px"
      onClick={downloadPdfDocument}
    >
      <Image src={iconAnchor} alt="icon" />
      Baixar regras de participação
    </Button>
  );
};
