import React, { useState } from "react";
import Select from "react-select";

import {
  Input,
  InputGroup,
  InputRightElement,
  Image,
  Button,
} from "@chakra-ui/react";

import iconRemove from "../../../../assets/images/icon-remove-red.svg";
import suggestions from "../sugestions";

export default function CustomSelect({ selectedOption, setSelectedOption }) {
  const [option, setOption] = useState("");

  const handleChange = (selectedOption) => {
    if (selectedOption.length > 3) return;
    else {
      setSelectedOption(selectedOption);
    }
  };

  const handleRemove = (value) => {
    const updateOptions = selectedOption.filter(
      (option) => option.id !== value.id
    );
    setSelectedOption(updateOptions);
  };

  return (
    <>
      <Select
        isMulti
        placeholder="Selecione uma afirmativa"
        controlShouldRenderValue={false}
        value={selectedOption}
        onChange={handleChange}
        options={suggestions}
        className="custom-select"
      />

      {selectedOption.map((val, index) => (
        <InputGroup key={index} h="56px" marginBottom="6px"
         alignItems="center">
          <Input
            value={val.label}
            readOnly
            color="#292B30"
            display="flex"
            alignItems={"center"}
            px="16px"
            fontSize="14px"
            h={['54px',"56px"]}
            fontWeight="600"
            borderWidth="1.5px"
            borderRadius="10px"
            borderColor="#B8CAE8"
            onChange={(e) => setOption(e.target.value)}
          />

          <InputRightElement
            children={
              <Button
                alt="remove option"
                p={0}
                bg="none"
                cursor="pointer"
                _hover={{
                  bg: "none",
                }}
                onClick={() => handleRemove(val)}
              >
                <Image
                  src={iconRemove}
                  alt="remove option"
                  h="18px"
                  w="18px"
                  cursor="pointer"
                />
              </Button>
            }
            marginRight="10px"
            alignSelf="center"
            display={"flex"}
            alignItems="center"
            h="100%"
          />
        </InputGroup>
      ))}
    </>
  );
}
