import { researchApi } from "../services/api-research";
import { useQuery } from "react-query";
import { queryClient } from "../services/queryCliente";

const fetchLanguageData = async (researchId) => {
  const response = await researchApi.get(
    `/organization-language?research=${researchId}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );
  const status = response.data;

  return status;
};

export const useLanguage = (researchId) => {
  return useQuery(
    "language",
    () => fetchLanguageData(researchId),
    {
      // The query will not execute until the userId exists
      enabled: !!researchId,
    },
    {
      staleTime: 30 * 1000,
      initialData: () => queryClient.getQueryData("language"),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState("language")?.dataUpdatedAt,
    }
  );
};
