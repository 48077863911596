import React from 'react'
import styled from 'styled-components'
import { homem } from '../../image/background'

const ContainerStyled = styled.div`
    background-image: url(${homem});
    background-repeat: no-repeat;
    width: 322px;
    hight: 140px;

`

const TextStyled = styled.p`
    margin-top: 41px;
    margin-left: 20px;
    margin-right: 144px;
    margin-bottom: 42px;
`

function Linguagens(props){
    return <ContainerStyled>
        {props.content === false && <TextStyled>
            {props.texto}
        </TextStyled>}
    </ContainerStyled>
}

export default Linguagens