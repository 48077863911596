import React, { useState } from "react";
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,  
} from "@chakra-ui/react";
import RenovationVoucherModal from "../../components/modal/RenovationVoucherModal";

const RenovationModal = ({ isOpen, onClose }) => {  
  const [renovationVoucherModal, setRenovationVoucherModal] = useState(false);

  let redirectToBuy = () =>{   
    window.open('https://conteudo.gptw.com.br/planos', '_blank');  
  }

  const handleModalRenovationVoucher = () => {
    setRenovationVoucherModal(true);
  };

  const handleCloseModalRenovationVoucher = () => {
    setRenovationVoucherModal(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      size="6xl"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        w={["100%", "552px"]}
        p="0px"
        borderRadius={["12px", "20px"]}
        overflow="hidden"
      >
        <ModalBody p={["32px", "60px 40px 60px 40px"]}>
          <Box display={"flex"} flexDir="column" alignItems="center" gap="24px">

            <Heading
              fontSize={["20px", "24px"]}
              lineHeight="33.6px"
              fontWeight="700"
              textAlign="center"
            >
                <Button
                  h="58px"
                  w="301px"
                  variant="outline"
                  colorScheme="red"
                  borderRadius="10px"
                  fontWeight="600"
                  fontSize="14px"
                  onClick={redirectToBuy}
                >
                  Não tenho código para renovação
                </Button>
              <br></br><br></br>
                <Button
                  h="58px"
                  w="301px"
                  variant="outline"
                  colorScheme="red"
                  borderRadius="10px"
                  fontWeight="600"
                  fontSize="14px"
                   onClick={handleModalRenovationVoucher}
                >
                  Tenho código para renovação
                </Button>
              <br></br>
            </Heading>
            <RenovationVoucherModal
                  isOpen={renovationVoucherModal}
                  onClose={handleCloseModalRenovationVoucher}
                />

          </Box>
        </ModalBody>
        <ModalFooter width="100%" p={0} height={["56px", "69px"]}>
          <Button
            width="100%"
            color="white"
            background="#E60000"
            borderRadius={0}
            height={["56px", "69px"]}
            fontWeight={[600, 400]}
            onClick={onClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RenovationModal;
