import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useLocation, Link } from "react-router-dom";
import ButtonLogout from "./ButtonLogout";
import ButtonRedirectLegado from "./ButtonRedirectLegado";

function SideDrawer({ links, isOpen, onClose }) {
  return (
    <Drawer isOpen={isOpen} placement="left" size={"md"} onClose={onClose}>
      <DrawerContent
        sx={{
          top: "100px !important",
          boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.1)",
          borderRadius: "0",
        }}
      >
        <DrawerBody p="32px 0 32px 24px">
          <Flex direction={"column"} gap="48px">
            <Flex gap="12px" direction={"column"}>
              {links.map((link, index) => {
                return <NavLink link={link} onClose={onClose} key={index} />;
              })}
            </Flex>
            <ButtonLogout title={"Sair"} />
            <ButtonRedirectLegado />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
export default SideDrawer;

const NavLink = ({ link, onClose }) => {
  const location = useLocation();
  const isCurrent = location.pathname.includes(link.path);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      className="link-active"
      to={link.path}
      style={{ textDecoration: "none" }}
      onClick={onClose}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex
        align="center"
        h="44px"
        gap="16px"
        borderRight={isCurrent ? "6px solid #FF1628" : "6px solid transparent"}
        opacity={isCurrent ? "1" : "0.6"}
        color={isCurrent ? (!!link.icon ? "#11131C" : "#FF1628") : "#22262D"}
        _hover={{
          color: "#11131C",
          opacity: "0.8",
        }}
      >
        {!!link.icon ? (
          <Icon
            width="24px"
            height="24px"
            as={link.icon}
            sx={{
              transition: "fill 0.3s",
              fill: isHovered || isCurrent ? "#FF1628" : "#444C54",
            }}
          />
        ) : null}
        <Text
          fontSize={"18px"}
          lineHeight={"18px"}
          fontWeight={500}
          whiteSpace={"nowrap"}
          h="fit-content"
          color={isCurrent ? (!!link.icon ? "#11131C" : "#FF1628") : "#22262D"}
        >
          {link.name}
        </Text>
      </Flex>
    </Link>
  );
};
