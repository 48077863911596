import React, { useEffect, useState, useRef } from "react";
import { Title, Button, InputCnpj, Checkbox, InputText, InputFile, InputPhone, ToolTips, InputEmail, InputPassword } from '../../style'
import { Container, Grid, GridItem, Box, Text, extendTheme} from '@chakra-ui/react'
import { apiFinancial } from "../../services/api-financial";
import { cnpj } from "cpf-cnpj-validator";
import iconEye from "../../assets/images/icon-eye.svg";
import iconEyeClosed from "../../assets/images/icon-eyeclosed.svg";
import iconError from "../../assets/images/icon-pending-red.svg";
import iconCheck from "../../assets/images/icon-check.svg";
import axios from "axios"
import SimpleModal from "../components/modal/SimpleModal";

const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
}

const theme = extendTheme({ breakpoints })

function Register(props) {
  const [CNPJ, setCNPJ] = useState("");
  const [CNPJApply, setCNPJApply] = useState("")
  const [CNPJFormatted, setCNPJFormatted] = useState("")
  const [CNPJApplyFormatted, setCNPJApplyFormatted] = useState("")
  const [voucher, setVoucher] = useState("")
  const [socialReason, setSocialReason] = useState("")
  const [fantasyName, setFantasyName] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [street, setStreet] = useState("")
  const [neighborhood, setNeighborhood] = useState("")
  const [cep, setCep] = useState("")
  const [streetNumber, setStreetNumber] = useState("")
  const [phone, setPhone] = useState("")
  const [responsibleName, setResponsibleName] = useState("")
  const [responsibleFirstName, setResponsibleFirstName] = useState("")
  const [responsibleLastName, setResponsibleLastName] = useState("")
  const [email, setEmail] = useState("")
  const [checkEmail, setCheckEmail] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [match, setMatch] = useState(false);
  const [active, setIsActive] = useState();
  const [imgExists, setImgExists] = useState();
  const [product, setProduct] = useState();
  const [planType, setPlanType] = useState();
  const [company, setCompany] = useState({});
  const [errorMsg, setErrorMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showAddNew, setShowAddNew] = useState(false);
  const [cnpjValid, setCnpjValid] = useState(false);
  const [isLoadingAddCompany, setIsLoadingAddCompany] = useState(false);
  const [companyExist, setCompanyExist] = useState(false);
  const [addCompanyMsg, setAddCompanyMsg] = useState(false);

  const CONNECT_BASE_URL =
  window["runConfig"].REACT_APP_CONNECT_BASE_URL ||
  "http://connect.domain.tld/";

  useEffect(() => {
    const params = new URLSearchParams(window ? window.location.search : {});
    if (params.get("cnpj") && params.get("voucher")) {

      setVoucher(params.get("voucher"))

      if(voucher.includes('GPTW2')) {
        setShowAddNew(false) //TODO colocar como true, tiramos porque fizemos deploy com essa funcionalidade em stand by por conta da complexidade
      }

      if (params.get("cnpjapply")) {
        setCNPJApply(params.get("cnpjapply"))
        setCNPJApplyFormatted(cnpj.format(params.get("cnpjapply")))
      }

      setCNPJ(params.get("cnpj"));
      setCNPJFormatted(cnpj.format(params.get("cnpj")))

      apiFinancial.get(`/salesforce/salesforceVoucherVerify?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}&salesforceVoucher=${voucher}`)
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data.message.records[0]
          const planoEscolhido = data["Plano_Escolhido__c"]
          setProduct(planoEscolhido.split("_")[0])
          setPlanType(planoEscolhido.split("_").slice(1).join("_"))
        } else {
          console.log('Status retornado da chamada para pegar produto: ', res.status)
          // setIsLoading(false)
        }
      })
      .catch(err => {
        console.log('Erro na chamada para verificar se empresa existe no sistem: ', err)
      })

      apiFinancial.post(`/company/getCNPJInfo?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}`, {
        cnpj: params.get("cnpjapply") ? CNPJApplyFormatted : CNPJFormatted,
      })
      .then(res => {
        setSocialReason(res.data.data.message.name)
        setFantasyName(res.data.data.message.fantasyName)
        setStreet(res.data.data.message.street)
        setStreetNumber(res.data.data.message.streetNumber)
        setCep(res.data.data.message.cep)
        setCity(res.data.data.message.city)
        setState(res.data.data.message.state)
        setNeighborhood(res.data.data.message.neighborhood)
        setPhone(res.data.data.message.phone)
        const addressFormatted = `${res.data.data.message.street}, ${res.data.data.message.streetNumber} - ${res.data.data.message.cep} - ${res.data.data.message.city} - ${res.data.data.message.state}` 
        setAddress(addressFormatted)
      })
    }
  }, [CNPJ, CNPJApply, voucher, product, planType]);

  useEffect(() => {
    setCompany({
      cep: cep,
      cityName: city,
      document: CNPJApplyFormatted !== "" ? CNPJApplyFormatted : CNPJFormatted,
      cnpjComprador: CNPJApplyFormatted !== "" ? CNPJFormatted : null,
      fantasyName: fantasyName ? fantasyName : socialReason,
      name: socialReason,
      district: neighborhood,
      phone: phone,
      stateName: state,
      streetAddres: street,
      streetNumber: streetNumber,
      confirmPassword: confirmPassword,
      password: password,
      email: email,
      responsibleName: responsibleName,
      responsibleSurName: responsibleLastName,
      voucher: voucher,
      product: product,
      planType: planType
    })

    console.log('company -> ', company)
  }, [
      phone, 
      fantasyName, 
      CNPJFormatted, 
      socialReason, 
      street, 
      neighborhood, 
      streetNumber, 
      cep, 
      city, 
      state,
      confirmPassword,
      password,
      email,
      responsibleName,
      voucher
    ])
  
  function handleResponsibleName(el) {
    setResponsibleName(el.value);
    const splitedName = responsibleName.split(" ")
    setResponsibleFirstName(splitedName[0])
    setResponsibleLastName(splitedName.slice(1).join(" "))
  }

  function handleEmail(el) {
    setEmail(el.value);
    const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    if (pattern.test(el.value)) {
      setCheckEmail(true)
    } else {
      setCheckEmail(false)
    }
  }

  function handleCNPJCompare() {
    if (CNPJ === CNPJApply) {
      return "checked"
    } else {
      return "unchecked"
    }
  }

  function handleSendInfo() {
    setErrorMsg("")
    setIsLoading(true)
    apiFinancial.post(`/company/createCompanyAndLogin?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}`, company)
    .then(res => {
      setIsLoading(false)
      setSuccessMsg("Seu cadastro ocorreu com sucesso! Agora é só logar na página para a qual estamos te redirecionando.")
    })
    .catch(err => {
      setErrorMsg('Parece que algo está errado. Verifique os dados inseridos. Caso o erro persista, fale com nosso atendimento.')
      console.log('Erro na chamada para cadastrar a empresa: ', err)
    })
  }

  function showPass (event) {
    let img = event.target.offsetParent.offsetParent.childNodes[0].firstChild
    let input = event.target.offsetParent.offsetParent.childNodes[1]
    if (input.type === "password") {
      input.type = "text"
      img.src = iconEyeClosed
    } else {
      input.type = "password"
      img.src = iconEye 
    }
  }

  function checkCnpj(cnpjNumber) {
    setAddCompanyMsg("")
    setCnpjValid(cnpj.isValid(cnpjNumber))
    if (cnpjValid) {
      checkIfCompanyAlreadyExists(cnpjNumber)
    } else {
      setAddCompanyMsg("CNPJ Inválido!")
    }
  }

  function checkIfCompanyAlreadyExists(cnpjNumber) {
    setIsLoadingAddCompany(true)
    apiFinancial.post(`/company/checkExist?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}`, {
      cnpj: cnpjNumber,
    })
    .then(res => {
      if (res.status === 200) {
        console.log('Data -> ', res.data.data.message)
        if (res.data.data.message.length === 0) {
          setCompanyExist(false)
          setAddCompanyMsg("")
          setIsLoadingAddCompany(false)
        } else {
          setAddCompanyMsg("Opa! Parece que este CNPJ já existe. Entre com um CNPJ de Participação abaixo ou entre em contato conosco.")
          setIsLoadingAddCompany(false)
        }
      } else {
        console.log('Status retornado da verificação da empresa: ', res.status)
        setIsLoadingAddCompany(false)
      }
    })
    .catch(err => {
      console.log('Erro na chamada para verificar se empresa existe no sistem: ', err)
    })
  }

  useEffect(() => {
    if (
      !!charNumberValid &&
      !!specialCharValid &&
      !!uppercaseValid &&
      !!numberValid &&
      !!match
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [
    match,
    setIsActive,
    charNumberValid,
    specialCharValid,
    uppercaseValid,
    numberValid,
  ]);
  // Check the length of the input
  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      setCharNumberValid(true);
    } else {
      setCharNumberValid(false);
    }
  };

  // Check for special characters
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);

    checkPasswordLength(event.target.value);
    checkSpecialCharacters(event.target.value);
    checkUppercase(event.target.value);
    checkNumber(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  useEffect(() => {
    if (password === confirmPassword) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }, [password, confirmPassword]);

  async function saveLogo(file) {
    if (file) {
      const AWS = require("aws-sdk");
      //TODO: ver se existe uma abordagem melhor

      AWS.config.update({
        accessKeyId: window["runConfig"].AWS_ACCESS_KEY_ID,
        secretAccessKey: window["runConfig"].AWS_SECRET_ACCESS_KEY,
      });

      const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        // eslint-disable-next-line no-undef
        region: window["runConfig"].AWS_REGION,
      });

      const params = {
        // eslint-disable-next-line no-undef
        Bucket: `${window["runConfig"].AWS_S3_BUCKET}/CompaniesLogos/${CNPJApply !== CNPJ ? CNPJApply : CNPJ}`,
        Key: `Logo.${file.name.split(".").pop()}`,
        Body: file,
        ACL: "public-read",
      };

      await s3.upload(params).promise();
      checkIfLogoExists();
    }
  }

  async function checkIfLogoExists() {
    //TODO: Colocar no banco de dados essa info, por hora ela está aqui porque é padrão e sempre se repete
    //mas é válido empreender um tempo para melhorar a organização disso
    await axios
          .get(`https://homolog-cert.s3.us-west-2.amazonaws.com/CompaniesLogos/${CNPJApply !== CNPJ ? CNPJApply : CNPJ}/Logo.png`)
          .then((res) => {
            setImgExists(true)
          })
          .catch((err) => {
            setImgExists(false)
          })
  }

  useEffect(() => {
    checkIfLogoExists()
  }, [imgExists]);

  return <Container
            width='100%'
            height={{base: '100%'}}
            display={'grid'}
            alignContent={'center'}
            justifyContent={'center'}
            // marginTop={'50px'}
            // marginBottom={'50px'}
          >
          <Grid marginBottom={'10px'}>
            <Title width={'100%'}>
              Informações da empresa
            </Title>
            <Text width={'100%'} style={{'fontSize':'14px'}}>
              Aqui você verá os dados da empresa, eles não são editáveis. Caso algum dado esteja estranho, verifique o seu cartão de registro do CNPJ.<br/>
              Preencha o <b>Nome do Responsável</b>, <b>E-mail</b>, <b>Senha</b> e <b>Confirmar Senha</b>.
            </Text>
          </Grid>
          <Grid templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)' }} gap={3}>
            <GridItem justifyItems={'center'}>
              <InputFile
                label="Adicione a logo da empresa"
                onClick={(el) => saveLogo(el.target.files[0])}
                img={imgExists ? `https://homolog-cert.s3.us-west-2.amazonaws.com/CompaniesLogos/${CNPJApply !== CNPJ ? CNPJApply : CNPJ}/Logo.png` : ""}
              />
            </GridItem>
            <GridItem display={'grid'} gap={3}>
              <Grid templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(3,1fr)'}} gap={3} marginBottom={'10px'} justifyItems={{base: 'center', lg: 'left'}}>
                <GridItem colSpan={1}>
                  <InputCnpj
                    label="CNPJ do comprador"
                    disabled={true}
                    icon={false}
                    value={CNPJFormatted}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <InputCnpj
                    label="CNPJ de quem vai concorrer as certificações"
                    disabled={true}
                    icon={false}
                    placeholder="Digite o CNPJ do concorrente"
                    value={CNPJApplyFormatted}
                  />
                </GridItem>
                <GridItem colSpan={1} display='grid' alignContent={'center'}>
           
                  <Checkbox
                    label="CNPJ é o mesmo do comprador"
                    disabled={true}
                    style={{pointerEvents:'none'}}
                    value={handleCNPJCompare()}
                  />
                </GridItem>
              </Grid>
              <Grid templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}} gap={3} marginBottom={'20px'} justifyItems={{base: 'center', lg: 'left'}}>
                <GridItem colSpan={1} width={{base: '260px', lg: '443px'}}>
                  <InputText
                    label="Razão social"
                    disabled={true}
                    placeholder="Digite a razão social"
                    width='100%'
                    valor={socialReason}
                  />
                </GridItem>
                <GridItem colSpan={1} width={{base: '260px', lg: '443px'}}>
                  <InputText
                    label="Nome fantasia"
                    disabled={true}
                    placeholder="Digite o nome fantasia"
                    width='100%'
                    valor={fantasyName}
                    />
                </GridItem>
              </Grid>
              <Grid templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(3,1fr)'}} gap={3} marginBottom={'20px'} justifyItems={{base: 'center', lg: 'left'}}>
                <GridItem colSpan={1} width={{base: '260px', lg: '443px'}}>
                  <InputText
                    label="Endereço"
                    disabled={true}
                    placeholder="Digite o endereço"
                    width='100%'
                    valor={address}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <InputPhone
                    label="Telefone da empresa"
                    disabled={true}
                    value={phone}
                    placeholder="Digite o telefone da empresa"
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <InputText
                    label="Nome do Responsável"
                    placeholder="Nome do Responsável"
                    width={'260px'}
                    onInput={(el) => handleResponsibleName(el.target)}
                  />
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>

          {showAddNew && 
          (<Grid templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(1,1fr)'}} gap={3} margin={'20px 0px'} justifyItems={{base: 'center', lg: 'center'}} alignItems={'center'}>
            <GridItem colSpan={{base: 1}}>
              <Text width={'100%'} style={{'fontSize':'14px', 'marginBottom':'0px'}}>
                Neste modelo de contrato você pode adicionar mais empresas na platafora.
              </Text>
              <Text width={'100%'} style={{'fontSize':'14px', 'marginBottom':'0px'}}>
                Para isso, adicione o CNPJ e clique em Adicionar Empresa.
              </Text>
            </GridItem>
            <GridItem colSpan={1} width={{base: '260px', lg: '443px'}} style={{'display':'grid','justifyItems':'center'}}>
              <div style={{'marginBottom':'10px'}}>
                <InputCnpj
                  label="CNPJ nova empresa"
                  disabled={false}
                  placeholder="Digite o cnpj"
                  width='100%'
                  onInput={(e) => checkCnpj(e.target.value)}
                  // valor={}
                />
                {(!cnpjValid || companyExist) && ( <Text fontSize="10px" color="red">{ addCompanyMsg }</Text> )}
              </div>
              <Button
                label="Adicionar empresa"
                isLoading={isLoadingAddCompany}
                disabled={cnpjValid ? false : true}
                // style={((responsibleName && email && password && confirmPassword && match && checkEmail) ? {} : {pointerEvents:'none'})}
                width='220px'
                // onClick={() => handleSendInfo()}
              />
            </GridItem>
          </Grid>)}


          <Grid templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(3,1fr)'}} gap={3} marginBottom={'10px'} marginTop={'0px'} justifyItems={{base: 'center', lg: 'left'}}>
            <GridItem colSpan={{base: 1, lg: 3}}>
              <Text width={'100%'} style={{'fontSize':'14px', 'marginBottom':'0px'}}>
                Informações de Acesso do Responsável
              </Text>
            </GridItem>
            <GridItem colSpan={1} width={{base: '260px', lg: '420px'}}>
              <InputEmail
                label="E-mail"
                placeholder="Digite o e-mail"
                width='420px'
                onInput={(el) => handleEmail(el.target)}
              />
              <Box mt="10px">
                {(email !== "") && <p
                  className={checkEmail ? "success-msg" : "error-msg"}
                > 
                 <b>{checkEmail ? "E-mail válido!" : "Por favor, insira um e-mail válido."}</b>  
                </p>}
              </Box>
            </GridItem>
            <GridItem colSpan={1} width={{base: '260px', lg: '420px'}}>
              <InputPassword
                label="Senha"
                placeholder="Digite a senha"
                width='420px'
                onInput={(el) => handlePasswordChange(el)}
              />
              <Box>
                <div style={{display:'grid', justifyContent:'center'}}>
                  <p
                    className={charNumberValid && specialCharValid && uppercaseValid && numberValid ? "success-msg" : "error-msg"}
                  > 
                    <b>Sua senha deve conter no mínimo:</b>  
                  </p>

                  <Grid wrap="wrap">
                    <div
                      className={charNumberValid ? "success-msg" : "error-msg"}
                    >
                      <img src={charNumberValid ? iconCheck : iconError} alt={charNumberValid ? "checado" : "erro"} width="12px"/>
                      <p className="validation-item">
                        8 caracteres 
                      </p>
                    </div>
                    <div
                      className={specialCharValid ? "success-msg" : "error-msg"}
                    >
                      <img src={specialCharValid ? iconCheck : iconError} alt={charNumberValid ? "checado" : "erro"} width="12px"/>
                      <p className="validation-item">
                        1 caractere especial
                      </p>
                    </div>

                    <div
                      className={uppercaseValid ? "success-msg" : "error-msg"}
                    >
                      <img src={uppercaseValid ? iconCheck : iconError} alt={charNumberValid ? "checado" : "erro"} width="12px"/>
                      <p className="validation-item">
                        1 letra maiúscula
                      </p>
                    </div>
                    <div className={numberValid ? "success-msg" : "error-msg"}>
                      <img src={numberValid ? iconCheck : iconError} alt={charNumberValid ? "checado" : "erro"} width="12px"/>
                      <p className="validation-item">
                        1 número
                      </p>
                    </div>
                  </Grid>
                </div>
              </Box>
            </GridItem>
            <GridItem colSpan={1} width={{base: '260px', lg: '420px'}}>
              <InputPassword
                label="Confirmar Senha"
                placeholder="Digite a Confirmação da Senha"
                width='420px'
                onInput={(el) => handleConfirmPasswordChange(el)}
              />
              <Box mt="10px">
                {(password !== "" && confirmPassword !== "") && <p
                  className={match ? "success-msg" : "error-msg"}
                > 
                 <b>{match ? "Agora é só confirmar!" : "As senhas não correspondem!"}</b>  
                </p>}
              </Box>
            </GridItem>
          </Grid>

          <Grid templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}} alignContent={'space-between'} row>
            <GridItem display={'grid'} colSpan={1} justifyContent={{base: 'center', lg: 'left'}} margin={{base: '20px', lg: '0px'}}>
              <a href={CONNECT_BASE_URL}>
                <Button
                  label="Cancelar"
                  type="outline"
                  width='220px'
                />
              </a>
            </GridItem>
            <GridItem display={'grid'} colSpan={1} justifyContent={{base: 'center', lg: 'right'}}>
              <Button
                label="Finalizar"
                isLoading={isLoading}
                disabled={responsibleName && email && password && confirmPassword && match && checkEmail? false : true}
                style={((responsibleName && email && password && confirmPassword && match && checkEmail) ? {} : {pointerEvents:'none'})}
                width='220px'
                onClick={() => handleSendInfo()}
              />
            </GridItem>
          </Grid>
          <SimpleModal isOpen={errorMsg} text={errorMsg} onClose={() => setErrorMsg("")}/>
          <SimpleModal isOpen={successMsg} text={successMsg} onClose={() => { window.location.replace("/login") }}/>
        </Container>
}

export default Register
