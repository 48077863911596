import React, { useEffect, useState } from "react";
import { Button, Flex, Spinner, useMediaQuery } from "@chakra-ui/react";
import {
  ButtonExportXLSX,
  ButtonExportCancelmentLogXLSX,
  CardStatus,
  Header,
  NavbarAdmin,
  Results,
  SearchBar,
} from "../../components";
import ModalFilters from "./modal/ModalFilters";
import ModalInfo from "./modal/ModalInfo";
import ModalOptions from "./modal/ModalOptions";
import ModalQuestions from "./modal/ModalQuestions";
import ModalStatus from "./modal/ModalStatus";
import TableStatus from "./table";
import StatusFilters from "./filters";
import {
  useListCompanies,
  useListStatus,
} from "../../../../hooks/Admin/useCompanyRegisterList";
import { getCookie } from "../../../common/cookieManager";

function StatusAdmin() {
  const [mobile] = useMediaQuery("(max-width: 992px)");
  const userGPTW = getCookie("user_email");
  const emailPartner = getCookie("roles").find((item) => item === "PARTNER")
    ? userGPTW
    : "";

  const [currentPage, setCurrentPage] = useState(1);

  const [meta, setMeta] = useState({
    start: 0,
    limit: 10,
    search: "",
    ufFilter: "",
    cityFilter: "",
    setorFilter: "",
    filter: "",
    accountOwnerFilter: "",
  });

  const setPage = (value) => {
    setMeta((prev) => ({
      ...prev,
      start: 10 * value - 10,
    }));

    setCurrentPage(value);
  };

  const { data: dataListStatus, isLoading, isFetching } = useListStatus(meta);

  useEffect(() => {
    const cardElement = document.getElementById("card-dashboard");
    if (cardElement) {
      if (isLoading || isFetching) {
        cardElement.classList.add("loading-cursor");
      } else {
        cardElement.classList.remove("loading-cursor");
      }
    }
  }, [isFetching, isLoading]);

  const { data: dataListCompanies } = useListCompanies({
    pageSize: 10,
    skip: 0,
    emailPartner: emailPartner,
    textSearch: "",
  });

  const [openModal, setOpenModal] = useState({
    openModalInfo: false,
    openModalFilters: false,
    openModalOptions: false,
    openModalQuestions: false,
    openModalStatus: false,
    data: undefined,
  });

  const handleModalInfo = (data) => {
    setOpenModal((prev) => ({
      ...prev,
      data: data,
      openModalInfo: !prev.openModalInfo,
    }));
  };
  const handleModalFilters = () => {
    setOpenModal((prev) => ({
      ...prev,
      openModalFilters: !prev.openModalFilters,
    }));
  };
  const handleModalOptions = () => {
    setOpenModal((prev) => ({
      ...prev,
      openModalOptions: !prev.openModalOptions,
    }));
  };
  const handleModalQuestions = () => {
    setOpenModal((prev) => ({
      ...prev,
      openModalQuestions: !prev.openModalQuestions,
    }));
  };
  const handleModalStatus = () => {
    setOpenModal((prev) => ({
      ...prev,
      openModalStatus: !prev.openModalStatus,
    }));
  };

  const handleModalClose = () => {
    setOpenModal({
      openModalInfo: false,
      openModalFilters: false,
      openModalOptions: false,
      openModalQuestions: false,
      openModalStatus: false,
      data: undefined,
    });
  };

  return (
    <NavbarAdmin>
      {isLoading ? (
        <Flex height="400px" justify={"center"} align="center">
          <Spinner />
        </Flex>
      ) : (
        <Flex gap={4} direction={"column"}>
          <Flex
            direction={["column", "column", "column", "row"]}
            gap={"24px"}
            justify="space-between"
            align={"center"}
            w="100%"
            minH="80px"
          >
            <Header title={"Status dos Clientes"} total={""} />
            {mobile ? (
              <>
                <SearchBar
                  textSearch={meta.search}
                  setTextSearch={(value) => {
                    setMeta((prev) => ({
                      ...prev,
                      search: value,
                    }));
                  }}
                  placeholder={"Digite o nome da empresa"}
                />
                <Flex gap="16px" w="100%" wrap={["wrap"]}>
                  <Button
                    variant="outlineRed"
                    onClick={handleModalOptions}
                    flex="1"
                    minW="130px"
                  >
                    Opções
                  </Button>
                  <Button
                    variant="red"
                    onClick={handleModalFilters}
                    flex="1"
                    minW="130px"
                  >
                    Filtros
                  </Button>
                  <ButtonExportXLSX minW="fit-content" flex={"1"} meta={meta} />
                </Flex>
              </>
            ) : (
              <>
                <Button variant="outlineRed" onClick={handleModalQuestions}>
                  Respostas dos Questionários
                </Button>
                <Button variant="red" onClick={handleModalStatus}>
                  Status Certificação
                </Button>
                <ButtonExportXLSX minW="fit-content" meta={meta} />
                <ButtonExportCancelmentLogXLSX minW="fit-content" meta={meta} />
              </>
            )}
          </Flex>

          {mobile ? (
            dataListStatus?.map((companie, index) => {
              return (
                <CardStatus
                  data={companie}
                  key={`${companie.codConnect}${index}`}
                  onClick={() => handleModalInfo(companie)}
                />
              );
            })
          ) : (
            <>
              <StatusFilters filtersValues={meta} setFiltersValues={setMeta} />
              <TableStatus
                data={dataListStatus}
                onClick={(companie) => handleModalInfo(companie)}
              />
            </>
          )}
          <Results
            meta={{
              last_page: Math.ceil(dataListCompanies?.count / 10),
              actual: null,
              total: null,
            }}
            currentPage={currentPage}
            setCurrentPage={setPage}
          />
        </Flex>
      )}

      <ModalFilters
        isOpen={openModal.openModalFilters}
        onClose={handleModalClose}
        setFiltersValues={setMeta}
        data={openModal.data}
      />

      <ModalInfo
        isOpen={openModal.openModalInfo}
        onClose={handleModalClose}
        data={openModal.data}
      />
      <ModalOptions
        isOpen={openModal.openModalOptions}
        onClose={handleModalClose}
        handleModalQuestions={handleModalQuestions}
        handleModalStatus={handleModalStatus}
      />
      <ModalQuestions
        isOpen={openModal.openModalQuestions}
        onClose={handleModalClose}
      />
      <ModalStatus
        isOpen={openModal.openModalStatus}
        onClose={handleModalClose}
      />
    </NavbarAdmin>
  );
}

export default StatusAdmin;
