import { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  useMediaQuery,
} from "@chakra-ui/react";
import { FileInput } from "../FileInput";
import { useMutation } from "react-query";
import { queryClient } from "../../../../services/queryCliente";
import SuccessModal from "../../../components/modal/SuccessModal";

const UploadSheetModal = ({
  onClose,
  isOpen,
  researchId,
  handleDownloadList,
  openUpgradeModal,
  refresh,
}) => {
  const [success, setSuccess] = useState(false);
  const [currentFile, setCurrentFile] = useState();
  const [employeesTotal, setEmployeesTotal] = useState(0);
  const [errorsTotal, setErrorsTotal] = useState(0);
  const [imageUrl, setImageUrl] = useState("");

  const [mobile] = useMediaQuery("(max-width: 1090px)");

  const { register, handleSubmit, formState, setError, trigger } = useForm();
  const { errors } = formState;

  const handleClose = () => {
    onClose();
    if (refresh) refresh()

    handleDownloadList();
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
    setCurrentFile(undefined);
  };

  const upsateSheet = useMutation(async () => {}, {
    onSuccess: () => {
      queryClient.invalidateQueries("research");
    },
  });

  const onSubmit = async (values) => {
    if (employeesTotal === 0) return;
    else {
      const response = await upsateSheet.mutateAsync();
      setSuccess(true);
      setEmployeesTotal(0);
      handleDownloadList();
    }
  };

  return success ? (
    <SuccessModal
      title="Planilha enviada com sucesso!"
      isOpen={isOpen}
      text="Por favor, aguarde enquanto processamos os dados. Neste momento, estamos cadastrando os colaboradores na pesquisa. Esse processo pode levar algum tempo, dependendo do número de colaboradores convidados. IMPORTANTE: Durante esse processo, não é possível realizar outras configurações na pesquisa."
      onClose={handleClose}
    />
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent
        w={["100vw", "581px"]}
        p="0px"
        borderRadius="20px"
        overflow="hidden"
        color="#292B30"
      >
        <ModalHeader p={["24px 24px 8px 24px", "40px"]}>
          <Box h="5px" w="20px" mb="8px" bg="#40BF91" />
          <Heading fontWeight="600" fontSize="18px">
            Enviar planilha de colaboradores
          </Heading>
        </ModalHeader>
        <ModalCloseButton bg="#F1F5FA"
        rounded={'full'}
         />
        <form onSubmit={onSubmit}>
          <ModalBody px={["24px", "40px"]}>
            <Text fontSize="14px" mb="24px">
              Após <b>baixar</b> o nosso template e preencher as informações
              envie para nós.
            </Text>

            <FileInput
              errorsTotal={errorsTotal}
              setEmployeesTotal={setEmployeesTotal}
              researchId={researchId}
              setImageUrl={setImageUrl}
              currentFile={currentFile}
              setCurrentFile={setCurrentFile}
              setError={setError}
              setErrorsTotal={setErrorsTotal}
              trigger={trigger}
              {...register("file")}
              error={errors.file}
              onClose={handleClose}
              openUpgradeModal={openUpgradeModal}
            />
          </ModalBody>
          <ModalFooter p={0}>
            <Button
              type="submit"
              bg={currentFile ? "red.100" : "#292B30"}
              opacity={currentFile ? "1" : "0.5"}
              borderRadius={0}
              h={["56px", "69px"]}
              w="100%"
              color="white"
              fontWeight="400"
            >
              Salvar planilhas
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UploadSheetModal;
