import { Button } from "@chakra-ui/button";

export function PaginationItem({ isCurrent = false, number, ...props }) {
  return (
    <Button
      w="38px"
      height="38px"
      maxW="38px"
      minW="unset"
      p={0}
      fontWeight={600}
      color={isCurrent ? "#fff" : "#22262D"}
      cursor={isCurrent ? "not-allowed" : "pointer"}
      isDisabled={isCurrent}
      _disabled={{
        opacity: "none",
        background: "#FF1628",
      }}
      _hover={{
        opacity: isCurrent ? "#FF1628" : 0.7,
      }}
      borderRadius={"none"}
      {...props}
    >
      {number}
    </Button>
  );
}
