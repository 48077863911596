import React from 'react'
import styled from 'styled-components'
import { Icon, Grid, Select } from '../../../style'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ContainerNav = styled.nav`
  margin-left: -33px;
  display: flex;
  flex-direction: row-reverse;

  background-color: #fff;

  position: fixed;
  top: 0;
  right: 0;

  width: 86%;

  z-index: 1;

`

const TextRed = styled.p`

font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 15px;
text-align: right;

color: #E60000;


`

const TextBlack = styled.p`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 14px;

text-align: right;

color: #5C6066;

margin-top: 4px;
`

function NavBarHorizontal(){
  const usuarioSistema = useSelector(state=> state.clickState.usuario);

  return <ContainerNav>

            <div style={{
              marginTop: '20px',
              marginBottom: '20px',
              marginleft: '24px',
              marginRight: '32px'
            }}>
            </div>
            <div style={{
              marginTop: '20px',
              marginBottom: '20px',
              marginLeft: '24px',
              marginRight: '24px'
            }} >
            </div>
            <div style={{
              marginLeft: '12px',
              marginRight: '24px',
              marginTop: '12px',
              marginBottom: '12px'
            }}>
              <Icon type="circle" width="40px" height="40px" />
            </div>
            <Grid column style={{
              marginTop: '12px',
              marginBottom: '12px'
            }}>
              <TextRed>
                {usuarioSistema.nome}
              </TextRed>
              <TextBlack>
                {usuarioSistema.email}
              </TextBlack>
            </Grid>
            <div
              style={{
                marginRight: '32px',
                marginLeft: '32px',
                marginTop: '20px',
                marginBottom: '20px'
              }}
            >
            </div>
            <div
              style={{
                marginTop: '14px',
                marginBottom: '14px'
              }}
            >
            </div>
         </ContainerNav>
}

export default NavBarHorizontal
