import React, { useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Flex,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { TagStatus } from "../../../components";
import { TbDots } from "react-icons/tb";
import { IconExclamation } from "../../../assets";
import { CNPJconverter } from "../../../../../helpers/CNPJconverter";

const MOCK_HEADER_TABLE_STATUS = [
  { name: "Nome da empresa", minW: "248px" },
  { name: "CNPJ", minW: "202px" },
  { name: "Produto", minW: "208px" },
  { name: "Estado", minW: "202px" },
  { name: "Responsável", minW: "266px" },
  { name: "Setor", minW: "148px" },
  { name: "Dono da conta", minW: "171px" },
  { name: "Status da Certificação", minW: "220px" },
  { name: "Detalhes", minW: "fit-content" },
];

const TableStatus = ({ data, onClick }) => {
  return (
    <TableContainer>
      <Table size="sm">
        <Thead bg="#C4CCCC">
          <Tr>
            {MOCK_HEADER_TABLE_STATUS.map((item, index) => {
              return (
                <Th
                  h="40px"
                  key={index}
                  p={
                    index === 0
                      ? "4px 0 4px 16px"
                      : MOCK_HEADER_TABLE_STATUS.length === index + 1
                      ? "4px 16px 4px 0px"
                      : "4px 0 4px"
                  }
                  minW={item.minW}
                  maxW={item.minW}
                  whiteSpace={"wrap"}
                  borderBottom="2px solid transparent"
                  textTransform="none"
                  fontSize={"14px"}
                  lineHeight={"16px"}
                  fontWeight={500}
                  color="#22262D"
                >
                  {item.name}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody bg="#ffffff">
          {data?.map((item, index) => {
            return (
              <Tr key={index} borderBottom="2px solid #F5F8F6">
                <Td borderBottom={"unset"} p="19px 0 19px 16px">
                  <TextTable text={item?.fantasia} />
                </Td>
                <Td borderBottom={"unset"} p="19px 0">
                  <TextTable text={CNPJconverter(item?.cnpj)} />
                </Td>
                <Td borderBottom={"unset"} p="19px 0">
                  <TextTable text={item?.Plano} />
                </Td>
                <Td borderBottom={"unset"} p="19px 0">
                  <TextTable text={`${item?.cidade} - ${item?.estado} `} />
                </Td>
                <Td borderBottom={"unset"} p="19px 0">
                  <TextTable text={item?.nomeContato} />
                </Td>
                <Td borderBottom={"unset"} p="19px 0">
                  <TextTable text={item?.setor} />
                </Td>
                <Td borderBottom={"unset"} p="19px 0">
                  <TextTable text={item?.donoConta} />
                </Td>
                <Td borderBottom={"unset"} p="19px 0">
                  <TextTable
                    tag={true}
                    text={item?.statusConnect}
                    data={item}
                  />
                </Td>
                <Td borderBottom={"unset"} p="19px 16px 19px 0">
                  <Flex p="12.5px 14.5px 12.5px 0px" justify="end">
                    <IconButton
                      aria-label="Mais Infos"
                      fontSize={"23px"}
                      bg="#ADAFB2"
                      color="#32383F"
                      minW="27px"
                      h="27px"
                      p="0"
                      outline={"none"}
                      icon={<TbDots />}
                      onClick={() => onClick(item)}
                    />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const TextTable = ({ text, tag, data }) => {
  const detailsStatus = () => {
    const status = data?.statusConnect || "";
    const diasParaExpirarCertificado = data?.diasParaExpirarCertificado || 0;

    switch (true) {
      case diasParaExpirarCertificado < 100 && diasParaExpirarCertificado > 0:
        return `${diasParaExpirarCertificado}`;
      case status.includes("andamento"):
      case status.includes("processo"):
      case status.includes("Reprovado"):
      case status.includes("Ainda"):
        return status;
      default:
        return "----";
    }
  };

  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    let timerId;
    if (isOpen) {
      timerId = setTimeout(onToggle, 3000);
    }
    return () => clearTimeout(timerId);
  }, [isOpen, onToggle]);

  return (
    <>
      {!!tag ? (
        <Flex
          h="fit-content"
          bg="white"
          w="fit-content"
          gap="12px"
          align="center"
        >
          <TagStatus name={text} transparent={true} />
          {!!!detailsStatus().includes("----") ? (
            <Tooltip
              closeOnMouseDown
              isOpen={isOpen}
              onClick={onToggle}
              label={detailsStatus()}
              aria-label={detailsStatus()}
              hasArrow
            >
              <Flex width="24px" height="24px" onClick={onToggle}>
                <IconExclamation width="24px" height="24px" />
              </Flex>
            </Tooltip>
          ) : null}
        </Flex>
      ) : (
        <Text
          fontWeight={500}
          fontSize={"14px"}
          lineHeight={"14px"}
          color={"#22262D"}
          whiteSpace={"wrap"}
          wordBreak="break-word"
          mr="8px"
        >
          {!!text && text !== "null" ? text : "----"}
        </Text>
      )}
    </>
  );
};
export default TableStatus;
