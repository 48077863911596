const MOCK_LIST_FILTERS_STATES = {
  data: [
    { value: "AC", label: "Acre - AC" },
    { value: "AL", label: "Alagoas - AL" },
    { value: "AP", label: "Amapá - AP" },
    { value: "AM", label: "Amazonas - AM" },
    { value: "BA", label: "Bahia - BA" },
    { value: "CE", label: "Ceará - CE" },
    { value: "DF", label: "Distrito Federal - DF" },
    { value: "ES", label: "Espírito Santo - ES" },
    { value: "GO", label: "Goiás - GO" },
    { value: "MA", label: "Maranhão - MA" },
    { value: "MT", label: "Mato Grosso - MT" },
    { value: "MS", label: "Mato Grosso do Sul - MS" },
    { value: "MG", label: "Minas Gerais - MG" },
    { value: "PA", label: "Pará - PA" },
    { value: "PB", label: "Paraíba - PB" },
    { value: "PR", label: "Paraná - PR" },
    { value: "PE", label: "Pernambuco - PE" },
    { value: "PI", label: "Piauí - PI" },
    { value: "RJ", label: "Rio de Janeiro - RJ" },
    { value: "RN", label: "Rio Grande do Norte - RN" },
    { value: "RS", label: "Rio Grande do Sul - RS" },
    { value: "RO", label: "Rondônia - RO" },
    { value: "RR", label: "Roraima - RR" },
    { value: "SC", label: "Santa Catarina - SC" },
    { value: "SP", label: "São Paulo - SP" },
    { value: "SE", label: "Sergipe - SE" },
    { value: "TO", label: "Tocantins - TO" },
  ],
};

const MOCK_LIST_FILTERS_STATUS = {
  data: [
    { status: "Em processo de pesquisa" },
    { status: "Certificado Reprovado" },
    { status: "Certificado Ativo" },
    { status: "Certificado Expirado" },
    { status: "Certificado à Expirar" },
  ],
};

const MOCK_LIST_FILTERS_PRODUCTS = [
  { value: "Light 100 a 999", label: "Light 100 a 999" },
  { value: "Light 30 a 99", label: "Light 30 a 99" },
  { value: "Light 5 a 29", label: "Light 5 a 29" },
  { value: "Light acima de 1000", label: "Light acima de 1000" },
  { value: "Plus 100 a 999", label: "Plus 100 a 999" },
  { value: "Plus 30 a 99", label: "Plus 30 a 99" },
  { value: "Plus acima de 1000", label: "Plus acima de 1000" },
  { value: "Premium 100 a 999", label: "Premium 100 a 999" },
  { value: "Premium 30 a 99", label: "Premium 30 a 99" },
  { value: "Premium acima de 1000", label: "Premium acima de 1000" },
  { value: "Projeto de consultoria", label: "Projeto de consultoria" },
];

export {
  MOCK_LIST_FILTERS_STATES,
  MOCK_LIST_FILTERS_STATUS,
  MOCK_LIST_FILTERS_PRODUCTS,
};
