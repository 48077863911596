import { Icon } from "@chakra-ui/react";

const IconExclamation = (props) => {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        d="M12 8V13M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
        stroke="#444C54"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M11.995 16H12.004"
        stroke="#444C54"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </Icon>
  );
};

export default IconExclamation;
