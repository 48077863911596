import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Buffer } from "buffer";
import {
  getCookie,
  removeAllCookie,
  setCookie,
} from "../../common/cookieManager";
import RenovationModal from "../../components/modal/RenovationModal";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Stack,
  Icon,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  Drawer,
  DrawerContent,
  Image,
  MenuList,
  MenuItem,
  Button,
  Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  FiHome,
  FiBook,
  FiCompass,
  FiSettings,
  FiUsers,
  FiExternalLink,
} from "react-icons/fi";
import { IoPodiumOutline } from "react-icons/io5";
import { AiOutlineMenu } from "react-icons/ai";
import {
  RiBillLine,
  RiPlayCircleFill,
  RiPlayCircleLine,
  RiPlayFill,
} from "react-icons/ri";
import { researchApi } from "../../../services/api-research";
import getFaturaService from "../../../services/getFaturaService";
import axios from "axios";
import { isPast, add, daysToWeeks, addMonths, isBefore } from "date-fns";
import { useResearchInfoDataQuery } from "../../../hooks/useResearchInfo";
import { useParticipationData } from "../../../hooks/useParticipationData";
import {
  logoSidebar,
  language,
  navbarIcon,
  iconSettings,
  iconLogout,
  selo5anosJPG,
  selo5anosPNG,
  selo5anosSVG,
  logo,
} from "../../../assets/images";
import logoGreatWork from "../../../assets/images/logoGreatWork.png";
import NotificationMenu from "../modal/NotificationsModal";
import { notificationsMock, empresas5AnosMock } from "./data";
import apiNotificationCenter from "../../../services/api-notification-center";
import SimpleModal from "../modal/SimpleModal";
import seloZip from "../../../assets/Selo_5_Anos.zip";
import { get } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CustomContext } from '../../../context/CustomContext';


export default function SidebarWithHeader({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const cr = urlParams.get("cr");
  const st = urlParams.get("st");
  const token = btoa(cr);
  const history = useHistory();

  const [companyName, setCompanyName] = useState("");
  const [cnpj, setCnpj] = useState();
  const [applicationEndDate, setApplicationEndDate] = useState("");
  const [applicationCreationDate, setApplicationCreationDate] = useState("");
  const [salesforceParticipationCode, setSalesforceParticipationCode] =
    useState("");
  const [participationPlan, setParticipationPlan] = useState("");
  const [listInvoice, setListInvoice] = useState("");
  const [contractDate, setContractDate] = useState("-");
  const [contractFinalDate, setContractFinalDate] = useState("-");
  const [emailOwnerAccount, setOwnerAccount] = useState("");
  const [renovationModal, setRenovationModal] = useState(false);
  const [voucher, setVoucher] = useState(undefined);
  const [canRenovation, setCanRenovation] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [notificationsCenter, setnotificationsCenter] = useState([]);
  const [planType, setPlanType] = useState("");
  const [sixMonthsExpired, setSixMonthsExpired] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [activeLinks, setActiveLinks] = useState([]);
  const { setConsultant } = useContext(CustomContext);
  const { setPlan } = useContext(CustomContext);
  const [tokenSignatures, setTokenSignatures] = useState();

  useEffect(() => {
    setTokenSignatures(Buffer.from(`${cnpj}:47a652098d0d85496c6ebb7bb0599597`).toString("base64"));
  }, [cnpj]);

  const { data: researchInfo } = useResearchInfoDataQuery(cr);
  const { data: participationData } = useParticipationData(cnpj);
  const companiesEncripted = getCookie("companies");
  const companiesWithCREncripted = getCookie("registers");

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [fullPlan, setfullPlan] = useState("");
  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleModalRenovation = () => {
    setRenovationModal(true);
  };

  const handleCloseModalRenovation = () => {
    setRenovationModal(false);
  };

  const manageResponsibles = () => {
    const url = `${window["runConfig"].REACT_APP_V2_URL}/manage-responsibles`;
    window.open(url, "_blank");
  };

  const downloadUseTerms = () => {
    history.push(`/terms?cr=${cr}&st=${st}&token=${token}`);
    // window.open(
    //   "https://gptwcertification.s3.us-west-2.amazonaws.com/templates/Termos_de_uso.pdf",
    //   "_blank"
    // );
  };

  //   const billsConnect = () => {
  //     console.log('cnpj ' + cnpj)
  //     const token = Buffer.from(cnpj).toString('base64')
  // 		window.open(`https://connect-financial-app.gptw.info/bills?token=${token}`,
  //      "_blank")
  // 	}

  const helpCenter = () => {
    window.open(`https://gptw-brasil.zendesk.com/hc/pt-br`, "_blank");
  };

  const updateFantasyName = () => {
    const url = `${window["runConfig"].REACT_APP_V2_URL}/company-information`;
    window.open(url, "_blank");
  };

  const logout = () => {
    removeAllCookie();
    window.location.href = "https://connect.gptw.info/login";
  };

  const changeCompany = (cr) => {
    window.location.href = `/dashboard?cr=${cr}`;
  };

  useEffect(() => {
    function findAndAddCR(array1, array2) {
      return array1.map((item1) => {
        const matchingObj = array2.find(
          (item2) => item2?.id_company === item1.id
        );
        if (matchingObj) {
          return { ...item1, cr: matchingObj.id };
        }
        return item1;
      });
    }

    if (!companies && companiesEncripted && companiesEncripted?.length > 1) {
      const companiesDecripted = atob(companiesEncripted);
      const companiesWithCRDecripted = atob(companiesWithCREncripted);

      const companiesParsed = JSON.parse(companiesDecripted);
      const companieWithCrdsParsed = JSON.parse(companiesWithCRDecripted);

      const matchValues = findAndAddCR(companiesParsed, companieWithCrdsParsed);
      //console.log("matchValues", matchValues);
      setCompanies(matchValues);
    }
  }, [companies, companiesEncripted, companiesWithCREncripted]);

  useEffect(() => {
    async function getCompanyData() {
      try {
        const { data } = await researchApi.get(`/research/status/${cr}`);
        setCompanyName(data.name);
      } catch (err) {
        console.log("Err", err);
      }
    }

    getCompanyData();
  }, [cr]);

  const checkRenovation = (endDate, creationDate) => {
    // Verifica se pode aparecer o botão de "Renovação"
    // O botão pode aparecer para quem já aplicou a pesquisa a mais de 3 meses
    if (endDate !== null && endDate !== undefined) {
      const date = new Date(endDate);
      const threeMonthsSinceDateEnd = addMonths(date, 3);

      if (isBefore(threeMonthsSinceDateEnd, new Date())) {
        setCanRenovation(true);
      }
    } else {
      const appCreationDate = new Date(creationDate);
      const sixMonthsSinceCreationDate = addMonths(appCreationDate, 6);

      if (isBefore(sixMonthsSinceCreationDate, new Date())) {
        setCanRenovation(true);
        setSixMonthsExpired(true);
      }
    }
  };

  useEffect(() => {
    if (researchInfo) {
      if (researchInfo?.applicationEndDate) {
        setApplicationEndDate(researchInfo?.applicationEndDate);
      }
      if (researchInfo?.applicationCreationDate) {
        setApplicationCreationDate(researchInfo?.applicationCreationDate);
      }
      setSalesforceParticipationCode(researchInfo?.salesforceParticipationCode);

      checkRenovation(
        researchInfo?.applicationEndDate,
        researchInfo?.applicationCreationDate
      );
    }
  }, [researchInfo]);

  useEffect(async () => {
    //verificando o plano para abertura dos pilares
    const urlConnect =
      window["runConfig"].REACT_APP_URL_FINANCIAL_API +
      `/connect/connect-product?secret=${window["runConfig"].REACT_APP_FINANCIAL_SECRET}&cr=${cr}`;

    axios.get(urlConnect).then((response) => {
      if (response.data.data && Object.keys(response.data.data).length !== 0) {
        let plan;
        let surveyType;
        let fullPlan = response.data.data.name.split("_");
        setfullPlan(response.data.data.name);
        switch (fullPlan[0]) {
          case "PREMIUM":
            plan = "PERFORMANCE";
            surveyType = "CERTIFICATION";
            break;
          case "PLUS":
            const plano = fullPlan[1] == 'MICRO' ? 'STARTER' : 'PRO'
            plan = plano;
            surveyType = "CERTIFICATION";
            break;
          case "LIGHT":
            plan = "BASIC";
            surveyType = "CERTIFICATION";
            break;
          default:
            plan = "CONSULTORIA";
            surveyType = "CONSULTING";
            break;
        }

        setCookie("PLANO", fullPlan[0]);
        setCookie("PLANO_FULL", response.data.data.name);
        setParticipationPlan(plan);
        setPlan(plan)
        setPlanType(surveyType);
      }
    });
  }, [cr, participationPlan, cnpj, fullPlan]);

  useEffect(() => {
    async function getCompanyFullData() {
      const url_company_info =
        window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
        `/company-register-info?cr=${cr}`;

      try {
        const company_info = await axios.get(url_company_info);

        const url_consultor =
          window["runConfig"].REACT_APP_URL_FINANCIAL_API +
          `/salesforce/responsibleConsultant?secret=${window["runConfig"].REACT_APP_FINANCIAL_SECRET}&cnpj=${company_info.data.company.cnpj}`;

        const consultor = await axios.get(url_consultor);

        if (!company_info.data.company.isProject) {
          if (
            !consultor.data.hasError &&
            consultor.data.data.message.totalSize !== 0 && consultor.data.data.message !== "NÃO DISPONIVEL"
          ) {
            const consultant = consultor.data.data.message.records[0].Sucesso_do_Cliente_em_Certificacao__c;
            setOwnerAccount(consultant);
            setConsultant(consultant);
          }
        } else {
          if (
            !consultor.data.hasError &&
            consultor.data.data.message.totalSize !== 0 && consultor.data.data.message !== "NÃO DISPONIVEL"
          ) {
            const consultant = consultor.data.data.message.records[0].Consultor_Regional__c;
            setOwnerAccount(consultant);
            setConsultant(consultant);
          }
        }

        if (company_info.data.company.voucher) {
          setVoucher(company_info.data.company.voucher);
        }

        console.log(company_info.data);
        console.log('AQUI');

        setCnpj(
          company_info.data.company.cnpj
            .replace(/\./g, "")
            .replace(/-/g, "")
            .replace(/\//, "")
        );
      } catch (err) {
        console.log("Err", err);
      }
    }

    getCompanyFullData();
  }, [cr]);

  useEffect(() => {
    async function getParticipationInvoices() {
      if (cnpj) {
        let document = cnpj;

        let key = "47a652098d0d85496c6ebb7bb0599597";

        getFaturaService(document, key).then((items) => {
          setListInvoice(items);
        });
      }
    }

    getParticipationInvoices();
  }, [cnpj]);

  useEffect(async () => {
    async function getDates() {
      try {
        let date;
        let finalDate;

        if (voucher) {
          const url_close_date =
            window["runConfig"].REACT_APP_URL_FINANCIAL_API +
            `/salesforce/opportunityCloseDate?secret=${window["runConfig"].REACT_APP_FINANCIAL_SECRET}&idOpportunity=${voucher}`;
          const { data } = await axios.get(url_close_date);

          if (data.data.message.records && data.data.message.records.length) {
            date = data.data.message.records[0].CloseDate.split("-");
            date = new Date(`${date[1]}/${date[2]}/${date[0]}`);
          }
        } else {
          date = new Date(applicationCreationDate); // Timestamp
          date = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getUTCDate()
          ); // Formatando pra UTC
        }

        // setApplicationEndDate(data.applicationEndDate)
        // setSalesforceParticipationCode(data.salesforceParticipationCode)

        if (date) {
          let yearFromDate = date.getFullYear();
          let monthFromYear = date.getMonth();
          let dayFromYear = date.getUTCDate();
          // console.log(date.toLocaleDateString("pt-BR"));
          finalDate = new Date(yearFromDate + 1, monthFromYear, dayFromYear);

          setContractFinalDate(finalDate.toLocaleDateString("pt-BR"));
          setContractDate(date.toLocaleDateString("pt-BR"));
        }
      } catch (err) {
        console.log("Err", err);
      }
    }
    getDates();

    const objNotification = {
      cnpj: cnpj,
      salesforceParticipationCode: salesforceParticipationCode,
      companyRegister: cr,
    };
    const { data } = await apiNotificationCenter.post(
      "/notification-center",
      objNotification
    );

    if (!data.hasError) {
      setnotificationsCenter(data.notification);
    }
  }, [listInvoice, applicationCreationDate, voucher]);

  const [LinkItems, setLinkItems] = useState([]);

  useEffect(() => {
    setLinkItems([
      {
        name: "Início",
        icon: FiHome,
        path: `/dashboard?cr=${cr}&st=${st}&token=${token}`,
        ref: "dashboard",
        type: "All",
      },
      {
        name: "Iniciar pesquisa",
        icon: RiPlayCircleLine,
        path: `/researches/certification?cr=${cr}&pd=${fullPlan}`,
        ref: "certification",
        type: "onlyCertification",
      },
      {
        name: "Colaboradores",
        icon: FiUsers,
        path: `/employees?cr=${cr}&st=${st}&token=${token}`,
        ref: "employees",
        type: "onlyCertification",
      },
      {
        name: "Questionários",
        icon: FiBook,
        path: `/registration/profile?cr=${cr}&token=${token}`,
        ref: "registration",
        type: "All",
      },
      {
        name: "Resultados",
        icon: FiCompass,
        path: `/results?cr=${cr}`,
        ref: "results",
        type: "All",
      },
      {
        name: "Rankings",
        icon: IoPodiumOutline,
        path: `/rankings?cr=${cr}&st=${st}&token=${token}`,
        ref: "rankings",
        type: "All",
      },
      {
        name: "Meus certificados",
        icon: FiSettings,
        path: `/certificates?cr=${cr}&st=${st}&token=${token}`,
        ref: "certificates",
        type: "All",
      },
      {
        name: "Faturas",
        icon: RiBillLine,
        path: `/bills?cr=${cr}&st=${st}&token=${tokenSignatures}`,
        ref: "bills",
        type: "All",
      },
      // {
      //   name: "Ajuda",
      //   icon: BiMessageAlt,
      //   path: `/ajuda?cr=${cr}`,
      //   ref: "ajuda",
      // },
      {
        name: "Comunidade GreatWork",
        path: `https://gptw-pwa.dialog.cm/login`,
        ref: "GreatWork",
        src: logoGreatWork,
        type: "All",
      },
    ]);
  }, [cr, st, token, fullPlan, tokenSignatures]);

  const isLargerThan1280 = useMediaQuery("min-width:1280px");

  useEffect(() => {
    const filteredLinks = LinkItems.filter((link) => {
      const today = new Date();
      const endDate = new Date(applicationEndDate);

      if (planType && planType === "CERTIFICATION") {
        if (!sixMonthsExpired && endDate && !isBefore(endDate, today)) {
          return true;
        } else {
          return link.ref !== "certification" && link.ref !== "employees";
        }
      } else if (!planType) {
        return link.type !== "onlyCertification";
      }
    });
    if (filteredLinks) {
      setActiveLinks(filteredLinks);
    }
  }, [planType, cnpj, LinkItems]);

  return (
    <Box
      w="100vw"
      minH="100vh"
      maxW="100vw"
      overflowX={"hidden"}
      overflowY={"hidden"}
      position={"relative"}
      bg="#f6f5fb"
    >
      <SidebarContent
        companyName={companyName}
        participationPlan={participationPlan}
        contractDate={contractDate}
        contractFinalDate={contractFinalDate}
        emailOwnerAccount={emailOwnerAccount}
        location={location}
        activeLinks={activeLinks}
        //filteredLinks={filteredLinks}
        onClose={() => onClose}
        handleModalRenovation={handleModalRenovation}
        renovationModal={renovationModal}
        canRenovation={canRenovation}
        handleCloseModalRenovation={handleCloseModalRenovation}
        h="100%"
        // overflowY="auto"
        display={{ base: "none", md: "none", lg: "none", xl: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent overflowY="auto">
          <SidebarContent
            companyName={companyName}
            participationPlan={participationPlan}
            contractDate={contractDate}
            contractFinalDate={contractFinalDate}
            emailOwnerAccount={emailOwnerAccount}
            location={location}
            activeLinks={activeLinks}
            //filteredLinks={filteredLinks}
            onClose={onClose}
            canRenovation={canRenovation}
            handleModalRenovation={handleModalRenovation}
            renovationModal={renovationModal}
            handleCloseModalRenovation={handleCloseModalRenovation}
            manageResponsibles={manageResponsibles}
            downloadUseTerms={downloadUseTerms}
            // billsConnect={billsConnect}
            helpCenter={helpCenter}
            updateFantasyName={updateFantasyName}
            logout={logout}
            companies={companies}
            changeCompany={changeCompany}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav
        onOpen={onOpen}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        onCloseModal={onCloseModal}
        cnpj={cnpj}
        companyName={companyName}
        manageResponsibles={manageResponsibles}
        downloadUseTerms={downloadUseTerms}
        helpCenter={helpCenter}
        updateFantasyName={updateFantasyName}
        logout={logout}
        companies={companies}
        changeCompany={changeCompany}
        notifications={notificationsCenter}
        canRenovation={canRenovation}
      />
      <Box
        ml={{ base: 0, md: 0, lg: 0, xl: "214px" }}
        p={["24px", "24px 30px"]}
      >
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  onClose,
  location,
  companyName,
  contractDate,
  contractFinalDate,
  participationPlan,
  applicationEndDate,
  emailOwnerAccount,
  handleModalRenovation,
  renovationModal,
  handleCloseModalRenovation,
  canRenovation,
  companies,
  changeCompany,
  planType,
  type,
  activeLinks,
  ...rest
}) => {
  return (
    <Box
      pl={["0", "28px"]}
      transition="3s ease"
      bg="white"
      w={["full", "full", "full", "full", "220px"]}
      pos="fixed"
      h="100vh"
      overflow="auto"
      {...rest}
    >
      <Flex
        className="link-active"
        p={[
          "32px 24px 12px 24px",
          "32px 24px 12px 0px",
          "32px 24px 12px 0px",
          "40px 24px 20px 0",
        ]}
        alignItems="center"
        borderBottom={["1px solid #E5E5E5", "none"]}
        justifyContent="space-between"
      >
        <Image src={logoSidebar} alt="sidebar logo" w={["48px", "60px"]} />

        <CloseButton
          display={{ base: "flex", md: "flex", lg: "flex", xl: "none" }}
          rounded="full"
          bg={"gray.50"}
          onClick={onClose}
        />
      </Flex>
      <Stack
        align="flex-start"
        spacing={["20px"]}
        pl={["24px", "unset"]}
        pt={["32px", "32px", "unset"]}
      >
        <Text
          fontSize="13px"
          display={["flex", "flex", "none"]}
          maxW="60%"
          color="#E60000"
          fontWeight="600"
          flex="1"
        >
          {companyName}
        </Text>
        <Flex
          w="100%"
          maxH="430px"
          // h="calc(100vh - 430px)"
          // overflowY={"auto"}
          direction="column"
        >
          <Flex direction="column" h="fit-content">
            {activeLinks.map((link) => (
              <NavItem
                location={location}
                current={link.ref}
                key={link.name}
                icon={link.icon}
                path={link.path}
              >
                <Text
                  lineHeight={["22px"]}
                  fontWeight={[
                    location.pathname.includes(link.ref) ? 600 : 400,
                    location.pathname.includes(link.ref) ? 500 : 400,
                  ]}
                >
                  {link.name}
                </Text>
                {location.pathname.includes(link.ref) && (
                  <Box
                    w={["6px", "4px"]}
                    h={["48px", "32px"]}
                    bg="#E60000"
                    ml="auto"
                  />
                )}
              </NavItem>
            ))}
          </Flex>
        </Flex>
        <Flex alignSelf={["start"]}>
          <Box
            lineHeight={["22px"]}
            fontWeight={[700]}
            fontSize={["10px"]}
            color={["#002070"]}
          >
            <div className="plan-type">
              Plano:{" "}
              <span style={{ color: "#40bf91" }}>{participationPlan}</span>
            </div>
            <div className="plan-info">
              <p>Data da Compra: {contractDate}</p>
              <p>
                Renovação Plano:{" "}
                <span style={{ color: "#e60000" }}>{contractFinalDate}</span>
              </p>
              {emailOwnerAccount !== "" && (
                <p>Consultor: {emailOwnerAccount}</p>
              )}
              <br></br>
              {canRenovation && (
                <div>
                  <Button
                    h="38px"
                    w="141px"
                    variant="outline"
                    colorScheme="red"
                    borderRadius="10px"
                    fontWeight="600"
                    fontSize="14px"
                    onClick={handleModalRenovation}
                  >
                    Renovar Certificado
                  </Button>
                </div>
              )}
            </div>
          </Box>
          {canRenovation && (
            <RenovationModal
              isOpen={renovationModal}
              onClose={handleCloseModalRenovation}
            />
          )}
        </Flex>
      </Stack>
    </Box>
  );
};

const NavItem = ({
  logo,
  icon,
  children,
  path,
  location,
  current,
  ...rest
}) => {
  const isCurrent = location.pathname.includes(current) ? true : false;
  const isExternal = path.startsWith("http");
  if (isExternal) {
    return (
      <a
        className="external-link-active"
        href={path}
        target="_blank"
        style={{ textDecoration: "none", height: "46px" }}
        rel="noopener noreferrer"
      >
        <Flex
          align="center"
          fontSize={["16px", "14px"]}
          role="group"
          h={["48px", "46px"]}
          gap={["26px", "22px"]}
          cursor="pointer"
          opacity={isCurrent ? "1" : "0.7"}
          _hover={{
            color: "#292B30",
            fontWeight: "medium",
          }}
          {...rest}
        >
          {icon ? (
            <Icon
              h="100%"
              color={isCurrent ? "red.100" : "inherit"}
              display="flex"
              alignItems="center"
              opacity={isCurrent ? "1" : "0.7"}
              fontSize={["22"]}
              _groupHover={{
                color: "#E60000",
              }}
              as={icon}
            />
          ) : (
            <Image
              src={logoGreatWork}
              alt="Logo GreatWork"
              boxSize="22px"
              objectFit="cover"
              borderRadius="2px"
            />
          )}
          {children}
        </Flex>
      </a>
    );
  } else {
    return (
      <Link
        className="link-active"
        to={path}
        style={{ textDecoration: "none", height: "46px" }}
      >
        <Flex
          align="center"
          fontSize={["16px", "14px"]}
          role="group"
          h={["48px", "46px"]}
          gap={["26px", "22px"]}
          cursor="pointer"
          opacity={isCurrent ? "1" : "0.7"}
          _hover={{
            color: "#292B30",
            fontWeight: "medium",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              h="100%"
              color={isCurrent ? "red.100" : "inherit"}
              display="flex"
              alignItems="center"
              opacity={isCurrent ? "1" : "0.7"}
              fontSize={["22"]}
              _groupHover={{
                color: "#E60000",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    );
  }
};

const MobileNav = ({
  onOpen,
  isOpenModal,
  setIsOpenModal,
  onCloseModal,
  cnpj,
  companyName,
  userEmail,
  manageResponsibles,
  downloadUseTerms,
  billsConnect,
  helpCenter,
  updateFantasyName,
  logout,
  companies,
  changeCompany,
  notifications,
  ...rest
}) => {
  return (
    <Flex
      w={"100%"}
      p={{ base: "32px 20px 12px 24px", lg: "0 36px 0 26px" }}
      height={{ base: "auto", xl: "64px" }}
      alignItems="center"
      bg={"white"}
      maxW="100vw"
      overflow={"hidden"}
      position={"unset"}
      zIndex={[1]}
      justifyContent={{
        base: "space-between",
        xl: "flex-end",
      }}
      gap="12px"
      {...rest}
    >
      <Image
        src={logoSidebar}
        alt="sidebar logo"
        display={{ base: "flex", xl: "none" }}
        w={["48px", "60px"]}
      />
      <SimpleModal
        title="Selo 5 Anos Reconhecida!"
        text="Parabéns! Sua empresa foi certificada por 5 anos consecutivos. Faça download do seu selo e apresente este marco para todas as pessoas importantes."
        isOpen={isOpenModal}
        onClose={onCloseModal}
        image={
          <Image
            src={selo5anosJPG}
            alt="sidebar logo"
            display={"block !important"}
            ml={"auto"}
            mr={"auto"}
            pb={"8px"}
            h={["230px"]}
          />
        }
        children={
          <a
            href={seloZip}
            download={"Selo 5 Anos"}
            target={"_blank"}
            rel="noreferrer"
          >
            <Button colorScheme="teal">Download</Button>
          </a>
        }
      />

      <Flex
        ml="auto"
        align="center"
        gap={["6px", "12px"]}
        h={"100%"}
        paddingTop={"10px"}
        paddingBottom={"10px"}
        flexWrap="nowrap"
      >
        {empresas5AnosMock.includes(cnpj) &&
          window["runConfig"].REACT_APP_SELO_5_ANOS === "active" ? (
          <Image
            src={selo5anosJPG}
            h={"100%"}
            w={"auto"}
            maxW={"71px"}
            onClick={() => setIsOpenModal(true)}
            cursor={"pointer"}
            display={["none", "none", "inherit"]}
          />
        ) : (
          <></>
        )}
        <Image src={language} alt="icon language" display={["inherit"]} />

        <NotificationMenu notifications={notifications} />
        {/* <NotificationMenu notifications={notificationsMock} /> */}
        <Flex
          w="fit-content"
          align="end"
          gap="1px"
          display={{ base: "none", md: "flex" }}
          flexDir="column"
        >
          <Text
            fontSize="13px"
            color="#E60000"
            fontWeight="600"
            textAlign="end"
          >
            {companyName}
          </Text>
          <Text fontSize="12px" fontWeight={600} color="gray.600">
            {userEmail}
          </Text>
        </Flex>

        <Menu>
          <MenuButton
            w="40px"
            minW="24px"
            p={0}
            transition="all 0.3s"
            _focus={{ boxShadow: "none" }}
            display={["none", "none", "inherit"]}
          >
            <Image src={navbarIcon} alt="Company icon" />
          </MenuButton>

          <MenuList
            h={"auto"}
            ml={["0", "300px"]}
            boxShadow="sm"
            px="12px"
            borderRadius="10px"
            border="none"
            overflow={"hidden"}
          >
            {companies &&
              companies.map((company, index) => (
                <MenuItem key={index} onClick={() => changeCompany(company.cr)}>
                  <Text as="span">
                    {company.fantasyName}/{company.cnpj}
                  </Text>
                </MenuItem>
              ))}
          </MenuList>
        </Menu>

        <Menu>
          <MenuButton
            w="24px"
            minW="24px"
            p={0}
            transition="all 0.3s"
            _focus={{ boxShadow: "none" }}
          >
            <img src={iconSettings} alt="icon settings" />
          </MenuButton>
          <MenuList
            ml={["0", "300px"]}
            boxShadow="sm"
            px="12px"
            borderRadius="10px"
            border="none"
          >
            <MenuItem onClick={() => manageResponsibles()}>
              <Text as="span">Gerenciar Responsáveis</Text>
            </MenuItem>
            <MenuItem onClick={() => downloadUseTerms()}>
              <Text as="span">Termos de Uso e Compromisso</Text>
            </MenuItem>

            <MenuItem onClick={() => updateFantasyName()}>
              <Text as="span">Atualizar Nome Fantasia</Text>
            </MenuItem>
            <MenuItem onClick={() => helpCenter()}>
              <Text as="span">Central de Ajuda</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Image src={iconLogout} alt="icon logout" onClick={() => logout()} />
      <IconButton
        display={{ base: "flex", xl: "none" }}
        onClick={onOpen}
        w="28px"
        fontSize="24px"
        fontWeight={400}
        variant="outline"
        border="none"
        aria-label="open menu"
        icon={<AiOutlineMenu />}
      />
    </Flex>
  );
};
