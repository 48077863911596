export const checkSizeCompany = (plan, qtdEmployees) => {
  let sizeCompany = 'BIG'

  if (plan !== undefined) {
    // Se tem plano é certificação, então o tamanho da empresa fica definida pelo plano    
    if (plan.search("SMALL") > 0 || plan.search("MICRO") > 0) {
      // Empresa PEQUENA , não precisa de CA
      sizeCompany = 'SMALL'
    }
  } else {
    // Não tem plano, é consultoria, verifica pelo tamanho da empresa
    if (qtdEmployees !== undefined) {
      if (qtdEmployees < 100) {
        sizeCompany = 'SMALL'
      }
    }  
  }

  return sizeCompany;
};
