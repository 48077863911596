import React from "react";
import { Icon } from "../../../style";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Text, Flex, Stack, Image, Box, Heading, Link} from "@chakra-ui/react";
import { successIcon, iconQuestionPilares } from "../../../assets/images";

const ALert = ({ ready, text }) => {
  return (
    <Flex
      height="auto"
      p="8.5px"
      bg={ready ? "#008000" : "red.100"}
      color="#ffffff"
      justify="center"
      align="center"
      gap={["4px"]}
      pos="relative"
    >
      {!ready && <Icon type="alert-white" />}

      <Text
        textAlign="center"
        fontSize={["12px", "13px"]}
        lineHeight={["14px"]}
      >
        {" "}
        {text}
      </Text>
    </Flex>
  );
};

export default function PilarQuestionnaireCard(props) {
  // console.error(props);
  const {
    bg,
    title,
    answered,
    iconColor,
    order,
    inicial,
    final,
    showAlert,
    iconePlay,
    estado,
    textoUrl,
    textoAlerta,
    todasRespondidas,
    text,
    finished,
    url,
  } = props;

  return (
    <Stack
      width="100%"
      height="203px"
      borderRadius="12px"
      overflow="hidden !important"
      style={{ order: order }}
      justify="space-between"
      spacing={0}
      pos="relative"
    >
      <Image
        borderRadius="12px"
        src={bg}
        alt=""
        pos="absolute"
        filter="brightness(0.3)"
        zIndex={0}
        left={0}
        top={0}
      />

      <Stack
        p={["24px", "32px 32px 24px"]}
        direction={"row"}
        pos="relative"
        h="100%"
      >
        <Image
          w={"22px"}
          pos="absolute"
          right="20px"
          top="20px"
          zIndex={2}
          src={answered ? successIcon : iconQuestionPilares}
          alt=""
        />

        <Flex
          flex={2}
          pos="relative"
          zIndex={1}
          flexDir={"column"}
          justify={"space-between"}
          m={"0 !important"}
        >
          <Stack spacing="10px">
            <Box w="20px" h="5px" borderRadius="6px" bg={iconColor} />
            <Heading
              as="h3"
              color="white"
              fontSize="18px"
              fontWeight={600}
              mb={showAlert ? "37px" : "51px"}
            >
              {title}
            </Heading>
          </Stack>
          {/* {message === "false" && (
            <ContainerStatus>
              <Icon type="alert-orange" /> {`Prazo de preenchimento encerrado!`}
            </ContainerStatus>
          )} */}

          {iconePlay === false && estado === true && (
            <Flex gap="12px" align="center">
              <Link href={url}>
                <Text color="white" textDecor="underline">
                  {textoUrl}
                </Text>
              </Link>
            </Flex>
          )}
          {iconePlay === false && props.estado === false && (
            <Flex gap="12px" align="center">
              <Link href={url}>
                <Text color="white" textDecor="underline">
                  {textoUrl}
                </Text>
              </Link>
            </Flex>
          )}
          {iconePlay === true && estado === false && (
            <Flex gap="12px" align="center">
              <Icon type="icone-play" />
              {"   "}
              <Link href={url}>
                <Text color="white" textDecor="underline">
                  {textoUrl}
                </Text>
              </Link>
            </Flex>
          )}
          {iconePlay === true && estado === true && (
            <Flex gap="12px" align="center">
              <Icon type="icone-play" />
              {"   "}
              <Link href={url}>
                <Text color="white" textDecor="underline">
                  {textoUrl}
                </Text>
              </Link>
            </Flex>
          )}
          {iconePlay}
        </Flex>

        <Flex flex={1} maxW="70px" mt="auto !important">
          <CircularProgressbar
            value={inicial}
            minValue={0}
            maxValue={10}
            strokeWidth={7}
            text={answered ? '100%' : '0%'}
            styles={buildStyles({
              textSize: "font-size: 16px",
              width: "71px",
              height: "71px",
              pathColor: `#40BF91`,
              textColor: "#ffff",
              trailColor: "#E5E5E5",
            })}
          />
        </Flex>
      </Stack>
      {showAlert && (
        <>
          {estado && todasRespondidas === false && (
            <ALert ready={false} text={textoAlerta} />
          )}
          {estado && todasRespondidas === true && (
            <ALert ready={true} text={textoAlerta} />
          )}
        </>
      )}
    </Stack>
  );
}
