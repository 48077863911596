import SimpleSidebar from "../components/nav/Sidebar"
import React, { useRef, useState, FC, useEffect, useContext } from "react";
import { CSVLink } from "react-csv";

import getUrlParams from "../../services/getUrlParams";
import { researchApi } from "../../services/api-research";

import iconSearch from "../../assets/images/icon-search.svg";
import iconUserGreen from "../../assets/images/icon-user-green.svg";
import iconUserGreenDarker from "../../assets/images/icon-user-green-darker.svg";
import iconUserBlue from "../../assets/images/icon-user-blue.svg";
import iconUserOrange from "../../assets/images/icon-user-orange.svg";
import iconSelected from "../../assets/images/icon-arrow-down.svg";

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  FormLabel,
  Tag,
  Text,
  Tooltip,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

import UploadModal from "./Modals/UploadModal";
import ModalColaborator from "./Modals/ModalColaborator";
import TableModel from "../components/table/Table";
import UploadSheetModal from "../pesquisas/certficacao/Modals/UploadSheet";
import {
  FiArrowLeft,
  FiArrowRight,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import axios from "axios";
import { UpgradePlan } from "../pesquisas/certficacao/Modals/upgradePlan";
// import Card from "../components/common/card/Card";

const Colaboradores = () => {
  // const { showPage, isLoading } = useContext(showCollaboratorContext);
  const [isLoading, setIsLoading] = useState(false)
  const [showPage, setShowPage] = useState(false)

  const params = new URL(window.location.href);
  const companyId = params.searchParams.get("cr");
  const [page, setPage] = useState(
    params.searchParams.get("pg") ? params.searchParams.get("pg") : 1
  );
  const [totalPages, setTotalPages] = useState(0);
  const [orderBy, setOrderBy] = useState(
    params.searchParams.get("order") ? params.searchParams.get("order") : "ASC"
  );
  const [filter, setFilter] = useState(
    params.searchParams.get("filter")
      ? params.searchParams.get("filter")
      : "All"
  );
  const [modal, setModal] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [modalColaborator, setModalColaborator] = useState(false);
  const [researchId, setResearchId] = useState();
  const [csvData, setCsvData] = useState([]);

  const [totalCollaborators, setTotalCollaborators] = useState(0);
  const [activeCollaborators, setActiveCollaborators] = useState(0);
  const [inactiveCollaborators, setInactiveCollaborators] = useState(0);
  const [currentCollaborators, setCurrentCollaborators] = useState(0);
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(0);

  const [upgradeModal, setUpgradeModal] = useState(false);
  const [planosDisponiveis, setPlanosDisponiveis] = useState(null)
  const [currentProductId, setCurrentProductId] = useState(null)
  const [vindiSignatureId, setVindiSignatureId] = useState(null)
  const [customerId, setCustomerId] = useState(null);
  const [faixaPlano, setFaixaPlano] = useState(null)

  const handleGetResearch = async () => {
    try {
      const response = await researchApi.get(
        `/research?id_company_register=${companyId}`
      );

      if (response.data.length > 0) {
        console.log('Carregando Inicio')
        setResearchId(response.data[0].id);
        handleDownloadList(response.data[0].id);
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetCollaborators = async () => {
    try {
      const response = await researchApi.get(
        `/employees/research/${researchId}?order=${orderBy}&filter=${filter}`
      );

      // if (response.data.total > 0) {
      console.log('Carregando Inicio')
      setTotalCollaborators(response.data.total);
      setActiveCollaborators(response.data.actives);
      setInactiveCollaborators(response.data.inactives);
      setCurrentCollaborators(response.data.pages[page]);
      setTotalPages(response.data.pages.totalPages);
      // console.log(currentCollaborators)
      // handleDownloadList(response.data[0].id);
      // } else {
      // }
    } catch (error) {
      console.log("error", error);
    }
  };

  async function isPageShown() {
    try {
      setIsLoading(true)
      const url_company_info =
      window['runConfig'].REACT_APP_URL_RESULT_SERVICE +
        `/company-register-info?cr=${companyId}`

      const { data } = await researchApi.get(`/research/researchInfo/${companyId}`);
      const company_info = await axios.get(url_company_info)
      
      const now = new Intl.DateTimeFormat('pt-BR').format(new Date()).split('/');
      const dateComparison = parseInt(`${now[2]}${now[1]}${now[0]}`);

      if (data.applicationDate) {
        const startDate = new Intl.DateTimeFormat('pt-BR').format(parseInt(data.applicationDate) - 86400000).split('/');
        dateComparison < parseInt(`${startDate[2]}${startDate[1]}${startDate[0]}`) && !company_info.data.company.isProject ? setShowPage(true) : setShowPage(false)

        console.log(startDate, now);
        console.log(dateComparison, parseInt(`${startDate[2]}${startDate[1]}${startDate[0]}`))

      } else {
        !company_info.data.company.isProject ? setShowPage(true) : setShowPage(false)
      }

      setIsLoading(false)
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    isPageShown();
  }, [companyId]);

  useEffect(() => {
    handleGetResearch();
  }, []);

  useEffect(() => {
    handleGetCollaborators();    
  }, [researchId, refresh]);

  async function handleDownloadList(research_id) {
    const r = researchId !== undefined ? researchId : research_id;
    const response = await researchApi.get(`export/${r}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });

    const csvData = response.data;  
    setCsvData(csvData);
  }

  const handleModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleUploadModal = () => {
    setModalUpload(true);
  };

  const handleCloseUploadModal = () => {
    setModalUpload(false);
  };

  const handleModalColaborator = (employee) => {
    setModalColaborator(true);
  };

  const handleCloseModalColaborator = () => {
    setModalColaborator(false);
  };

  const handleRefresh = () => {
    setRefresh(refresh => refresh + 1);
  }

  const handleOrder = (event) => {
    params.searchParams.set('order', event.target.value);
    setOrderBy(event.target.value)
    window.history.pushState(null, 'Connect App', params.href);
    handlePageChange(1);
  }

  const handleFilter = (event) => {
    params.searchParams.set('filter', event.target.value);
    setFilter(event.target.value)
    window.history.pushState(null, 'Connect App', params.href);
    handlePageChange(1);
  }

  const handlePageChange = (pageChange) => {
    params.searchParams.set('page', pageChange);
    window.history.pushState(null, 'Connect App', params.href);
    setPage(pageChange)
    handleRefresh();
  }

  const openUpgradeModal = async (planos, customerId, oldProductId, signatureId, faixa) => {
    console.log('upgradeModal');
    setPlanosDisponiveis(planos);
    setCustomerId(customerId);
    setCurrentProductId(oldProductId);
    setVindiSignatureId(signatureId);
    setFaixaPlano(faixa);
    handleCloseModal();
    setUpgradeModal(true);
  }

  const closeUpgradeModal = () => {
    setUpgradeModal(false);
  }

  return (
    <SimpleSidebar>
      <Container maxW="container.xl" pt={4} pb={4}>
        {/* <Card hasCloseButton /> */}
          {isLoading ? (
            <Flex justify="center" h="40px" alignItems="center" mt="30px">
              Carregando...
            </Flex>
          ) : !showPage ? (
            <Flex justify="center" h="40px" alignItems="center" mt="30px">
              <Heading as="h3" fontSize="18px" lineHeight="21px">
                Essa página não está disponível pois a pesquisa está em andamento. Não é mais possível realizar edição OU sua participação é de Consultoria
              </Heading>
            </Flex>
          ) : (
            <>
              <Flex justify="space-between" h="40px" alignItems="center" mt="30px">
                <Heading as="h3" fontSize="18px" lineHeight="21px">
                  Colaboradores
                </Heading>
                <Box ml="auto" mr="24px"></Box>
                <Flex h="100%" gap="20px">
                  <Button
                    color="#E60000"
                    variant="outline"
                    w="265px"
                    h="100%"
                    borderColor="#E60000"
                    borderRadius="10px"
                    onClick={handleDownloadList}
                  >
                    <CSVLink data={csvData}> Baixar Planilha Preenchida </CSVLink>
                  </Button>

                  <Button
                    onClick={handleModal}
                    bg="#E60000"
                    color="white"
                    variant="outline"
                    w="265px"
                    h="100%"
                    borderRadius="10px"
                    border="none"
                  >
                    Adicionar colaboradores
                  </Button>
                </Flex>
              </Flex>

              <Grid templateColumns="repeat(4, 1fr)" gap={4} mt="32px" mb="24px">
                <GridItem
                  bg="white"
                  border="1px"
                  borderColor="#CCCCCC"
                  colSpan={1}
                  h="86px"
                  borderRadius="12px"
                  p="25px"
                >
                  <Flex
                    h="100%"
                    alignItems={"center"}
                    justify="space-between"
                    gap="5px"
                  >
                    <Box>
                      <img src={iconUserBlue} alt="icon user" />
                    </Box>
                    <Text color="#011F3B" fontSize="13px" lineHeight="19px">
                      Colaboradores
                      <br />
                      <b>Cadastrados</b>
                    </Text>

                    <Tag
                      bg="#d1daf7"
                      color="#174BD6"
                      fontSize="18px"
                      fontWeight="700"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="center"
                      w="76px"
                      h="40px"
                    >
                      <Text>{totalCollaborators}</Text>
                    </Tag>
                  </Flex>
                </GridItem>
                <GridItem
                  bg="white"
                  border="1px"
                  borderColor="#CCCCCC"
                  colSpan={1}
                  h="86px"
                  borderRadius="12px"
                  p="25px"
                >
                  <Flex
                    h="100%"
                    alignItems={"center"}
                    justify="space-between"
                    gap="5px"
                  >
                    <Box>
                      <img src={iconUserGreen} alt="icon user" />
                    </Box>
                    <Text color="#011F3B" fontSize="13px" lineHeight="19px">
                      Colaboradores
                      <br />
                      <b>Ativos</b>
                    </Text>

                    <Tag
                      bg="#d4f7fa"
                      color="#2CD9DD"
                      fontSize="18px"
                      fontWeight="700"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="center"
                      w="76px"
                      h="40px"
                    >
                      <Text>{activeCollaborators}</Text>
                    </Tag>
                  </Flex>
                </GridItem>

                <GridItem
                  bg="white"
                  border="1px"
                  borderColor="#CCCCCC"
                  colSpan={1}
                  h="86px"
                  borderRadius="12px"
                  p="25px"
                >
                  <Flex
                    h="100%"
                    alignItems={"center"}
                    justify="space-between"
                    gap="5px"
                  >
                    <Box>
                      <img src={iconUserOrange} alt="icon user" />
                    </Box>
                    <Text color="#011F3B" fontSize="13px" lineHeight="19px">
                      Colaboradores
                      <br />
                      <b>Inativos</b>
                    </Text>

                    <Tag
                      bg="#ffe1cc"
                      color="#FF6700"
                      fontSize="18px"
                      fontWeight="700"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="center"
                      w="76px"
                      h="40px"
                    >
                      <Text>{inactiveCollaborators}</Text>
                    </Tag>
                  </Flex>
                </GridItem>

                <GridItem
                  bg="white"
                  border="1px"
                  borderColor="#CCCCCC"
                  colSpan={1}
                  h="86px"
                  borderRadius="12px"
                  p="25px"
                >
                  <Flex
                    h="100%"
                    alignItems={"center"}
                    justify="space-between"
                    gap="5px"
                  >
                    <Box>
                      <img src={iconUserGreenDarker} alt="icon user" />
                    </Box>
                    <Text color="#011F3B" fontSize="13px" lineHeight="19px">
                      Colaboradores
                      <br />
                      <b>Selecionados</b>
                    </Text>

                    <Tag
                      bg="#dbf2ea"
                      color="#40BF91"
                      fontSize="18px"
                      fontWeight="700"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="center"
                      w="76px"
                      h="40px"
                    >
                      <Text>{activeCollaborators}</Text>
                    </Tag>
                  </Flex>
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(4, 1fr)" gap={6} h="62px" mb="26px">
                <GridItem w="100%" h="100%">
                  <FormLabel mb="8px" fontSize="11px" fontFamily="Raleway">
                    Ordernar por
                  </FormLabel>
                  <Select
                    fontSize="14px"
                    fontWeight="600"
                    icon={<img src={iconSelected} />}
                    borderRadius="10px"
                    borderColor="#B8CAE8"
                    onChange={handleOrder}
                    value={orderBy}
                  >
                    <option value={"ASC"}>A a Z</option>
                    <option value={"DESC"}>Z a A</option>
                  </Select>
                </GridItem>
                <GridItem w="100%" h="100%">
                  <FormLabel mb="8px" fontSize="11px" fontFamily="Raleway">
                    Status
                  </FormLabel>
                  <Select
                    fontSize="14px"
                    fontWeight="600"
                    icon={<img src={iconSelected} />}
                    onChange={handleFilter}
                    borderRadius="10px"
                    borderColor="#B8CAE8"
                    value={filter}
                  >
                    <option value={"All"}>Todos os status</option>
                    <option value={"Ativo"}>Ativos</option>
                    <option value={"Inativo"}>Inativos</option>
                  </Select>
                </GridItem>
                <GridItem w="100%" h="100%"></GridItem>
                <GridItem w="100%" h="100%" display="flex" alignItems="end">
                  <Text fontSize="14px" ml="auto">
                    Visualizando <b> {count} </b>resultados de{" "}
                    <b>{totalCollaborators}</b>
                  </Text>
                </GridItem>
              </Grid>

              <TableModel
                currentPage={page}
                currentCollaborators={currentCollaborators}
                handleCount={setCount}
                total={totalCollaborators}
                active={activeCollaborators}
                handleRefresh={handleRefresh}
              />

              <Grid>
                <Flex justifyContent="space-between" m={4} alignItems="center">
                  <Flex>
                    <Tooltip label="Primeira Página">
                      <IconButton
                        onClick={() => handlePageChange(1)}
                        isDisabled={page === 1 ? true : false}
                        icon={<FiArrowLeft h={3} w={3} />}
                        mr={4}
                      />
                    </Tooltip>
                    <Tooltip label="Página Anterior">
                      <IconButton
                        onClick={() => handlePageChange(page - 1)}
                        isDisabled={page === 1 ? true : false}
                        icon={<FiChevronLeft h={6} w={6} />}
                      />
                    </Tooltip>
                  </Flex>

                  <Flex alignItems="center">
                    <Text flexShrink="0" mr={8}>
                      Página{" "}
                      <Text fontWeight="bold" as="span">
                        {page}
                      </Text>{" "}
                      de{" "}
                      <Text fontWeight="bold" as="span">
                        {totalPages}
                      </Text>
                    </Text>
                    <Text flexShrink="0">Ir para:</Text>{" "}
                    <NumberInput
                      ml={2}
                      mr={8}
                      w={28}
                      min={1}
                      max={totalPages}
                      onChange={(value) => {
                        handlePageChange(value)
                      }}
                      value={page}
                      defaultValue={page}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Flex>

                  <Flex>
                    <Tooltip label="Próxima Página">
                      <IconButton
                        onClick={() => handlePageChange(page + 1)}
                        isDisabled={page === totalPages ? true : false}
                        icon={<FiChevronRight h={6} w={6} />}
                      />
                    </Tooltip>
                    <Tooltip label="Última Página">
                      <IconButton
                        onClick={() => handlePageChange(totalPages)}
                        isDisabled={page === totalPages ? true : false}
                        icon={<FiArrowRight h={3} w={3} />}
                        ml={4}
                      />
                    </Tooltip>
                  </Flex>
                </Flex>
              </Grid>
              <Modal isOpen={modal} onClose={handleCloseModal} size="xl">
                <ModalOverlay />
                <ModalContent
                  borderRadius="20px"
                  p="20px"
                  w="600px"
                  textAlign="center"
                  h="248px"
                >
                  <ModalHeader fontSize="18px">
                    Como deseja adicionar os colaboradores?
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Button
                      onClick={handleUploadModal}
                      bg="#E60000"
                      mb="10px"
                      color="white"
                      w="453px"
                      borderRadius="10px"
                      h="56px"
                    >
                      Adicionar via planilha
                    </Button>
                    <Button
                      onClick={
                        totalCollaborators === 0 ? null : handleModalColaborator
                      }
                      bg="#E60000"
                      color="white"
                      w="453px"
                      borderRadius="10px"
                      h="56px"
                      disabled={totalCollaborators === 0 ? true : false}
                    >
                      Adicionar manualmente
                    </Button>
                  </ModalBody>
                </ModalContent>
              </Modal>
              {upgradeModal &&
              window["runConfig"].REACT_APP_UPGRADE_PLAN === "active" ? (
                <UpgradePlan
                  close={closeUpgradeModal}
                  planos={planosDisponiveis}
                  customerId={customerId}
                  oldProductId={currentProductId}
                  signatureId={vindiSignatureId}
                  faixa={faixaPlano}
                  visible={upgradeModal}
                  cr={companyId}
                />
              ) : (
                ""
              )}

              <UploadSheetModal
                researchId={researchId}
                isOpen={modalUpload}
                onClose={handleCloseUploadModal}
                handleDownloadList={handleDownloadList}
                openUpgradeModal={openUpgradeModal}
                refresh={handleRefresh}
              />
              {/* <UploadModal isOpen={modalUpload} onClose={handleCloseUploadModal} /> */}
              <ModalColaborator
                isOpen={modalColaborator}
                onClose={handleCloseModalColaborator}
                researchId={researchId}
                total={totalCollaborators}
                active={activeCollaborators}
                refresh={handleRefresh}
              />
            </>
          )}
        </Container>
    </SimpleSidebar>
  );
};

export default Colaboradores;
