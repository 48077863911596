import { useEffect, useState } from "react"
import axios from "axios"

import logoSidebar from "../../../assets/images/logo-sidebar.svg"
import iconPendency from "../../../assets/images/icon-pendency.svg"
import iconMore from "../../../assets/images/icon-more.svg"

import { BadgeContainer, BottomContainer, TextContainer } from "./styles"

import {
  Grid,
  GridItem,
  Flex,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"

const Rankings = () => {
  const [isFetching, setIsFetching] = useState(true)
  const [error, setError] = useState(false)
  const [rankings, setRankings] = useState([])
  const [companyInfo, setCompanyInfo] = useState([])
  const [links, setLinks] = useState([])

  const params = new URLSearchParams(window.location.search)

  const url_company_info =
    window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
    `/company-register-info?cr=${params.get("cr")}`


  function openLink(link) {
    const a = document.createElement("a")
    a.target = "_blank"
    a.href = link
    a.click()
  }

  useEffect(() => {
    params.has("cr") ? setError(false) : setError(true)
    setIsFetching(true)

    const getRankings = async () => {
      try {
        const company_info = await axios.get(url_company_info)
        setCompanyInfo(company_info)

        const url_rankings =
          window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
          `/certificate-gens/by-company/${company_info.data.company.id}`

        const result = await axios.get(url_rankings)

        setRankings(result.data.results)
        setLinks(result.data.links)
        setIsFetching(false)
      } catch (error) {

        setError(true)

        return
      }
    }

    getRankings()
  }, [])

  let ranks = null

  if (!isFetching) {
    if (!error && rankings && rankings.length !== 0) {
      ranks = rankings
        .filter((item) => {
          return !item?.ranking_name.match(/\d{4}/gm)
        })
        .map((item) => {
          let cert_link = null

          links.forEach((link) => {
            let cert_name = link
              .split(`${companyInfo.data.company.id}/`)[1]
              .split(".pdf")[0]
              .replaceAll("_", " ")

            let name = cert_name.slice(0, -5)
            let year = parseInt(cert_name.slice(-4))

            if (
              year === item.year &&
              name ===
                item.ranking_name
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replace('+', "")
                  .replace('ª', "a")
                  .replace(',', "") +
                  " " +
                  item.size_cut.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(',', "")
            ) {
              cert_link = link
            }
          })
          return {
            ...item,
            cert_link,
          }
        })

      ranks.sort((a, b) => {
        return b.year - a.year
      })
      // console.log(ranks)
    }
  }

  return (
    <>
      {error ? (
        <Flex justify={"center"}>
          Nenhum certificado disponível.
        </Flex>
      ) : isFetching ? (
        <Flex justify={"center"}>Carregando...</Flex>
      ) : rankings.length === 0 ? (
        <Flex justify={"center"}>
          Nenhum certificado disponível.
        </Flex>
      ) : (
        <Grid templateColumns="repeat(4, 1fr)" gap={6} w="100%">
          {ranks.map((certificado, key) => (
            <GridItem
              w="100%"
              h="256px"
              bg="white"
              border="1px"
              borderColor="#CCCCCC"
              borderRadius="12px"
              height="auto"
              overflow="hidden"
              key={key}
            >
              <Box p={4} display="flex" flexDirection={"column"}>
                <Flex justify={"space-between"} height={"20px"}>
                  <img src={iconPendency} alt="icon pendency" />
                  <Menu>
                    <MenuButton>
                      <img src={iconMore} alt="more" />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => openLink(certificado.cert_link)}>
                        Baixar certificado
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
                <BadgeContainer
                  style={{
                    backgroundColor: certificado.certificate_badge_color,
                  }}
                >
                  <TextContainer>
                    <h1>Melhores Empresas para Trabalhar™</h1>
                    <p>{certificado.ranking_name}</p>
                  </TextContainer>
                  <BottomContainer>
                  <img
                    src={logoSidebar}
                    alt="sidebar logo"
                    style={{ width: "70px", marginTop: "10px" }}
                  />
                  <h1>{certificado.year}</h1>
                  </BottomContainer>
                </BadgeContainer>
              </Box>
              <Box bg="#e9e8ef" h="auto" w="100%" p={4}>
                <Flex justify="space-between" align="center">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#002070",
                      fontWeight: "500",
                    }}
                  >
                    {certificado.ranking_name}
                  </span>
                  <span
                    style={{
                      color: "#E60000",
                      fontSize: "14px",
                      fontWeight: "bold",
                      lineHeight: "22px",
                    }}
                  >
                    {certificado.year}
                  </span>
                </Flex>
              </Box>
            </GridItem>
          ))}
        </Grid>
      )}
    </>
  )
}

export default Rankings
