import React, { useEffect, useState } from "react";

import {
  Container,
  Stack,
  Text,
  Image,
  Flex,
  Tabs,
  Box,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  FormLabel,
  Button,
  Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import SimpleSidebar from "../components/nav/Sidebar";
import Card from "../components/card/index";
import ResultsTable from "./ResultsTable";

import resultsSearch from "../../assets/images/results-search.svg";
import RankingTable from "./RankingTable";
import axios from "axios";
import ResultsMobile from "./ResultsMobile";
import RankingSummariesTable from "./RankingSummariesTable";

function Results() {
  const [mobile] = useMediaQuery("(min-width: 1024px)");
  const params = new URLSearchParams(window.location.search);

  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(false);
  const [certificates, setCertificates] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isFetchingSummaries, setIsFetchingSummaries] = useState(true);
  const [errorSummaries, setErrorSummaries] = useState(false);
  const [summaries, setSummaries] = useState(null);

  const url_company_info =
    window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
    `/company-register-info?cr=${params.get("cr")}`;

  const getCertificates = async () => {
    try {
      const company_info = await axios.get(url_company_info);

      const url_certificados =
        window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
        `/participation?dc=${company_info.data.company.cnpj
          .replace(/\./g, "")
          .replace(/-/g, "")
          .replace(/\//, "")}`;

      const certificados = await axios.get(url_certificados);

      if (certificados.data !== undefined && certificados.data.length > 0) {
        // filtrando somente as participações de certificação e as pequisas já finalizadas
        const researchsCertification = certificados.data.filter(
          (item) => item.idSurvey !== "null"
        );

        //Filtrando somente as pesquisas ja finalizadas
        const researchs = researchsCertification.filter(
          (item) => item.researchFinished === true || item.hasCertificate === true
        );

        setTableData(researchs);
      } else {
        return;
      }
      setCertificates(certificados.data);
      setIsFetching(false);
    } catch (error) {
      setError(true);
      return;
    } finally {
      setIsFetching(false);
    }
  };

  const getSummaries = async () => {
    try {
      const company_info = await axios.get(url_company_info);

      const url_summaries =
      window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
      `/ranking-summary?cr=${company_info.data.company.id}`;

      const sumarios = await axios.get(url_summaries);

      if (sumarios.data !== undefined && sumarios.data.length > 0) {
        setSummaries(sumarios.data);
      } else {
        return;
      }
      setIsFetchingSummaries(false);
    } catch (error) {
      setErrorSummaries(true);
      return;
    } finally {
      setIsFetchingSummaries(false);
    }
  };

  useEffect(() => {
    params.has("cr") ? setError(false) : setError(true);
    setIsFetching(true);

    getCertificates();
    getSummaries();
  }, []);

 
  return (
    <SimpleSidebar>
      {isFetching === true ? (
        <Box w="100%" textAlign="center" h="30vh" pt="20px">
          <Spinner />
        </Box>
      ) : (
        <Container maxW="100%" ml={["unset", "auto"]} p={["0", "24px"]}>
          <Card
            title="Resultados das Pesquisas de Clima"
            text="Verifique os resultados das pesquisas de clima e seus relatórios."
          />

          <Flex h="24px" mt="32px" align="center" justify={"space-between"}>
            <Text fontSize={"18px"} lineHeight="21px" fontWeight={700}>
              Resultados
            </Text>

        {/* <Button bg="none" p={0}>
          <Image src={resultsSearch} alt="search" />
        </Button> */}
      </Flex>

          <Tabs variant="unstyled" mt="20px">
            <Flex justifyContent="space-between" mt="25px" h="27px" mb="35px">
              <TabList color="#292B30">
                <Tab
                  fontSize={["14px", "16px"]}
                  lineHeight={["21px",'18.78px']}
                  _focus={{
                    border: "none",
                  }}
                  _selected={{
                    fontWeight: "700",
                    borderBottom: "3px solid #E60000",
                    bg: "none",
                  }}
                >
                  Pesquisas
                </Tab>
                <Tab
                  fontSize={["14px", "16px"]}
                  lineHeight={["21px",'18.78px']}
                  _focus={{
                    border: "none",
                  }}
                  _selected={{
                    fontWeight: "700",
                    borderBottom: "3px solid #E60000",
                    bg: "none",
                  }}
                >
                  Sumários de Rankings
                </Tab>
              </TabList>
            </Flex>
            <TabPanels>
              <TabPanel p={0} pt="28px">
                {mobile ? (
                  <ResultsTable researchsList={tableData} />
                ) : (
                  <Stack>
                    {tableData.map((data, index) => (
                      <ResultsMobile key={index} research={data} />
                    ))}
                  </Stack>
                )}
              </TabPanel>
              <TabPanel p={0} pt="28px">
                { summaries ?
                  <RankingSummariesTable summaries={ summaries } />
                : "Não há sumários para esta empresa!" }
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      )}
    </SimpleSidebar>
  );
}

export default Results;
