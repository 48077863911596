import { Table, Thead, Tr, Th, Tbody, Link, Flex } from "@chakra-ui/react";
import axios from "axios";
import { useState, useEffect } from "react";
import { getCookie } from "../../common/cookieManager";
import { TermsModal } from "../../components/modal/termsModal";

const TermosLista = () => {
  const [loading, setLoading] = useState(false);
  const [termos, setTermos] = useState([]);
  const [termModal, setTermModal] = useState(false);
  const [termo, setTermo] = useState();

  const email = getCookie("user_email") || "joaovictor.vbt@gmail.com";
  const companyCnpj = getCookie("company_cnpj") || "06140533000155";
  const urlGetTermos = `${window["runConfig"].REACT_APP_URL_RESULT_SERVICE}/participation/terms-agreed?dc=${companyCnpj}&user=${email}`;
  
  const fetchTermo = async (versionTerm) => {
    const urlGetTermo = `${window["runConfig"].REACT_APP_URL_RESULT_SERVICE}/participation/term?term=${versionTerm}`;
    const response = await axios.get(urlGetTermo);
    
    return response.data.data;
  };

  const fetchTermModal = async (versionTerm) => {
    setLoading(true);
    const termo = await fetchTermo(versionTerm);
    setTermo(termo[0].rules_in_html);
    setLoading(false);
  };

  useEffect(() => {
    if (termo) {
      setTermModal(true);
    }
  }, [termo]);

  const closeTermModal = () => {
    setTermModal(false);
  };

  useEffect(() => {
    const fetchTermos = async () => {
      setLoading(true);
      const response = await axios.get(urlGetTermos);
      setTermos(response.data.data);
      setLoading(false);
    };
    fetchTermos();
  }, [email, companyCnpj]);

  return (
    <>
      <TermsModal isOpen={termModal} onClose={closeTermModal} content={termo} />
      <Flex justify="end">
        <Link
          href={`https://gptwcertification.s3.us-west-2.amazonaws.com/templates/Termos_de_uso.pdf`}
          target="_blank"
          fontWeight={500}
          fontSize="16px"
          color="#E60000"
          textDecoration="underline"
        >
          Download Termo de Uso Atual
        </Link>
      </Flex>
      <Table variant="simple">
        <Thead bg="#e2e1e7">
          <Tr color="#292B30" fontSize="12px">
            <Th
              textTransform="none"
              color="##292B30"
              fontFamily="Raleway"
              px="24px"
              w="80px"
            >
              CNPJ
            </Th>
            <Th
              textTransform="none"
              color="##292B30"
              fontFamily="Raleway"
              px="24px"
              w="80px"
            >
              IP de Origem
            </Th>
            <Th
              textTransform="none"
              color="##292B30"
              fontFamily="Raleway"
              px="24px"
              w="80px"
            >
              Data do aceite
            </Th>
            <Th
              textTransform="none"
              color="##292B30"
              fontFamily="Raleway"
              px="24px"
              w="80px"
            >
              Usuário
            </Th>
            <Th
              textTransform="none"
              color="##292B30"
              fontFamily="Raleway"
              px="24px"
              w="80px"
            >
              Versão do Termo
            </Th>
            <Th
              textTransform="none"
              color="##292B30"
              fontFamily="Raleway"
              px="24px"
              w="80px"
            >
              Ações
            </Th>
          </Tr>
        </Thead>
        <Tbody bg="white">
          {loading ? (
            <Tr>
              <Th colSpan="6" textAlign="center">
                Carregando...
              </Th>
            </Tr>
          ) : (
            <>
              {termos.length === 0 ? (
                <Tr>
                  <Th colSpan="6" textAlign="center">
                    Nenhum termo encontrado.
                  </Th>
                </Tr>
              ) : (
                <>
                  {termos.map((termo, index) => (
                    <Tr key={index}>
                      <Th
                        textTransform="none"
                        color="#292B30"
                        fontFamily="Raleway"
                        px="24px"
                        w="80px"
                      >
                        {termo.cnpj}
                      </Th>
                      <Th
                        textTransform="none"
                        color="#292B30"
                        fontFamily="Raleway"
                        px="24px"
                        w="80px"
                      >
                        {termo.id_origem}
                      </Th>
                      <Th
                        textTransform="none"
                        color="#292B30"
                        fontFamily="Raleway"
                        px="24px"
                        w="80px"
                      >
                        {termo.date_creation}
                      </Th>
                      <Th
                        textTransform="none"
                        color="#292B30"
                        fontFamily="Raleway"
                        px="24px"
                        w="80px"
                      >
                        {termo.user}
                      </Th>
                      <Th
                        textTransform="none"
                        color="#292B30"
                        fontFamily="Raleway"
                        px="24px"
                        w="80px"
                      >
                        {termo.versionTerm}
                      </Th>
                      <Th
                        textTransform="none"
                        color="#292B30"
                        fontFamily="Raleway"
                        px="24px"
                        w="80px"
                      >
                        <Link
                          fontWeight={500}
                          fontSize="12px"
                          color="#0000ff"
                          textDecoration="underline"
                        >
                        <span
                          target="_blank"
                          onClick={() => fetchTermModal(termo.versionTerm)}
                        >
                          Visualizar
                        </span>
                        </Link>
                      </Th>
                    </Tr>
                  ))}
                </>
              )}
            </>
          )}
        </Tbody>
      </Table>
    </>
  );
};

export default TermosLista;
