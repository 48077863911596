import {
  Stack,
  Text,
  CircularProgress,
  CircularProgressLabel
} from "@chakra-ui/react";

export default function CustomCircularProgressBar({
  value,
  text,
  color,
  label,
  disabled,
  differentSize,
}) {
  return (
    <Stack align="center" spacing="8px">
      <CircularProgress
        value={value}
        color={color}
        size={differentSize ? differentSize : "82"}
        minH={differentSize ? differentSize : "82px"}
        thickness="7px"
        capIsRound={true}
        trackColor="#E5E5E5"
        className={disabled && "circular-fill"}
      >
        <CircularProgressLabel fontSize="16px" color="#5C6066" fontWeight={700}>
          {text}
        </CircularProgressLabel>
      </CircularProgress>

      {label && (
        <Text fontSize="sm" lineHeight="16.44px" textAlign="center">
          {label}
        </Text>
      )}
    </Stack>
  );
}
