import {
  Stack,
  Select as ChakraSelect,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { IoMdArrowDropdown } from "react-icons/io";

const Select = ({
  name,
  label,
  error = null,
  placeholder,
  children,
  value,
  setValue,
  ...rest
}) => {
  return (
    <FormControl isInvalid={!!error}>
      <Stack spacing="8px">
        {!!label && (
          <FormLabel htmlFor={name} fontSize="12px" lineHeight="14px" mb="0px">
            {label}
          </FormLabel>
        )}

        <ChakraSelect
          name={name}
          id={name}
          placeholder={placeholder || "Todos"}
          color="#444C54"
          w="100%"
          h="48px"
          fontSize="sm"
          lineHeight="24px"
          fontWeight={400}
          borderColor="#DEE5E4"
          borderRadius="none"
          cursor="pointer"
          _focus={{
            border: "1px solid #DEE5E4",
          }}
          _selected={{
            color: "#11131C",
            fontWeight: 600,
          }}
          value={!!value ? value : ""}
          onChange={(value) => {
            const tempValue = !!value.target.value ? value.target.value : "";
            setValue(tempValue);
          }}
          _disabled={{
            bg: "#5C6066",
            color: "rgba(229, 229, 229, 0.5)",
          }}
          icon={<IoMdArrowDropdown color="#444C54" fontSize="24px" />}
          {...rest}
        >
          {children}
        </ChakraSelect>
      </Stack>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default Select;
