import { researchApi } from "../../services/api-research";
import { useQuery } from "react-query";
import { queryClient } from "../../services/queryCliente";

const fetchCompanyResearchs = async (companyId) => {
  const response = await researchApi.get(
    `/research/?id_company_register=${companyId}`
  );
  const status = response.data

  return status;
};

export const useCompanyResearchs = (companyId) => {
  return useQuery(
    "companyResearchs",
    () => fetchCompanyResearchs(companyId),
    {
      // The query will not execute until the userId exists
      enabled: !!companyId,
    },
    {
      staleTime: 30 * 1000,
      initialData: () => queryClient.getQueryData("companyResearchs"),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState("companyResearchs")?.dataUpdatedAt,
    }
  );
};