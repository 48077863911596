import React from "react";
import { Input, InputGroup, Icon, InputRightElement } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

const SearchBar = ({ textSearch, setTextSearch, ...props }) => {
  return (
    <InputGroup borderRadius="0">
      <Input
        value={textSearch}
        onChange={(value) => setTextSearch(value.target.value)}
        borderColor="#DEE5E4"
        fontSize={"14px"}
        _placeholder={{
          color: "#444C54",
          fontWeight: 400,
          fontSize: "14px",
        }}
        minH="48px"
        borderRadius="0"
        whiteSpace={"nowrap"}
        overflow="hidden"
        textOverflow="ellipsis"
        {...props}
      />
      <InputRightElement minH="48px">
        <Icon
          as={FiSearch}
          color="#444C54"
          alt="icon search"
          height="24px"
          width="24px"
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchBar;
