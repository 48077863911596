import { forwardRef, ForwardRefRenderFunction, ReactNode } from "react";
import { FieldError } from "react-hook-form";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";

const InputBase = (
  { name, label, error = null, icon, iconRight, ...rest },
  ref
) => {
  
  return (
    <FormControl isInvalid={!!error} mb="12px"
    >
    <Stack>
      {!!label && (
        <FormLabel
          id={name}
          htmlFor={name}
          fontSize="12px"
          fontWeight="400"
          color="#292B30"
          marginBottom="0px"
        >
          {label}
        </FormLabel>
      )}
      <InputGroup display="flex" alignItems="center" h="56px"
      >
        {icon && (
          <InputLeftElement
            children={icon}
            alignSelf="center"
            display={"flex"}
            
            alignItems="center"
            h="100%"
          />
        )}
        {iconRight && (
          <InputRightElement
            display={"flex"}
            alignItems="center"
            h="100%"
            children={iconRight}
            alignSelf="center"
            pr="10px"
          />
        )}
        <ChakraInput
          name={name}
          id={name}
         color='#292B30'
          display="flex"
          alignItems={"center"}
          px='16px'
          fontSize='14px'
          h="56px"
          fontWeight="600"
          borderWidth="1.5px"
          borderRadius="10px"
          marginTop="0px"
          borderColor="#B8CAE8"
          bgColor="white"
          _hover={{
            // bgColor: "whiteAlpha.100",
            borderWidth: "2px",
          }}
          w="100%"
          ref={ref}
          {...rest}
        />
      </InputGroup>
    </Stack>
    {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
  </FormControl>
  );
};

export const Input = forwardRef(InputBase);