import React from "react";
import { ModalWithClose } from "../../../components";
import { Button, Flex } from "@chakra-ui/react";

const ModalOptions = ({
  isOpen,
  onClose,
  handleModalQuestions,
  handleModalStatus,
}) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <ModalWithClose title="Opções" isOpen={isOpen} onClose={handleClose}>
      <Flex direction="column" gap="16px" p="24px 24px 52px 24px">
        <Button variant="red" onClick={handleModalStatus}>
          Status Certificação
        </Button>
        <Button variant="outlineRed" onClick={handleModalQuestions}>
          Respostas dos Questionários
        </Button>
      </Flex>
    </ModalWithClose>
  );
};

export default ModalOptions;
