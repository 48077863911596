/* eslint-disable react-hooks/rules-of-hooks */
import api from "./api";

export default function getFaturaService(document, key) {
  if ( key === '47a652098d0d85496c6ebb7bb0599597') {
    return api.get(`/signature?secret=7F96231D019091DC6DFCDC5086A1EAA8&document=${document}`)
    .then(response => treatData(response.data));
  } else {
    return Promise.resolve([]);
  }

}

function treatData(info) {
  let invoices = [];
  if (info.hasError) {
    console.log('Erro na requisição');
    return;
  }

  if (info.data.message.length > 0) {
    const invoiceData = info.data.message;

    for (const item in invoiceData) 
    {
      let invoice = {};

      if (invoiceData[item].subscription === null) {
        invoice = {
          id : invoiceData[item].id,
          creationDate : invoiceData[item].created_at,
          customerName : invoiceData[item].customer.name,
          customerEmail : invoiceData[item].customer.email,
          url : invoiceData[item].url,
          cycle : 1,
          amount : invoiceData[item].amount,
          status : invoiceData[item].charges[0].status,
          paymentMethod : invoiceData[item].charges[0].payment_method.public_name !== 'Cartão de crédito' ? 'Boleto Bancário' : 'Cartão de Crédito',
          dueAt : invoiceData[item].due_at,
          plan : 'Multa de cancelamento'
        }
      } else {
        invoice = {
          id : invoiceData[item].id,
          creationDate : invoiceData[item].created_at,
          customerName : invoiceData[item].customer.name,
          customerEmail : invoiceData[item].customer.email,
          url : invoiceData[item].url,
          cycle : invoiceData[item].period.cycle,
          amount : invoiceData[item].amount,
          status : invoiceData[item].status,
          paymentMethod : invoiceData[item].charges[0].payment_method.public_name !== 'Cartão de crédito' ? 'Boleto Bancário' : 'Cartão de Crédito',
          dueAt : invoiceData[item].due_at,
          plan : invoiceData[item].subscription.plan.name
        }
      }

      invoices.push(invoice);
    }

  }

  return invoices;
}
