import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import axios from "axios"
import styled from "styled-components"

import SimpleSidebar from "../components/nav/Sidebar"
import iconDownload from "../../assets/images/icon-download.svg"
import logoSidebar from "../../assets/images/logo-sidebar.svg"
import logoGray from "../../assets/images/selo-gptw-expirado.png"
import ReactGA from 'react-ga';


import {
  Heading,
  Container,
  Link,
  Flex,
  Box,
  Tabs,
  TabList,
  Tab,
  Image,
} from "@chakra-ui/react"
import { downloadCert } from "../../services/downloadCert"

const Topmenu = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  padding: 5px;
  background-color: white;
  z-index: 1000;
`

const EmpresaCertificada = () => {
  const params = new URLSearchParams(window.location.search)

  const [isFetching, setIsFetching] = useState(true)
  const [error, setError] = useState(false)
  const [expired, setExpired] = useState(false)
  const [company, setCompany] = useState(null)
  const [research, setResearch] = useState(null)
  const [image, setImage] = useState(null)
  const [surveyEndDate, setSurveyEndDate] = useState(null)
  const [formattedEndDate, setFormattedEndDate] = useState(null)

  const url_company_info =
    window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
    `/company-register-info?cr=${params.get("s")}`

  useEffect(() => {
    params.has("s") ? setError(false) : setError(true)
    setIsFetching(true)

    const getParticipation = async () => {
      try {
        const company_info = await axios.get(url_company_info)

        setCompany(company_info.data.company)

        const url_pesquisa =
          window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
          `/participation/participation-info?dc=${params.get("s")}`

        const pesquisa = await axios.get(url_pesquisa)

        setResearch(pesquisa.data)
        if (
          company_info.data.length === 0 ||
          !pesquisa.data.applicationEndDate
        ) {
          console.log("ERRO 1")
          setError(true)
        }
        let timestamp = null

        timestamp = pesquisa.data.applicationEndDate
        const surveyEndDateObject = new Date(timestamp)

        let localSurveyEndDate = surveyEndDateObject.getUTCDate()
        localSurveyEndDate +=
          (surveyEndDateObject.getMonth() + 1).length === 1 ? "-0" : "-"
        localSurveyEndDate += surveyEndDateObject.getMonth() + 1
        localSurveyEndDate += `-${surveyEndDateObject.getFullYear()}`

        let today = new Date()
          .toLocaleDateString("pt-BR", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
          .split(/\//g)

        today = `${today[2]}${today[1]}${today[0]}`

        setSurveyEndDate(localSurveyEndDate)

        let localFormattedEndDate = localSurveyEndDate.split("-")

        localFormattedEndDate = new Date(
          `${localFormattedEndDate[1]}/${localFormattedEndDate[0]}/${localFormattedEndDate[2]}`
        )

        localFormattedEndDate.setDate(localFormattedEndDate.getDate() + 365)

        localFormattedEndDate = localFormattedEndDate.toLocaleDateString(
          "pt-BR",
          {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }
        )

        let endDate = localFormattedEndDate.split(/\//g)

        endDate = `${endDate[2]}${endDate[1]}${endDate[0]}`

        if (endDate < today) {
          console.log("EXPIRADO")
          setError(true)
          setExpired(true)
        } else {
          const imagem = await downloadCert(
            {
              surveyEndDate: localSurveyEndDate,
              formattedEndDate: localFormattedEndDate,
              idCompanyRegister: params.get("s"),
            },
            "png"
          )
          setImage(imagem)
        }

        setFormattedEndDate(localFormattedEndDate)

        setIsFetching(false)
      } catch (err) {
        console.log("ERRO 2")
        console.log(err)
        setError(true)
        return
      }
    }

    getParticipation()
  }, [])

  useEffect(() => {
    ReactGA.pageview(window["runConfig"].REACT_APP_CONNECT_BASE_URL + window.location.search);
  }, []);

  let selo = null

  if (!isFetching) {
    if (((!expired && !error) || (expired)) && surveyEndDate && formattedEndDate) {
      selo = { ...research, surveyEndDate, formattedEndDate }
    }
  }

  return (
    <>
      <Topmenu>
        <Image src={logoSidebar} alt="sidebar logo" w={["48px", "60px"]} />
      </Topmenu>
      <Container
        maxW="container.xl"
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
        flex={1}
        flexDir={"column"}
      >
        {error && !expired ? (
          "Nenhuma participação com certificado disponível encontrada."
        ) : isFetching ? (
          "Carregando..."
        ) : error && expired ? (
          <>
            <Heading as="h3" mt="30px" fontSize={"24px"}>
              CERTIFICADO EXPIRADO
            </Heading>
            <Image src={logoGray} maxWidth={340} mt={4} />
            <Heading
              as="h3"
              mt={8}
              color={"#002070"}
              fontSize={24}
              maxW={400}
              textAlign={"center"}
            >
              {company.fantasyName}
            </Heading>
            <Heading as="h3" mt={4} fontSize={"30px"}>
              {selo.formattedEndDate}
            </Heading>
          </>
        ) : !selo ? (
          "Nenhuma participação com certificado disponível encontrada."
        ) : (
          <>
            <Heading as="h3" mt="30px" fontSize={"24px"}>
              CERTIFICADO DA EMPRESA
            </Heading>
            <Image src={image} maxWidth={340} mt={4} />
            <Heading
              as="h3"
              mt={8}
              color={"#002070"}
              fontSize={24}
              maxW={400}
              textAlign={"center"}
            >
              {company.fantasyName}
            </Heading>
            <Heading as="h3" mt={4} fontSize={"30px"}>
              {selo.formattedEndDate}
            </Heading>
          </>
        )}
        <Box mt={8}>
          Saiba mais sobre o GPTW e a Certificação
          <Link
            fontWeight="600"
            href="https://gptw.com.br/certificacao/"
            color="#E60000"
            isExternal={true}
            variant="ghost"
          >
            &nbsp;clicando aqui!
          </Link>
        </Box>
        <Box mt={4}>
          Você acha que sua empresa é um Great Place to Work?
          <Link
            fontWeight="600"
            href="https://conteudo.gptw.com.br/indicacao-empresa-certificacao"
            color="#E60000"
            isExternal={true}
            variant="ghost"
          >
            &nbsp;Indique sua empresa&nbsp;
          </Link>
          para uma Certificação GPTW e nós entraremos em contato
        </Box>
      </Container>
    </>
  )
}

export default EmpresaCertificada
