import { useQuery } from "react-query";
import axios from "axios";

export const fetchParticipation = async (cnpj) => {
  const url_company_info =
    window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
    `/participation?dc=${cnpj}`;

  const response = await axios.get(url_company_info);
  return response?.data;
};

export const useParticipationData = (cnpj) => {
  return useQuery(
    ["participation-data", cnpj],
    () => fetchParticipation(cnpj),
    {
      enabled: !!cnpj,
      refetchOnWindowFocus: false,
    }
  );
};
