import React, { useState } from "react";
import {
  useMediaQuery,
  Button,
  Image,
  Heading,
  ScaleFade,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import people from "../../../assets/images/people.svg";
import iconClose from "../../../assets/images/icon-close.svg";

const titleMock = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";
const titleMockMobile = "Lorem ipsum.";
const descriptionMock =
  "Sed in libero commodo enim laoreet auctor. Donec nibh, non gravida nibh. Orci varius natoque penatibus.";
const descriptionMockMobile = "Sed in libero commodo enim laoreet auctor.";

const Card = ({ title = "", text = "", hasCloseButton }) => {
  const [close, setOnClose] = useState(true);

  const [mobile] = useMediaQuery("(max-width: 1090px)");
  const titleModel = mobile ? titleMockMobile : titleMock;
  const description = mobile ? descriptionMockMobile : descriptionMock;

  const handleClose = () => {
    setOnClose(false);
  };
  return (
    <>
      <ScaleFade in={close} reverse unmountOnExit>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          p={["4", "6"]}
          bg="#e2e1e7"
          borderRadius="12px"
          position="relative"
        >
          {hasCloseButton && (
            <Button
              h="23px"
              position={"absolute"}
              right={0}
              top="16px"
              p={0}
              onClick={handleClose}
              bg="transparent"
              leftIcon={<img src={iconClose} alt="icon close" />}
              _hover={{
                background: "transparent",
              }}
            />
          )}
          <GridItem w="100%" colSpan={2}>
            <Heading
              as="h3"
              fontSize={["17px", "24px"]}
              lineHeight="28px"
              fontWeight={700}
            >
              {title ? title : titleModel}
            </Heading>
            <Text pt="16px" fontSize={"14px"}>
              {text ? text : description}
            </Text>
          </GridItem>
          <GridItem w="100%" colSpan={1}
          position='relative'>
            <Image src={people} alt=""
            position={'absolute'}
            bottom={'-16px'}
             />
          </GridItem>
        </Grid>

        {/* <Flex
              p={["4", "6"]}
              bg="#e2e1e7"
              h="141px"
              borderRadius="12px"
              flexDir="column"
              position="absolute"
              w="100%"
              bottom={0}
            >
              {hasCloseButton && (
                <Button
                  h="23px"
                  position={"absolute"}
                  right={0}
                  top="16px"
                  p={0}
                  onClick={handleClose}
                  bg="transparent"
                  leftIcon={<img src={iconClose} alt="icon close" />}
                  _hover={{
                    background: "transparent",
                  }}
                ></Button>
              )}

            
             

              <img
                src={people}
                alt="people"
                style={{
                  height: "150px",
                  position: "absolute",
                  bottom: "0",
                  right: "70px",
                }}
              />
            </Flex> */}
      </ScaleFade>
    </>
  );
};

export default Card;
