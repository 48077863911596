import { object, string, array, mixed } from "yup";

const validFileExtensions = {
  image: [
    "jpg",
    "png",
    "jpeg",
    "svg",
    "webp",
    "pdf",
    "docx",
    "dotx",
    "txt",
    "csv",
    "ppt",
  ],
};

function formatFileSize(bytes, decimalPoint) {
  if (bytes === 0) return "0 Bytes";
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

function isValidFileType(files) {
  const list = Array.from(files);
  return list.every(
    (file) =>
      validFileExtensions["image"].indexOf(
        file.name?.toLowerCase().split(".").pop()
      ) > -1
  );
}

function isValidFileSize(files) {
  const list = Array.from(files).map((file) => file.size);

  const initialValue = 0;
  const size = list.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );

  return size / (1024 * 1024) < 10;
}

const schema = object().shape({
  answer: string().required("Campo obrigatório"),
  tags: array().min(1),
});

const filesSchema = object().shape({
  files: mixed()
    .required("VALIDATION_FIELD_REQUIRED")
    .test(
      "is-valid-max",
      "Você pode selecionar no máximo 10 arquivos",
      (value) => value && Array.from(value)?.length <= 10
    )
    .test(
      "is-valid-type",
      "Tipo de arquivo inválido",
      (value) => value && isValidFileType(value)
    )
    .test(
      "is-valid-size",
      "O tamanho máximo permitido é de 10GB",
      (value) => value && isValidFileSize(value)
    ),
});

function findDifferentValues(array1, array2) {
  const idTagsArray1 = array1.map((element) => element.idTag);
  const differentValues = array2.filter(
    (value) => !idTagsArray1.includes(value)
  );
  return differentValues;
}

function findMissingValues(array1, array2) {
  const removedValues = [];

  for (let i = 0; i < array1.length; i++) {
    if (!array2.includes(array1[i].idTag)) {
      removedValues.push(array1[i].idTag);
    }
  }

  return removedValues;
}

export {
  validFileExtensions,
  formatFileSize,
  isValidFileSize,
  isValidFileType,
  schema,
  filesSchema,
  findDifferentValues,
  findMissingValues,
};
