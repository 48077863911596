import React from "react";
import {
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { iconPinMap } from "../../../assets";

const CardDashboard = ({ data }) => {
  console.log("🚀 ~ data:", data);

  const [mobile] = useMediaQuery("(max-width: 992px)");
  const editClient = () => {
    const cr = data.id;
    //const linkToRedirect = `https://connect.gptw.info/dashboard?cr=${cr}`;
    const linkToRedirect = `${window["runConfig"].REACT_APP_CONNECT_BASE_URL}/dashboard?cr=${cr}`;

    window.open(linkToRedirect, "_blank");
  };
  return (
    <Flex
      direction={"column"}
      gap={"16px"}
      justify="space-between"
      align={"center"}
      w="100%"
      p={["16px", "16px", "16px", "32px"]}
      border="2px solid #F5F8F6"
      bg={"#fff"}
    >
      <Flex gap="24px" w="100%" align={["center", "center", "center", "start"]}>
        <Flex
          minH={["45px", "45px", "45px", "53px"]}
          direction={"column"}
          justify={"center"}
          gap={"8px"}
          w="100%"
          align={"start"}
        >
          {!!data?.company?.fantasyName ? (
            <Text
              fontWeight={500}
              fontSize={"12px"}
              lineHeight={"15px"}
              color="#FF1628"
              textTransform="uppercase"
              whiteSpace={"nowrap"}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {data?.company?.fantasyName}
            </Text>
          ) : null}
          {!!data?.company?.name ? (
            <Heading
              fontWeight={600}
              fontSize={["18px", "18px", "18px", "20px"]}
              lineHeight={["22px", "22px", "22px", "30px"]}
              color="#22262D"
            >
              {data?.company?.name}
            </Heading>
          ) : null}
        </Flex>
        {mobile ? (
          <Button
            h="48px"
            variant={"unstyled"}
            color="#FF1628"
            borderColor="#FF1628"
            borderRadius="unset"
            textDecor={"underline"}
            _hover={{ opacity: 0.7 }}
            minW="50px"
            p="8px 0"
            onClick={editClient}
          >
            Visualizar
          </Button>
        ) : (
          <Button
            w="fit-content"
            p="0 24px"
            variant={"outline"}
            color="#FF1628"
            borderColor="#FF1628"
            borderRadius="unset"
            _hover={{ opacity: 0.7 }}
            onClick={editClient}
          >
            Visualizar
          </Button>
        )}
      </Flex>
      <Divider borderColor="#EDF1EF" />
      <Flex
        w="100%"
        gap={["16px", "16px", "16px", "32px"]}
        h="40px"
        justify="center"
      >
        <TitleText title={"CNPJ"} text={data?.company?.cnpj} />
        <Divider orientation="vertical" borderColor="#EDF1EF" />
        <TitleText
          title={"Código de participação"}
          text={data?.company?.salesforce}
        />
        {mobile ? null : (
          <>
            <Divider orientation={"vertical"} borderColor="#EDF1EF" />
            <TitleText text={data?.company?.city} />
          </>
        )}
      </Flex>
      {mobile ? (
        <>
          <Divider borderColor="#EDF1EF" />
          <TitleText text={data?.company?.city} />
        </>
      ) : null}
    </Flex>
  );
};

export default CardDashboard;

const TitleText = ({ title, text }) => {
  return (
    <Flex
      gap={!!title ? "4px" : "12px"}
      align={!!title ? "start" : "center"}
      direction={!!title ? "column" : "row"}
      justify={!!title ? "center" : "start"}
      flex={"1"}
      w="100%"
      h={["35px", "35px", "35px", "40px"]}
    >
      {!!title ? (
        <Text
          fontWeight={500}
          fontSize={"12px"}
          lineHeight={"14px"}
          color="#555E66"
          whiteSpace={"nowrap"}
        >
          {title}
        </Text>
      ) : (
        <Image src={iconPinMap} alt="Estado e cidade" />
      )}
      <Text
        fontWeight={600}
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["17px", "17px", "17px", "20px"]}
        color="#32383F"
        whiteSpace={"nowrap"}
      >
        {text}
      </Text>
    </Flex>
  );
};