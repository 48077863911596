import React, { useState } from "react";
import {
  Box,
  Grid,
  Heading,
  Text,
  Textarea,
  Input,
  Flex,
  Button,
} from "@chakra-ui/react";
import apiForms from "../../../services/api-forms";

const Feedback = ({ title = "", text = "", hasCloseButton, show }) => {
  const [close, setOnClose] = useState(true);
  const [feedbackValue, setFeedbackValue] = useState(undefined);
  const [feedbackCheckEmail, setFeedbackCheckEmail] = useState(undefined);
  const [feedbackEmail, setFeedbackEmail] = useState(undefined);
  const [feedbackText, setFeedbackText] = useState(undefined);
  const [feedbackUrl, setFeedbackUrl] = useState(undefined);
  const [feedbackDate, setFeedbackDate] = useState(undefined);
  const [feedbackCountry, setFeedbackCountry] = useState(undefined);
  const [feedbackDevice, setFeedbackDevice] = useState(undefined);
  const [feedbackBrowser, setFeedbackBrowser] = useState(undefined);
  const [feedbackOS, setFeedbackOS] = useState(undefined);

  const handleClose = async (value) => {
    if (value === false) {
      setFeedbackUrl(document.URL);
      setFeedbackDevice(navigator.userAgent);
      setFeedbackBrowser(navigator.appCodeName);
      setFeedbackOS(navigator.platform || navigator.userAgentData.platform);
      await getIp();
    } else {
      setFeedbackValue(undefined);
      setFeedbackEmail(undefined);
      setFeedbackText(undefined);
      setFeedbackUrl(undefined);
      setFeedbackDate(undefined);
      setFeedbackCheckEmail(undefined);
    }

    setOnClose(value);
  };

  const handleFeedBackValue = (e) => {
    if (feedbackValue !== undefined) {
      const selectedBefore = document.getElementById(
        `feedBack${feedbackValue}`
      );
      selectedBefore.style.fontSize = "20px";
    }

    setFeedbackValue(e.target.parentNode.value);
    e.target.style.fontSize = "26px";
  };

  async function getIp() {
    let urlGetIP =
      "https://api.ipdata.co/?api-key=5bbd9691312272461d9ea6ee5357246239b8e6a4bcf035424817c15e";

    await fetch(urlGetIP, { method: "GET" })
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        if (res.ip) {
          setFeedbackDate(res.time_zone.current_time);
          setFeedbackCountry(res.country_name);
        } else {
          console.log("Problem getting IPData " + JSON.stringify(res));
          this.setState({ clientIP: "NÃO IDENTIFICADO" });
        }
      })
      .catch((err) => console.log(err));
  }

  const checkEmail = (email) => {
    let response;
    let test = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

    if (email === "") {
      response = undefined;
    } else if (test && test.length) {
      response = true;
    } else {
      response = false;
    }
    setFeedbackCheckEmail(response);
    console.log(feedbackCheckEmail);
    return response;
  };

  const handleEmail = () => {
    const email = document.getElementById("feedbackEmail").value;
    if (checkEmail(email)) {
      setFeedbackEmail(email);
    }
  };

  const handleText = () => {
    setFeedbackText(document.getElementById("feedbackText").value);
  };

  const sendEmail = async () => {
    let urlSendEmail = "https://api-message-service.gptw.info/api/send-email";

    await fetch(urlSendEmail, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        auth: "7F96231D019091DC6DFCDC5086A1EAA8",
      },
      body: `[{
        "from": "produtos_gptw@gptwbrasil.com.br",
        "to": ["ronald.scapin@gptw.com.br", "jose.souza@gptw.com.br"],
        "subject": "New Feedback",
        "body": "<!DOCTYPE html><html><body><div style=background-color:#487eb0;color:white;padding:20px;><h2 style=color:white;>Feedback de Cliente</h2><p style=color:white;>Enviado por: <a style=color:#dcdde1>${
          feedbackEmail ? feedbackEmail : "Anônimo"
        }</a></p><p style=color:white;>Nota: ${feedbackValue}</p><p style=color:white;>Mensagem: ${feedbackText}</p><p style=color:white;>Página de Origem do Feedback: <a style=color:#dcdde1>${feedbackUrl}</a></p></div></body></html>"
      }]`,
    })
      .then((res) => {
        console.log("E-mail Enviado! -> ", res);
      }, "jsonp")
      .catch((err) => console.log(err));
  };

  const handleSend = () => {
    const feedbackJson = {
      date: feedbackDate ? feedbackDate : "",
      country: feedbackCountry ? feedbackCountry : "",
      sourcePage: feedbackUrl ? feedbackUrl : "",
      device: feedbackDevice ? feedbackDevice : "",
      browser: feedbackBrowser ? feedbackBrowser : "",
      os: feedbackOS ? feedbackOS : "",
      emotion: feedbackValue ? feedbackValue : "",
      message: feedbackText ? feedbackText : "",
      email: feedbackEmail ? feedbackEmail : "",
    };

    apiForms
      .post("/api/feedbacks/", feedbackJson, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setOnClose(true);
        setFeedbackValue(undefined);
        setFeedbackEmail(undefined);
        setFeedbackText(undefined);
        setFeedbackUrl(undefined);
        setFeedbackDate(undefined);
      });

    sendEmail();
  };

  return (
    <>
      {show ? (
        <>
          <Box
            display={{ base: "none", sm: "none", md: "grid" }}
            position="fixed"
            bottom="50%"
            right="-50px"
            h="30px"
            alignContent="center"
            backgroundColor="#f4364c"
            borderRadius="5px 5px 0px 0px"
            p="5"
            filter="auto"
            transform="rotate(-90deg)"
            cursor="pointer"
            zIndex="9999999"
            onClick={() => handleClose(false)}
            opacity="0.7"
            _hover={{ opacity: "1" }}
          >
            <Grid
              h="20px"
              borderRadius="12px"
              position="relative"
              w="120px"
              bottom={0}
              alignContent="center"
              justifyContent="center"
              templateColumns="16px 80px"
              gap="10px"
            >
              <Text>&#128512;</Text>
              <Text
                display="inline"
                as="p"
                fontSize="16px"
                lineHeight="24px"
                fontFamily="Raleway"
                fontWeight="bold"
                color="white"
                p="0"
                textAlign="center"
              >
                Feedback
              </Text>
            </Grid>
          </Box>
        </>
      ) : null}
      <>
        {show && !close ? (
          <Box
            display={{ base: "none", sm: "none", md: "grid" }}
            position="fixed"
            bottom="30%"
            right="5px"
            h="360px"
            w="250px"
            backgroundColor="white"
            border="1px solid #f4364c"
            borderRadius="5px"
            zIndex="9999999"
          >
            <Grid
              h="100%"
              borderRadius="12px"
              position="relative"
              w="100%"
              bottom={0}
              gap="10px"
            >
              <Heading
                backgroundColor="#f4364c"
                color="white"
                borderRadius="3px 3px 0px 0px"
                alignSelf="top"
                p="5"
                textAlign="center"
                fontWeight="bold"
                h="30px"
              >
                Conte tudo!
              </Heading>
              <Grid h="100%" w="100%" p="5">
                <Grid h="100%" w="100%" p="5">
                  <Text
                    display="inline"
                    as="p"
                    fontSize="12px"
                    lineHeight="16px"
                    fontFamily="Raleway"
                    fontWeight="bold"
                    color="gray"
                    textAlign="center"
                    p="0"
                  >
                    Como foi a sua Experiência:
                  </Text>
                  <Flex w="100%" p="5" justifyContent="space-between" h="40">
                    <Button value="1">
                      <p
                        id="feedBack1"
                        onClick={(event) => handleFeedBackValue(event)}
                      >
                        &#128544;
                      </p>
                    </Button>
                    <Button value="2">
                      <p
                        id="feedBack2"
                        onClick={(event) => handleFeedBackValue(event)}
                      >
                        &#128543;
                      </p>
                    </Button>
                    <Button value="3">
                      <p
                        id="feedBack3"
                        onClick={(event) => handleFeedBackValue(event)}
                      >
                        &#128528;
                      </p>
                    </Button>
                    <Button value="4">
                      <p
                        id="feedBack4"
                        onClick={(event) => handleFeedBackValue(event)}
                      >
                        &#128512;
                      </p>
                    </Button>
                    <Button value="5">
                      <p
                        id="feedBack5"
                        onClick={(event) => handleFeedBackValue(event)}
                      >
                        &#128525;
                      </p>
                    </Button>
                  </Flex>
                </Grid>
                <Grid h="100%" w="100%" p="5">
                  <Text
                    display="inline"
                    as="p"
                    fontSize="12px"
                    fontFamily="Raleway"
                    fontWeight="bold"
                    color="gray"
                    textAlign="center"
                    p="0"
                  >
                    E-mail
                  </Text>
                  <Input
                    id="feedbackEmail"
                    w="100%"
                    h="30px"
                    fontSize="14px"
                    border={"1px solid #5C6066"}
                    borderRadius="5px"
                    resize="none"
                    p="2px"
                    onInput={() => handleEmail()}
                    type="email"
                  />
                  {feedbackCheckEmail === false && (
                    <Text color="red" fontSize="12px">
                      O e-mail inserido é inválido!
                    </Text>
                  )}
                  {feedbackCheckEmail === true && (
                    <Text color="green" fontSize="12px">
                      O e-mail inserido é válido!
                    </Text>
                  )}
                  {feedbackCheckEmail === undefined &&
                    !!feedbackValue &&
                    !!feedbackText &&
                    !!!feedbackEmail && (
                      <Text color="red" fontSize="12px">
                        Só conseguimos te responder caso você se identifique!
                      </Text>
                    )}
                </Grid>
                <Grid h="100%" w="100%" p="5">
                  <Text
                    display="inline"
                    as="p"
                    fontSize="12px"
                    fontFamily="Raleway"
                    fontWeight="bold"
                    color="gray"
                    textAlign="center"
                    p="0"
                  >
                    Escreva aqui o que você acha:
                  </Text>
                  <Textarea
                    id="feedbackText"
                    w="100%"
                    h="70px"
                    fontSize="14px"
                    border="1px solid #5C6066"
                    focusBorderColor="#5C6066"
                    borderRadius="5px"
                    resize="none"
                    p="2px"
                    onInput={() => handleText()}
                  />
                </Grid>
              </Grid>
              <Flex w="100%" p="5" justifyContent="space-between">
                <Button
                  type="submit"
                  w="60px"
                  h="30px"
                  fontSize="14px"
                  fontWeight="bold"
                  color="#f4364c"
                  border="2px solid #f4364c"
                  borderRadius="5px"
                  p="5"
                  onClick={() => handleClose(true)}
                >
                  Fechar
                </Button>
                <Button
                  type="submit"
                  w="60px"
                  h="30px"
                  fontSize="14px"
                  fontWeight="bold"
                  color="white"
                  backgroundColor={
                    feedbackValue &&
                    feedbackCheckEmail !== false &&
                    feedbackText
                      ? "teal"
                      : "grey"
                  }
                  borderRadius="5px"
                  p="5"
                  onClick={() => handleSend()}
                  cursor={
                    feedbackValue &&
                    feedbackCheckEmail !== false &&
                    feedbackText
                      ? "pointer"
                      : "not-allowed"
                  }
                  isDisabled={
                    feedbackValue &&
                    feedbackCheckEmail !== false &&
                    feedbackText
                      ? false
                      : true
                  }
                >
                  Enviar
                </Button>
              </Flex>
            </Grid>
          </Box>
        ) : null}
      </>
    </>
  );
};

export default Feedback;
