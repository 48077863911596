import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  Grid,
  GridItem,
  Box,
  Text,
  Flex,
  Button,
  chakra,
  useMediaQuery,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { AttentionModal } from "../../components/modal/";
import queryParamsPresent from "../../rankings/get-params";
import { CustomTextarea } from "../../components/textarea";
import PilarQuestionnaireCard from "./PilarQuestionnaireCard";
import { AboutModal } from "./Questionario/AboutModal";
import { Badge } from "../../components/badge";
import { pilaresForAllBg } from "../../../assets/images";
import Breadcrumbs from "../../components/breadcrumbs";
import api from "./api";
import SidebarWithHeader from "../../components/nav/Sidebar";
import {
  useTemplateDataQuery,
  useParticipationDataQuery,
  useDataQuery,
} from "./hooks";
import {
  DescriptionHelpItems,
  DescriptionSectionDesktop,
  LastDescriptionHelpItems,
} from "./small-components";
import { format, addDays, isAfter } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useResearchInfoDataQuery } from "../../../hooks/useResearchInfo";
import { useCompanyData } from "../../../helpers/useCompanyInfo";
import ConfirmationModal from "./ConfirmationModal";
import { getCookie } from "../../common/cookieManager";
import axios from "axios";

const schema = yup.object({
  additionalInformation: yup
    .string()
    .trim()
    .max(5000, "O texto deve ter no máximo 5000 caracteres")
    .matches(/^(?!\s*$).+/, "A descrição é obrigatória")
    .required("A descrição é obrigatória"),
  textareaEnabled: yup.boolean(),});

function TextAreaControl({ control, maxLenght }) {
  const finalAnswer = useWatch({
    control,
    name: "additionalInformation",
  });

  return (
    <Flex align="center" justify="start" pt={["16px", "20px"]}>
      <Text as="span" fontWeight={600}>
        {finalAnswer?.length > 0 ? finalAnswer.length : "0"}
      </Text>
      <Text as="span">/{maxLenght} caracteres</Text>
    </Flex>
  );
}

const fetchRankings = async (companyId) => {
  const { data } = await axios.get(`https://connect.gptw.info/api/rankings/rankings?company_id=${companyId}`);
  return data;
};

const CadastrosUser = () => {
  const toast = useToast();
  const scrollRef = useRef(null);

  const queryClient = useQueryClient();
  const [st, id, token] = queryParamsPresent();
  const email = getCookie("user_email");
  const { data: companyData } = useCompanyData(id);
  const { data: answersData, answersDataIsLoading } = useDataQuery(id);
  const { data: templateData, isLoading: templateDataIsLoading } =
    useTemplateDataQuery(id);
  const { data: participationData } = useParticipationDataQuery(id);
  const { data: researchData } = useResearchInfoDataQuery(id);

  const [mobile] = useMediaQuery("(max-width: 48em)");
  const [finalResearchDate, setFinalResearchDate] = useState(null);
  const [isThirtyDaysPassed, setThirtyDaysPassed] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [submitAfterConfirmation, setSubmitAfterConfirmation] = useState(false);
  const [success, setSuccess] = useState(false);

  const { data: rankingsData } = useQuery(["rankings", id], () => fetchRankings(id));

  const {
    control,
    register,
    watch,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      additionalInformation: participationData?.additionalInformation || "",
      textareaEnabled: false,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });


  const getEarliestEndLimitDate = (items) => {
    const activeItems = items.filter((item) => item.status === "ACTIVE");
    if (activeItems.length === 0) {
      return null;
    }
    
    const earliestDate = activeItems
      .map((item) => new Date(item.end_limit_date))
      .reduce((earliest, current) => (current < earliest ? current : earliest));

    return earliestDate;
  };

  const earliestDateRule = window["runConfig"].REACT_APP_EARLIER_DATE_RULE;

  const watchTextareaEnabled = watch('textareaEnabled')

  useEffect(() => {
    if (researchData && researchData?.applicationEndDate && rankingsData) {
      let limitDate;
      if (earliestDateRule === "active") {
        console.log("rankings: ", rankingsData)
        const earliestDate = getEarliestEndLimitDate(rankingsData);
        if (!earliestDate) {
          setThirtyDaysPassed(false);
          return;
        }
        limitDate = new Date(earliestDate);
      } else {
        let date = new Date(researchData?.applicationEndDate);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59); // Formatando pra UTC
        limitDate = addDays(date, 30);
      }
      const currentDate = new Date();
      const hasPassedThirtyDays = isAfter(currentDate, limitDate);
      setThirtyDaysPassed(hasPassedThirtyDays);
      setFinalResearchDate(limitDate);

      if (
        participationData?.finished &&
        participationData?.deadline_reopening
      ) {
        const reopeningDate = new Date(participationData?.deadline_reopening);
        const canReopen = isAfter(reopeningDate, new Date());
        const afterThirtyDays = isAfter(reopeningDate, limitDate);
        if (canReopen && afterThirtyDays) {
          setThirtyDaysPassed(false);
          setFinalResearchDate(reopeningDate);
        }
      }
    }
  }, [researchData, participationData, earliestDateRule, rankingsData]);

  useEffect(() => {
    if (!!participationData && participationData?.additionalInformation) {
      setValue(
        "additionalInformation",
        participationData.additionalInformation
      );
    }
    setValue("textareaEnabled", false);
  }, [participationData]);

  const sendMail = async () => {
    try {
      await axios.post(`https://api-message-service.gptw.info/api/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          auth: "7F96231D019091DC6DFCDC5086A1EAA8",
        },
        body: `[{
          "from": "produtos_gptw@gptwbrasil.com.br",
          "to": ["deyse.alves@greatpeople.com.br", "joao.teixeira@greatpeople.com.br"],
          "subject": "Pilares enviados. Participação ${researchData?.salesforceParticipationCode}",
          "body": "<!DOCTYPE html><html><body><div style=background-color:#487eb0;color:white;padding:20px;><h2 style=color:white;>Pilares Enviados</h2><p style=color:white;>Os Pilares for All da pesquisa ${researchData?.salesforceParticipationCode} foram enviados  com sucesso!</p></div></body></html>"
        }]`,
      })
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (success) {
      sendMail();
    }
  }, [success]);


  const update = useMutation(
    async (values) => {
      try {
        const {updatedValues, textArea} = values;
        if (participationData === undefined) {
          await api.post("/pillarsForAll", updatedValues);
        } else {
          await api.put("/pillarsForAll", updatedValues);
        }
        if (!textArea) {
          setSuccess(true);          
        } else {  
          setSuccess(false);
        }
        setValue("textareaEnabled", false);
      } catch (error) {
        toast({
          title: "Falha no envio",
          description: "Não foi possivel atualizar os dados.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setSuccess(false);
        setSubmitAfterConfirmation(false);
        setValue("textareaEnabled", false);
        console.error(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("pilares-participation");
      },
    }
  );

  async function updateAdditionalInformation() {
    const newAdditionalInformation = watch("additionalInformation");

    const isValid = !(!!errors?.additionalInformation || !!errors?.textareaEnabled);

    if (isValid) {
      let updatedValues;
      if (participationData === undefined) {
        updatedValues = {
          idFormQuestion: answersData[0]?.idFormQuestion || null,
          idCompanyRegister: id,
          additionalInformation: newAdditionalInformation,
          deadline_reopening: null,
          finished: false,
          companyName: companyData?.company?.name,
          companyCNPJ: companyData?.company?.cnpj,
          participationCode: researchData?.salesforceParticipationCode,
          salesforceId: researchData?.salesforceId,
          user: email,
        };
      } else {
        updatedValues = {
          id: participationData?.id,
          idCompanyRegister: id,
          finished: false,
          additionalInformation: newAdditionalInformation,
        };
      }
      update.mutateAsync({updatedValues, textArea: true});
    }
  }

  const onSubmit = async (values) => {
    if (watchTextareaEnabled) return

    if (!submitAfterConfirmation) {
      handleConfirmationModal();
    } else {
      let updatedValues;
      if (participationData === undefined) {
        updatedValues = {
          idFormQuestion: answersData[0]?.idFormQuestion || null,
          idCompanyRegister: id,
          additionalInformation: values.additionalInformation,
          finished: false,
          companyName: companyData?.company?.name,
          companyCNPJ: companyData?.company?.cnpj,
          participationCode: researchData?.salesforceParticipationCode,
          salesforceId: researchData?.salesforceId,
          user: email,
        };
      } else {
        updatedValues = {
          id: participationData?.id,
          idCompanyRegister: id,
          additionalInformation: values.additionalInformation,
          finished: true,
          deadline_reopening: null,
          avaliationStatus: "WAITING_ATTRIBUTION",
          sendedAt: new Date(),
          user: email,
        };
      }
      await update.mutateAsync({updatedValues, textArea: false});
    }
  };

  const toggleTextarea = () => {
    setValue("textareaEnabled", true);
    // setTextareaEnabled(!textareaEnabled);
  };

  const handleConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const scrollToErrorElement = () => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    if (errors && (errors?.additionalInformation || errors?.textareaEnabled)) {
      scrollToErrorElement();
    }
  }, [errors]);

  useEffect(() => {
    if (submitAfterConfirmation) {
      handleSubmit(onSubmit)();
    }
  }, [submitAfterConfirmation]);

  const handleDownload = () => {
    fetch(
      `${process.env.PUBLIC_URL}/resources/manual_preenchimento_praticas_culturais.pdf`,
      {
        method: "GET",
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `manual_preenchimento_praticas_culturais.pdf`
        );

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  const renderedIdpillars = [];

  if (templateDataIsLoading || answersDataIsLoading) {
    return (
      <Flex height="100vh" justify="center" align="center">
        <Spinner />
      </Flex>
    );
  }
  return (
    <SidebarWithHeader>
      <Box pr="16px" as="form" onSubmit={handleSubmit(onSubmit)}>
        <Breadcrumbs
          breadcumbsItems={[
            {
              nome: "Cadastros",
              link: `/registration/profile?cr=${id}&st=${st}&token=${token}`,
            },
            {
              nome: "Pilares for All",
              link: "#",
              current: true,
            },
          ]}
          mb={["22px", "26px"]}
        />

        {!mobile && (
          <DescriptionSectionDesktop handleDownload={handleDownload} />
        )}

        {mobile && <AboutModal handleDownload={handleDownload} />}

        <Box mt={["34px", "48px"]}>
          <Stack spacing="24px">
            <chakra.h2 fontSize={["16px", "20px"]} fontWeight="700">
              Informações gerais
            </chakra.h2>

            <Grid
              fontSize={["14px", "16px"]}
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(6, 1fr)",
              }}
              gap={[0, 4]}
            >
              <GridItem colSpan={2} maxW="443px">
                <Text>
                  <Text as="span" fontWeight={600} lineHeight="28px">
                    Faça uma breve descrição da principal atividade da sua
                    empresa.
                  </Text>{" "}
                  Inclua informações sobre:
                </Text>
              </GridItem>

              <GridItem colSpan={2} display="flex" pr="40px">
                <DescriptionHelpItems />
              </GridItem>
              <GridItem colSpan={2} ref={scrollRef}>
                <LastDescriptionHelpItems />
              </GridItem>
            </Grid>
            {participationData?.finished || isThirtyDaysPassed ? (
              <CustomTextarea
                {...register("additionalInformation")}
                isDisabled={true}
                height={["715px", "318px"]}
              />
            ) : (
              <CustomTextarea
                {...register("additionalInformation")}
                error={errors.additionalInformation}
                isDisabled={!watchTextareaEnabled}
                height={["715px", "318px"]}
              />
            )}

            {participationData?.finished || isThirtyDaysPassed ? (
              <Stack w="100%" my={["16px", "24px"]}>
                <Badge>
                  {participationData?.finished ? (
                    <Text fontWeight={500} letterSpacing="0.5">
                      Pilares For All já enviado para <b>avaliação.</b>
                    </Text>
                  ) : (
                    <Text fontWeight={500} letterSpacing="0.5">
                      Prazo de preenchimento encerrado{" "}
                    </Text>
                  )}
                </Badge>
              </Stack>
            ) : (
              <Flex align="center" justify="space-between">
                <Flex>
                  {(errors.additionalInformation || errors.textareaEnabled) && (
                    <Text
                      as="span"
                      fontSize="14px"
                      fontWeight={500}
                      style={{ color: "red" }}
                    >
                      {errors.textareaEnabled ? errors.textareaEnabled.message : errors.additionalInformation.message}
                    </Text>
                  )}
                </Flex>
                <Flex align="center" justify="end" gap="32px" pt="12px">
                  <TextAreaControl control={control} maxLenght={5000} />

                  <Button
                    variant={!watchTextareaEnabled ? "secondary" : "primary"}
                    width="170px"
                    height="40px"
                    // isDisabled={!isValid}
                    isLoading={update.isLoading}
                    type="button"
                    onClick={
                      watchTextareaEnabled
                        ? () => updateAdditionalInformation()
                        : toggleTextarea
                    }
                  >
                    {!watchTextareaEnabled ? "Habilitar edição" : "Salvar"}
                  </Button>
                </Flex>
              </Flex>
            )}
          </Stack>
        </Box>

        <Grid
          templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
          gap="16px"
          pt={["32px", "48px"]}
        >
          {!!answersData &&
            answersData.map((d, index) => {
              // Check if the idpillar has already been rendered
              if (renderedIdpillars.includes(d.idpillar)) {
                return null; // Skip rendering if it has been rendered
              }

              // Add the current idpillar to the renderedIdpillars array
              renderedIdpillars.push(d.idpillar);

              return (
                <PilarQuestionnaireCard
                  key={index}
                  iconColor="#2CD9DD"
                  bg={pilaresForAllBg}
                  inicial={d.answered ? 10 : 0}
                  order={+d.positionquestion}
                  answered={d.answered}
                  title={d.namepillar}
                  url={`/registration/profile/pilares-for-all/quest?pillar=${d?.idpillar}&cr=${id}`}
                  estado={false}
                  iconePlay={true}
                  textoUrl={
                    participationData?.finished || isThirtyDaysPassed
                      ? "Visualizar"
                      : "Editar"
                  }
                />
              );
            })}
        </Grid>

        {participationData?.finished || isThirtyDaysPassed ? (
          <Stack w="100%" my={["16px", "24px"]}>
            <Badge>
              {participationData?.finished ? (
                <Flex justify="space-between" gap={["12px", "100px"]} w="full">
                  <Text>
                    <Text as="span" fontWeight={600}>
                      Pilares For All
                    </Text>{" "}
                    enviado para avaliação no dia{" "}
                    <b>
                      {format(
                        new Date(participationData?.sendedAt),
                        "dd/MM/yyyy",
                        {
                          locale: ptBR,
                        }
                      )}
                    </b>{" "}
                  </Text>
                </Flex>
              ) : (
                <Text fontWeight={500} letterSpacing="0.5">
                  Prazo de preenchimento encerrado
                </Text>
              )}
            </Badge>
          </Stack>
        ) : (
          <Flex
            direction={["column", "row"]}
            justify="space-between"
            pt="32px"
            gap="32px"
          >
            {finalResearchDate && (
              <Badge w="full" borderRadius="10px" bg="#FFD9C0">
                <Flex align="center" justify="center" w="full">
                  <Text>
                    <b>Não esqueça de enviar sua avaliação!</b> O prazo final
                    para envio é até o dia{" "}
                    <b>
                      {" "}
                      {finalResearchDate &&
                        format(finalResearchDate, "dd/MM/yyyy", {
                          locale: ptBR,
                        })}
                    </b>
                  </Text>
                </Flex>
              </Badge>
            )}
            <Button
              type="submit"
              ml="auto"
              height={["56px", "40px"]}
              minW="265px"
              variant="primary"
              width={["full", "265px"]}
              isDisabled={
                !templateData?.allRequiredAnswered ||
                participationData?.finished ||
                isThirtyDaysPassed || watchTextareaEnabled
              }
              isLoading={isSubmitting}
            >
              Enviar para avaliação
            </Button>
          </Flex>
        )}
      </Box>

      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={handleCloseConfirmationModal}
        setSubmitAfterConfirmation={setSubmitAfterConfirmation}
        isSubmitting={isSubmitting}
        researchData={researchData}
        success={success}
        setSuccess={success}
      />
    </SidebarWithHeader>
  );
};

export default CadastrosUser;
