import React, { useState } from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import { Header, NavbarAdmin } from "../../components";

function ManageAdmin() {
  const loading = false;
  const [textSearch, setTextSearch] = useState();

  return (
    <NavbarAdmin>
      {loading ? (
        <Flex height="400px" justify={"center"} align="center">
          <Spinner />
        </Flex>
      ) : (
        <Flex gap={4} direction={"column"}>
          <Header
            title={"Empresas"}
            total={10}
            textSearch={textSearch}
            setTextSearch={setTextSearch}
          />
        </Flex>
      )}
    </NavbarAdmin>
  );
}
export default ManageAdmin;
