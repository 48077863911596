import React from "react";
import { Tag as ChakraTag } from "@chakra-ui/react";

const TagStatus = ({ name, transparent }) => {
  const variantColors = {
    GREEN: {
      color1: "#EAFFD0",
      color2: "#059E30",
      text: "Certificada",
    },
    PURPLE: {
      color1: "#FDEEFF",
      color2: "#9D139F",
      text: "Certificada",
    },
    RED: {
      color1: "#FFEAE3",
      color2: "#D31A1A",
      text: "Expirado",
    },
    AQUA: {
      color1: "#EEFFFB",
      color2: "#0EBF98",
      text: "Ativo",
    },
    BLUE: {
      color1: "#EAF6FF",
      color2: "#0D5990",
      text: "Reprovado",
    },
    YELLOW: {
      color1: "#FFFCEE",
      color2: "#E3B309",
      text: "Reprovado",
    },
    GRAY: {
      color1: "#F5F8F6",
      color2: "#555E66",
      text: "Aguardando",
    },
  };

  const selectedVariant = () => {
    switch (true) {
      case name.includes("Certificada"):
        return variantColors.GREEN;
      // case name.includes("Reprovado"):
      //   return variantColors.BLUE;
      case name.includes("Ativo"):
        return variantColors.AQUA;
      case name.includes("Expirado"):
        return variantColors.RED;
      case name.includes("À Expirar"):
        return variantColors.PURPLE;
      case name.includes("Reprovado"):
        return variantColors.YELLOW;
      case name.includes("andamento"):
      case name.includes("processo"):
      default:
        return variantColors.GRAY;
    }
  };

  return (
    <ChakraTag
      bg={transparent ? selectedVariant().color1 : selectedVariant().color2}
      color={transparent ? selectedVariant().color2 : selectedVariant().color1}
      border={transparent ? `1px solid ${selectedVariant().color2}` : "none"}
      borderRadius="8px"
      p={["6px 12px", "6px 12px", "6px 12px", "8px 16px"]}
      fontSize={["12px", "12px", "12px", "14px"]}
      lineHeight={["13px", "13px", "13px", "16px"]}
      fontWeight={600}
      minW="142px"
      maxW="142px"
      minH={"fit-content"}
      whiteSpace="wrap"
      justifyContent={"center"}
    >
      {selectedVariant().text}
    </ChakraTag>
  );
};

export default TagStatus;
