import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Box, Button, Flex, IconButton, Image, Input } from "@chakra-ui/react";
import { BsX } from "react-icons/bs";

/**
 * @typedef {object} Props
 * @property {(data: ImageItem[]) => void} onChange
 * @property {boolean} multiple
 * @property {string | undefined} name
 * @property {boolean} readOnly
 * @property {string[]} values
 * @property {React.CSSProperties} style
 */

/**
 * @typedef {object} Ref
 * @property {HTMLInputElement | null} current
 */

/**
 * @typedef {object} ImageItem
 * @property {string} type
 * @property {string | File} value
 */

/**
 * @param {Props} props
 * @param {Ref} ref
 */
const CustomInputImage = (props, ref) => {
  /**
   * @type {[ImageItem[], (state: React.SetStateAction<ImageItem[]> => ImageItem[])]}
   */
  const [images, setImages] = useState([]);

  const inputRef = useRef(null);

  const { onChange, values, ...inputProps } = props;

  const handleImageChange = (event) => {
    const files = event.target.files;
    const newImages = [];

    if (!files) return;

    for (let i = 0; i < files.length; i++) {
      newImages.push({ type: "file", value: files[i] });
    }

    setImages((prev) => [...prev, ...newImages]);
    if (onChange) {
      onChange([...images, ...newImages]);
    }
  };

  const handleOpenFilePicker = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleRemoveImage = async (indexToRemove) => {
    if (images[indexToRemove].type === "url") {
      const message =
        "Você está prestes a remover uma imagem que já está salva. Tem certeza de que deseja apagá-la?";
      const result = window.confirm(message);
      if (!result) return;
    }

    const updatedImages = images.filter((_, i) => i !== indexToRemove);
    setImages(updatedImages);
    if (onChange) {
      onChange(updatedImages);
    }
  };

  useImperativeHandle(ref, () => inputRef.current, []);

  useEffect(() => {
    if (!Array.isArray(values)) return;

    const defaultValues = values
      .filter((value) => !!value)
      .map((value) => ({ type: "url", value }));

    setImages(defaultValues);
  }, []);

  return (
    <Box>
      <Button onClick={handleOpenFilePicker}>Selecionar Imagens</Button>

      <Input
        {...inputProps}
        hidden
        value={""}
        onInput={handleImageChange}
        accept=".jpeg, .png, .jpg"
        type="file"
        ref={inputRef}
      />

      <Flex mt="4" flexWrap="wrap">
        {images?.map((image, index) => (
          <Box key={index} mr="2" mb="2" position="relative">
            {typeof image.value === "string" && image.type === "url" && (
              <Image
                boxSize="100px"
                borderRadius={10}
                src={image.value}
                alt={`Imagem ${index + 1}`}
                title={image.value}
              />
            )}

            {typeof image.value === "object" && image.type === "file" && (
              <Image
                boxSize="100px"
                borderRadius={10}
                src={URL.createObjectURL(image.value)}
                alt={`Imagem ${index + 1}`}
                title={image.value.name}
              />
            )}

            <IconButton
              size="xs"
              colorScheme="red"
              aria-label="Remover Imagem"
              icon={<BsX size={22} />}
              position="absolute"
              top="2"
              right="2"
              borderRadius="full"
              onClick={() => handleRemoveImage(index)}
            />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default forwardRef(CustomInputImage);
