import { useEffect, useState } from "react"
import axios from "axios"
import { downloadAll, downloadCert } from "../../../services/downloadCert"

import seloCompleto from "../../../assets/images/gptwSeloNaoLogo.png"
import seloSemData from "../../../assets/images/gptwSeloSemData.png"
import seloDistorcido from "../../../assets/images/gptwSeloDistorcido.png"
import seloCores from "../../../assets/images/gptwSeloAplicacaoCor.png"
import logo from "../../../assets/images/icon-certificacao.svg"
import logoGray from "../../../assets/images/icon-certificacao-gray.svg"

import iconPendency from "../../../assets/images/icon-pendency.svg"
import iconMore from "../../../assets/images/icon-more.svg"

import {
  Button,
  Grid,
  GridItem,
  Flex,
  Box,
  Tag,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { InnerContainer, Modal, ModalContainer } from "./styles"

const Certificacoes = () => {
  const params = new URLSearchParams(window.location.search)

  const [isFetching, setIsFetching] = useState(true)
  const [error, setError] = useState(false)
  const [certificates, setCertificates] = useState(null)
  const [modal, setModal] = useState(null)

  const url_company_info =
    window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
    `/company-register-info?cr=${params.get("cr")}`

  useEffect(() => {
    params.has("cr") ? setError(false) : setError(true)
    setIsFetching(true)

    const getCertificates = async () => {
      try {
        const company_info = await axios.get(url_company_info)

        const url_certificados =
          window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
          `/participation/valid?dc=${company_info.data.company.cnpj
            .replace(/\./g, "")
            .replace(/-/g, "")
            .replace(/\//, "")}`

        const certificados = await axios.get(url_certificados)

        setCertificates(certificados.data)
        if (certificados.data.length === 0) {
          setError(true)
        }
        setIsFetching(false)
      } catch (error) {
        setError(true)
        return
      }
    }

    getCertificates()
  }, [])
  let certs = null

  let today = new Date()
    .toLocaleDateString("pt-BR", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })
    .split(/\//g)

  today = `${today[2]}${today[1]}${today[0]}`

  if (isFetching) {
    console.log("loading...")
  } else {
    if (!error && certificates && certificates.length !== 0) {
      certs = certificates
        .filter((certificado) => {
          if (certificado.surveyEndDate) {
            const endDate = new Date(certificado.surveyEndDate.split('-').reverse().join('-')).getTime() + 86399999;

            if ((certificado.status === 'AUDITING') || 
                (Date.now() < endDate + 172800000 && certificado.status !== 'APPROVED')) {
              return false;
            }
            if ((Date.now() > endDate + 172800000 && certificado.ti !== "null") || (certificado.status = 'APPROVED')) {
              return true
            }
            return false
          }
          return false
        })
        .map((certificado) => {
          let endDate = certificado.expirationDate.split("-")
          endDate = new Date(
            `${endDate[1]}/${endDate[0]}/${endDate[2]}`
            //.replace(/-/g, "/").replace(/T.+/, "")
          )

          endDate = endDate.toLocaleDateString("pt-BR", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })

          let formattedEndDate = endDate
          endDate = endDate.split(/\//g)

          endDate = `${endDate[2]}${endDate[1]}${endDate[0]}`

          return {
            ...certificado,
            download: true,
            endDate,
            formattedEndDate,
          }
        })

      certs.sort((a, b) => {
        return b.endDate - a.endDate
      })
    }
  }

  const openModal = (method, parameters) => {
    setModal(
      <ModalContainer onClick={() => setModal(null)}>
        <Modal>
          <InnerContainer>
            <h1>Orientações de uso do selo</h1>
            <p>
              Abaixo estão as orientações de uso para o selo da Certificação
              GPTW. Ao aceitar os Termos de Uso da plataforma GPTW Connect, o
              usuário também concorda que irá seguir as orientações fornecidas
              neste documento, sob pena de invalidação de sua certificação, caso
              as regras descritas abaixo não sejam cumpridas.{" "}
            </p>
            <ul>
              <li>
                Não é permitido utilizar o logo do GPTW para divulgar a
                certificação da empresa. A empresa deverá utilizar sempre o selo
                de certificação nesses casos.{" "}
              </li>
              <img
                src={seloCompleto}
                style={{
                  maxHeight: "225px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                alt="Selo gptw completo e selo gptw apenas o logo"
              />
              <li>
                Elementos como período de expiração e/ou país de origem que
                compõem o selo de certificação não devem ser alterados ou
                excluídos
              </li>
              <img
                src={seloSemData}
                style={{
                  maxHeight: "225px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                alt="Selo gptw completo e selo gptw apenas o logo"
              />
              <li>Não é permitido redimensionar o selo fora de escala. </li>
              <img
                src={seloDistorcido}
                style={{
                  maxHeight: "225px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                alt="Selo gptw completo e selo gptw apenas o logo"
              />
              <li>
                Não é permitido alterar as cores do selo de certificação.{" "}
              </li>
              <img
                src={seloCores}
                style={{
                  maxHeight: "225px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                alt="Selo gptw completo e selo gptw apenas o logo"
              />
              <li>
                Não é permitido utilizar o selo após sua data de expiração.{" "}
              </li>
              <li>
                Caso haja alguma mudança destes termos de uso, o cliente terá
                que fazer novamente a validação deste documento por meio da
                plataforma GPTW Connect.{" "}
              </li>
              <li>
                A Certificação é apenas o primeiro nível dos reconhecimentos que
                uma organização pode obter do GPTW. Portanto, se sua organização
                apenas possui este selo de certificação, ao divulgar o
                reconhecimento, utilize o termo “certificada”, e não “premiado”.
                Organizações premiadas são apenas aquelas que deram
                prosseguimento ao processo de certificação e obtiveram também o
                selo de premiação em algum ranking das Melhores Empresas para
                Trabalhar.
              </li>
            </ul>
            <h2>Para prosseguir e utilizar o selo, clique no botão abaixo:</h2>
            <Button
              onClick={() =>
                method(parameters[0], parameters[1], parameters[2])
              }
              style={{ padding: "10px 20px" }}
            >
              Eu aceito
            </Button>
          </InnerContainer>
        </Modal>
      </ModalContainer>
    )
  }
  // TODO: Modificar a rota de gerar o selo pra incluir o qr code na imagem em todos os formatos (QR Code com link pra página pública, passando CR na URL)
  // TODO: Criar uma página de acesso público no Connect App.
  // TODO: Fazer uma rota no Result Service pra trazer os dados da empresa e a imagem do selo de certificação utilizando o CR.
  // TODO: Caso o selo esteja expirado, mostrar isso na rota acima.
  // * Parte 2, fazer depois
  // TODO: Fazer o selo de certificação do zero (inteiramente com o Canvas).
  // TODO: Permitir baixar selo expirado
  return (
    <>
      {error ? (
        <Flex justify={"center"}>
          Nenhuma participação com certificado disponível encontrada.
        </Flex>
      ) : isFetching ? (
        <Flex justify={"center"}>Carregando...</Flex>
      ) : certs.length === 0 ? (
        <Flex justify={"center"}>
          Nenhuma participação com certificado disponível encontrada.
        </Flex>
      ) : (
        <Grid templateColumns="repeat(4, 1fr)" gap={6} w="100%">
          {modal}
          {certs.map((certificado, key) => (
            <GridItem
              w="100%"
              h="56px"
              bg="white"
              border="1px"
              borderColor="#CCCCCC"
              borderRadius="12px"
              height="auto"
              overflow="hidden"
              key={key}
            >
              <Box p={4} display="flex" flexDirection={"column"}>
                <Flex justify={"space-between"} height={"20px"}>
                  {certificado.endDate > today ? (
                    <img
                      src={iconPendency}
                      alt="icon pendency"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        alert(
                          "Você pode baixar este selo clicando nos 3 pontinhos à direita e escolhendo um formato."
                        )
                      }
                    />
                  ) : (
                    <img
                      src={iconPendency}
                      alt="icon pendency"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        alert("Selos expirados não podem ser baixados.")
                      }
                    />
                  )}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#2e2e2e",
                      fontWeight: "500",
                      position: "relative",
                    }}
                  >
                    {certificado.participationCode}
                  </span>
                  {certificado.endDate > today ? (
                    <Menu>
                      <MenuButton>
                        <img src={iconMore} alt="more" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() =>
                            openModal(downloadCert, [certs[key], "png"])
                          }
                        >
                          Baixar selo em <b>&nbsp;.PNG</b>
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            openModal(downloadCert, [certs[key], "jpeg"])
                          }
                        >
                          Baixar selo em <b>&nbsp;.JPEG</b>
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            openModal(downloadCert, [certs[key], "png", "svg"])
                          }
                        >
                          Baixar selo em <b>&nbsp;.SVG</b>
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            openModal(downloadCert, [certs[key], "png", "pdf"])
                          }
                        >
                          Baixar selo em <b>&nbsp;.EPS</b>
                        </MenuItem>
                        <MenuItem
                          onClick={() => openModal(downloadAll, [certs[key]])}
                        >
                          Baixar<b>&nbsp;todos&nbsp;</b>os selos
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  ) : (
                    <div style={{ width: "20px", height: "20px" }} />
                  )}
                </Flex>
                <img
                  src={certificado.endDate > today ? logo : logoGray}
                  alt="gptw logo de certificação"
                  style={{
                    margin: "0 auto",
                  }}
                />

                <Tag
                  fontWeight="600"
                  bg="#e9e8ef"
                  w="65px"
                  fontSize="12px"
                  alignSelf={"end"}
                >
                  Nota:{" "}
                  {certificado.ti.length > 2
                    ? certificado.ti.replace(/\..+/, "")
                    : certificado.ti}
                </Tag>
              </Box>
              {certificado.endDate > today ? (
                <Box bg="#e9e8ef" h="auto" w="100%" p={4}>
                  <Flex justify="space-between" align="center">
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#002070",
                        fontWeight: "500",
                      }}
                    >
                      Certificado expira em:
                    </span>
                    <span
                      style={{
                        color: "#E60000",
                        fontSize: "14px",
                        fontWeight: "bold",
                        lineHeight: "22px",
                      }}
                    >
                      {certificado.formattedEndDate}
                    </span>
                  </Flex>
                </Box>
              ) : (
                <Box bg="#fce6e6" h="auto" w="100%" p={4}>
                  <Flex justify="space-between" align="center">
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#E60000",
                        fontWeight: "500",
                      }}
                    >
                      Certificado expirado em:
                    </span>
                    <span
                      style={{
                        color: "#E60000",
                        fontSize: "14px",
                        fontWeight: "bold",
                        lineHeight: "22px",
                      }}
                    >
                      {certificado.formattedEndDate}
                    </span>
                  </Flex>
                </Box>
              )}
            </GridItem>
          ))}
        </Grid>
      )}
    </>
  )
}

export default Certificacoes
