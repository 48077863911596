import React from "react";
import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import TagStatus from "../../Tag/TagStatus";

const CardStatus = ({ data, onClick }) => {
  return (
    <Flex
      direction={"column"}
      gap={"16px"}
      justify="space-between"
      align={"center"}
      w="100%"
      p="16px"
      border="2px solid #F5F8F6"
      bg={"#fff"}
    >
      <Flex
        gap="24px"
        w="100%"
        align={["center", "center", "center", "start"]}
        justify={"space-between"}
      >
        <Flex
          minH="63px"
          direction={"column"}
          justify={"center"}
          gap={"6px"}
          w="100%"
          align={"start"}
        >
          <Text
            fontWeight={500}
            fontSize={"12px"}
            lineHeight={"14px"}
            color="#555E66"
            aria-label={"Informações da Empresa"}
          >
            Informações da Empresa
          </Text>
          {!!data?.fantasia ? (
            <Text
              w="100%"
              fontWeight={600}
              fontSize={"16px"}
              lineHeight={"24px"}
              color="#32383F"
              aria-label={data?.fantasia}
            >
              {data?.fantasia}
            </Text>
          ) : null}
          {!!data?.razaoSocial ? (
            <Text
              fontWeight={600}
              fontSize="12px"
              lineHeight="15px"
              aria-label={data?.razaoSocial}
              color="#444C54"
            >
              {data?.razaoSocial}
            </Text>
          ) : null}
        </Flex>
        <Flex w="fit-content">
          <Button
            h="fit-content"
            variant={"unstyled"}
            color="#FF1628"
            borderColor="#FF1628"
            borderRadius="unset"
            textDecor={"underline"}
            _hover={{ opacity: 0.7 }}
            lineHeight="11px"
            minW="66px"
            p="8px 0"
            onClick={onClick}
          >
            Ver mais
          </Button>
        </Flex>
      </Flex>
      <Divider borderColor="#EDF1EF" />
      <Flex
        w="100%"
        gap={["16px", "16px", "16px", "32px"]}
        minH="40px"
        justify="center"
      >
        <TitleText title={"Status"} text={data?.statusConnect} tag={true} />
        <Divider orientation="vertical" borderColor="#EDF1EF" />
        <TitleText title={"Produto"} text={data?.Plano} />
      </Flex>
    </Flex>
  );
};

export default CardStatus;

const TitleText = ({ title, text, tag }) => {
  return (
    <Flex gap={"6px"} direction="column" flex={"1"} w="100%" minH="45px">
      <Text
        fontWeight={500}
        fontSize={"12px"}
        lineHeight={"14px"}
        color="#555E66"
      >
        {title}
      </Text>
      {tag ? (
        <TagStatus name={text} transparent={true} />
      ) : (
        <Text
          fontWeight={700}
          fontSize="14px"
          lineHeight="14px"
          color="#32383F"
          whiteSpace={"nowrap"}
        >
          {text}
        </Text>
      )}
    </Flex>
  );
};
