import React from 'react';
import styled from 'styled-components';

const ContainerStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 40px;
    margin-bottom: 40px;

`

const NumberItem = styled.div`
    

    ${props=>{
        if(props.selecionado){
            return  `
                font-family: Raleway;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                            
                color: #E60000;
                border-radius: 4px;
                background-color: #F2C4C9;

                padding-top: 4px;
                padding-bottom: 4px;
                padding-left: 8px;
                padding-right: 8px; 
            `
        }
        else{
            return `
                font-family: Raleway;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                
                color: #292B30;
                background-color: transparent;

                
                padding-top: 4px;
                padding-bottom: 4px;
                padding-left: 8px;
                padding-right: 8px; 
            `
        }
    }}


   
`

function PagesList(props){
    return  <ContainerStyled>
                {props.lista.map((chave, indice) => {
                    let selecionado = false;
                    if(props.selecionado === indice)selecionado = true;
                    return <NumberItem selecionado={selecionado}>{chave.id}</NumberItem>
                })}
            </ContainerStyled>
}

export default PagesList;