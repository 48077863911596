import {
  Button,
  Icon,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import { useState } from "react";
import { removeAllCookie } from "../../../common/cookieManager";

const ButtonLogout = ({ title, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [mobile] = useMediaQuery("(max-width: 992px)");

  const logout = () => {
    removeAllCookie();
    // window.location.href = "https://connect.gptw.info/login";
    window.location.replace("/login");
  };

  return mobile ? (
    <Button
      variant={"unstyled"}
      leftIcon={
        <IconLogout
          width="24px"
          height="24px"
          style={{
            transition: "fill 0.3s",
            fill: isHovered ? "#FF1628" : "#444C54",
          }}
        />
      }
      _hover={{
        color: "#11131C",
        opacity: "0.8",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      display={"flex"}
      gap="16px"
      justifyContent="start"
      fontSize={"18px"}
      lineHeight={"18px"}
      fontWeight={500}
      w="fit-content"
      color={isHovered ? "#11131C" : "#22262D"}
      opacity={isHovered ? "1" : "0.6"}
      sx={{
        span: {
          margin: "0",
        },
      }}
      onClick={logout}
      {...props}
    >
      {title}
    </Button>
  ) : (
    <Tooltip label={title}>
      <IconButton
        variant={"unstyled"}
        size={"sm"}
        icon={
          <IconLogout
            style={{
              transition: "fill 0.3s",
              fill: isHovered ? "#FF4553" : "#444C54",
            }}
          />
        }
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={logout}
        {...props}
      />
    </Tooltip>
  );
};

const IconLogout = (props) => {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" {...props}>
      <path
        opacity="0.4"
        d="M12 9.59996V22.3866C12 26.6666 14.6667 29.3333 18.9333 29.3333H22.3867C26.6533 29.3333 29.32 26.6666 29.32 22.4V9.59996C29.3333 5.33329 26.6667 2.66663 22.4 2.66663H18.9333C14.6667 2.66663 12 5.33329 12 9.59996Z"
      />
      <path d="M7.42732 10.8267L2.96065 15.2934C2.57399 15.6801 2.57399 16.3201 2.96065 16.7067L7.42732 21.1734C7.81399 21.5601 8.45399 21.5601 8.84065 21.1734C9.22732 20.7867 9.22732 20.1467 8.84065 19.7601L6.08065 17.0001H20.334C20.8807 17.0001 21.334 16.5467 21.334 16.0001C21.334 15.4534 20.8807 15.0001 20.334 15.0001H6.08065L8.84065 12.2401C9.04065 12.0401 9.13399 11.7867 9.13399 11.5334C9.13399 11.2801 9.04065 11.0134 8.84065 10.8267C8.45399 10.4267 7.82732 10.4267 7.42732 10.8267Z" />
    </Icon>
  );
};

export default ButtonLogout;
