import React from "react";
import {
  Flex,
  Text,
  Stack,
  Heading,
  Grid,
  Box,
  Link,
  AlertIcon,
} from "@chakra-ui/react";
import { Icon } from "../../style";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import background from "../../style/modal/dialog/background.png";
import { IoAlertCircleOutline } from "react-icons/io5";

const ALert = ({ ready, text }) => {
  return (
    <Flex
      w="100%"
      h="auto"
      p="8.5px"
      bg={ready ? "#008000" : "red.100"}
      color="#ffffff"
      justify="center"
      align="center"
      gap={["4px"]}
      textAlign="center"
    >
      {!ready && <Icon type="alert-white" />}

      <Text fontSize={["10px", "12px", "13px"]} lineHeight={["14px"]}>
        {" "}
        {text}
      </Text>
    </Flex>
  );
};

function Dialog(props) {
  return (
    <Stack
      width={["100%", props.width ? props.width : "100%"]}
      backgroundImage={background}
      backgroundSize="cover"
      borderRadius="12px"
      backgroundRepeat="no-repeat"
      overflow={"hidden"}
      h="auto"
      position="relative"
      justify={"space-between"}
      style={{ order: props.order }}
    >
      <Stack py="25px" px="24px" spacing={"18px"}>
        <Flex w={"100%"} justify={"space-between"}>
          <Box
            width="20px"
            height="5px"
            color={props.cor}
            bgColor={props.cor}
            borderRadius="6px"
            top="32px"
            left="32px"
          />
          <Icon type="alert-white" />
        </Flex>
        <Heading
          as="h3"
          fontSize={["18px"]}
          color="white"
          fontWeight={600}
          pb="20px"
        >
          {props.titulo}
        </Heading>
        {/* {props.status === true &&  <ContainerStatus>
                <Icon type="check" /> {' '} {props.concluidos} {' '} {props.concluidos <= 1 ? "concluído": "concluídos"}
                <Icon type="alert-orange" /> {' '} {props.pendentes} {' '} {props.pendentes <= 1 ? "pendente": "pendentes"}
              </ContainerStatus>
            } */}
        {props.message === "false" && (
          <Box
            fontSize="14px"
            lineHeight="16px"
            color="#ffffff"
            top="78px"
            left="32px"
          >
            <Icon type="alert-orange" /> {`Prazo de preenchimento encerrado!`}
          </Box>
        )}
        <Box
          width="70px"
          height="70px"
          pos="absolute"
          bottom="58px"
          right="32px"
        >
          <CircularProgressbar
            value={props.inicial}
            minValue={0}
            maxValue={props.final}
            text={props.inicial + "/" + props.final}
            styles={buildStyles({
              textSize: "font-size: 16px",
              width: "71px",
              height: "71px",
              pathColor: `#40BF91`,
              textColor: "#ffff",
              trailColor: "#E5E5E5",
            })}
          />
        </Box>
        {props.iconePlay === false && props.estado === true && (
          <Box verticalAlign="middle" bottom="52px" left="32px">
            <Link
              href={props.url}
              fontSize="15px"
              textDecor="underline"
              color="#fff"
            >
              {props.textoUrl}
            </Link>
          </Box>
        )}
        {props.iconePlay === false && props.estado === false && (
          <Box verticalAlign="middle" bottom="32px" left="32px">
            <Link
              href={props.url}
              fontSize="15px"
              textDecor="underline"
              color="#fff"
            >
              {props.textoUrl}
            </Link>
          </Box>
        )}
        {props.iconePlay === true && props.estado === false && (
          <Box verticalAlign="middle" bottom="32px" left="32px">
            <Icon type="icone-play" />
            {"   "}
            <Link
              href={props.url}
              fontSize="15px"
              textDecor="underline"
              color="#fff"
            >
              {props.textoUrl}
            </Link>
          </Box>
        )}
        {props.iconePlay === true && props.estado === true && (
          <Box verticalAlign="middle" bottom="52px" left="32px">
            <Icon type="icone-play" />
            {"   "}
            <Link
              href={props.url}
              fontSize="15px"
              textDecor="underline"
              color="#fff"
            >
              {props.textoUrl}
            </Link>
          </Box>
        )}
        {props.iconePlay}
      </Stack>
      {props.estado && props.todasRespondidas === false && (
        <ALert ready={false} text={props.textoAlerta} />
      )}
      {props.estado && props.todasRespondidas === true && (
        <ALert ready={true} text={props.textoAlerta} />
      )}
    </Stack>
  );
}

const Forms = ({ formsInfo }) => {
  return (
    <Grid
      templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]}
      gap={["25px", "16px"]}
    >
      {formsInfo.length > 0 ? (
        formsInfo.map((form, index) =>
          form.titulo === "Questionário Ranking" ||
          form.titulo === "Questionário Certificação" ? (
            <Dialog
              key={index}
              cor={form.cor}
              titulo={
                form.titulo === "Questionário Ranking"
                  ? "Questionário de Ranking"
                  : "Questionário de Certificação"
              }
              concluidos={form.status.concluido}
              pendentes={form.status.pendentes}
              url={form.link.url}
              textoUrl={form.link.texto}
              inicial={form.progress.inicial}
              final={form.progress.final}
              iconePlay={form.iconePlay}
              iconeAlerta={form.iconeAlerta}
              estado={form.alerta.estado}
              textoAlerta={form.alerta.texto}
              message={form.message.value}
              status={true}
              todasRespondidas={form.alerta.todasRespondidas}
              order={form.order}
              style={{ position: "sticky", top: "0", zIndex: "9999" }}
            />
          ) : null
        )
      ) : (
        <Flex w="100%" h="200px">
          aaaaaaaaaaaaaaa
        </Flex>
      )}
    </Grid>
  );
};

export default Forms;
