import { useQuery } from "react-query";
import { dashboardApi, extractApi } from "../../services/api-research";
import { authenticate } from "../../services/authServices";

const fetchListCompanies = async ({
  skip,
  pageSize,
  emailPartner,
  textSearch,
}) => {
  let params = new URLSearchParams();
  params.append("skip", skip);
  if (!!pageSize) params.append("pageSize", pageSize);
  if (!!emailPartner)
    params.append("emailPartner", encodeURIComponent(emailPartner));
  if (!!textSearch) params.append("textSearch", encodeURIComponent(textSearch));

  const { data } = await dashboardApi.get(`/company/listCompanies`, {
    params: params,
  });
  return data;
};

export const useListCompanies = ({
  skip = 0,
  pageSize = 10,
  emailPartner,
  textSearch,
}) => {
  return useQuery(
    ["list-companies", skip, pageSize, emailPartner, textSearch],
    () =>
      fetchListCompanies({
        skip,
        pageSize,
        emailPartner,
        textSearch,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 5000,
    }
  );
};

const fetchListStatus = async ({
  start,
  limit,
  search,
  ufFilter,
  cityFilter,
  setorFilter,
  filter,
  accountOwnerFilter,
}) => {
  let params = new URLSearchParams();
  params.append("start", start);
  if (!!limit) params.append("limit", limit);
  if (!!search) params.append("search", encodeURIComponent(search));
  if (!!ufFilter) params.append("ufFilter", ufFilter);
  if (!!cityFilter) params.append("cityFilter", encodeURIComponent(cityFilter));
  if (!!setorFilter)
    params.append("setorFilter", encodeURIComponent(setorFilter));
  if (!!filter) params.append("filter", encodeURIComponent(filter));
  if (!!accountOwnerFilter)
    params.append("accountOwnerFilter", encodeURIComponent(accountOwnerFilter));

  const { data } = await dashboardApi.get(`/company/listStatus`, {
    params: params,
  });
  return data;
};

export const useListStatus = ({
  start = 0,
  limit = 50,
  search,
  ufFilter,
  cityFilter,
  setorFilter,
  filter,
  accountOwnerFilter,
}) => {
  return useQuery(
    [
      "list-status",
      start,
      limit,
      search,
      ufFilter,
      cityFilter,
      setorFilter,
      filter,
      accountOwnerFilter,
    ],
    () =>
      fetchListStatus({
        start,
        limit,
        search,
        ufFilter,
        cityFilter,
        setorFilter,
        filter,
        accountOwnerFilter,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 5000,
    }
  );
};

const fetchFilters = async (url) => {
  const { data } = await dashboardApi.get(`/company/${url}`);
  return data;
};

export const useFilters = (url) => {
  return useQuery([url], () => fetchFilters(url), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
  });
};

export const fetchReportStatusConnect = async ({
  nroFuncionariosIni = 0,
  nroFuncionariosFim = 0,
  produto,
}) => {
  let params = new URLSearchParams();
  params.append("nroFuncionariosIni", nroFuncionariosIni);
  params.append("nroFuncionariosFim", nroFuncionariosFim);
  if (!!produto) params.append("produto", encodeURIComponent(produto));

  const { data } = await dashboardApi.get(`/company/reportStatus`, {
    params: params,
  });
  return data;
};

export const fetchExportStatus = async ({
  start,
  limit,
  search,
  ufFilter,
  cityFilter,
  setorFilter,
  filter,
  accountOwnerFilter,
}) => {
  let params = new URLSearchParams();
  params.append("start", start);
  if (!!limit) params.append("limit", limit);
  if (!!search) params.append("search", encodeURIComponent(search));
  if (!!ufFilter) params.append("ufFilter", ufFilter);
  if (!!cityFilter) params.append("cityFilter", encodeURIComponent(cityFilter));
  if (!!setorFilter)
    params.append("setorFilter", encodeURIComponent(setorFilter));
  if (!!filter) params.append("filter", encodeURIComponent(filter));
  if (!!accountOwnerFilter)
    params.append("accountOwnerFilter", encodeURIComponent(accountOwnerFilter));

  const { data } = await dashboardApi.get(`/company/exportStatus`, {
    params: params,
  });
  return data;
};

const loginAuthApi = window["runConfig"].REACT_APP_CONNECT_API_AUTH_LOGIN || "";
const passwordAuthApi =
  window["runConfig"].REACT_APP_CONNECT_API_AUTH_PASSWORD || "";

export const fetchExportQuestions = async (participations, isUser) => {
  let result = {};
  try {
    const authResponse = await authenticate(loginAuthApi, passwordAuthApi);
    const authToken = authResponse.data.access_token;
    const apiInstance = extractApi(authToken);

    const requestData = {
      type: "CODE",
      participations: [participations],
    };
    console.log("🚀 ~ requestData.participations:", requestData.participations);

    var pathApi = "get-side-by-side";
    if(isUser){
      pathApi += "/user"
    }

    result = await apiInstance.post(pathApi, requestData, {
      responseType: "blob",
    });
    const url1 = URL.createObjectURL(result.data);
    const a = document.createElement("a");
    a.href = url1;

    let d = new Date();
    const dateToFile = d.getTime();

    let fileName = `Respostas Dos Quetionários_${participations}_${dateToFile}.xlsx`;
    a.download = fileName;
    a.click();
  } catch (error) {
    console.error("Erro na requisição de extração dos dados:", error);
    throw error;
  }
  return result;
};
