import {
  Box,
  FormLabel,
  CircularProgress,
  CircularProgressLabel,
  Icon,
  Image,
  Text,
  FormControl,
  FormErrorMessage,
  Flex,
  useToast,
  Tooltip,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import { useState, forwardRef, useCallback, useEffect } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { researchApi } from "../../../services/api-research";
import iconAdd from "../../../assets/images/icon-add-file.svg";
import iconRemove from "../../../assets/images/icon-remove-red.svg";

const FileInputBase = (
  {
    name,
    currentFile,
    error,
    setImageUrl,
    setCurrentFile,
    setError,
    errorsTotal,
    setErrorsTotal,
    setEmployeesTotal,
    onChange,
    trigger,
    researchId,
    onClose,
    openUpgradeModal,
    ...rest
  },
  ref
) => {
  const toast = useToast();
  const [progress, setProgress] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [cancelToken, setCancelToken] = useState({});
  const [errorFull, setErrorFull] = useState(false);
  const [fileName, setFileName] = useState("");

  const [mobile] = useMediaQuery("(max-width: 1090px)");

  const filtrarPlanos = (planos, min) => {
    const filtrados = planos.filter((plano) => {
      // console.log(parseInt(plano.name.match(/(\d+)/)[0]))
      // console.log(min)
      console.log(parseInt(plano.name.match(/(\d+)/)[0]) > min, plano.name)
      return parseInt(plano.name.match(/(\d+)/)[0]) > min
    })

    filtrados.sort((a, b) => {
      return parseInt(a.name.match(/(\d+)/)[0]) - parseInt(b.name.match(/(\d+)/)[0])
    })

    console.log(filtrados)
    return filtrados
  }

  const handleImageUpload = useCallback(
    async (event) => {
      if (!event.target.files?.length) {
        return;
      }

      setCurrentFile(undefined);
      setEmployeesTotal(0);
      setIsSending(true);

      await onChange(event);
      trigger("file");

      const formData = new FormData();

      formData.append("research", researchId);
      formData.append(event.target.name, event.target.files[0]);

      const { CancelToken } = axios;
      const source = CancelToken.source();
      setCancelToken(source);
      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (e) => {
          setProgress(Math.round((e.loaded / e.total) * 100));
        },
        // cancelToken: source.token,
      };

      try {
        const response = await researchApi.post(
          "/employees/upload",
          formData,
          config
        );
        console.log("res", response);
        const success = response.data.filter((res) => !res.error);
        const findErrors = response.data.filter((res) => res.error);

        if (findErrors.length) {
          setErrorsTotal(findErrors.length);
        }

        if (success.length > 0) {
          setEmployeesTotal(success.length);
          setFileName(event.target.files[0].name);
          setCurrentFile(event.target.files[0]);
        } else {
          setErrorFull(true);
          throw Error(response.data.find((res) => res.error === true).message);
        }
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message;
          if (error.response.data.limitExceeded && window["runConfig"].REACT_APP_UPGRADE_PLAN === "active") {

            const url_company_info =
              window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
              `/company-register-info?cr=${error.response.data.cr}`

            const company_info = await axios.get(url_company_info)

            const url_financial_signature =
              window["runConfig"].REACT_APP_URL_FINANCIAL_API +
              `/signature/verify?secret=${window["runConfig"].REACT_APP_FINANCIAL_SECRET}&document=${company_info.data.company.cnpj.replaceAll('.', '').replace('/', '').replace('-', '')}`

            const financial_signature = await axios.get(url_financial_signature);

            console.log(financial_signature.data);
            if (!Object.keys(financial_signature.data.data.assinatura).length) {
              errorMessage =
                "O número de funcionários enviados é diferente das capacidades do plano cadastrado. Se deseja mudar de plano, procure o seu consultor ou envie sua solicitação para atendimento@gptwbrasil.com.br.";

              toast({
                title: "Falha no envio",
                description: errorMessage,
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            } else {
              // Fazer requisição pra vindi, puxando os planos e passando o parâmetro da url pra isso
              let current_product = 0;

              financial_signature.data.data.assinatura.product_items.map(
                (product_item) => {
                  if (product_item.status === "active") {
                    current_product = product_item.id;
                    return true;
                  }
                  return false;
                }
              );
              console.log(financial_signature.data.data.assinatura);

              const url_result_plans =
                window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
                `/vindi/plansForUpgrade?currentPlan=${error.response.data.plan}&planSize=${error.response.data.min}`;

              const result_plans = await axios.get(url_result_plans);


              if (result_plans.data.length === 0) {
                errorMessage =
                  "O número de funcionários enviados é diferente das capacidades do plano cadastrado. Se deseja mudar de plano, procure o seu consultor ou envie sua solicitação para atendimento@gptwbrasil.com.br.";

                toast({
                  title: "Falha no envio",
                  description: errorMessage,
                  status: "error",
                  duration: 10000,
                  isClosable: true,
                });
              } else {
                // abrir a modal passando a lista de planos já filtrada
                setTimeout(() => {
                  openUpgradeModal(
                    result_plans.data,
                    financial_signature.data.data.assinatura.customer.id,
                    current_product,
                    financial_signature.data.data.assinatura.id,
                    error.response.data.plan,
                    error.response.data.cr
                  );
                }, 1050);
              }
            }
            
            setTimeout(() => {
              setIsSending(false);
              setProgress(0);
              onClose();
            }, 1000);
            return;
          };
        } else if (error.request) {
          console.log("error request", error.request);
        } else if (error.message.includes("login já está sendo usado")) {
          errorMessage = error.message;
        } else {
          errorMessage =
            "Ocorreu um erro ao realizar o upload da sua planilha.";
        }

        toast({
          title: "Falha no envio",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          setIsSending(false);
          setProgress(0);
          onClose();
        }, 1000);
      } finally {
        // setIsSending(false);
        // setProgress(0);
      }
    },
    [
      trigger,
      toast,
      onChange,
      onClose,
      setCurrentFile,
      researchId,
      setEmployeesTotal,
      setErrorsTotal,
    ]
  );

  useEffect(() => {
    if (errorFull && isSending && cancelToken?.cancel) {
      cancelToken.cancel("Cancelled file upload.");
      setCancelToken(null);
    }
  }, [cancelToken, errorFull, isSending]);

  const handleRemoveFile = () => {
    setEmployeesTotal(0);
    setCancelToken(null);
    setCurrentFile(undefined);
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel
        mx="auto"
        w="100%"
        h={['88px',"92px"]}
        htmlFor={name}
        cursor={isSending ? "progress" : "pointer"}
        opacity={isSending ? 0.5 : 1}
      >
        {currentFile && !isSending ? (
          <Box textAlign="center">
            <InputGroup display="flex" h="58px" alignItems="center">
              <Input
                defaultValue={fileName}
                readOnly
                w="100%"
                h="58px"
                fontWeight="500"
                px="25px"
                color="#292B30"
                borderRadius="10px"
                border="1px"
                borderColor="#40BF91"
              />
              <InputRightElement width="4.5rem" h="100%">
                <Button
                  cursor="pointer"
                  variant="ghost"
                  display="flex"
                  alignItems="center"
                  onClick={handleRemoveFile}
                >
                  <Image src={iconRemove} alt="remove" />
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        ) : (
          <Flex
            w="100%"
            h="full"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            borderRadius="md"
            bgColor="pGray.800"
            color="pGray.200"
            borderWidth={error?.message && 2}
            borderColor={error?.message && "red.500"}
          >
            {isSending ? (
              <>
                <CircularProgress
                  trackColor="pGray.200"
                  value={progress}
                  color="orange.500"
                >
                  <CircularProgressLabel>{progress}%</CircularProgressLabel>
                </CircularProgress>
                <Text as="span" pt={2} textAlign="center">
                  Enviando...
                </Text>
              </>
            ) : (
              <Box pos="relative" h="full" w="100%">
                {!!error && (
                  <Tooltip label={error.message} bg="red.500">
                    <FormErrorMessage
                      pos="absolute"
                      right={2}
                      top={2}
                      mt={0}
                      zIndex="tooltip"
                    >
                      <Icon as={FiAlertCircle} color="red.500" w={4} h={4} />
                    </FormErrorMessage>
                  </Tooltip>
                )}

                <Flex
                  w="100%"
                  h={['88px',"102px"]}
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="10px"
                  border={!!error ? "none" : "1px"}
                  gap="16px"
                  borderColor="#5C6066"
                  borderStyle="dashed"
                >
                  <Image src={iconAdd} alt="icon add" />
                  {mobile ? (
                    <Text textAlign="center" fontSize="14px">
                    <b> Clique aqui</b> para selecionar o arquivo
                    </Text>
                  ) : (
                    <Text as="span" pt={2} textAlign="center" fontSize="14px">
                      <b>Arraste</b> e <b>solte</b> sua planilha ou{" "}
                      <b>clique aqui</b> para buscá-la
                    </Text>
                  )}
                </Flex>
              </Box>
            )}
          </Flex>
        )}
        <input
          data-testid={name}
          // disabled={isSending}
          id={name}
          name={name}
          onChange={handleImageUpload}
          ref={ref}
          type="file"
          style={{
            display: "none",
          }}
          {...rest}
        />
      </FormLabel>

      {!!error && (
        <Text color="red.100" fontSize="14px" fontWeight="600" mt="10px">
          {error.message}
        </Text>
      )}
    </FormControl>
  );
};

export const FileInput = forwardRef(FileInputBase);
