import apiAuth from './api-auth'

async function authenticate(user, pass) {
    const body = {	login: user,
	                password: pass,
	                applicationName: "Connect"}
    let result = {}    
    try {
        result = await apiAuth.post('/auth/login', body)   
    } catch (error) {
        console.error(error)
    }

    return result
}

async function recover(user) {
    let result = {}    

    try {
        result = await apiAuth.post(`/auth/recover?login=${user}`)        
    } catch (error) {
        console.error(error)
    }

    return result
}

async function reset(key, pass) {
    const body = {	login: "",
	                password: pass,
	                applicationName: "Connect" }
    let result = {}    
    try {
        result = await apiAuth.put(`/auth/reset/${key}`, body)        
    } catch (error) {
        console.error(error)
    }

    return result
}

async function isCustomerActive(document) {
    let result = {}    

    try {
        result = await apiAuth.get(`/auth/generic/is-active/${document}`)        
    } catch (error) {
        console.error(error)
    }

    return result
}

export { authenticate, recover, reset, isCustomerActive } 