import { useState, useEffect } from "react";
import { BsBagDash } from "react-icons/bs";

import {
  Button,
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Heading,
} from "@chakra-ui/react";

import { InputCnpj } from '../../../style';
import { InputVoucher } from '../../../style'

// Componente popup para preenchimento das infos da empresa, não foi utilizado mas pode vir a ser
// import CompanyInfo from "./CompanyInfo";

import { cnpj } from 'cpf-cnpj-validator'; 
import { apiFinancial } from "../../../services/api-financial";

const FirstAccess = ({ onClose, isOpen, url }) => {
  //TODO: Ronald - ver se o popup é melhor que uma nova tela
  //TODO: Ronald - refatorar funções para evitar duplicidade

  const [companyInfoModal, setCompanyInfoModal] = useState(false);
  const [success, setSuccess] = useState(false)
  const [cnpjNumber, setCnpjNumber] = useState("")
  const [cnpjValid, setCnpjValid] = useState(false)
  const [cnpjCleanNumber, setCnpjCleanNumber] = useState("")
  const [checkCompanyExist, setCompanyExist] = useState(true)
  const [cnpjCompradorMsg, setCnpjCompradorMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [cnpjAplicaPesquisaNumber, setCnpjAplicaPesquisaNumber] = useState("")
  const [cnpjAplicaPesquisaValid, setCnpjAplicaPesquisaValid] = useState(false)
  const [cnpjAplicaPesquisaCleanNumber, setCnpjAplicaPesquisaCleanNumber] = useState("")
  const [checkCompanyAplicaPesquisaExist, setAplicaPesquisaExist] = useState(true)
  const [cnpjAplicaPesquisaMsg, setCnpjAplicaPesquisaMsg] = useState("")
  const [voucher, setVoucher] = useState("")
  const [voucherMsg, setVoucherMsg] = useState("")
  const [cleanVoucher, setCleanVoucher] = useState("")
  const [voucherValid, setVoucherValid] = useState(false)
  const [voucherIsOk, setVoucherIsOk] = useState(false)

  useEffect(() => {
    if(cnpjValid) {
      checkIfCompanyAlreadyExists()
      setCnpjCompradorMsg("")
    }
  }, [cnpjValid])

  useEffect(() => {
    if(cnpjAplicaPesquisaValid) {
      checkIfAplicaPesquisaAlreadyExists()
      setCnpjAplicaPesquisaMsg("")
    }
  }, [cnpjAplicaPesquisaValid])

  useEffect(() => {
    if(voucherValid) {
      checkVoucherIsOk()
      setVoucherMsg("")
    }
  }, [voucherValid])
 
  function handleCnpj(cnpjValue) {
    setCnpjCompradorMsg("")
    checkCnpj(cnpjValue)
    let cleanCnpj = cnpjValue
      .replaceAll("/", "")
      .replaceAll(".", "")
      .replaceAll("-", "");
    setCnpjNumber(cnpjValue);
    setCnpjCleanNumber(cleanCnpj)

    if(!cnpjValid) {
      setCnpjCompradorMsg("CNPJ Inválido")
    }
  }

  function handleCnpjAplicaPesquisa(cnpjValue) {
    setCnpjAplicaPesquisaMsg("")
    checkCnpjAplicaPesquisa(cnpjValue)
    let cleanCnpj = cnpjValue
      .replaceAll("/", "")
      .replaceAll(".", "")
      .replaceAll("-", "");
    setCnpjAplicaPesquisaNumber(cnpjValue);
    setCnpjAplicaPesquisaCleanNumber(cleanCnpj)

    if(!cnpjAplicaPesquisaValid) {
      setCnpjAplicaPesquisaMsg("CNPJ Inválido")
    }
  }

  function handleVoucher(voucherValue) {
    setVoucherMsg("")
    checkVoucher(voucherValue)
    let cleanVoucher = voucherValue
      .replaceAll("-", "");
    setVoucher(voucherValue);
    setCleanVoucher(cleanVoucher)

    if(!voucherValid) {
      setVoucherMsg("Voucher Inválido")
    }
  }

  function checkCnpj(cnpjNumber) {
    setCnpjValid(cnpj.isValid(cnpjNumber))
  }

  function checkCnpjAplicaPesquisa(cnpjNumber) {
    setCnpjAplicaPesquisaValid(cnpj.isValid(cnpjNumber))
  }

  function checkVoucher(voucherNumber) {
    const voucherSplited = voucherNumber.split("-")
    if (voucherSplited[0] !== "") {
      const voucherValidChars = voucherSplited[1].includes('_')
      if ((voucherSplited[0] === "GPTW1" || voucherSplited[0] === "GPTW2") && !voucherValidChars) {
        setVoucherValid(true)
      } else {
        setVoucherValid(false)
        setVoucherMsg('Ops! Verifique o seu voucher.')
      }
    } else {
      setVoucherValid(false)
    }
  }

  function checkIfCompanyAlreadyExists() {
    setIsLoading(true)
    apiFinancial.post(`/company/checkExist?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}`, {
      cnpj: cnpjNumber,
    })
    .then(res => {
      if (res.status === 200) {
        console.log('Data -> ', res.data.data.message)
        if (res.data.data.message.length === 0) {
          setCompanyExist(false)
          setIsLoading(false)
        } else {
          setCnpjCompradorMsg("Opa! Parece que este CNPJ já existe. Entre com um CNPJ de Participação abaixo ou entre em contato conosco.")
          setIsLoading(false)
        }
      } else {
        console.log('Status retornado da verificação da empresa: ', res.status)
        setIsLoading(false)
      }
    })
    .catch(err => {
      console.log('Erro na chamada para verificar se empresa existe no sistem: ', err)
    })
  }

  function checkIfAplicaPesquisaAlreadyExists() {
    setIsLoading(true)
    apiFinancial.post(`/company/checkExist?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}`, {
      cnpj: cnpjAplicaPesquisaNumber,
    })
    .then(res => {
      if (res.status === 200) {
        console.log('Data -> ', res.data.data.message)
        if (res.data.data.message.length === 0) {
          setAplicaPesquisaExist(false)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          setCnpjAplicaPesquisaMsg("Opa! Parece que este CNPJ já existe.")
        }
      } else {
        console.log('Status retornado da verificação da empresa: ', res.status)
        setIsLoading(false)
      }
    })
    .catch(err => {
      console.log('Erro na chamada para verificar se empresa existe no sistem: ', err)
    })
  }

  function checkVoucherIsOk() {
    setIsLoading(true)
    apiFinancial.get(`/salesforce/salesforceVoucherVerify?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}&salesforceVoucher=${voucher}`)
    .then(res => {
      if (res.status === 200) {
        if (res.data.data.message.records.length !== 0) {
          const data = res.data.data.message.records[0]
          // Depois melhorar para verificar se o voucher cola com o CNPJ da empresa, mas precisa fazer um join no endpoint CNPJ Emissao é o da SAD
          // if(cnpjNumber === data['CNPJ_Emissao__c'] && data['IsWon'] && !data['Cupom_utilizado__c']) {
          if(data['IsWon'] && !data['Cupom_utilizado__c']) {
            setVoucherIsOk(true)
          } else {
            setVoucherMsg('Parece que algo está errado. Verifique os dados inseridos. Caso o erro persista, fale com nosso atendimento.')
          }
          setIsLoading(false)
        } else {
          setIsLoading(false)
          setVoucherMsg("Opa! Verifique o seu voucher.")
        }


      } else {
        console.log('Status retornado da verificação da empresa: ', res.status)
        setIsLoading(false)
      }
    })
    .catch(err => {
      console.log('Erro na chamada para verificar se empresa existe no sistem: ', err)
    })
  }

  const handleCompanyInfo = () => {
    setCompanyInfoModal(true);
  };

  const handleCloseCompanyInfo = () => {
    setCompanyInfoModal(false);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseSuccess = () => {
    onClose();
    setSuccess(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent
        borderRadius={["20px 20px 0 0", "30px"]}
        style={{
          width: "543px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <ModalHeader p={["17px", "70px"]} bg={["#F5F5FB", "none"]}>
          <Heading fontSize={["18px", "28px"]}>Primeiro acesso</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={["24px", "70px"]} display="grid" alignContent={'center'}>
          <Text fontSize={["16px", "18px"]} lineHeight={["28px"]} mb="30px">
            Para iniciarmos o seu primeiro acesso, digite o número do CNPJ
            cadastrado e o código recebido por e-mail enviado pelo consultor.
          </Text>

          <InputCnpj
            id="cnpj"
            label="CNPJ do comprador"
            placeholder="Digite o CNPJ do comprador"
            type="string"
            icon={<BsBagDash color="#9ea3ad" fontSize="22px" />}
            value={cnpjNumber}
            onChange={(e) => {
              handleCnpj(e.target.value);
            }}
          />
          
          {cnpjCompradorMsg && (
            <Text fontSize="10px" color="red">{ cnpjCompradorMsg }</Text>
          )}

          <label
            // style={cnpjValid && checkCompanyExist ? {
            //   fontFamily: "Raleway",
            //   fontStyle: "normal",
            //   fontWeight: "normal",
            //   fontSize: "12px",
            //   lineHeight: "14px",
            //   color: "#292B30",
            //   marginTop: "10px"
            // } : {display:'none'}}
            style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "14px",
                color: "#292B30",
                marginTop: "10px"
            }}
          >
            CNPJ que vai aplicar a pesquisa
          </label>

          <InputCnpj
            id="cnpjAplicaPesquisa"
            type="string"
            icon={<BsBagDash color="#9ea3ad" fontSize="22px" />}
            value={cnpjAplicaPesquisaNumber}
            onChange={(e) => {
              handleCnpjAplicaPesquisa(e.target.value);
            }}
            // style={cnpjValid && checkCompanyExist ? {} : {display:'none'}}
          />
          
          {cnpjValid && checkCompanyExist && cnpjAplicaPesquisaMsg && (
            <Text fontSize="10px" color="red">{ cnpjAplicaPesquisaMsg }</Text>
          )}

          <InputVoucher
            id="voucher"
            type="string"
            label="Voucher"
            icon={<BsBagDash color="#9ea3ad" fontSize="22px" />}
            value={voucher}
            onChange={(e) => {
              handleVoucher(e.target.value);
            }}
          />
          
          {(!voucherValid || !voucherIsOk) && (
            <Text fontSize="10px" color="red">{ voucherMsg }</Text>
          )}
          
          <Button
            colorScheme={((cnpjValid && !checkCompanyExist) 
              || (cnpjAplicaPesquisaValid && !checkCompanyAplicaPesquisaExist))
              && (voucherValid && voucherIsOk)
              ? "red" : "gray"}
            isLoading={isLoading}
            h="56px"
            borderRadius={10}
            fontSize="16px"
            width="100%"
            mt="25px"
            mb="50px"
            style={((cnpjValid && !checkCompanyExist) 
              || (cnpjAplicaPesquisaValid && !checkCompanyAplicaPesquisaExist))
              && (voucherValid && voucherIsOk)
              ? {} : {pointerEvents:'none'}}
            // onClick={handleCompanyInfo}
          >
            <a href={cnpjAplicaPesquisaCleanNumber !== "" ? 
              url + `/register/?cnpj=${cnpjCleanNumber}&voucher=${voucher}&cnpjapply=${cnpjAplicaPesquisaCleanNumber}`
              :
              url + `/register/?cnpj=${cnpjCleanNumber}&voucher=${voucher}`
            } width="443px" height="56px">
              Prosseguir
            </a>
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FirstAccess;
