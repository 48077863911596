import React from "react";
import "./index.css"; // Arquivo CSS para estilização

const Highlight = ({ text, highlightWords }) => {
  console.log("🚀 ~ highlightWords:", highlightWords);

  console.log("🚀 ~ text:", text);

  // Função para envolver as palavras destacadas em uma tag span com uma classe de highlight
  const highlightText = () => {
    let highlightedText = text;
    highlightWords &&
      Array.isArray(highlightWords) &&
      highlightWords.forEach((word, index) => {
        const regex = new RegExp(`\\b${word}\\b`, "gi");
        highlightedText = highlightedText.replace(
          regex,
          `<span class="highlight" key="${index}${word}">${word}</span>`
        );
      });
    return { __html: highlightedText };
  };
  return (
    <div
      className="highlight-container"
      dangerouslySetInnerHTML={highlightText()}
    />
  );
};

export default Highlight;
