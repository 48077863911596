import React from "react";
import {
  Text,
  Box,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Image,
  Stack,
} from "@chakra-ui/react";
import {
  iconCompleted,
  iconAttentBulk,
  iconAwardBlue,
  menuNotifications,
} from "../../../assets/images";

function NotificationMenu({ notifications }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        p={0}
        w="24px"
        bg="none"
        _hover={{
          bg: "none",
        }}
      >
        <Image src={menuNotifications} alt="Open notifications" />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent
          w={["100%", "770px"]}
          borderRadius={["20px", "12px"]}
          overflow="hidden"
        >
          <ModalHeader
            fontSize="18px"
            fontWeight={700}
            py={["16px", "30px"]}
            pb={["16px", "14px"]}
            bg={["#F5F5FB", "none"]}
          >
            Últimas mensagens
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={0} pb="24px">
            {notifications?.map((notification, index) => (
              <Box
                key={index}
                py={["14px", "4"]}
                borderBottom={
                  index !== notifications.length - 1
                    ? "1px solid #E5E5E5"
                    : "none"
                }
              >
                <NotificationItem notification={notification} />
              </Box>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

const NotificationItem = ({ notification }) => {
  const params = new URL(window.location.href);
  const companyRegister = params.searchParams.get("cr");

  let link = window.location.origin + notification.buttonLink + `?cr=${companyRegister}`;

  if(notification.buttonLink.includes('://') || notification.buttonLink == '#') {
    link = notification.buttonLink;
  }

  const icon =
    notification?.type === "Attention"
      ? iconAttentBulk
      : notification?.type === "Complete"
      ? iconCompleted
      : iconAwardBlue;
  return (
    <Flex
      minH={["unset", "50px"]}
      align={["start", "center"]}
      justify="space-between"
      flexDir={["column", "row"]}
      gap="12px"
    >
      <Stack align="start" spacing="10px">
        <Flex
          height={["24px", "18px"]}
          align="center"
          px="12px"
          bg={notification?.tagBg}
          borderRadius="20px"
        >
          <Text fontSize="12px" fontWeight="600" color={notification?.color}>
            {notification?.topic}
          </Text>
        </Flex>

        <Flex align="center" gap="8px">
          <Image src={icon} alt="" w="24px" />

          <Text
            fontSize="sm"
            fontWeight={500}
            dangerouslySetInnerHTML={{ __html: notification?.message }}
          />
        </Flex>
      </Stack>

      {notification?.hasButton && (
        <Button
          as="a"
          variant="navlink"
          fontSize={["12px", "sm"]}
          href={link}
        >
          {notification?.buttonText}
        </Button>
      )}
    </Flex>
  );
};

export default NotificationMenu;
