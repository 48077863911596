import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  height: 90vh;
  width: 90vh;
  background-color: #fff;
  border-radius: 12px;
  padding: 40px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  overflow: auto;

  * + * {
    margin-top: 20px;
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
  }

`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 6px;
  background-color: white;

  h1 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: auto;

  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    color: white;
  }
`