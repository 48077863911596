import { createContext, useEffect, useState, useContext } from "react";
import { useResearch } from "../helpers/useResearch";
import { subDays, isAfter } from "date-fns";

export const CustomContext = createContext({});

export function CustomProvider({ children }) {
  const urlParams = new URLSearchParams(window.location.search);
  const cr = urlParams.get("cr");
  const [plan, setPlan] = useState("");
  const [consultant, setConsultant] = useState("");

  const [custom, setCustom] = useState(true);
  const [id, setId] = useState();
  const [certificationResearch, setCertificationResearch] = useState();
  const { data, isError } = useResearch(id !== undefined ? id : undefined);

  useEffect(() => {
    if (data && !isError) setCertificationResearch(data);
  }, [data, isError]);

  useEffect(() => {
    if (data?.period.from) {
      const limitDate = subDays(new Date(data.period.from), 1);
      const isValid = isAfter(new Date(), limitDate);
      if (isValid) {
        setCustom(false);
      } else return;
    }
  }, [data]);

  const handleCustom = () => {
    setCustom(false);
  };

  return (
    <CustomContext.Provider value={{ 
      custom, 
      handleCustom, 
      setId, 
      certificationResearch,
      setPlan, 
      plan,
      setConsultant,
      consultant, 
    }}>
      {children}
    </CustomContext.Provider>
  );
}

export function useCompanyData() {
  const context = useContext(CustomContext);

  return context;
}
