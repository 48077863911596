import axios from "axios"

export const downloadReport = async (research, language = 'pt-Br') => {
  console.log(language);

  // Se a pesquisa não rodou no sistema de pesquisa não acessamos o relatorio
  if(research.idSurvey.length > 6) {
    return alert('Relatório não disponível')
  }

  try {
    const url_result =
    window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
      `/report/connectReport/${research.idSurvey}?language=${language}`

    const report = await axios.get(url_result, {responseType: 'blob'})    

    const url = URL.createObjectURL(report.data)

    const a = document.createElement('a')
    a.download = `relatorio${research.idSurvey}.pptx`
    a.href = url
    a.click()    

    return report
  } catch (error) {
    return new Error('erro')
  }
}