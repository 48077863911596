import Cookies from "universal-cookie";
const cookies = new Cookies();

const DOMAIN = window["runConfig"].REACT_APP_COOKIE_DOMAIN || ".domain.tld";

function setCookie(key, value) {
  cookies.set(key, value, { path: "/", domain: DOMAIN });
}

function getCookie(key) {
  return cookies.get(key);
}

function removeCookie(name) {
  cookies.remove(name);
}

function getAllCookies() {
  return cookies.getAll();
}

function removeAllCookie() {
  cookies.remove("company_cnpj", { path: "/", domain: ".gptw.info" });
  cookies.remove("company_name", { path: "/", domain: ".gptw.info" });
  cookies.remove("company_plan", { path: "/", domain: ".gptw.info" });
  cookies.remove("gptw-ctx", { path: "/", domain: ".gptw.info" });
  cookies.remove("roles", { path: "/", domain: ".gptw.info" });
  cookies.remove("credential", { path: "/", domain: ".gptw.info" });
  cookies.remove("selected-company-register", {
    path: "/",
    domain: ".gptw.info",
  });
  cookies.remove("registers", { path: "/", domain: ".gptw.info" });
  cookies.remove("companies", { path: "/", domain: ".gptw.info" });
  cookies.remove("user_name", { path: "/", domain: ".gptw.info" });
  cookies.remove("user_email", { path: "/", domain: ".gptw.info" });

  cookies.remove("company_cnpj", { path: "/", domain: "localhost" });
  cookies.remove("company_name", { path: "/", domain: "localhost" });
  cookies.remove("company_plan", { path: "/", domain: "localhost" });
  cookies.remove("gptw-ctx", { path: "/", domain: "localhost" });
  cookies.remove("roles", { path: "/", domain: "localhost" });
  cookies.remove("credential", { path: "/", domain: "localhost" });
  cookies.remove("selected-company-register", {
    path: "/",
    domain: "localhost",
  });
  cookies.remove("registers", { path: "/", domain: "localhost" });
  cookies.remove("companies", { path: "/", domain: "localhost" });
  cookies.remove("user_name", { path: "/", domain: "localhost" });
  cookies.remove("user_email", { path: "/", domain: "localhost" });
}

export { setCookie, getCookie, removeCookie, getAllCookies, removeAllCookie };
