import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import styled from "styled-components";
import arrowRight from "../../assets/images/arrow-right.svg";
import iconEye from "../../assets/images/icon-eye.svg";
import iconEyeClosed from "../../assets/images/icon-eyeclosed.svg";
import iconLogin from "../../assets/images/icon-login-email.svg";
import iconPassword from "../../assets/images/icon-password.svg";
import login from "../../assets/images/login.png";
import logoJornada from "../../assets/images/logo-jornada.png";
import { authenticate, isCustomerActive } from "../../services/authServices";
import { getCookie, setCookie } from "../common/cookieManager";
import { HTTP_STATUS } from "../common/httpStatus";
import { Input } from "../components/input/index";
import { ModalReset } from "./modalReset";
import ForgotPassword from "./modals/ForgotPassword";

import { useForm } from "react-hook-form";

import FirstAccess from "./modals/FirstAccess";

const BtnHideModal1 = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 32px;
  right: 32px;
`;

function Login() {
  const [active, setIsActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const homeRedirect = (gptw, selectedCompanyRegister, isNewHome) => {
    const authApi = window["runConfig"].REACT_APP_CONNECT_BASE_URL;
    const authLegacy = window["runConfig"].REACT_APP_CONNECT_BASE_URL_LEGACY
      ? window["runConfig"].REACT_APP_CONNECT_BASE_URL_LEGACY
      : "https://certificacao.gptw.info";

    let url = "";
    if (gptw) {
      // Usuarios gptw - acessam home com acesso a todas as empresas
      url = `${authApi}/admin/dashboard`;
    } else {
      // url = `http://localhost:3008/dashboard`;
      if (isNewHome) {
        // Usuários habilitados para acessarem através de nova home (connect-app)
        // Estamos liberando nova tela aos poucos
        url = `${authApi}/dashboard?cr=${selectedCompanyRegister.id}`;
      } else {
        url = `${authLegacy}/`;
      }
    }

    window.location.replace(url);
  };

  const CONNECT_BASE_URL =
    window["runConfig"].REACT_APP_CONNECT_BASE_URL ||
    "http://connect.domain.tld/";

  useEffect(() => {
    const token = getCookie("credential");

    if (token) {
      homeRedirect();
    }
    const params = new URLSearchParams(window ? window.location.search : {});
    if (params.get("v")) {
      setRequestKey(params.get("v"));
      handleResetPasswordModal();
    }
  }, []);

  useEffect(() => {
    ReactGA.pageview(
      window["runConfig"].REACT_APP_CONNECT_BASE_URL + window.location.search
    );
  }, []);

  const [modalPassword, setModalPassword] = useState(false);
  const [firstAccessModal, setFirstAccessModal] = useState(false);

  let [requestKey, setRequestKey] = useState("");

  const [isErrorVisible, setErrorVisible] = useState(false);
  const [isMessageVisible, setMessage] = useState(false);

  const [modalUpdatePassword, setModalUpdatePassword] = useState(false);
  // const [modalUpdatePassword, setModalUpdatePassword] = useState(true);

  function handleResetPasswordModal(forceOpen = false) {
    if (forceOpen) {
      return setModalUpdatePassword(true);
    }
    return setModalUpdatePassword(!modalUpdatePassword);
  }

  async function onSubmit(data) {
    setIsSubmitting(true);
    const { email, password } = data;

    const response = await authenticate(email, password);

    console.log("🚀 ~ response:", response);
    setCookie("response", response);

    if (!response.data) {
      setErrorVisible(true);
      setIsSubmitting(false);
      return;
    }

    if (response.data.refresh_token) {
      setErrorVisible(false);

      const credentials = {
        credential: {
          user: {
            email: email,
            name: response.data.user_name,
            permissions: response.data.permissions,
            isGptw: response.data.isGptw,
          },
          permissions: response.data.permissions,
          roles: response.data.roles,
        },
      };

      const companiesRegister = response.data.companyRegisters.map(
        (register) => {
          return {
            id: register.externalId,
            paid: true,
            company: {
              fantasyName: register.company.shortName || "",
              name: register.company.name || "",
              cnpj: register.company.document || "",
              id: register.company.externalId || "",
              isProject: register.isProject || false,
            },
          };
        }
      );

      const registers = response.data.companyRegisters.map((register) => {
        return {
          id: register.externalId,
          paid: true,
          id_company: register.company.externalId,
          isProject: register.isProject || false,
        };
      });

      const companies = await Promise.all(
        response.data.companyRegisters.map(async (register) => {
          const { data } = await isCustomerActive(register.company.document);

          return {
            fantasyName: register.company.shortName || "",
            name: register.company.name || "",
            cnpj: register.company.document || "",
            id: register.company.externalId || "",
            isActive: data.active,
            isNewHome: data.newHome,
          };
        })
      );

      if (companies.length === 1) {
        const statusCompany = companies[0].isActive;

        if (!statusCompany) {
          alert(
            "Lamentamos informar que o cadastro da sua empresa foi bloqueado temporariamente devido à inatividade no sistema. Para resolver essa situação, por favor entre em contato com o nosso suporte pelo e-mail atendimento@gptwbrasil.com.br. Estamos aqui para ajudá-lo!"
          );
          setIsSubmitting(false);
          return;
        }
      }

      let isNewHome = false;
      if (companies[0]) {
        setCookie("company_cnpj", companies[0].cnpj);
        setCookie("company_name", companies[0].name);
        setCookie("newHome", companies[0].isNewHome);
        isNewHome = companies[0].isNewHome;
      } else {
        setCookie("newHome", false);
      }

      setCookie("gptw-ctx", btoa(JSON.stringify(credentials)));
      setCookie("roles", response.data.roles);

      setCookie("companies", btoa(JSON.stringify(companies)));
      setCookie("registers", btoa(JSON.stringify(registers)));

      setCookie("user_name", response.data.user_name);
      setCookie("user_email", email);
      setCookie("selected-company-register", companiesRegister[0]);

      setCookie("credential", btoa(JSON.stringify(credentials)));

      homeRedirect(response.data.isGptw, companiesRegister[0], isNewHome);
    } else if (response.status === HTTP_STATUS.CREATED) {
      setMessage(true);
      setErrorVisible(false);
    } else {
      setErrorVisible(true);
      setMessage(false);
    }
  }

  const handleForgotPassword = () => {
    setModalPassword(true);
  };

  const handleCloseForgotPassword = () => {
    setModalPassword(false);
  };

  const handleFirstAccess = () => {
    setFirstAccessModal(true);
  };

  const handleCloseFirst = () => {
    setFirstAccessModal(false);
  };

  function showPass(event) {
    let img = event.target.offsetParent.offsetParent.childNodes[1].firstChild;
    let input = event.target.offsetParent.offsetParent.childNodes[2];
    if (input.type === "password") {
      input.type = "text";
      img.src = iconEyeClosed;
    } else {
      input.type = "password";
      img.src = iconEye;
    }
  }

  const pass = watch("password");
  const userEmail = watch("email");
  useEffect(() => {
    if (pass !== undefined && userEmail !== undefined) {
      if (pass.length !== 0 && userEmail.length !== 0) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
  }, [watch, pass, userEmail]);

  return (
    <Stack
      minH={"100vh"}
      maxH={["auto", "100vh"]}
      maxW={"100vw"}
      overflowX="hidden"
      direction={{ base: "column", md: "row" }}
      bg="#ffffff"
      spacing={0}
    >
      <Box flex={[1]} w={["100vw", "60vw"]} h={["auto", "100vh"]}>
        <Image
          alt={"Login Image"}
          objectFit={"cover"}
          src={login}
          h={["274px", "100%"]}
          w={["100%", "100%"]}
        />
      </Box>
      <Flex
        flex={[2, 1]}
        direction={"column"}
        align={["flex-start", "center"]}
        justify={["flex-start", "center"]}
        position={"relative"}
        overflow="hidden"
      >
        <Box w={["100%", "70%"]} maxW={["unset", "lg"]} p={["32px 16px", 2]}>
          <img src={logoJornada} alt="Jornada" draggable="false" />
          <Heading fontSize={["24px", "28px"]} mb="40px" textAlign="center">
            Que bom ter você por aqui.
          </Heading>

          <Box as="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={["24px"]}>
              <Input
                label="E-mail"
                placeholder="Digite seu e-mail"
                type="email"
                icon={<img src={iconLogin} alt="icon e-mail" />}
                {...register("email")}
                h="52px"
                fontWeight={[400, 600]}
                borderRadius="8px"
                borderColor="#B8CAE8"
              />

              <Input
                type="password"
                placeholder="Informe sua senha"
                icon={<img src={iconPassword} alt="icon password" />}
                iconRight={
                  <img
                    onClick={(e) => showPass(e)}
                    src={iconEye}
                    alt="icon eye"
                  />
                }
                label="Senha"
                {...register("password")}
                h="52px"
                fontWeight={[400, 600]}
                borderRadius="8px"
                borderColor="#B8CAE8"
              />
            </Stack>
            <Flex
              mt={["18px"]}
              justify="space-between"
              align="center"
              w="100%"
              h="24px"
              color="#292B30"
            >
              {/* <Checkbox
                w="18px"
                h="18px"
                colorScheme="red"
                whiteSpace={"nowrap"}
              >
                <Text fontSize="14px">Lembrar senha</Text>
              </Checkbox> */}
              <Button
                w="154px"
                h="56px"
                color="#E60000"
                fontSize="14px"
                variant="link"
                gap="11.42px"
                lineHeight={"16px"}
                fontWeight={["400", "600"]}
                onClick={handleForgotPassword}
                _hover={{
                  color: "#E60000",
                }}
                _focus={{
                  color: "#292B30",
                  fontWeight: "400",
                }}
              >
                <Text fontSize={"14px"}>Esqueci minha senha</Text>

                <Image
                  src={arrowRight}
                  alt="arrow right"
                  w="5.91px"
                  h="13.2px"
                />
              </Button>
            </Flex>
            <Box h={["42px"]} />
            <Button
              type="submit"
              w="100%"
              isLoading={isSubmitting}
              color={!active ? "#5C6066" : "white"}
              fontSize="16px"
              fontWeight={700}
              lineHeight={"19px"}
              borderRadius="10px"
              h="56px"
              backgroundColor={!active ? "#E5E5E5" : "#E60000"}
              _hover={{
                opacity: "0.2",
              }}
            >
              Fazer login
            </Button>

            {isErrorVisible && (
              <Text fontSize="14px">Usuário ou senha inválidos</Text>
            )}

            {isMessageVisible && (
              <div>
                Primeiro acesso. Favor verificar o email para resetar a senha
              </div>
            )}
          </Box>
        </Box>
        <Flex
          w={["100vw", "100%"]}
          mt={["32px"]}
          position={["relative", "absolute"]}
          bottom="0"
          left={0}
          background="#f1f5fa"
          justifyContent="center"
          alignItems={"center"}
          textAlign={"center"}
          height={["unset", "82px"]}
          py={["16px", "20px"]}
        >
          <Text
            fontSize={["14px", "15px"]}
            lineHeight="21px"
            maxW={["unset", "80%"]}
          >
            Caso tenha feito sua <b>compra</b> com algum de nossos{" "}
            <b>consultores</b>, faça seu
            <Link
              as="button"
              fontSize={"15px"}
              textDecor="underline"
              color="#E60000"
              lineHeight="21px"
              fontWeight="600"
              ml="5px"
              onClick={handleFirstAccess}
            >
              primeiro acesso aqui
            </Link>
          </Text>
        </Flex>
      </Flex>
      <ForgotPassword
        isOpen={modalPassword}
        onClose={handleCloseForgotPassword}
        fechar={handleCloseForgotPassword}
      />
      <FirstAccess
        onClose={handleCloseFirst}
        isOpen={firstAccessModal}
        url={CONNECT_BASE_URL}
      />

      <ModalReset
        isOpen={modalUpdatePassword}
        requestKey={requestKey}
        onClose={handleResetPasswordModal}
        setModalUpdatePassword={setModalUpdatePassword}
        // disabled={true}
      />

      <BtnHideModal1 onClick={handleResetPasswordModal}>
        {/* <Icon type="close-gray-circle" /> */}
      </BtnHideModal1>
    </Stack>
  );
}

export default Login;