import { rankingApi as api } from "../services/api-research";
import { useQuery } from "react-query";
import { queryClient } from "../services/queryCliente";

const fetchRankings = async (companyId) => {
  const { data } = await api.get(`/rankings?company_id=${companyId}`);
  

  return data;
};
export const useRankings = (companyId) => {
  return useQuery(["rankings", companyId], () => fetchRankings(companyId), {
    staleTime: 30 * 1000,
    initialData: () => queryClient.getQueryData("rankings"),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState("rankings")?.dataUpdatedAt,
  });
};
