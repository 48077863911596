import {
  Stack,
  Grid,
  GridItem,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListItem,
  ListIcon,
  useMediaQuery,
  Spinner,
} from "@chakra-ui/react";
import { AiTwotoneCheckCircle } from "react-icons/ai";

export const DescriptionSectionDesktop = ({handleDownload}) => (
  <Accordion defaultIndex={[0]} allowToggle overflow={"hidden"}>
  <AccordionItem
    borderRadius="12px"
    border="none"
    overflow="hidden"
  >
    <h2>
      <AccordionButton bg="#E5E5E5" py="16px" px="24px">
        <Box
          as="span"
          flex="1"
          textAlign="left"
          fontSize={["16px", "20px"]}
          lineHeight={["18.78px", "23.48px"]}
          fontWeight={700}
        >
          Sobre os Pilares for All
        </Box>
        <Text as="span" fontSize="sm" mr="8px">
          Mais informações
        </Text>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel
      p={"24px 24px 36px 24px"}
      bg="white"
      position={"relative"}
      _after={{
        content: "''",
        width: "full",
        height: "11px",
        position: "absolute",
        bottom: 0,
        left: 0,
        transform: "rotate(-180deg)",
        bg: "#E5E5E5",
        borderRadius: "12px 12px 0px 0px",
        zIndex: 2,
      }}
    >
      <Stack spacing="16px">
        <Text fontSize="sm" lineHeight="28px">
          Logo após a conclusão da etapa de Certificação, vem a
          etapa de Ranking, em que são necessários os preenchimentos
          de alguns questionários, dentre eles o dos Pilares For All
          (Culture Audit / C.A.), obrigatório para a participação em
          qualquer um dos Rankings GPTW. Por isso sua organização em
          até 30 dias após o término da Pesquisa de Clima
          Organizacional GPTW para preencher os Pilares for All, mas
          não é necessário aguardar a finalização da pesquisa para
          iniciar o preenchimento.
        </Text>
        <Text fontSize="sm" lineHeight="28px">
          Os Pilares for All são importantes para mostrarem aos
          Avaliadores GPTW as práticas culturais e políticas de
          gestão de pessoas diferenciadas, ou seja, que nunca
          poderiam ser confundidas com outras experiência de
          trabalho no mesmo setor. Ao responder o questionário dos
          Pilares for All, tenha em mente que torna a cultura da sua
          empresa única e como sua empresa oferece uma experiência
          singular as pessoas funcionárias, ou seja, reflita sobre o
          que sua organização tem de especial e conte sua história
          do que ela faz de melhor. Deixe claro como os valores e
          filosofia da organização direcionam as práticas e geram
          impacto positivo na vida das pessoas que trabalham nela,
          nos negócios e na sociedade usando de dados e exemplos
          práticos.
        </Text>
        <Text fontSize="sm" lineHeight="28px">
          Observação: o preenchimento deve ser feito em língua
          Portuguesa.
        </Text>
        <Text as="span" fontSize="sm" lineHeight="28px">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://youtu.be/ISg0MgLGl6s?lumUserSessionId=null&certEvolving=true&externCompanyRegisterId=18894bdd-1ed3-40f5-89bb-b81a580366a9"
          >
            <Text
              as="span"
              color="red.100"
              textDecor="underline"
              fontWeight={600}
            >
              Clique aqui
            </Text>{" "}
          </a>{" "}
          para conhecer a Metodologia GPTW for All
        </Text>

        <Text as="span" fontSize="sm" lineHeight="28px">
          <Text
            as="button"
            onClick={handleDownload}
            color="red.100"
            textDecor="underline"
            fontWeight={600}
          >
            Clique aqui
          </Text>{" "}
          para conferir o manual de preenchimento dos Pilares for
          All
        </Text>
      </Stack>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
);

export const DescriptionHelpItems = () => (
  <List spacing={0}>
  <ListItem
    display={"flex"}
    alignItems={"center"}
    height="28px"
  >
    <ListIcon
      as={AiTwotoneCheckCircle}
      color="red.100"
      fontSize="8px"
    />
    <Text fontSize={"sm"}>O número de funcionários;</Text>
  </ListItem>
  <ListItem
    display={"flex"}
    alignItems={"center"}
    height="28px"
  >
    <ListIcon
      as={AiTwotoneCheckCircle}
      color="red.100"
      fontSize="8px"
    />
    <Text fontSize={"sm"} whiteSpace="nowrap">
      O Ramo de atividades da sua organização;
    </Text>
  </ListItem>
  <ListItem
    display={"flex"}
    alignItems={"center"}
    height="28px"
  >
    <ListIcon
      as={AiTwotoneCheckCircle}
      color="red.100"
      fontSize="8px"
    />
    <Text fontSize={"sm"}>
      Principais produtos e/ou serviços;
    </Text>
  </ListItem>
</List>
)

export const LastDescriptionHelpItems = () => (
  <List spacing={0}>
  <ListItem
    display={"flex"}
    alignItems={"center"}
    height="28px"
  >
    <ListIcon
      as={AiTwotoneCheckCircle}
      color="red.100"
      fontSize="8px"
    />
    <Text fontSize={"sm"}>Clientes e concorrentes;</Text>
  </ListItem>
  <ListItem
    display={"flex"}
    alignItems={"center"}
    height="28px"
  >
    <ListIcon
      as={AiTwotoneCheckCircle}
      color="red.100"
      fontSize="8px"
    />
    <Text fontSize={"sm"} whiteSpace="nowrap">
    Missão, visão e valores
    </Text>
  </ListItem>

</List>
)