import React, { forwardRef } from "react";
import { Textarea as Chakratextarea, FormControl } from "@chakra-ui/react";

const InputBase = (
  { name, label, error = null, isDisabled, defaultValue, ...rest },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      <Chakratextarea
        name={name}
        id={name}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        error={error}
        placeholder="Digite ..."
        color="black.100"
        height="318px"
        border="1px solid #B8CAE8"
        borderColor="#B8CAE8"
        fontSize="sm"
        lineHeight="28px"
        fontWeight={600}
        bg="white"
        p="16px"
        borderRadius="12px"
        _disabled={{
          fontWeight: 600,
          bg: "#5C6066",
          color: "#E5E5E5",
        }}
        ref={ref}
        {...rest}
      />
    </FormControl>
  );
};

export const CustomTextarea = forwardRef(InputBase);
