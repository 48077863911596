import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ButtonClose from "../../Button/ButtonClose";

function ModalWithClose({
  title,
  isOpen,
  onClose,
  children,
  footer,
  ...modalContentProps
}) {
  const handleCLose = () => {
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCLose}
      isCentered
      scrollBehavior={"inside"}
    >
      <ModalOverlay />

      <ModalContent borderRadius="none" {...modalContentProps}>
        <ModalHeader bg="#F5F8F6" color="#22262D" p="12px 24px">
          <Flex justify="space-between" align="center" gap="10px">
            <Heading
              fontWeight={700}
              fontSize={"16px"}
              lineHeight={"16px"}
              color="#22262D"
            >
              {title}
            </Heading>
            <ButtonClose onClick={handleCLose} />
          </Flex>
        </ModalHeader>
        <ModalBody p="unset" minH="100%">
          {children}
        </ModalBody>
        {!!footer ? (
          <ModalFooter p="0 24px 52px 24px" w="100%">
            {footer}
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
}

export default ModalWithClose;
