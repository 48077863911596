import { Icon, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { IconClose } from "../../assets";

const ButtonHamburguer = ({ isOpen, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <IconButton
      variant={"unstyled"}
      size={"sm"}
      icon={
        isOpen ? (
          <IconClose
            style={{
              transition: "fill 0.3s",
              opacity: isHovered ? "1" : "0.6",
              fill: "#22262D",
            }}
          />
        ) : (
          <IconHamburguer
            style={{
              transition: "stroke 0.3s",
              stroke: isHovered ? "#FF4553" : "#22262D",
            }}
          />
        )
      }
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    />
  );
};

const IconHamburguer = (props) => {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" {...props}>
      <path
        d="M4 9.33331H28M9.33333 16H28M16 22.6666H28"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </Icon>
  );
};

export default ButtonHamburguer;
