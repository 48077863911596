import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '../..'


const RadioStyled = styled.div`
    width: 73px;
    height: 32px;
    cursor: pointer;
    text-align: center;
    border-radius: 20px;
    vertical-align: middle;

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    
    color: #292B30;

    ${props =>{
        if(props.estado === true){
            return `
                background-color: #E60000;  
                color: #fff;    
            `
        }
        else {
            return `
                background-color: #E5E5E5;   
                color: #292B30;      
            `
        }
    }}

`

function Radio(props){
    const [estado, setEstado ] = useState(props.estado)
    const lista = ["radio-no", "radio-yes"]
    const [alternar, setAlternar] = useState(lista[0])


    return  <RadioStyled  
                    {...props}
                    estado={estado}
                    onClick={()=>{
                        setEstado(!estado)
                        if(lista[0] === alternar){
                            setAlternar(lista[1])
                        }
                        else{
                            setAlternar(lista[0])
                        }
                    }}
                >   
                    <Icon type={alternar} />
                    
                    {props.sexo && props.sexo}
                    {props.label && props.label}
            </RadioStyled>
}

export default Radio