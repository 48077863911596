import { useState } from "react";
import {
  Flex,
  Box,
  Button,
  Stack,
  Text,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  IconButton,
  ModalHeader,
  Heading,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { TbDotsVertical } from "react-icons/tb";

const ResultsMobile = ({ research }) => {
  const [modal, setModal] = useState(false);
  const endDate = new Date(research.surveyEndDate.split('-').reverse().join('-')).getTime() + 86399999;

  const handleModal = () => {
    setModal(!modal);
  };
  return (
    <>
      {endDate < Date.now() && Date.now() < endDate + 172800000 ? (<></>) : research.status === 'AUDITING' ? (<></>) : (
        <Box>
          <ModalOptions isOpen={modal} onClose={handleModal} />
          <Flex
            p="10px 12px"
            align="center"
            justify="space-between"
            bg="gray.400"
            h="44px"
          >
            <Text
              fontSize="13px"
              lineHeight="25px"
              fontWeight={700}
              textDecor="underline"
            >
              Pesquisa de certificação
            </Text>

            <Button
              bg="white"
              as={IconButton}
              py="3.75px"
              w="20px"
              minW={0}
              maxW="20px"
              h="20px"
              p={0}
              aria-label="Search database"
              icon={<TbDotsVertical />}
              onClick={handleModal}
            ></Button>
          </Flex>
          <Stack
            p="12px"
            spacing="4px"
            fontSize="13px"
            bg="white"
            color="black.100"
            lineHeight="25px"
          >
            <Flex align="center" justify="space-between">
              <Text color="black.100" fontWeight={600} lineHeight="25px">
                Período
              </Text>
              {research.surveyStartDate === null &&
              research.surveyEndDate === null ? (
                <>-- a --</>
              ) : (
                <Text fontSize="14px">
                  {research.surveyStartDate}
                  <Text as="span"> a </Text>
                  {research.surveyEndDate}
                </Text>
              )}
            </Flex>

            {/* <Flex align="center" justify="space-between">
              <Text color="black.100" fontWeight={600} lineHeight="25px">
                Resultados disponíveis em
              </Text>
              <Text>15/11/2021</Text>
            </Flex> */}

            <Flex align="center" justify="space-between">
              <Text fontWeight={600}>Nota</Text>
              <Text> {research.ti ? research.ti : "--"}</Text>
            </Flex>

            <Flex align="center" justify="space-between">
              <Text fontWeight={600}>Resultado</Text>

              {research.minimumSample ? (
                <Tag
                  fontSize="13px"
                  bg="#40BF91"
                  color="white"
                  borderRadius={"6px"}
                  fontWeight={600}
                  py="4px"
                  px="8px"
                >
                  Certificado
                </Tag>
              ) : (
                <Tag
                  fontSize="13px"
                  bg="red.100"
                  color="white"
                  borderRadius={"6px"}
                  fontWeight={600}
                  py="4px"
                  px="8px"
                >
                  Não certificado
                </Tag>
              )}
            </Flex>

            <Flex align="center" justify="space-between">
              <Text color="black.100" fontWeight={600} lineHeight="25px">
                Expira em
              </Text>
              {research.expirationDate === null ? (
                <Text>--</Text>
              ) : (
                <Text fontSize="14px">{research.expirationDate}</Text>
              )}
            </Flex>
          </Stack>
        </Box>
      ) }
    </>
  );
};

const ModalOptions = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        w={["100vw", "1112px"]}
        h={["auto", "552px"]}
        borderRadius="20px 20px 0px 0px"
        overflow={"hidden"}
        p={0}
      >
        <ModalHeader bg="#F5F5FB" py="17px">
          <Heading as="h3" fontSize={"18px"} color="black.100">
            Opções
          </Heading>
        </ModalHeader>

        <ModalCloseButton bg="white" rounded={"full"} top="14px" w="32px" />

        <ModalBody>
          <Stack spacing={"0px"} p="14px 0 19px 0">
            <LinkBox maxW="sm" py="16px" borderBottom="1px solid #E5E5E5">
              <Text fontSize={"14px"} fontWeight={500}>
                <LinkOverlay href="/results/resume">Ver resumo</LinkOverlay>
              </Text>
            </LinkBox>
            <LinkBox maxW="sm" py="16px" borderBottom="1px solid #E5E5E5">
              <Text fontSize={"14px"} fontWeight={500}>
                <LinkOverlay href="#">Relatório completo</LinkOverlay>
              </Text>
            </LinkBox>
            <LinkBox maxW="sm" py="16px" borderBottom="1px solid #E5E5E5">
              <Text fontSize={"14px"} fontWeight={500}>
                <LinkOverlay href="#">Caderno de respostas</LinkOverlay>
              </Text>
            </LinkBox>
            <LinkBox maxW="sm" py="16px" borderBottom="1px solid #E5E5E5">
              <Text fontSize={"14px"} fontWeight={500}>
                <LinkOverlay href="#">Resultado Trust Index</LinkOverlay>
              </Text>
            </LinkBox>
            <LinkBox maxW="sm" py="16px" borderBottom="1px solid #E5E5E5">
              <Text fontSize={"14px"} fontWeight={500}>
                <LinkOverlay href="#">Selo da Certificação</LinkOverlay>
              </Text>
            </LinkBox>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ResultsMobile;
