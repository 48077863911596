import * as React from "react";
import { useQuery } from "react-query";
import { researchApi as api } from "../../services/api-research";

const useCertification = (id) => {
  const [certificated, setCertificaded] = React.useState(false);
  const [certificationLoading, setCertificationLoading] = React.useState(true);

  const fetchResearch = async () => {
    const res = await api.get(`/research/certificated/${id}`);
    if (res && res.data) return res.data;
  };

  const { data, isError, isFetched } = useQuery("res", () => fetchResearch());  

  React.useEffect(() => {
    if (data === true) { 
      setCertificaded(true)
      setCertificationLoading(false);
    }
  }, [data]);

  return [certificated, isFetched, isError];
};

export default useCertification;