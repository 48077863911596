import {
  Grid as ChakraGrid,
  Container,
  Flex,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { researchApi } from "../../../services/api-research";
import Questionarios from "../../components/card/Questionario";
import SimpleSidebar from "../../components/nav/Sidebar";
import axios from "axios";

const Tematicas = () => {
  const [thematics, setThematics] = useState([]);
  const [hasLogin, setHasLogin] = useState(false);
  const [credentials, setCredentials] = useState();
  const [crInfo, setCrInfo] = useState();
  const [refresh, setRefresh] = useState(0)
  const [thematicsSurveyStatus, setThematicsSurveyStatus] = useState();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const crURL = urlParams.get("cr");

  const toast = useToast();

  const transformeDataSurvey = (registros) => {
    const response = [];

    registros.forEach(registro => {
      // OBS: A requisição sempre irá trazer um array de arrays, onde o array filho tem essa estrutura:
      // [["e9adfef4-9ad2-42bd-a1de-68b1c660d2d1",null,4021]]
      const obj = {
        surveyId: registro[2],
        responseEnd: registro[1],
        companyRegister: registro[0]
      }

      response.push(obj);
    });

    return response;
  }

  
  useEffect(() => {
    const getSurveyStatus = async () => {
      try {
        const { data } = await researchApi.get(`/thematic-research/status/${crURL}`);
        
        if (data.length > 0) {
          const response = transformeDataSurvey(data)
          setThematicsSurveyStatus(response);

        }
      } catch (error) {
        console.log("ResearchApi ERROR > ", error);
      }
    };
    getSurveyStatus();
  }, [])

  useEffect(() => {
    const getCredentials = async () => {
      try {
        const response_credentials_exist = await researchApi.get(
          `/respondent-credential/company/${crURL}`
        );

        if (response_credentials_exist.data.length > 0) {
          setHasLogin(true);
          setCredentials(response_credentials_exist.data[0]);
        } else {
          console.log("unregistered");
        }
      } catch (error) {
        console.log("ResearchApi ERROR > ", error);
      }
    };

    getCredentials();
  }, []);

  useEffect(() => {
    const getCRInfo = async () => {
      try {
        const url_company_info =
          window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
          `/company-register-info?cr=${crURL}`;

        const company_info = await axios.get(url_company_info);

        if (company_info.data) {
          setCrInfo(company_info.data.company);
        }
      } catch (error) {
        console.log("ResearchApi ERROR > ", error);
      }
    };
    getCRInfo();

  }, []);

  const handleRefresh = () => {
    setRefresh(refresh => refresh + 1);
  }

  useEffect(() => {
    const handleGetThematics = async () => {
      try {
        const response_thematics = await researchApi.get(`/thematic-research`);
        if (response_thematics.data.length > 0) {
          const result = [];
          for (let i = 0; i < response_thematics.data.length; i++) {
            let current = response_thematics.data[i];
            if(current.is_deleted == true) continue;

            const response_subbed = await researchApi.get(
              `/thematic-research-subscribers/research/${current.id}`
            );

            const surveyStatus = thematicsSurveyStatus.find((surveyRegistry) => surveyRegistry.surveyId == current.survey_id);

            result.push({
              id: current.id,
              title: current.title,
              survey_code: current.survey_id,
              status: current.status,
              link: current.survey_link,
              expires_on: current.expires_on,
              is_subbed: response_subbed.data.length > 0 ? true : false,
              response_end: surveyStatus?.responseEnd ? surveyStatus?.responseEnd : null,
            });
          }

          setThematics(result);
        }
      } catch (error) {
        console.log("ResearchApi ERROR > ", error);
      }
    };

    handleGetThematics();
  }, [refresh, thematicsSurveyStatus]);

  const subscribe = async (survey, id, link) => {

    try {
      let credentials_stateless = credentials;

      if (!hasLogin) {
        try {
          const response_credentials = await researchApi.post(
            `/respondent-credential/${crURL}`
          );
  
          setHasLogin(true);
          setCredentials(response_credentials.data);
          credentials_stateless = response_credentials.data;
        } catch (err) {
          toast({
            title: "Falha na inscrição:",
            description: err.response.data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }

      await researchApi.post(`/thematic-research/add-respondent`, {
        companyName: crInfo.name,
        cnpj: crInfo.cnpj,
        researchId: survey,
        login: credentials_stateless.login,
        password: credentials_stateless.password,
      });
      await researchApi.post(`/thematic-research-subscribers`, {
        company_register: crURL,
        research_id: id,
      });

      handleRefresh();

      redirect(link);
      // refresh()
    } catch (error) {
      // toast({
      //   title: "Falha na inscrição:",
      //   description: error.response.data.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      // });
      console.log("SUBSCRIPTION ERROR > ", error);
    }
  };

  const redirect = async (link) => {
    try {
      const response_link = await researchApi.post(`/cripto/encrypt`, {
        user: {
          login: credentials.login,
          password: credentials.password,
        },
        urlResearch: `${window['runConfig'].REACT_APP_URL_SURVEY_FORM_UI}${link}`,
      });
      console.log(response_link);
      
      window.open(response_link.data, "_blank");
    } catch (err) {
      // toast({
      //   title: "Falha ao gerar link:",
      //   description: err.response.data.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      // });
      console.log("LINK ERROR > ", err);
    }

    return true;
  };

  return (
    <SimpleSidebar>
      <Container maxW="container.xl" pt={4} pb={4}>
        <Stack
          spacing={"20px"}
          w={["100%"]}
          position="relative"
          px={["0px", "30px"]}
        >
          <Heading as="h3" fontSize="18px" lineHeight="21px">
            Questionários Temáticos
          </Heading>

          <ChakraGrid
            templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
            gap="16px"
            mt="10px"
            w="100%"
          >
            {thematics.length !== 0 ? (
              <>
                {thematics.map((questionario, idx) => {
                  return (
                    <Questionarios
                      cor={questionario.is_subbed ? "#ffff55" : "#717171"}
                      titulo={questionario.title}
                      expires={questionario.expires_on}
                      type='thematic'
                      inscrito={questionario.is_subbed}
                      inicial={0}
                      final={0}
                      url={false}
                      textoUrl={
                        !questionario.is_subbed
                          ? "Inscrever"
                          : "Acessar Questionário"
                      }
                      textoAlerta={"Texto Alerta"}
                      message={false}
                      status={true}
                      surveyLink={questionario.link}
                      surveyCode={questionario.survey_code}
                      surveyId={questionario.id}
                      responseEnd={questionario.response_end}
                      action={questionario.is_subbed ? redirect : subscribe}
                      method={questionario.is_subbed ? "redirect" : "subscribe"}
                    />
                  );
                })}
              </>
            ) : (
              <>Carregando!</>
            )}
          </ChakraGrid>
        </Stack>
      </Container>
    </SimpleSidebar>
  );
};

export default Tematicas;
