import React, { useEffect, useState, useContext } from "react";
import { format, parseISO, formatDistance, isPast } from "date-fns";
import { ptBR } from "date-fns/locale";

import {
  Box,
  Flex,
  GridItem,
  Text,
  Button,
  Heading,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";

import { useResearch } from "../../../helpers/useResearch";
import InitialDateModal from "./Modals/InitialDateModal";
import WaitImportRespondentModal from "./Modals/WaitImportRespondentModal";
import iconBar from "../../../assets/images/icon-bar.svg";
import { CustomContext } from "../../../context/CustomContext";

const ResearchDate = ({ researchId, importRespondentIsRunning, totalRespondents, extern_researchId, onSubmitChangeDate }) => {
  const { custom } = useContext(CustomContext);
  const [mobile] = useMediaQuery("(max-width: 1090px)");

  const { data: researchsData } = useResearch(researchId);
  const [modal, setModal] = useState(false);

  const [initialDate, setInitialDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [daysUntilStart, setDaysUntilStart] = useState(null);
  const [canCustomize, setCanCustomize] = useState(false);
  const [blockDateChange, setBlockDateChange] = useState(false);
  const [isOpenWaitImportModal, setIsOpenWaitImportModal] = useState(false);
  const [textModaSurveyNotRespondent, setTextModaSurveyNotRespondent] = useState('');

  useEffect(() => {
    if (researchsData.research.application_date) {
      const initial = format(
        parseISO(researchsData.period.from),
        "dd/MM/yyyy",
        {
          locale: ptBR,
        }
      );

      const end = format(parseISO(researchsData.period.to), "dd/MM/yyyy", {
        locale: ptBR,
      });

      const startDate = formatDistance(
        new Date(),
        new Date(parseISO(researchsData.research.application_date)),
        {
          locale: ptBR,
        }
      );
      setInitialDate(initial);
      setEndDate(end);
      setDaysUntilStart(startDate);
    }
  }, [researchsData.period.from, researchsData.period.to]);

  const handleModal = () => {
    if(importRespondentIsRunning && extern_researchId!=null){
      setIsOpenWaitImportModal(true);
      setTextModaSurveyNotRespondent('');
    } else if(totalRespondents===0) {
      setTextModaSurveyNotRespondent('Para realizar o agendamento da pesquisa é necessário ter realizado o Upload da Planilha de Funcionários. ');
      setIsOpenWaitImportModal(true);
    } else {
      setModal(true);
    }
  };

  const handleCloseModalWaitImport = () => {
    setIsOpenWaitImportModal(false);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  useEffect(() => {
    if (researchsData.employees_sheet === true) {
      setCanCustomize(true);
    }
  }, [researchsData]);

  useEffect(() => {
    const startDate = researchsData.research.application_date;
    if (startDate !== null && isPast(new Date(startDate))) {
      console.log(startDate)
      setBlockDateChange(true);
    }
  }, [researchsData]);

  return (
    <Box
      w={["100%", "100%", "50%"]}
      h={["293px", "220px", "100%"]}
      mr={["0", "16px"]}
      bg="white"
      border="1px solid #CCCCCC"
      p={["22px", "30px"]}
      borderRadius="12px"
    >
      <InitialDateModal
        data={researchsData}
        initialDate={initialDate}
        endDate={endDate}
        researchId={researchId}
        isOpen={modal}
        onClose={handleCloseModal}
        blockDateChange={blockDateChange}
        canCustomize={canCustomize}
        onSubmitChangeDate={onSubmitChangeDate}
        importRespondentIsRunning={importRespondentIsRunning}
      />

      <WaitImportRespondentModal isOpen={isOpenWaitImportModal} onClose={handleCloseModalWaitImport} text={textModaSurveyNotRespondent}/>

      <Box borderRadius="10px" position={"relative"}>
        <Flex h={["auto", "65px"]} justify="space-between">
          <Box h="100%" w="100%">
            <Image src={iconBar} alt="icon bar" />
            <Heading as="h4" fontSize="18px" pt={["8px", "12px"]}>
              Início da Pesquisa de Clima
            </Heading>

            <Box w="100%">
              <Text fontSize="14px" pt="12px" mb={["24px", "10px"]}>
                Sua pesquisa iniciará em
                {researchsData.initial_date ? (
                  <Text as="span" fontWeight="600" ml="5px">
                    {initialDate}
                  </Text>
                ) : (
                  <Text as="span" fontWeight="600" ml="5px">
                    {/* -- dias{" "} */}
                  </Text>
                )}
              </Text>
              <Flex gap="16px">
                <Box
                  border="1px solid #40BF91"
                  borderRadius="12px"
                  w={["100%", "234px"]}
                  h="83px"
                  p="16px"
                >
                  {researchsData.initial_date ? (
                    <Text fontWeight="700" color="#292B30" mb="2px">
                      {initialDate}
                    </Text>
                  ) : (
                    <Text fontWeight="700" color="#5C6066" mb="2px">
                      --/--/----
                    </Text>
                  )}

                  <Box
                    h="2px"
                    w="25px"
                    bg="red.100"
                    borderRadius="5px"
                    mb="5px"
                  />
                  <Text
                    fontSize={["13px", "14px"]}
                    color="#5C6066"
                    whiteSpace={"nowrap"}
                  >
                    Previsão de início
                  </Text>
                </Box>

                <Box
                  border="1px solid #40BF91"
                  borderRadius="12px"
                  w={["100%", "234px"]}
                  h="83px"
                  p={["14px", "16px"]}
                >
                  {researchsData.research.application_date_end ? (
                    <Text fontWeight="700" color="#292B30" mb="2px">
                      {endDate}
                    </Text>
                  ) : (
                    <Text fontWeight="700" color="#5C6066" mb="2px">
                      --/--/----
                    </Text>
                  )}

                  <Box
                    h="2px"
                    w="25px"
                    bg="red.100"
                    borderRadius="5px"
                    mb="5px"
                  />
                  <Text fontSize={["13px", "14px"]} color="#5C6066">
                    {mobile ? " Previsão de fim" : " Previsão de encerramento"}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Box>
          <Button
            w={["100%", "120px", "140px", "175px"]}
            position={["absolute"]}
            right={["unset", 0]}
            top={["unset", 0]}
            bottom="-70px"
            h={["48px"]}
            variant="secondary"
            color={!custom ? "#292B30" : "red.100"}
            disabled={blockDateChange}
            borderColor={!custom ? "#292B30" : "red.100"}
            onClick={handleModal}
          >
            {researchsData.initial_date ? "Editar" : "Configurar"}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default ResearchDate;
