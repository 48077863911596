import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  Faturas,
  InfoOrganizacao,
  Cadastro,
  Login,
  Pesquisas,
  Certificacao,
  Certificados,
  Results,
  Rankings,
  Home,
  Register,
  EmpresaCertificada,
  Colaboradores,
  Tematicas,
  CadastroPilaresForAll,
  CadastrosUserQuestionnaire,
  StatusAdmin,
  DashboardAdmin,
  ManageAdmin,
  Termos,
} from "./app/index";
import { getCookie } from "./app/common/cookieManager";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./services/queryCliente";

import { ChakraProvider, useMediaQuery } from "@chakra-ui/react";
import { GlobalStyle } from "./style";
import { theme } from "./theme/theme";
import "./styles.scss";
import { CustomProvider } from "./context/CustomContext";
import Feedback from "../src/app/components/feedback";
import React from "react";

import ReactGA from "react-ga";
// Inicialize o ReactGA com o seu código de rastreamento

if (window["runConfig"].REACT_GOOGLE_ANALYTICS === "active") {
  ReactGA.initialize("G-MPBT7X0C9G");
}

export const showCollaboratorContext = React.createContext(null);

function App() {
  const [showPage, setShowPage] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const isLargerThan1280 = useMediaQuery("(min-width: 1280px)");

  function isValidToken() {
    let token = getCookie("credential");

    if (
      !token &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/certified-company" &&
      !/\/+(register\/\?cnpj=)+(\d{14})+(&voucher=)+(GPTW[12]-)+(\w{15})$/.test(
        window.location.pathname + window.location.search
      ) &&
      !/\/+(register\/\?cnpj=)+(\d{14})+(&voucher=)+(GPTW[12]-)+(\w{15})+(&cnpjapply=)+(\d{14})$/.test(
        window.location.pathname + window.location.search
      ) &&
      !/\/+(gptw\/run-as)\/+(\w{8})+(-)+(\w{4})+(-)+(\w{4})+(-)+(\w{4})+(-)+(\w{12})+(\/cultureAudit)$/.test(
        window.location.pathname
      )
    ) {
      window.location.replace("/login");
    }
    return token ? true : false;
  }
  isValidToken();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={true} />
      <Feedback title="Feedback" show={isLargerThan1280 === true} />
      <CustomProvider>
        <ChakraProvider resetCSS theme={theme}>
          <GlobalStyle />
          <Router>
            <showCollaboratorContext.Provider
              value={{
                showPage: showPage,
                setShowPage: setShowPage,
                isLoading: isLoading,
                setIsLoading: setIsLoading,
              }}
            >
              <Switch>
                <Route path="/admin/dashboard">
                  <DashboardAdmin />
                </Route>
                <Route path="/admin/status-dos-clientes">
                  <StatusAdmin />
                </Route>
                {/* <Route path="admin/gerenciar">
                  <ManageAdmin />
                </Route> */}

                <Route path="/bills">
                  <Faturas />
                </Route>

                <Route path="/registration/profile/info">
                  <InfoOrganizacao />
                </Route>
                <Route path="/registration/profile/pilares-for-all/quest">
                  <CadastrosUserQuestionnaire />
                </Route>
                <Route path="/registration/profile/pilares-for-all">
                  <CadastroPilaresForAll />
                </Route>

                {/* <Route path="/registration/profile"><PerfilOrganizacional /></Route> */}
                <Route path="/registration">
                  <Cadastro />
                </Route>

                {window["runConfig"].REACT_APP_QUESTIONARIOS_TEMATICOS ===
                "active" ? (
                  <Route path="/tematicos">
                    <Tematicas />
                  </Route>
                ) : null}

                <Route path="/researches/certification">
                  <Certificacao />
                </Route>

                <Route path="/researches">
                  <Pesquisas />
                </Route>

                <Route path="/certificates">
                  <Certificados />
                </Route>

                <Route path="/certified-company">
                  <EmpresaCertificada />
                </Route>

                {showPage ? (
                  <Route path="/employees">
                    <Colaboradores />
                  </Route>
                ) : (
                  <Redirect from="/employees" to="/login" />
                )}

                <Route path="/results">
                  <Results />
                </Route>

                <Route path="/rankings">
                  <Rankings />
                </Route>

                {/* <Route path="/results/resume">
                  <Resume />
                </Route> */}
                <Route path="/results">
                  <Results />
                </Route>

                <Route path="/login">
                  <Login />
                </Route>

                <Route path="/dashboard">
                  <Home />
                </Route>

                <Route path="/register">
                  <Register />
                </Route>

                <Route path="/terms">
                  <Termos />
                </Route>

                <Route path="/*">
                  <Login />
                </Route>

                <Redirect from="*" to="/login" />
              </Switch>
            </showCollaboratorContext.Provider>
          </Router>
        </ChakraProvider>
      </CustomProvider>
    </QueryClientProvider>
  );
}

export default App;
