/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import apiForms from "../../services/api-forms";
import { useFormsStatus } from "./use-forms.js";
import { useQuery } from "react-query";
import {
  Stack,
  Heading,
  Tag,
  Text,
  Button,
  Flex,
  Link,
  Spinner,
  Image,
  useMediaQuery,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  keyframes,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import { researchApi } from "../../services/api-research";
import queryParamsPresent from "../rankings/get-params";
import {
  logo,
  iconAdd,
  iconAward,
  iconUser,
  iconWaiting,
  lineCard,
  pendingRed,
} from "../../assets/images/index";
import SimpleSidebar from "../components/nav/Sidebar";
import { getAllCookies, getCookie } from "../common/cookieManager";
import { dashboardApi as api } from "../../services/api-research";
import Forms from "./Forms";
import WelcomeCard from "./WelcomeCard";
import { useCompanyResearchs } from "../results/useResearchs";
import { useCompanyData } from "../../helpers/useCompanyInfo";
import axios from "axios";
import { TermsAgreementModal } from "./TermsAgreementModal";
import { useCertificados } from "./use-selos";
import {
  AuditingCertification,
  CertificationCurrentStatus,
  DeniedCertificate,
  ExpiredCertificate,
  NotScheduledCertificate,
  SuccessCertificate,
  WaitingFormsAnswers,
  WaitingResults,
} from "./resultCard";

import ReactGA from "react-ga";
import ModalProps from "../../style/modal/popup/index.js";
import { logRoles } from "@testing-library/react";
import NPS from "../components/nps/index.jsx";
import arrowUp from "../../assets/images/arrow-up.svg";

function Home() {
  const currentUser = getCookie("user-name");
  const [st, id, token] = queryParamsPresent();

  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const [company, setCompany] = useState();
  const [certificationStatus, setCertificationStatus] = useState();

  const [definitelyCertified, setDefinitelyCertified] = useState();
  const [certificateDenied, setCertificateDenied] = useState(false);
  const [auditingCertificate, setAuditingCertificate] = useState(false);
  const [certificationQuestionnaire, setCertificationQuestionnaire] =
    useState();

  const { data: research } = useCompanyResearchs(id);
  const { data: certificados } = useCertificados(company);
  const [plan, setPlan] = useState("");

  const [forms, setForms] = useState([]);
  const [formsStatus, setFormsStatus] = useState([]);
  const [formsInfo, setFormsInfo] = useState([]);
  const [researchInfo, setResearchInfo] = useState([]);
  const [researchNotScheduling, setResearchNotScheduling] = useState(false);
  const [formLink, setFormLink] = useState("");
  const [rankingFormLink, setRankingFormLink] = useState("");
  const [researchId, setResearchId] = useState();
  const [isGptwRole, setIsGptwRole] = useState(false);
  const [accEmail, setAccEmail] = useState("");

  const { data: formsResponse } = useFormsStatus(id);

  const fetchResearch = async () => {
    const res = await researchApi.get(`/research/certificated/${id}`);
    if (res) return res.data;
  };
  const { data: certificaded } = useQuery("is-certificaded", () =>
    fetchResearch()
  );
 

  const fetchResearchStatus = async () => {
    const res = await api.get(`certification/status/${id}`);
    if (res && res.data) {
      return res.data;
    }
  };
  const {
    data: registrationStatus,
    isError: registrationStatusError,
    isFetched: registrationStatusFetched,
  } = useQuery("registration-status", () => fetchResearchStatus(), {
    refetchOnWindowFocus: false,
  });
  const { data: companyData, isError: companyDataError } = useCompanyData(id);
  const [termsAgreementModal, setTermsAgreementModal] = useState(false);
  const [termsAgreementContent, setTermsAgreementContent] = useState();
  const [isLargerThan1380] = useMediaQuery("(min-width: 1380px)");
  const [isLargerThan980] = useMediaQuery("(min-height: 980px)");
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
  const [isSmallerThan768] = useMediaQuery("(max-width: 768px)");

  const [isOpen, setIsOpen] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const cr = urlParams.get("cr");
  //console.log(researchInfo, "researchInfo", cr, st);

  useEffect(() => {
    if (research && research.length > 0) {
      setResearchId(research[0].id);
    }
  }, [research]);

  useEffect(() => {
    ReactGA.pageview(
      window["runConfig"].REACT_APP_CONNECT_BASE_URL + window.location.search
    );
  }, []);

  useEffect(() => {}, [certificateDenied, definitelyCertified]);

  useEffect(() => {
    if (companyData) {
      setCompany(companyData.company);
    }

    const plano = getCookie("PLANO");
    if (plano !== "") {
      setPlan(plano);
    }
  }, [companyData, company]);

  useEffect(() => {
    const getRoles = () => {
      const roles = getCookie("roles");
      const isGptw =
        roles && roles.find((item) => item === "GPTW") ? true : false;
      if (isGptw) {
        setIsGptwRole(true);
      }
    };
    getRoles();
  }, [isGptwRole]);

  // // requisição que verifica o termo de uso
  useEffect(() => {
    async function getTermsAgreementStatus() {
      const email = getCookie("user_email");  
      setAccEmail(email);

      if (email && !isGptwRole) {
        const cnpj = company?.cnpj
          .replace(/\./g, "")
          .replace(/-/g, "")
          .replace(/\//, "");

        try {
          const res = await axios.get(
            `${window["runConfig"].REACT_APP_URL_RESULT_SERVICE}/participation/check-terms?dc=${cnpj}&user=${email}`
          );
          if (res && res.data && res.data.active === true) {
            setTermsAgreementContent(res?.data?.rulesInHtml);
            setTermsAgreementModal(true);
          } else {
            setTermsAgreementModal(false);
          }
        } catch (err) {
          console.log("Erro na requisição do termo", err);
        }
      }
    }
    if (company) {
      getTermsAgreementStatus();
    }
  }, [company]);

  useEffect(() => {
    if (researchInfo && researchInfo.researchState !== undefined) {
      if (
        researchInfo.applicationEndDate < Date.now() &&
        Date.now() < researchInfo.applicationEndDate + 172800000 &&
        researchInfo.status !== "APPROVED"
      ) {
        setAuditingCertificate(true);
      } else if (researchInfo.status === "AUDITING") {
        setAuditingCertificate(true);
      } else if (
        researchInfo.researchState.match("REPROVED") &&
        researchInfo.score !== 0
      ) {
        setStatus("denied");
        setCertificateDenied(true);
      } else if (researchInfo.status === "APPROVED") {
        setAuditingCertificate(false);
      }

      if (researchInfo.researchState === "STARTED") {
        setStatus("progress");
      } else if (
        researchInfo.researchState === "CREATED" &&
        researchInfo.applicationDate
      ) {
        setStatus("waiting");
      } else if (researchInfo.researchState === "CREATED") {
        setStatus("first");
      } else if (
        (researchInfo.score === 0 ||
          researchInfo.score === null ||
          researchInfo.score === undefined) &&
        researchInfo.applicationEndDate < Date.now()
      ) {
        setAuditingCertificate(true);
      }
    }
  }, [definitelyCertified, researchInfo]);

  useEffect(() => {
    async function getCurrentStatus() {
      const statusUpdated = {};
      try {
        const employeesTotal = await api.get(`employees/${id}`);
        if (employeesTotal && employeesTotal.data)
          statusUpdated.employees = employeesTotal.data.employees_quantity;

        const certificationsTotal = await api.get(
          `certification/quantity/${company.cnpj
            .replace(/\./g, "")
            .replace(/-/g, "")
            .replace(/\//, "")}`
        );
        if (certificationsTotal && certificationsTotal.data)
          statusUpdated.certifications =
            certificationsTotal.data.certification_quantity;

        setCertificationStatus(statusUpdated);
      } catch (err) {
        console.log("Erro na busca por id informações da pesquisa", err);
      }
    }

    if (id !== undefined && company) {
      getCurrentStatus();
    }
  }, [id, company, companyDataError]);

  useEffect(() => {
    if (certificaded !== undefined) {
      if (!!certificaded && researchInfo.researchState === "CERTIFICATED") {
        setDefinitelyCertified(true);
      } else {
        setDefinitelyCertified(false);
      }
    }
  }, [certificaded, researchInfo]);

  useEffect(() => {
    researchApi.get(`/research/researchInfo/${id}`).then((res) => {
      if (res.data) {
        setResearchInfo(res.data);
        // Calculo do prazo de 6 meses para agendamento
        if (res.data.applicationCreationDate !== undefined) {
          const today = new Date();
          let lockDate = new Date(res.data.applicationCreationDate);
          lockDate.setMonth(lockDate.getMonth() + 6);
          if (
            res.data.researchState === "CREATED" &&
            lockDate <= today &&
            !res.data.applicationDate &&
            !res.data.applicationEndDate
          ) {
            setResearchNotScheduling(true);
          }
        }
      }
    });
  }, []);
  useEffect(() => {
    function formDataFactory() {
      if (forms.length > 1 && formsStatus?.length > 0) {
        let transformedData = [];

        forms.forEach((formInfo) => {
          let responses = formsStatus.filter(
            (data, index) => formsStatus[index][0].form === formInfo.type
          );

          if (formInfo.nameVersion === "Questionário Certificação") {
            setFormLink(
              `/registration/profile/info?form=${formInfo.type}&cr=${id}&st=${st}`
            );
          }

          if (formInfo.nameVersion === "Questionário Ranking") {
            setRankingFormLink(
              `/registration/profile/info?form=${formInfo.type}&cr=${id}&st=${st}`
            );
          }

          transformedData.push({
            cor: "#EA42AE",
            titulo: formInfo.nameVersion,
            status: { concluido: 3, pendentes: 2 },
            message: { value: st },
            link: {
              url: `/registration/profile/info?form=${formInfo.type}&cr=${id}&st=${st}`,
              texto: (
                <span>
                  {/* <b>Ver</b> cadastros */}
                  {st === "true" ? <b>Responder</b> : <b>Visualizar</b>}
                </span>
              ),
            },
            progress: {
              inicial: `${responses[0][0].totalAnswered}`,
              final: `${responses[0][0].totalQuestions}`,
            },
            iconePlay: false,
            iconeAlerta: true,
            alerta: {
              texto:
                responses[0][0].allRequiredAnswered === true ? (
                  <span>
                    Parabéns! Você <b>respondeu</b> todas as questões
                    obrigatórias.
                  </span>
                ) : (
                  <span>
                    Você ainda tem <b>questões</b> que precisam ser{" "}
                    <b>preenchidas</b>.
                  </span>
                ),
              estado:
                responses[0][0].totalAnswered ===
                responses[0][0].totalQuestionstrue
                  ? false
                  : true,
              todasRespondidas: responses[0][0].allRequiredAnswered,
            },
            order: formInfo.order,
          });
        });
        setFormsInfo(transformedData);
      }
    }

    formDataFactory();
  }, [forms, formsStatus]);

  useEffect(() => {
    if (formsResponse) {
      setFormsStatus(formsResponse.data);
      formsResponse.data.forEach((forms) => {
        const questionnaire = forms.find((f) => f.form === "CB");
        if (!!questionnaire && questionnaire?.allRequiredAnswered) {
          setCertificationQuestionnaire(true);
        }
      });
    }
  }, [formsResponse]);

  useEffect(() => {
    async function getForms() {
      setLoading(true);
      try {
        const response = await apiForms.get(`/api/form-questions`);
        if (response && response.data) setForms(response.data.data);
      } catch (err) {
        console.log("Err", err);
      }
    }

    getForms();
  }, [id]);

  useEffect(() => {
    if (
      definitelyCertified !== undefined &&
      formsInfo?.length > 0 &&
      registrationStatusFetched &&
      researchInfo.researchState !== undefined
    ) {
      if (status !== "" && certificationStatus) {
        setLoading(false);
      }

      if (definitelyCertified === true && research !== undefined) {
        setLoading(false);
      }
      if (definitelyCertified === true && status === "") {
        setStatus("certificaded");
        setLoading(false);
      }
      if (status === "" && researchInfo.researchState === "EXPIRED") {
        setStatus("expired");
        setLoading(false);
      }

      if (auditingCertificate) {
        setLoading(false);
      }

      if (researchInfo) {
        setLoading(false);
      }
    }
  }, [
    status,
    certificationStatus,
    formsInfo,
    definitelyCertified,
    research,
    registrationStatusFetched,
    researchInfo,
  ]);

  const handleTermsAgreementModal = () => {
    setTermsAgreementModal(false);
  };

  return (
    <SimpleSidebar path="">
      {loading ? (
        <Flex height="400px" justify={"center"} align="center">
          <Spinner />
        </Flex>
      ) : (
        <Flex
          gap={4}
          p={["0", "16px"]}
          direction={["column", "column", "column", "column", "row"]}
        >
        <TermsAgreementModal
            company={company}
            content={termsAgreementContent}
            isOpen={termsAgreementModal}
            onClose={handleTermsAgreementModal}
          />
          {!isGptwRole && !termsAgreementModal && <ModalProps />}
          <Stack width="100%" direction={"column"} align={"center"}>
            <Flex
              w={"100%"}
              flexDir="column"
              align={"center"}
              justifyContent={"center"}
              mt={["0px"]}
              gap={["32px", "35px"]}
            >
              <WelcomeCard
                currentUser={currentUser}
                backgroundActive={status === "progress"}
              />

              <Flex
                w={"100%"}
                h={["auto", "131px"]}
                flexDir="column"
                justifyContent={"space-between"}
                gap={["20px", "20px", "unset"]}
                mb={["0px", "16epx"]}
              >
                <Heading as="h3" fontSize="18px" lineHeight={["30px", "21px"]}>
                  Resumo da jornada
                </Heading>
                <Flex
                  h={["160px", "160px", "86px"]}
                  gap={["8px", "8px", 4]}
                  justify={["none", "start", "start", "start"]}
                  color="white"
                >
                  <Flex
                    minW={["103px", "121px", "235px"]}
                    w={["116px", "180px", "235px"]}
                    minH={"86px"}
                    h={"fit-content"}
                    bg="#002070"
                    borderRadius="12px"
                    p={["12px 14px", "12px 14px", "12.5px 16px"]}
                    justify="space-between"
                    direction={["column", "row", "row", "row"]}
                    gap="8px"
                  >
                    <Stack
                      spacing={["12px", "12px", "12px", "7.5px"]}
                      align="start"
                    >
                      <img src={iconUser} alt="icon user" />

                      <Text
                        fontSize={["12px", "13px"]}
                        lineHeight={["20px", "19px"]}
                        fontWeight={"200"}
                      >
                        Funcionários
                        <br />
                        <b>Cadastrados</b>
                      </Text>
                    </Stack>
                    <Stack
                      spacing={["12px", "12px", "7.5px"]}
                      align={["center", "center", "start", "end"]}
                    >
                      <Link
                        href={`/employees?cr=${id}`}
                        fontSize="12px"
                        fontWeight="100"
                        textDecor="underline"
                        display={["none", "initial", "initial", "initial"]}
                      >
                        Ver todos
                      </Link>

                      <Tag
                        bg="#2CD9DD"
                        color="#002070"
                        fontWeight="700"
                        borderRadius="8px"
                        justifyContent={"center"}
                        w={["100%", "42px", "100%", "64px"]}
                        h="40px"
                      >
                        <Text>{researchInfo?.employeesQuantity}</Text>
                      </Tag>
                    </Stack>
                  </Flex>

                  <Flex
                    minW={["103px", "121px", "235px"]}
                    w={["116px", "180px", "235px"]}
                    minH={"86px"}
                    h={"fit-content"}
                    bg="#002070"
                    borderRadius="12px"
                    p={["12px", "12px 14px", "12.5px 16px"]}
                    justify="space-between"
                    direction={["column", "row", "row", "row"]}
                    gap={"8px"}
                  >
                    <Stack spacing={["12px", "7.5px"]} align="start">
                      <img src={iconAward} alt="icon award" />

                      <Text
                        color="white"
                        fontSize={["12px", "13px"]}
                        lineHeight={["20px", "19px"]}
                        fontWeight={"200"}
                      >
                        Certificações
                        <br />
                        <b>Conquistadas</b>
                      </Text>
                    </Stack>
                    <Stack
                      spacing={["12px", "12px", "7.5px"]}
                      align={["center", "center", "start", "end"]}
                    >
                      <Link
                        href={`/employees?cr=${id}`}
                        fontSize="12px"
                        fontWeight="100"
                        textDecor="underline"
                        display={["none", "initial", "initial", "initial"]}
                      >
                        Ver todos
                      </Link>

                      <Tag
                        bg="#FFB81A"
                        color="#002070"
                        fontWeight="700"
                        borderRadius="8px"
                        justifyContent={"center"}
                        w={["100%", "42px", "100%", "64px"]}
                        h="40px"
                      >
                        <Text>{certificationStatus?.certifications}</Text>
                      </Tag>
                    </Stack>
                  </Flex>
                </Flex>
              </Flex>
              <Stack
                w={"100%"}
                h={["auto", "auto", "auto", "auto", "249px"]}
                spacing="24px"
                justifyContent={"space-between"}
              >
                <Flex justify="space-between" w={"100%"}>
                  <Heading as="h3" fontSize="18px" lineHeight="21px">
                    Questionários
                  </Heading>

                  <Link
                    href={`/registration/profile?cr=${id}&st=${st}&token=${token}`}
                    fontWeight={500}
                    fontSize="14px"
                    color="#E60000"
                    textDecoration="underline"
                  >
                    Ver todos os questionários
                  </Link>
                </Flex>

                <Forms formsInfo={formsInfo} />
              </Stack>
              <Stack
                h={["auto", "auto", "auto", "auto", "160px"]}
                w={"100%"}
                spacing="24px"
              >
                <Heading as="h3" fontSize="18px" lineHeight="21px">
                  Rankings
                </Heading>
                <Flex
                  bg="white"
                  direction={["column", "column", "row", "row"]}
                  justify="space-between"
                  align="center"
                  gap={["24px", "40px"]}
                  h={["auto", "auto", "auto", "auto"]}
                  p={["24px", "24px 36px 24px"]}
                  borderRadius="12px"
                >
                  <Flex
                    gap={["5px", "12px"]}
                    height={["93px"]}
                    align="center"
                    width="100%"
                  >
                    <img
                      src={logo}
                      alt="logo certificado"
                      style={{
                        width: "64px",
                      }}
                    />

                    <Flex
                      align={"flex-start"}
                      h={["auto", "auto", "auto", "auto"]}
                      justify={"center"}
                    >
                      <Image src={iconAdd} alt="icon add" mt="10px" />
                      <Text
                        minW={"200px"}
                        fontSize={isLargerThan1380 ? "14px" : "12px"}
                        lineHeight="19px"
                        ml={["6px", "10px"]}
                      >
                        <b> 40 rankings disponíveis em várias categorias.</b>
                        <br />
                        Após conquistar o selo de Certificação, sua empresa
                        poderá se inscrever para concorrer aos Rankings
                        elegíveis!
                      </Text>
                    </Flex>
                  </Flex>
                  <Button
                    as="a"
                    href={"https://gptw.com.br/ranking/calendario-de-rankings/"}
                    h={["56px", "48px"]}
                    w={["100%", "201px"]}
                    minW={["181px"]}
                    color="red.100"
                    border="1px solid #E60000"
                    borderRadius="10px"
                    fontWeight="600"
                    fontSize="14px"
                    bgColor={"transparent"}
                  >
                    Ver todos os Rankings
                  </Button>
                </Flex>
              </Stack>
            </Flex>
          </Stack>
          {isLargerThan1280 ? (
            <Stack
              width="35%"
              bg="white"
              borderRadius="12px"
              p="28px"
              flexDir="column"
              spacing="16px"
            >
              <Flex justify={"space-between"}>
                <Heading as="h3" fontSize="18px" lineHeight="21px">
                  Certificação - {researchInfo.salesforceParticipationCode}
                </Heading>
              </Flex>
              certificado
              {company && company.isProject ? (
                auditingCertificate ? (
                  <AuditingCertification props={{ id }} />
                ) : certificateDenied ? (
                  <DeniedCertificate
                    props={{ id, pendingRed, lineCard, researchInfo }}
                  />
                ) : researchNotScheduling ? (
                  <NotScheduledCertificate props={{ pendingRed }} />
                ) : researchInfo.researchState === "EXPIRED" ? (
                  <ExpiredCertificate props={{ company, research, id }} />
                ) : researchInfo.score === 0 ? (
                  <WaitingResults props={{ id }} />
                ) : researchInfo.score &&
                  researchInfo.score !== 0 &&
                  formsInfo?.length > 0 &&
                  !formsInfo.find((forms) => {
                    return forms.titulo === "Questionário Certificação";
                  }).alerta.todasRespondidas ? (
                  <WaitingFormsAnswers
                    props={{ pendingRed, formLink, rankingFormLink }}
                  />
                ) : definitelyCertified === true &&
                  certificateDenied === false &&
                  researchInfo.researchState !== "EXPIRED" &&
                  !certificateDenied ? (
                  <SuccessCertificate
                    props={{ company, research, id, pendingRed }}
                  />
                ) : (
                  <CertificationCurrentStatus
                    props={{
                      research,
                      researchId,
                      status,
                      id,
                      iconWaiting,
                      lineCard,
                      plan,
                      registrationStatusFetched,
                      certificationQuestionnaire,
                      registrationStatus,
                      registrationStatusError,
                    }}
                  />
                )
              ) : auditingCertificate ? (
                <AuditingCertification props={{ id }} />
              ) : definitelyCertified === true &&
                certificateDenied === false &&
                researchInfo.researchState !== "EXPIRED" &&
                !certificateDenied ? (
                <>
                  <NPS companyRegister={company} email={accEmail}/>
                  <SuccessCertificate
                    props={{ company, research, id, pendingRed }}
                  />
                </>
              ) : certificateDenied ? (
                <DeniedCertificate
                  props={{ id, pendingRed, lineCard, researchInfo }}
                />
              ) : researchNotScheduling ? (
                <NotScheduledCertificate props={{ pendingRed }} />
              ) : researchInfo.researchState === "EXPIRED" ? (
                <ExpiredCertificate props={{ company, research, id }} />
              ) : (
                <CertificationCurrentStatus
                  props={{
                    research,
                    researchId,
                    status,
                    id,
                    iconWaiting,
                    lineCard,
                    plan,
                    registrationStatusFetched,
                    certificationQuestionnaire,
                    registrationStatus,
                    registrationStatusError,
                  }}
                />
              )}
            </Stack>
          ) : (
            <>
              {isLargerThan980 ? (
                <Button
                  pos={"fixed"}
                  bottom={0}
                  w={["100vw"]}
                  fontSize={"14px"}
                  fontWeight={700}
                  ml={["-24px", "-46px"]}
                  borderRadius={"20px 20px 0px 0px"}
                  bg={"#E60000"}
                  color={"#FFFFFF"}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Acompanhar certificação{" "}
                  {<Image ml={"8px"} w={"24px"} src={arrowUp} />}
                </Button>
              ) : (
                <Button
                  w={["100vw"]}
                  mt={"25px"}
                  fontSize={"14px"}
                  fontWeight={700}
                  ml={["-24px", "-46px"]}
                  mb={["-24px", "-40px"]}
                  borderRadius={"20px 20px 0px 0px"}
                  bg={"#E60000"}
                  color={"#FFFFFF"}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Acompanhar certificação{" "}
                  {<Image ml={"8px"} w={"24px"} src={arrowUp} />}
                </Button>
              )}
            </>
          )}
        </Flex>
      )}
      <Drawer
        placement={"bottom"}
        onClose={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
      >
        <DrawerOverlay />
        <DrawerContent h={"82%"} p={0} zIndex={2}>
          <Button
            w={["100%"]}
            mt={"-32px"}
            fontSize={"14px"}
            fontWeight={700}
            borderRadius={"20px 20px 0px 0px"}
            bg={"#E60000"}
            color={"#FFFFFF"}
            onClick={() => setIsOpen(!isOpen)}
          >
            Acompanhar certificação{" "}
            {
              <Image
                ml={"8px"}
                w={"24px"}
                src={arrowUp}
                sx={{
                  transform: "rotate(180deg)",
                }}
              />
            }
          </Button>
          <DrawerBody p={0}>
            <Stack
              width="100%"
              bg="white"
              borderRadius="12px"
              p="24px"
              direction="column"
              spacing="16px"
            >
              <Flex w={"100%"} justify={"space-between"}>
                {researchInfo.isNewRegister ||
                (researchInfo.isNewRegister === false &&
                  researchInfo.researchState !== "EXPIRED") ||
                researchInfo.countdownFinish > 0 ? (
                  <Text fontSize={["15px", "18px"]} fontWeight={700}>
                    Certificação
                  </Text>
                ) : (
                  <></>
                )}{" "}
                {researchInfo.isNewRegister ||
                (researchInfo.isNewRegister === false &&
                  researchInfo.researchState !== "EXPIRED") ||
                researchInfo.countdownFinish > 0 ? (
                  <Link
                    fontSize={["11px", "14px"]}
                    fontWeight={500}
                    color={"#E60000"}
                    textDecor={"underline"}
                  >
                    Ver todas as pesquisas
                  </Link>
                ) : (
                  <></>
                )}
              </Flex>
              {company && company.isProject ? (
                auditingCertificate ? (
                  <AuditingCertification props={{ id }} />
                ) : certificateDenied ? (
                  <DeniedCertificate
                    props={{ id, pendingRed, lineCard, researchInfo }}
                  />
                ) : researchNotScheduling ? (
                  <NotScheduledCertificate props={{ pendingRed }} />
                ) : researchInfo.researchState === "EXPIRED" ? (
                  <ExpiredCertificate props={{ company, research, id }} />
                ) : researchInfo.score === 0 ? (
                  <WaitingResults props={{ id }} />
                ) : researchInfo.score &&
                  researchInfo.score !== 0 &&
                  formsInfo?.length > 0 &&
                  !formsInfo.find((forms) => {
                    return forms.titulo === "Questionário Certificação";
                  }).alerta.todasRespondidas ? (
                  <WaitingFormsAnswers
                    props={{ pendingRed, formLink, rankingFormLink }}
                  />
                ) : definitelyCertified === true &&
                  certificateDenied === false &&
                  researchInfo.researchState !== "EXPIRED" &&
                  !certificateDenied ? (
                  <SuccessCertificate
                    props={{ company, research, id, pendingRed }}
                  />
                ) : (
                  <CertificationCurrentStatus
                    props={{
                      research,
                      researchId,
                      status,
                      id,
                      iconWaiting,
                      lineCard,
                      plan,
                      registrationStatusFetched,
                      certificationQuestionnaire,
                      registrationStatus,
                      registrationStatusError,
                    }}
                  />
                )
              ) : auditingCertificate ? (
                <AuditingCertification props={{ id }} />
              ) : definitelyCertified === true &&
                certificateDenied === false &&
                researchInfo.researchState !== "EXPIRED" &&
                !certificateDenied ? (
                <>
                  <NPS companyRegister={company} email={accEmail}/>
                  <SuccessCertificate
                    props={{ company, research, id, pendingRed }}
                  />
                </>
              ) : certificateDenied ? (
                <DeniedCertificate
                  props={{ id, pendingRed, lineCard, researchInfo }}
                />
              ) : researchNotScheduling ? (
                <NotScheduledCertificate props={{ pendingRed }} />
              ) : researchInfo.researchState === "EXPIRED" ? (
                <ExpiredCertificate props={{ company, research, id }} />
              ) : (
                <CertificationCurrentStatus
                  props={{
                    research,
                    researchId,
                    status,
                    id,
                    iconWaiting,
                    lineCard,
                    plan,
                    registrationStatusFetched,
                    certificationQuestionnaire,
                    registrationStatus,
                    registrationStatusError,
                  }}
                />
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </SimpleSidebar>
  );
}
export default Home;
