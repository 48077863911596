import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import unchecked from './unchecked.svg'
import checked from './checked.svg'
import { Icon  } from '../../../style'


const DivContent = styled.div`
	display: flex;
	flex-direction: row;
	cursor: pointer;
	vertical-align: bottom;
  	max-width: 260px;
	align-items: center;
	justify-content: center;
`

function Checkbox(props){
	const [imagem, setImagem ] = useState(unchecked);

	useEffect (() => {
		if (props.value === "checked") {
			setImagem(checked)
		} else {
			setImagem(unchecked)
		}
	}, [props.value])

	function handleImagem(){
		if(imagem === unchecked){
			setImagem(checked)
		}
		else{
			setImagem(unchecked)
		}
	}

	return (
		<div {...props} >
			<DivContent onClick={()=>{handleImagem()}}>
				<Icon 	src={imagem} />

				{props.label}
			</DivContent>
		</div>
	);
}

export default Checkbox
