import React from "react"
import styled from "styled-components"

import SimpleSidebar from "../components/nav/Sidebar"
import Certificacoes from "./certificacoes"
import iconDownload from "../../assets/images/icon-download.svg"

import {
  Heading,
  Container,
  Link,
  Flex,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react"
import Rankings from "./rankings"
import ReactGA from 'react-ga';

const Certificados = () => {
  React.useEffect(() => {
    ReactGA.pageview(window["runConfig"].REACT_APP_CONNECT_BASE_URL + window.location.search);
  }, []);

  return (
    <SimpleSidebar>
      <Container maxW="container.xl">
        {/* <Card hasCloseButton /> */}
        <Heading as="h3" fontSize={"18px"} mt="30px">
          Meus certificados
        </Heading>

        <Tabs variant="unstyled">
          <Flex justifyContent="space-between" mt="25px" h="27px" mb="35px">
            <TabList>
              <Tab
                _selected={{
                  fontWeight: "bold",
                  borderBottom: "4px solid #E60000",
                }}
              >
                Certificações
              </Tab>
              <Tab
                _selected={{
                  fontWeight: "bold",
                  borderBottom: "4px solid #E60000",
                }}
              >
                Rankings
              </Tab>
            </TabList>

            <Link
              fontSize="16px"
              fontWeight="600"
              w="auto"
              href="https://d335luupugsy2.cloudfront.net/cms%2Ffiles%2F2705%2F1631109072Novo_E-book_Fui_Certificada_e_agora.pdf"
              leftIcon={<img src={iconDownload} alt="icon download" />}
              color="#E60000"
              isExternal={true}
              variant="ghost"
            >
              <Box display="flex" flex="1">
                <img src={iconDownload} alt="icon download" />
                &nbsp; Baixar guia da empresa certificada
              </Box>
            </Link>
          </Flex>
          <TabPanels>
            <TabPanel>
              <Certificacoes />
            </TabPanel>
            <TabPanel>
              <Rankings />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </SimpleSidebar>
  )
}

export default Certificados