/* eslint-disable react-hooks/rules-of-hooks */
import api from "./api";

export default async function updateInvoiceDueDateService(newDate, document) {
    const body = {
        document,
        newDate
    }

    return await api.post(`/signature?secret=7F96231D019091DC6DFCDC5086A1EAA8`, body)
    .then(response => {
        console.log(JSON.stringify('Resposta updateInvoiceDueDateService ' + response.data));
    });
}




