import React, { useEffect, useState, useContext } from "react";
import { CSVLink } from "react-csv";

import getUrlParams from "../../../services/getUrlParams";
import { useResearch } from "../../../helpers/useResearch";

import iconPendency from "../../../assets/images/icon-pendency.svg";
import iconBar from "../../../assets/images/icon-bar.svg";
import iconSuccess from "../../../assets/images/icon-success.svg";
import iconWarning from "../../../assets/images/icon-warning.svg";
import backBlue from "../../../assets/images/back-blue.svg";
import certificationLogo from "../../../assets/images/icon-logo-certificacao.svg";
import bgFull from "../../../assets/images/bg-card-mobile.svg";

import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Tag,
  Text,
  Button,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Image,
  Spinner,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useMediaQuery,
} from "@chakra-ui/react";

import UploadSheetModal from "./Modals/UploadSheet";
import { UpgradePlan } from "./Modals/upgradePlan";
import CardForm from "./CardForm";
import SimpleSidebar from "../../components/nav/Sidebar";
import LanguageData from "./LanguageData";
import ResearchDate from "./ResearchDate";
import Steps from "./Steps";
import SpecificQuestions from "./SpecifQuestions";
import Affirmatives from "./Affirmatives";

import { researchApi } from "../../../services/api-research";
import { CustomContext } from "../../../context/CustomContext";
import Comunication from "./Comunication";
import Breadcrumbs from "../../components/breadcrumbs";

const makeId = () => {
  let id = "";

  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let index = 0; index < 7; index++) {
    id += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return id;
};

const ResearchCertificates = () => {
  const { document: doc } = getUrlParams("");
  const companyId = doc[0];
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const pd = urlParams.get("pd");
  const token = btoa(companyId);

  const [companyResearch, setCompanyResearch] = useState();
  const [mobile] = useMediaQuery("(max-width: 1090px)");
  const [smallThan1360] = useMediaQuery("(max-width: 1370px)");

  const [allStepsComplete, setAllStepsCompletes] = useState(false);
  const [creatingRegistration, setCreatingRegistration] = useState(false);
  const [researchId, setResearchId] = useState();
  const [researchInfo, setResearchInfo] = useState();
  const { custom, setId } = useContext(CustomContext);

  const canCustomize = pd.includes("PREMIUM");
  const [uploadModal, setUploadModal] = useState(false);

  const [upgradeModal, setUpgradeModal] = useState(false);
  const [planosDisponiveis, setPlanosDisponiveis] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [vindiSignatureId, setVindiSignatureId] = useState(null);
  const [faixaPlano, setFaixaPlano] = useState(null);
  const [importRespondentIsRunning, setImportRespondentIsRunning] = useState(true);

  const handleGetResearch = async () => {
    try {
      const response = await researchApi.get(
        `/research?id_company_register=${companyId}`
      );
      
      if (response.data.length > 0) {
        setCompanyResearch(response.data[0]);
        setId(response.data[0].id);
        setResearchId(response.data[0].id);
        handleDownloadList(response.data[0].id);

      } else {
        setCreatingRegistration(true);

        const values = {
          status: "Active",
          salesforce_participation_code: makeId(),
          id_company_register: companyId,
          plan: pd,
        };

        const res = await researchApi.post(`/research`, {
          ...values,
        });
        if (!!res.data) {
          console.log("Pesquisa criada", res);
          setId(res.data.id);
          setResearchId(res.data.id);
          setCreatingRegistration(false);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };


  useEffect(() => {
    handleGetResearch();
  }, []);


  const handleResearchInfo = async () => {
    try {
      const response = await researchApi.get(
        `/research/researchInfo/${companyId}`
      )
      if (response.data) {
        setResearchInfo(response.data)        
      }

    } catch (error) {
      console.log("error", error);
    }
  }

  const setFollowStatusImportRespondents = () => {
    setImportRespondentIsRunning(true);
  }

  const followStatusImportRespondents = async (interval) => {
    try {

      if(researchId){

        const response = await researchApi.get(
          `/research/status-upload-respondent/${researchId}`
        )
  
        if (response.status===200) {
          clearInterval(interval);
          setImportRespondentIsRunning(false)
        }
      }

    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      followStatusImportRespondents(interval);
    }, 2000);
  },[importRespondentIsRunning]);  

  useEffect(() => {
    handleResearchInfo()
  },[]);  

  useEffect(() => {
    const interval = setInterval(() => {
      followStatusImportRespondents(interval);
    }, 2000);
    return () => clearInterval(interval);
  }, [researchId]);

  
  const { data } = useResearch(!!researchId ? researchId : undefined);
  // console.log("researchId", !!researchId);
  const breadcumbs = [
    {
      nome: "Pesquisas",
      tipo: "link",
      path: `/researches?cr=${companyId}&st=true&token=${token}`,
      icone: "",
      ativado: false,
    },
    {
      nome: "Pesquisa de Clima",
      tipo: "link",
      link: queryString,
      icone: "",
      ativado: true,
    },
  ];

  const handleUpload = () => {
    setImportRespondentIsRunning(true);
    setUploadModal(true);
  };

  const handleCloseUpload = () => {
    handleGetResearch();
    setImportRespondentIsRunning(true);
    setUploadModal(false);
  };

  async function getTemplate() {
    fetch(
      `https://gptwcertification.s3.us-west-2.amazonaws.com/templates/Planilha_de_Funcionarios.xlsx`,
      {
        method: "GET",
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Planilha_de_Funcionarios.xlsx`);

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  }

  const [csvData, setCsvData] = useState([]);

  async function handleDownloadList(research_id) {
    const r = researchId !== undefined ? researchId : research_id;
    const response = await researchApi.get(`export/${r}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });

    const csvData = response.data;
    setCsvData(csvData);
  }

  useEffect(() => {
    async function checkPlanUpdate() {
      if (data !== undefined && data.research.plan !== pd) {
        const updateValues = {
          ...data.research,
          plan: pd,
        };

        const response = await researchApi.put(`/research/${researchId}`, {
          ...updateValues,
        });
      }
    }
    checkPlanUpdate();
  }, [data]);

  const openUpgradeModal = async (
    planos,
    customerId,
    oldProductId,
    signatureId,
    faixa
  ) => {
    setPlanosDisponiveis(planos);
    console.log(planosDisponiveis);
    console.log(planos);
    setCustomerId(customerId);
    setCurrentProductId(oldProductId);
    setVindiSignatureId(signatureId);
    setFaixaPlano(faixa);
    setUpgradeModal(true);
  };

  const closeUpgradeModal = () => {
    setUpgradeModal(false);
  };

  return (
    <SimpleSidebar>
      {data === undefined && creatingRegistration === false ? (
        <Box w="100%" textAlign="center" h="30vh" pt="20px">
          <Spinner />
        </Box>
      ) : creatingRegistration === true ? (
        <Box w="100%" textAlign="center" h="30vh" pt="20px">
          <Text fontWeight={500}>
            {" "}
            Estamos cadastrando sua Pesquisa de Clima
          </Text>
          <Spinner />
        </Box>
      ) : (
        <Container
          maxW="container.xl"
          pt={[0, 4]}
          pb={4}
          px={[0, smallThan1360 ? "16px" : "20px"]}
        >
          <Stack spacing={["24px", "20px"]}>
            <Flex justifyContent="space-between">
              <Breadcrumbs breadcumbsItems={breadcumbs} />

              <Flex
                h="20px"
                alignItems="center"
                gap="8px"
                display={["none", "flex"]}
              >
                <Text fontSize="14px">Status</Text>
                <Text
                  fontSize="14px"
                  color={
                    data.research.status === "Aprovada" ? "#40BF91" : "#FF6700"
                  }
                  fontWeight="600"
                >
                  {data.research.status}
                </Text>
                <Image src={iconPendency} alt="pendente" />
              </Flex>
            </Flex>

            <Grid
              display={{ base: "none", md: "none", lg: "grid", xl: "grid" }}
              templateColumns="repeat(4, 1fr)"
              gap={4}
              h="148px"
              bg="white"
              borderRadius={"12px"}
              overflow="hidden"
              border="1px solid #CCCCCC"
            >
              <GridItem
                h="100%"
                colSpan={1}
                w="350px"
                backgroundRepeat={"no-repeat"}
                backgroundImage={backBlue}
                backgroundSize="fill"
                pl="32px"
                pt="55px"
              >
                <Tag
                  bg="#40BF91"
                  color="white"
                  fontSize="14px"
                  lineHeight="16px"
                  h="24px"
                  w="96px"
                >
                  Certificação
                </Tag>
                <Heading
                  as="h3"
                  color="white"
                  fontSize="18px"
                  lineHeight="21px"
                  my="10px"
                >
                  Rumo a certificação!
                </Heading>
              </GridItem>

              <GridItem
                colSpan={3}
                h="100%"
                p={["20px", "14px 20px 16px 20px"]}
              >
                {data.research.status !== "Aprovada" ? (
                  <Steps
                    researchId={researchId}
                    cr={companyId}
                    setCompleted={setAllStepsCompletes}
                  />
                ) : (
                  <Flex gap="36px">
                    <Image
                      src={certificationLogo}
                      alt="Logo certificação"
                      maxH="116px"
                    />
                    <Box>
                      <Text fontSize="15px" lineHeight="22.4px">
                        Parabéns!
                      </Text>
                      <Text
                        fontSize="15px"
                        fontWeight="600"
                        lineHeight="20.4px"
                        mt="5px"
                      >
                        Sua empresa
                        <br />
                        foi certificada!
                      </Text>

                      <Tag
                        fontSize="14px"
                        mt="10px"
                        bg="rgba(0, 32, 112, 0.1)"
                        borderRadius="6px"
                        color="#002070"
                        h="16px"
                        fontWeight="600"
                      >
                        Nota: 92
                      </Tag>
                    </Box>

                    <Box fontSize="13px" w="250px" color="#292B30" ml="20px">
                      <Text mb="10px" fontSize="13px" lineHeight="18.2px">
                        Agora que você conquistou a
                        <br />
                        <b>
                          Certificação GPTW, é hora de concorrer aos nossos
                          rankings!
                        </b>
                      </Text>
                      <Text fontSize="13px" lineHeight="18.2px">
                        Confira os
                        <Text
                          as="span"
                          color="red.100"
                          fontWeight="600"
                          textDecor="underline"
                          ml="4px"
                        >
                          Rankings disponíveis aqui
                        </Text>
                      </Text>
                    </Box>
                  </Flex>
                )}
              </GridItem>
            </Grid>

            <Accordion
              defaultIndex={[0]}
              allowMultiple
              display={{
                base: "initial",
                md: "initial",
                lg: "none",
                xl: "none",
              }}
              // display={["initial", "none"]}
            >
              <AccordionItem borderRadius={"12px"} border="1px solid #CCCCCC">
                <Stack
                  borderRadius={"12px"}
                  h="120px"
                  p={["32px 24px 32px 24px"]}
                  backgroundRepeat={"no-repeat"}
                  backgroundImage={bgFull}
                  backgroundSize="cover"
                  bgPosition={"right"}
                  position={"relative"}
                >
                  <Tag
                    bg="#40BF91"
                    color="white"
                    fontSize="14px"
                    lineHeight="16px"
                    h="24px"
                    w="96px"
                  >
                    Certificação
                  </Tag>
                  <Heading
                    as="h3"
                    color="white"
                    fontSize="18px"
                    lineHeight="21px"
                  >
                    Rumo a certificação!
                  </Heading>
                  <AccordionButton
                    position={"absolute"}
                    bottom="0"
                    transform={"translateX(30%)"}
                    color="white"
                  >
                    <AccordionIcon />
                  </AccordionButton>
                </Stack>

                <AccordionPanel pb={4}>
                  <Steps
                    researchId={researchId}
                    cr={companyId}
                    setCompleted={setAllStepsCompletes}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <Box>
              <Tabs variant="unstyled">
                <TabList gap={["16px", "40px"]}>
                  <Tab
                    whiteSpace="nowrap"
                    padding={["10px 0 10px 0", "10px 0px 12px 0"]}
                    fontSize={["14px", "16px"]}
                    textAlign={["left", " center"]}
                    fontWeight="500"
                    _selected={{
                      fontWeight: "bold",
                      borderBottom: mobile
                        ? "3px solid #E60000"
                        : "4px solid #E60000",
                    }}
                  >
                    {mobile
                      ? "Participar da pesquisa"
                      : "Etapas para participar da Pesquisa de Clima"}
                  </Tab>
                  <Tab
                    whiteSpace="nowrap"
                    padding={[0, "10px 0 12px 0"]}
                    fontSize={["14px", "16px"]}
                    fontWeight="500"
                    textAlign={["left", " center"]}
                    _selected={{
                      fontWeight: "bold",
                      borderBottom: mobile
                        ? "3px solid #E60000"
                        : "4px solid #E60000",
                    }}
                  >
                    {mobile
                      ? "Visualizar resultados"
                      : "Etapas para visualizar os resultados"}
                  </Tab>
                </TabList>
                {allStepsComplete && (
                  <Flex
                    w="100%"
                    h={["auto", "auto", "36px"]}
                    bg="#FFCCCC"
                    mt="24px"
                    mb="10px"
                    borderRadius="10px"
                    alignItems="center"
                    justify="center"
                    gap="8px"
                    px="12px"
                    py={"6px"}
                  >
                    <Image src={iconWarning} alt="icon" />
                    <Text
                      fontSize={["13px", "14px"]}
                      textAlign={"center"}
                      color="red.100"
                    >
                      <b>Atenção!</b> Você poderá fazer quaisquer alterações até
                      2 dias antes do início da Pesquisa de Clima
                    </Text>
                  </Flex>
                )}

                {!custom && (
                  <Flex
                    w="100%"
                    h="36px"
                    bg="#FFCCCC"
                    mt="24px"
                    mb="10px"
                    borderRadius="10px"
                    alignItems="center"
                    justify="center"
                    gap="8px"
                  >
                    <Image src={iconWarning} alt="icon" />
                    <Text fontSize="14px" color="red.100" fontWeight="600">
                      Nenhuma alteração pode mais ser feita.
                    </Text>
                  </Flex>
                )}
                <TabPanels>
                  <TabPanel px={0}>
                    <Flex
                      gap={["20px", "12px", "20px"]}
                      h={["auto", "206px"]}
                      flexDir={["column", "row"]}
                      pt={["unset", "12px"]}
                    >
                      <CardForm researchId={researchId} cr={companyId} />
                      <Stack
                        align="start"
                        w={["100%", "50%", "100%"]}
                        h={["auto", "206px"]}
                        spacing={["24px", "10px", "24px"]}
                        border="1px solid #CCCCCC"
                        bg="white"
                        borderRadius={["12px", "20px"]}
                        position="relative"
                        p={["24px", "24px", "30px 20px 30px 30px"]}
                      >
                        <Box>
                          <img src={iconBar} alt="icon bar" />
                          <Text fontSize="16px" fontWeight="600" pt={1.5}>
                            Enviar planilha de colaboradores para seleção
                          </Text>
                        </Box>
                        <Image
                          src={iconPendency}
                          alt="icon"
                          position="absolute"
                          right="24px"
                          top="0px"
                        />
                        <Flex
                          h={["auto", "108px"]}
                          flexDir={["column-reverse", "column-reverse", "row"]}
                          align="center"
                          w="100%"
                          justifyContent={"space-between"}
                        >
                          <Flex
                            mt={["24px", "unset"]}
                            flexDir={["row", "row", "column"]}
                            w={["100%", "100%", "50%"]}
                            h={["48px", "36px", "auto"]}
                            gap={["20px", "12px", "14px"]}
                          >
                            <Button
                              variant="primary"
                              fontSize={["14px", "13px", "16px"]}
                              w={["100%", "265px"]}
                              onClick={getTemplate}
                            >
                              Baixar template
                            </Button>

                            {importRespondentIsRunning ? (
                            <Button
                            isLoading
                            loadingText='Importando respondentes'
                            colorScheme='teal'
                            variant='outline'
                            w={["100%", "265px"]}
                            >
                            
                            </Button>
                            ) : (
                              <Button
                              variant="secondary"
                              fontSize={["14px", "13px", "16px"]}
                              w={["100%", "265px"]}
                              color={!custom ? "#292B30" : "red.100"}
                              disabled={!custom}
                              borderColor={!custom ? "#292B30" : "red.100"}
                              _hover={{
                                bg: "#E60000",
                                color: "white",
                              }}
                              onClick={handleUpload}
                            >
                              {data.employees_sheet
                                ? "Atualizar planilha"
                                : "Enviar planilha"}
                            </Button>
                            )}
                            
                          </Flex>
                          {data.employees_sheet ? (
                            <Box
                              w={["100%", "371px"]}
                              border="1px solid #40BF91"
                              borderRadius="12px"
                              py="16px"
                              px="20px"
                              h="108px"
                              position="relative"
                            >
                              <Image
                                src={iconSuccess}
                                alt="success"
                                position="absolute"
                                right="20px"
                              />
                              <Text fontWeight="600" color="#292B30">
                                {companyResearch?.total_elegible_employees} colaboradores
                              </Text>

                              <Box
                                w="20px"
                                color="red.100"
                                h="2px"
                                mt="5px"
                                bg="red.100"
                              />
                              <Flex
                                h="16px"
                                mt="5px"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Text
                                  fontSize={["13px", "14px"]}
                                  color="#5C6066"
                                >
                                  {mobile
                                    ? "Selecionados para a pesquisa"
                                    : "Selecionados para a Pesquisa de Clima"}
                                </Text>

                                <Button
                                  bg="none"
                                  p={0}
                                  _hover={{
                                    bg: "none",
                                  }}
                                  fontSize="14px"
                                  color="#E60000"
                                  fontWeight="500"
                                  textDecor="underline"
                                  onClick={handleDownloadList}
                                >
                                  {" "}
                                  <CSVLink data={csvData}> Ver lista </CSVLink>
                                </Button>
                              </Flex>
                              <Flex
                                h="15px"
                                align={"center"}
                                gap="6px"
                                color="#5C6066"
                                mt="10px"
                              >
                                <Text fontSize={["13px", "14px"]}>
                                  Amostra mÍnima:
                                </Text>
                                <Text fontSize={"14px"} fontWeight="600">
                                  {data.research.minimum_sample}
                                </Text>
                              </Flex>
                            </Box>
                          ) : (
                            <Box
                              onClick={handleUpload}
                              cursor="pointer"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              border="1px dashed #40BF91"
                              w={["100%", "100%", "361px"]}
                              h={["67px", "40px", "100%"]}
                              borderRadius="10px"
                            >
                              <Text fontSize={"13px"}>
                                Envie a planilha de colaboradores
                              </Text>
                            </Box>
                          )}
                        </Flex>
                      </Stack>
                    </Flex>
                    {canCustomize && (
                      <Stack
                        bg="#ffffff"
                        mt="20px"
                        border="1px solid #CCCCCC"
                        borderRadius="12px"
                        spacing={["22px"]}
                        align="flex-start"
                        px={["0", "32px"]}
                        pb={["32px"]}
                      >
                        <Stack
                          align="flex-start"
                          spacing={"8px"}
                          px={["22px", "0px"]}
                          pt={["24px", "32px"]}
                        >
                          <img src={iconBar} alt="icon bar" />
                          <Heading
                            as="h4"
                            fontSize={"18px"}
                            fontWeight="600"
                            color="#292B30"
                          >
                            {mobile
                              ? "Personalização da pesquisa"
                              : "Personalização da Pesquisa de Clima"}
                          </Heading>
                        </Stack>

                        <Affirmatives researchId={researchId} importRespondentIsRunning={importRespondentIsRunning} totalRespondents={companyResearch?.total_elegible_employees} extern_researchId={companyResearch?.extern_researchId}/>
                        <LanguageData researchId={researchId} importRespondentIsRunning={importRespondentIsRunning} totalRespondents={companyResearch?.total_elegible_employees} extern_researchId={companyResearch?.extern_researchId}/>
                        <SpecificQuestions researchId={researchId} importRespondentIsRunning={importRespondentIsRunning} totalRespondents={companyResearch?.total_elegible_employees} extern_researchId={companyResearch?.extern_researchId}/>
                      </Stack>
                    )}
                    <Stack
                      w="100%"
                      spacing={["24px", "16px", "0"]}
                      h={["auto", "auto", "224px"]}
                      mt="20px"
                      direction={["column", "column", " row"]}
                    >
                      <ResearchDate researchId={researchId} importRespondentIsRunning={importRespondentIsRunning} totalRespondents={companyResearch?.total_elegible_employees} extern_researchId={companyResearch?.extern_researchId} onSubmitChangeDate={setFollowStatusImportRespondents}/>
                      <Comunication />
                    </Stack>
                    {upgradeModal &&
                    window["runConfig"].REACT_APP_UPGRADE_PLAN === "active" ? (
                      <UpgradePlan
                        close={closeUpgradeModal}
                        planos={planosDisponiveis}
                        customerId={customerId}
                        oldProductId={currentProductId}
                        signatureId={vindiSignatureId}
                        faixa={faixaPlano}
                        visible={upgradeModal}
                        cr={companyId}
                      />
                    ) : (
                      ""
                    )}
                    <UploadSheetModal
                      researchId={researchId}
                      isOpen={uploadModal}
                      onClose={handleCloseUpload}
                      handleDownloadList={handleDownloadList}
                      openUpgradeModal={openUpgradeModal}
                    />
                  </TabPanel>
                  <TabPanel></TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Stack>
        </Container>
      )}
    </SimpleSidebar>
  );
};

export default ResearchCertificates;
