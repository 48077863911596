import React, { useEffect, useState } from "react";
import { format, formatDistance } from "date-fns";
import { ptBR } from "date-fns/locale";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  Box,
  Text,
  Flex,
  Image,
  Heading,
  Stack,
  Divider,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";
import iconCheck from "../../assets/images/icon-check.svg";
import pending from "../../assets/images/pending-gray.svg";
import { researchApi } from "../../services/api-research";
import moment from "moment";

export default function Status({ done, initial, final }) {
  return (
    <Flex gap="12px" align="center">
      <Image src={done ? iconCheck : pending} alt="Status icon" />
      <Box>
        <Text fontSize={"14px"}>
          <Text as="span" fontWeight={700} mr="4px">
            {initial}
          </Text>
          {final}
        </Text>
      </Box>
    </Flex>
  );
}

export function Progress({ status }) {
  const values = Object.values(status);
  values[3] = values[3].ok;
  return (
    <Box p="24px" mt="10px" pos="relative" overflow={"hidden"}>
      <Divider color="#CCCCCC" pos="absolute" top={0} w="90%" />
      <Heading as="h3" fontSize={"14px"} lineHeight="20px" fontWeight={"600"}>
        Resumo das etapas concluídas
      </Heading>
      <Flex mt="20px" align="center" gap="12px">
        <Stack flex={1} align="center" justify="center" spacing="12.5px">
          <Box w="62px">
            <CircularProgressbar
              value={values.filter((v) => !!v).length}
              minValue={0}
              maxValue={4}
              text={values.filter((v) => !!v).length + "/" + 4}
              strokeWidth={12}
              styles={buildStyles({
                textSize: "20px",
                pathColor: "#40BF91",
                textColor: "#40BF91",
                trailColor: "#E5E5E5",
              })}
            />
          </Box>
          <Text fontSize={"13px"} textAlign="center">
            Etapas para <b> participar </b>
            <br />
            da pesquisa
          </Text>
        </Stack>
        <Stack flex={1} align="center" justify="center" spacing="12.5px">
          <Box w="62px">
            <CircularProgressbar
              value={1}
              minValue={0}
              maxValue={2}
              text={1 + "/" + 2}
              strokeWidth={12}
              styles={buildStyles({
                textSize: "20px",
                pathColor: `#174BD6`,
                textColor: "#174BD6",
                trailColor: "#E5E5E5",
              })}
            />
          </Box>
          <Text fontSize={"13px"} textAlign="center">
            Etapas para <b> visualizar </b>
            <br />
            os resultados
          </Text>
        </Stack>
      </Flex>
    </Box>
  );
}

export const StartInformation = ({ id, research, progress }) => {
  const [initial, setInitial] = useState(null);
  const [end, setEnd] = useState(null);
  const [daysUntilStart, setDaysUntilStart] = useState(null);
  const [daysInteger, setDaysInteger] = useState(0);
  const [urlForm, setUrlForm] = useState("");
  const [summary, setSummary] = useState();
  const [progressBar, setProgressBar] = useState(0);

  useEffect(async () => {
    async function getCert() {
      const res = await researchApi.get(
        `/research/summary/${research.extern_researchId}`
      );

      if (res && res.data) {
        setSummary(res.data);
      }
    }
    if (research.application_date) {
      getCert();
      const initial = format(
        new Date(research.application_date),
        "dd/MM/yyyy",
        {
          locale: ptBR,
        }
      );

      const end = format(
        new Date(research.application_date_end),
        "dd/MM/yyyy",
        {
          locale: ptBR,
        }
      );
      const startDate = formatDistance(
        new Date(),
        new Date(research.application_date),
        {
          locale: ptBR,
        }
      );

      setInitial(initial);
      setEnd(end);

      setDaysUntilStart(startDate);
      const int = +startDate?.toString()[0];
      setDaysInteger(int > 10 ? 100 - int : int * 10);
    }

    const { data } = await researchApi.get(
      `/research/form-url/${research.extern_researchId}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    if (data) {
      setUrlForm(data.split("//")[1]);
    } else {
      setUrlForm("pesquisa.gptw.com.br");
    }
  }, [research]);
  const [isLargerThan1383] = useMediaQuery("(min-width: 1383px)");

  useEffect(() => {
    if (!!summary) {
      let percentage =
        (summary?.finished_answers / summary?.total_workers) * 100;
      setProgressBar(percentage);
    }
  }, [summary]);

  return (
    <Box p="24px" border="1px solid #E60000" borderRadius="8px" color="#292B30">
      <Text fontSize={"14px"} lineHeight="20px" mb="4px">
        {progress
          ? "Como está o andamento da sua pesquisa"
          : "Sua pesquisa inicia em"}
      </Text>
      {progress ? (
        <>
          <Flex
            gap={["9px", "12px"]}
            mb="10px"
            align="end"
            justify={"space-between"}
          >
            <Stack spacing={0} minW="150px">
              <Text fontSize={["11px", "13px"]} lineHeight={["16px", "18px"]}>
                Respostas até hoje
                <Text
                  as="span"
                  ml="5px"
                  fontSize={["16px", "18px"]}
                  lineHeight={["21px", "23px"]}
                  fontWeight={700}
                  color="red.100"
                >
                  {summary?.finished_answers}
                </Text>
              </Text>

              <Text fontSize={["11px", "13px"]} lineHeight={["16px", "18px"]}>
                Mínimo de respostas
                <Text
                  as="span"
                  ml="5px"
                  fontSize={["16px", "18px"]}
                  lineHeight={["21px", "23px"]}
                  fontWeight={700}
                  color="red.100"
                >
                  {research?.minimum_sample}
                </Text>
              </Text>
            </Stack>

            <Box w="50%" pb="6px">
              <ProgressBar
                bgColor={"#40BF91"}
                height="10px"
                completed={progressBar}
                baseBgColor="#e0e0de"
                isLabelVisible={false}
                className="progress-bar-wrapper"
              />
            </Box>
          </Flex>
          <Text fontSize="13px">
            Total de func. selecionados
            <Text
              as="span"
              ml="5px"
              fontSize="15px"
              color="#40BF91"
              fontWeight={700}
            >
              {summary?.total_workers}
            </Text>
          </Text>

          <Text fontSize={"14px"} mt="8px">
            Período:{" "}
            <Text as="span" fontWeight={600}>
              de {initial} a {end}
            </Text>
          </Text>
        </>
      ) : (
        <>
          <Flex gap="12px" mb="16px" align="end">
            <Text
              fontSize={isLargerThan1383 ? "20px" : "18px"}
              fontWeight={700}
              color="red.100"
            >
              {daysUntilStart}
            </Text>

            <Box w="50%" pb="8px">
              <ProgressBar
                bgColor={"#40BF91"}
                height="10px"
                completed={daysInteger}
                baseBgColor="#e0e0de"
                isLabelVisible={false}
                className="progress-bar-wrapper"
              />
            </Box>
          </Flex>
          <Text fontSize="14px" mb="12px">
            Período:{" "}
            <Text as="span" fontWeight={600}>
              de {initial} a {end}
            </Text>
          </Text>
          <Text fontSize="14px">Link da pesquisa:</Text>{" "}
          <Link
            href={`${window.location.protocol}//${urlForm}/${research.public_url}`}
            target="_blank"
            fontSize="14px"
            fontWeight={600}
            color="red.100"
          >{`${urlForm}/${research.public_url}`}</Link>
        </>
      )}
    </Box>
  );
};
