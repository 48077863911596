import React, { useState } from "react";
import {
  Box,
  Button,
  Text,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,  
  Input
} from "@chakra-ui/react";

const FinishRenovationModal = ({ isOpen, onClose }) => {  
  const [finishRenovationModal, setFinishRenovationModal] = useState(false);

  const handleModalFinishRenovation = () => {
    setFinishRenovationModal(true);
  };

  const handleCloseModalFinishRenovation = () => {
    setFinishRenovationModal(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        w={["100%", "552px"]}
        p="0px"
        borderRadius={["12px", "20px"]}
        overflow="hidden"
      >
        <ModalBody p={["32px", "60px 40px 60px 40px"]}>
          <Box display={"flex"} flexDir="column" alignItems="center" gap="24px">

            <Heading
              fontSize={["20px", "24px"]}
              lineHeight="33.6px"
              fontWeight="700"
              textAlign="center"
            >
            {"Rumo à Certificação!"}
            </Heading>
            <Text mb='8px'>Informe o código para renovação (Voucher)</Text>
            <Input placeholder='Código de Renovação' />    
            <Button
                  h="38px"
                  w="141px"
                  variant="outline"
                  colorScheme="red"
                  borderRadius="10px"
                  fontWeight="600"
                  fontSize="14px"
                  onClick={handleModalFinishRenovation}
                >
                  Validar
                </Button>        
          </Box>
          <FinishRenovationModal
                  isOpen={finishRenovationModal}
                  onClose={handleCloseModalFinishRenovation}
                />
        </ModalBody>
        <ModalFooter width="100%" p={0} height={["56px", "69px"]}>
          <Button
            width="100%"
            color="white"
            background="#E60000"
            borderRadius={0}
            height={["56px", "69px"]}
            fontWeight={[600, 400]}
            onClick={onClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FinishRenovationModal;
