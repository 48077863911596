import React, { useState } from "react";
import { ModalWithClose, Select } from "../../../components";
import { Button, Flex } from "@chakra-ui/react";
import {
  MOCK_LIST_FILTERS_STATES,
  MOCK_LIST_FILTERS_STATUS,
} from "../../../mock";
import { useFilters } from "../../../../../hooks/Admin/useCompanyRegisterList";

const ModalFilters = ({ isOpen, onClose, setFiltersValues }) => {
  const [tempFiltersValues, setTempFiltersValues] = useState({
    ufFilter: "",
    cityFilter: "",
    setorFilter: "",
    filter: "",
    accountOwnerFilter: "",
  });

  const handleClose = () => {
    onClose();
  };

  const handleSumbmit = () => {
    setFiltersValues((prev) => ({
      ...prev,
      ufFilter: tempFiltersValues.ufFilter,
      cityFilter: tempFiltersValues.cityFilter,
      setorFilter: tempFiltersValues.setorFilter,
      filter: tempFiltersValues.filter,
      accountOwnerFilter: tempFiltersValues.accountOwnerFilter,
    }));
    handleClose();
  };

  const handleRemoveFilters = () => {
    setTempFiltersValues({
      ufFilter: "",
      cityFilter: "",
      setorFilter: "",
      filter: "",
      accountOwnerFilter: "",
    });
    setFiltersValues((prev) => ({
      ...prev,
      ufFilter: "",
      cityFilter: "",
      setorFilter: "",
      filter: "",
      accountOwnerFilter: "",
    }));
  };

  const { data: dataFiltersListCities } = useFilters("listCities");
  const { data: dataFiltersListStates } = MOCK_LIST_FILTERS_STATES;
  const { data: dataFiltersListSectors } = useFilters("listSectors");
  const { data: dataFiltersListStatus } = MOCK_LIST_FILTERS_STATUS;
  const { data: dataFiltersListAccountOwners } =
    useFilters("listAccountOwners");

  return (
    <ModalWithClose
      title="Filtros"
      isOpen={isOpen}
      onClose={handleClose}
      minH="100vh"
      footer={
        <Flex gap={"16px"} justify="space-between" w="100%">
          <Button variant="invisible" onClick={handleRemoveFilters} w="100%">
            Remover filtros
          </Button>
          <Button variant="red" onClick={handleSumbmit} w="100%">
            Aplicar filtros
          </Button>
        </Flex>
      }
    >
      <Flex
        direction="column"
        gap="32px"
        p="24px 24px 24px 24px"
        minH="100%"
        justify="space-between"
      >
        <Flex direction="column" gap="16px">
          <Flex flex="1" gap="16px">
            <Flex flex="1">
              <Select
                placeholder={"Todos os Estados"}
                name={"ufFilter"}
                value={tempFiltersValues.ufFilter}
                setValue={(value) =>
                  setTempFiltersValues((prev) => ({
                    ...prev,
                    ufFilter: value,
                  }))
                }
              >
                {dataFiltersListStates?.map((opt, index) => {
                  return (
                    <option value={opt.value} key={index}>
                      {opt.label}
                    </option>
                  );
                })}
              </Select>
            </Flex>
            <Flex flex="1">
              <Select
                placeholder={"Todas as cidades"}
                name={"cityFilter"}
                value={tempFiltersValues.cityFilter}
                setValue={(value) =>
                  setTempFiltersValues((prev) => ({
                    ...prev,
                    cityFilter: value,
                  }))
                }
              >
                {dataFiltersListCities?.map((opt, index) => {
                  return (
                    <option value={opt.cidade} key={index}>
                      {opt.cidade}
                    </option>
                  );
                })}
              </Select>
            </Flex>
          </Flex>
          <Flex flex="1">
            <Select
              placeholder={"Todos os Setores"}
              name={"setorFilter"}
              value={tempFiltersValues.setorFilter}
              setValue={(value) =>
                setTempFiltersValues((prev) => ({
                  ...prev,
                  setorFilter: value,
                }))
              }
            >
              {dataFiltersListSectors?.map((opt, index) => {
                return (
                  <option value={opt.setor} key={index}>
                    {opt.setor}
                  </option>
                );
              })}
            </Select>
          </Flex>
          <Flex flex="1">
            <Select
              placeholder={"Todos os Status"}
              name={"filter"}
              value={tempFiltersValues.filter}
              setValue={(value) =>
                setTempFiltersValues((prev) => ({
                  ...prev,
                  filter: value,
                }))
              }
            >
              {dataFiltersListStatus?.map((opt, index) => {
                return (
                  <option value={opt.status} key={index}>
                    {opt.status}
                  </option>
                );
              })}
            </Select>
          </Flex>
          <Flex flex="1">
            <Select
              placeholder={"Todas as contas"}
              name={"accountOwnerFilter"}
              value={tempFiltersValues.accountOwnerFilter}
              setValue={(value) =>
                setTempFiltersValues((prev) => ({
                  ...prev,
                  accountOwnerFilter: value,
                }))
              }
            >
              {dataFiltersListAccountOwners?.map((opt, index) => {
                return (
                  <option value={opt.donoConta} key={index}>
                    {opt.donoConta}
                  </option>
                );
              })}
            </Select>
          </Flex>
        </Flex>
      </Flex>
    </ModalWithClose>
  );
};

export default ModalFilters;
