import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Tag,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { BsBookmarkCheck } from "react-icons/bs";
import { FiBook } from "react-icons/fi";
import { SlQuestion } from "react-icons/sl";
import Selo from "./Selo";
import Status, { Progress, StartInformation } from "./Items";
import { getCookie } from "../common/cookieManager";
import { iconWaiting, lineCard, pendingRed } from "../../assets/images/index";
import { useListAffirmatives } from "../../helpers/useListAffirmatives";
import { useSpecificQuestions } from "../../helpers/useSpecificQuestions";
import { useLanguage } from "../../helpers/useLanguageInfo";

export const CertificationCurrentStatus = (props) => {
  const plano = getCookie("PLANO_FULL");

  const { data: affirmativeData } = useListAffirmatives(props.props.researchId);
  const [customised, setCustomised] = useState(false);

  useEffect(() => {
    if (affirmativeData && affirmativeData[0] !== undefined) {
      setCustomised(true);
    }
  }, [affirmativeData]);

  const isLargerThan1280 = useMediaQuery("(min-width: 1280px)");

  console.log(isLargerThan1280);

  return (
    <>
      {props.props.research && (
        <>
          {props.props.status === "waiting" ||
          props.props.status === "progress" ? (
            <StartInformation
              id={props.props.id}
              research={props.props.research[0]}
              progress={props.props.status === "progress"}
            />
          ) : null}
        </>
      )}

      {props.props.status === "waiting" ? (
        <Flex
          w="100%"
          textAlign={"center"}
          align="start"
          color="white"
          bg="red.100"
          borderRadius="8px"
          fontSize={"13px"}
          p="10px 22px"
        >
          <Image src={iconWaiting} alt="Icon waiting" mt="2px" />
          <Text fontSize={["12px", "13px"]}>
            <Text as="span" fontWeight={700}>
              {" "}
              Atenção!
            </Text>{" "}
            Você poderá fazer qualquer alteração na pesquisa até
            <Text as="span" fontWeight={700}>
              {" "}
              2 dias antes{" "}
            </Text>
            de seu início.
          </Text>
        </Flex>
      ) : props.props.status === "progress" && isLargerThan1280 === true ? (
        <>
          <Button
            // onClick={handleClose}
            w="100%"
            justifyContent={"flex-start"}
            color="white"
            bg="red.100"
            h="36px"
            borderRadius="10px"
            fontSize={"13px"}
            fontWeight={400}
            cursor="none"
            _hover={{
              bg: "#E60000",
            }}
          >
            Continue de onde você parou!
            <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
              {" "}
              Falta pouco!{" "}
            </span>
          </Button>
        </>
      ) : props.props.status === "first" && isLargerThan1280 === true ? (
        <>
          <Button
            w="100%"
            justifyContent={"flex-start"}
            color="red.100"
            bg="#FFCCCC"
            h="36px"
            borderRadius="8px"
            fontSize={"13px"}
            fontWeight={600}
            cursor="none"
            _hover={{
              bg: "#FFCCCC",
            }}
          >
            Comece sua Jornada de Certificação & Ranking GPTW!
          </Button>
        </>
      ) : null}

      {props.props.research && (
        <Box border="1px solid #CCCCCC" borderRadius="12px" pb="25px">
          <Stack
            h="148px"
            bg="#174BD6"
            borderRadius="12px"
            px="24px"
            position="relative"
            spacing="8px"
            color="white"
            align="start"
            justify="center"
          >
            <Image
              src={props.props.lineCard}
              alt="line background"
              pos="absolute"
              top="0"
              right="12px"
            />
            <Tag
              fontSize="14px"
              fontWeight={600}
              bg="#40BF91"
              color="white"
              p="4px 8px"
              h="24px"
              borderRadius="6px"
            >
              {" "}
              Certificação
            </Tag>
            <Heading as="h3" fontSize="20px" lineHeight="24px">
              Rumo a certificação!
            </Heading>
            <Link
              href={`researches/certification?cr=${props.props.id}&pd=${plano}`}
              fontSize="14px"
              textDecor={"underline"}
              zIndex="1200"
            >
              {props.props.status === "first"
                ? "Iniciar pesquisa"
                : "Continuar pesquisa"}
            </Link>
          </Stack>
          {props.props.registrationStatusFetched && (
            <Stack borderRadius="12px" p="25px 24px" pacing="10px">
              {/* <Status
                done={certificationQuestionnaire}
                initial="Cadastro"
                final="Inicial"
              /> */}

              <Status
                done={props.props.certificationQuestionnaire}
                initial="Questionário"
                final="de certificação"
              />

              {props.props.registrationStatus &&
              props.props.registrationStatusError !== true ? (
                <Status
                  done={props.props.registrationStatus.employees_sheet}
                  initial="Planilha"
                  final="de Funcionários"
                />
              ) : props.props.registrationStatusError &&
                props.props.research !== undefined &&
                props.props.research?.length > 0 ? (
                <Status
                  done={
                    props.props.research[0].total_employees !== null &&
                    props.props.research[0].total_employees > 0
                  }
                  initial="Planilha"
                  final="de Funcionários"
                />
              ) : (
                <Status
                  done={false}
                  initial="Planilha"
                  final="de funcionários"
                />
              )}

              {props.props.research &&
                props.props.research?.length > 0 &&
                props.props.research[0].plan?.includes("PREMIUM") && (
                  <Status
                    done={customised}
                    initial="Personalização"
                    final="da Pesquisa"
                  />
                )}

              {props.props.registrationStatus &&
              props.props.registrationStatusError === false ? (
                <Status
                  done={props.props.registrationStatus.initial_date.ok}
                  initial="Data Início"
                  final="da Pesquisa"
                />
              ) : props.props.registrationStatusError &&
                props.props.research !== undefined &&
                props.props.research?.length > 0 ? (
                <Status
                  done={props.props.research[0].application_date !== null}
                  initial="Data de início"
                  final="da Pesquisa de Clima"
                />
              ) : (
                <Status
                  done={props.props.status !== "first" ? true : false}
                  initial="Data Início"
                  final="da Pesquisa"
                />
              )}
            </Stack>
          )}
          {props.props.status !== "waiting" &&
            !!props.props.registrationStatus && (
              <Progress status={props.props.registrationStatus} />
            )}
        </Box>
      )}
    </>
  );
};

export const WaitingResults = (props) => {
  return (
    <>
      <Button
        w="100%"
        color="white"
        bg="#FFB81A"
        h="38px"
        borderRadius="8px"
        fontSize={"12px"}
        cursor="none"
        _hover={{
          bg: "#FFA80A",
        }}
      >
        Sua pesquisa ainda não foi avaliada
      </Button>

      <Stack mt="24px" spacing="16px">
        <Text fontSize={"16px"} fontWeight={600} color="#292B30">
          Próximos passos
        </Text>

        <Flex fontSize="13px" gap="9.6px" align="start">
          <Image src={pendingRed} alt="" mt="2px" />
          <Stack spacing="6px">
            <Text>
              <b>
                Espere a notificação do time de Certificação GPTW, é momento de
                aguardar um pouco mais os resultados. Aproveite o tempo para
                preencher o{" "}
                <Link
                  href={`/registration/profile/info?form=CB&cr=${props.props.id}&st=active`}
                  color="red.100"
                  fontWeight={600}
                  textDecor="underline"
                >
                  Questionário de Certificação!
                </Link>{" "}
                caso ainda não tenha feito.
              </b>
            </Text>

            <Text>
              Confira os{" "}
              <Link
                href={
                  "https://gptw.com.br/wp-content/uploads/2023/09/Criterios-de-Ranking-2023-31.07.2023.pdf"
                }
                color="red.100"
                fontWeight={600}
                textDecor="underline"
              >
                critérios de cada Ranking.
              </Link>
            </Text>

            <Text>
              Entenda como garantir sua{" "}
              <Link
                href="https://www.youtube.com/watch?v=T_QLimIhzqk"
                color="red.100"
                fontWeight={600}
                textDecor="underline"
              >
                inscrição nos Rankings.
              </Link>
            </Text>
          </Stack>
        </Flex>

        <Flex fontSize="13px" gap="9.6px" align="start">
          <FiBook fontSize={"20px"} color="red" />
          <Stack spacing="8px">
            <Text>
              {" "}
              <b>Em caso de resultado positivo</b> a comunicação dessa
              importante conquista deve ser baseada em nosso{" "}
              <Link
                href="https://d335luupugsy2.cloudfront.net/cms%2Ffiles%2F2705%2F1631109072Novo_E-book_Fui_Certificada_e_agora.pdf"
                color="red.100"
                fontWeight={600}
                textDecor="underline"
                ml="4px"
              >
                e-book
              </Link>
            </Text>
          </Stack>
        </Flex>

        <Flex gap="9.6px" align="start">
          <BsBookmarkCheck fontSize={"36px"} color="red" />

          <Text fontSize="13px">
            <b>O Relatório de Certificação:</b> Lembre-se de acessar o
            <Link
              href={`/results?cr=${props.props.id}`}
              color="red.100"
              fontWeight={600}
              textDecor="underline"
              mx="4px"
            >
              Relatório de Certificação com os resultados
            </Link>
            da Pesquisa de Clima para entender os pontos de melhoria e colocar
            nossas recomendações de ações em prática
          </Text>
        </Flex>
        <Flex fontSize="13px" gap="9.6px" align="start">
          <SlQuestion fontSize={"16px"} color="red" />
          <Stack spacing="12px">
            <Text>
              <b>Precisa de ajuda</b> para saber como tratar melhor os
              resultados da pesquisa?
            </Text>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};

export const AuditingCertification = (props) => {
  const plano = getCookie("PLANO");

  return (
    <>
      <Button
        whiteSpace={"normal"}
        w="100%"
        color="white"
        bg="#ff6700"
        minH="38px"
        borderRadius="8px"
        fontSize={"12px"}
        cursor="none"
      >
        A pesquisa de clima foi finalizada e está sendo auditada
      </Button>

      <Stack mt="24px" spacing="16px">
        <Text fontSize={"16px"} fontWeight={600} color="#292B30">
          Próximos passos
        </Text>

        <Flex fontSize="13px" gap="9.6px" align="start">
          <Image src={pendingRed} alt="" mt="2px" />
          <Stack spacing="6px">
            <Text>
              <b>
                Agora é hora dos Auditores GPTW avaliarem se as regras para uma
                adequada pesquisa de clima organizacional foram respeitadas.
                Esse processo de auditoria foi criado para assegurar
                certificações mais consistentes e seguras tanto para as empresas
                quanto para seus colaboradores, observando a confidencialidade,
                a voluntariedade e o anonimato para responder a pesquisa. Você
                pode conhecer os critérios{" "}
                <Link
                  href={`https://gptw-brasil.zendesk.com/hc/pt-br/articles/4407476075540-Quais-s%C3%A3o-as-regras-de-aplica%C3%A7%C3%A3o-da-Pesquisa-de-Clima-GPTW-`}
                  color="red.100"
                  fontWeight={600}
                  textDecor="underline"
                >
                  aqui
                </Link>{" "}
                e, após essa validação, o seu resultado estará disponível aqui
                no Connect em até 03 dias úteis após a data final da pesquisa de
                clima.
              </b>
            </Text>
            <Text></Text>
            <Text></Text>
            <Text>
              <b>
                E se ficar com alguma dúvida, consulte as principais perguntas e
                respostas do processo em nossa{" "}
                <Link
                  href={`https://gptw-brasil.zendesk.com/hc/pt-br`}
                  color="red.100"
                  fontWeight={600}
                  textDecor="underline"
                >
                  Central de Ajuda
                </Link>
                . Depois de acessar esse documento, caso ainda reste alguma
                questão, participe ao vivo do{" "}
                <Link
                  href={`https://conteudo.gptw.com.br/plantao-de-duvidas-gptw`}
                  color="red.100"
                  fontWeight={600}
                  textDecor="underline"
                >
                  Plantão de Dúvidas GPTW
                </Link>{" "}
                que acontece toda sexta-feira, das 10h às 11h. Para qualquer
                outra dúvida você poderá direcionar o contato em nosso{" "}
                <Link
                  href="mailto:atendimento@gptwbrasil.com.br"
                  color="red.100"
                  fontWeight={600}
                  textDecor="underline"
                >
                  Suporte técnico
                </Link>{" "}
                (Seg. à Sex. / 9h às 18h).
              </b>
            </Text>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};

export const WaitingFormsAnswers = ({ id, st }) => {
  return (
    <>
      <Button
        w="100%"
        color="white"
        bg="#FFB81A"
        h="38px"
        borderRadius="8px"
        fontSize={"12px"}
        cursor="none"
        _hover={{
          bg: "#FFA80A",
        }}
      >
        Preencha os Questionários
      </Button>

      <Stack mt="24px" spacing="16px">
        <Text fontSize={"16px"} fontWeight={600} color="#292B30">
          Próximos passos
        </Text>

        <Flex fontSize="13px" gap="9.6px" align="start">
          <Image src={pendingRed} alt="" mt="2px" />
          <Stack spacing="6px">
            <Text>
              <b>
                Para liberar o selo, é necessário preencher o{" "}
                <Link
                  href={`/registration/profile/info?form=CB&cr=${id}&st=${st}`}
                  color="red.100"
                  fontWeight={600}
                  textDecor="underline"
                >
                  Questionário de Certificação.
                </Link>
              </b>{" "}
              Para participar do ranking, é necessário preencher o{" "}
              <Link
                href={`/registration/profile/info?form=PD&cr=${id}&st=${st}`}
                color="red.100"
                fontWeight={600}
                textDecor="underline"
              >
                Questionário de Ranking
              </Link>{" "}
              (Setorial, Regional e Nacional)
            </Text>

            <Text>
              Confira os{" "}
              <Link
                href={
                  "https://gptw.com.br/wp-content/uploads/2023/09/Criterios-de-Ranking-2023-31.07.2023.pdf"
                }
                color="red.100"
                fontWeight={600}
                textDecor="underline"
              >
                critérios de cada Ranking.
              </Link>
            </Text>

            <Text>
              Entenda como garantir sua{" "}
              <Link
                href="https://www.youtube.com/watch?v=T_QLimIhzqk"
                color="red.100"
                fontWeight={600}
                textDecor="underline"
              >
                inscrição nos Rankings.
              </Link>
            </Text>
          </Stack>
        </Flex>

        <Flex fontSize="13px" gap="9.6px" align="start">
          <FiBook fontSize={"20px"} color="red" />
          <Stack spacing="8px">
            <Text>
              {" "}
              <b>Em caso de resultado positivo</b> a comunicação dessa
              importante conquista deve ser baseada em nosso{" "}
              <Link
                href="https://d335luupugsy2.cloudfront.net/cms%2Ffiles%2F2705%2F1631109072Novo_E-book_Fui_Certificada_e_agora.pdf"
                color="red.100"
                fontWeight={600}
                textDecor="underline"
                ml="4px"
              >
                e-book
              </Link>
            </Text>
          </Stack>
        </Flex>

        <Flex gap="9.6px" align="start">
          <BsBookmarkCheck fontSize={"36px"} color="red" />

          <Text fontSize="13px">
            <b>O Relatório de Certificação:</b> Lembre-se de acessar o
            <Link
              href={`/results?cr=${id}`}
              color="red.100"
              fontWeight={600}
              textDecor="underline"
              mx="4px"
            >
              Relatório de Certificação com os resultados
            </Link>
            da Pesquisa de Clima para entender os pontos de melhoria e colocar
            nossas recomendações de ações em prática
          </Text>
        </Flex>
        <Flex fontSize="13px" gap="9.6px" align="start">
          <SlQuestion fontSize={"16px"} color="red" />
          <Stack spacing="12px">
            <Text>
              <b>Precisa de ajuda</b> para saber como tratar melhor os
              resultados da pesquisa?
            </Text>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};

export const SuccessCertificate = (props) => {
  return (
    <>
      <Button
        w="100%"
        color="white"
        bg="#002070"
        h="38px"
        borderRadius="8px"
        fontSize={"12px"}
        cursor="none"
        _hover={{
          bg: "#E60000",
        }}
      >
        Parabéns! Sua empresa foi certificada!
      </Button>

      {props.props.research && (
        <Selo
          company={props.props.company}
          research={props.props.research}
          cr={props.props.id}
        />
      )}

      <Stack mt="24px" spacing="16px">
        <Text fontSize={"16px"} fontWeight={600} color="#292B30">
          Próximos passos
        </Text>

        <Flex fontSize="13px" gap="9.6px" align="start">
          <Image src={pendingRed} alt="" mt="2px" />
          <Stack spacing="6px">
            <Text>
              Agora que a sua empresa conquistou a{" "}
              <b>
                {" "}
                Certificação GPTW, é hora de inscrevê-la para concorrer aos
                Rankings GPTW!
              </b>
            </Text>

            <Text>
              Confira os{" "}
              <Link
                href={
                  "https://gptw.com.br/wp-content/uploads/2023/09/Criterios-de-Ranking-2023-31.07.2023.pdf"
                }
                color="red.100"
                fontWeight={600}
                textDecor="underline"
              >
                critérios de cada Ranking.
              </Link>
            </Text>

            <Text>
              Entenda como garantir sua{" "}
              <Link
                href="https://www.youtube.com/watch?v=T_QLimIhzqk"
                color="red.100"
                fontWeight={600}
                textDecor="underline"
              >
                inscrição nos Rankings.
              </Link>
            </Text>
          </Stack>
        </Flex>

        <Flex fontSize="13px" gap="9.6px" align="start">
          <FiBook fontSize={"20px"} color="red" />
          <Stack spacing="8px">
            <Text>
              {" "}
              Para a comunicação dessa importante conquista, compartilhamos
              dicas valiosas e melhores práticas
              <Link
                href="https://d335luupugsy2.cloudfront.net/cms%2Ffiles%2F2705%2F1631109072Novo_E-book_Fui_Certificada_e_agora.pdf"
                color="red.100"
                fontWeight={600}
                textDecor="underline"
                ml="4px"
              >
                neste e-book
              </Link>
            </Text>
          </Stack>
        </Flex>

        <Flex gap="9.6px" align="start">
          <BsBookmarkCheck fontSize={"36px"} color="red" />

          <Text fontSize="13px">
            <b>Relatório de Certificação:</b> Lembre-se de acessar o
            <Link
              href={`/results?cr=${props.props.id}`}
              color="red.100"
              fontWeight={600}
              textDecor="underline"
              mx="4px"
            >
              Relatório de Certificação com os resultados
            </Link>
            da Pesquisa de Clima para entender os pontos de melhoria e colocar
            nossas recomendações de ações em prática
          </Text>
        </Flex>
        <Flex fontSize="13px" gap="9.6px" align="start">
          <SlQuestion fontSize={"16px"} color="red" />
          <Stack spacing="12px">
            <Text>
              <b>Precisa de ajuda</b> para saber como tratar melhor os
              resultados da pesquisa?
            </Text>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};

export const DeniedCertificate = (props) => {
  return (
    <>
      <Flex
        w="100%"
        textAlign={"center"}
        align="start"
        color="white"
        bg="red.100"
        borderRadius="8px"
        p="10px 22px"
      >
        <Text fontSize={"15px"} fontWeight={600}>
          Empresa não certificada
        </Text>
      </Flex>
      <Stack spacing={["-25px"]} align={["start", "end"]}>
        <Tag
          fontWeight="600"
          bg="#FFE4E1"
          w="65px"
          fontSize="12px"
          alignSelf={"start"}
          color="	#FF0000"
        >
          Nota:{props.props.researchInfo.score}
          {/* {certificado.ti.length > 2
            ? certificado.ti.replace(/\..+/, "")
            : certificado.ti} */}
        </Tag>
      </Stack>

      <Stack
        h="148px"
        bg="#174BD6"
        borderRadius="12px"
        px="24px"
        position="relative"
        spacing="8px"
        color="white"
        align="start"
        justify="center"
      >
        <Image
          src={lineCard}
          alt="line background"
          pos="absolute"
          top="0"
          right="12px"
        />
        <Tag
          fontSize="14px"
          fontWeight={600}
          bg="#40BF91
  "
          color="white"
          p="4px 8px"
          h="24px"
          borderRadius="6px"
        >
          {" "}
          Certificação
        </Tag>
        <Heading as="h3" fontSize="20px" lineHeight="24px">
          Rumo a certificação!
        </Heading>
      </Stack>

      <Stack mt="24px" spacing="16px">
        <Text fontSize={"16px"} fontWeight={600} color="#292B30">
          Não foi desta vez!
        </Text>

        <Flex fontSize="13px" gap="9.6px" align="start">
          <Image src={pendingRed} alt="" mt="2px" />
          <Stack spacing="10px">
            <Text>
              <b>Infelizmente</b> a empresa não realizou a Pesquisa de Clima
              dentro do prazo ou então não atingiu os critérios (amostra mínima
              de respondentes e mínimo de 70% de satisfação dos funcionários)
              para a Certificação,
              <b> por isso é hora de entender o contexto</b>
            </Text>

            <Text>
              Se a empresa não atingiu os critérios de elegibilidade, aprofunde
              o entendimento do Relatório de Certificação e confira,
              <Link
                href="https://www.youtube.com/watch?v=FXFZhoGPCoo"
                color="red.100"
                fontWeight={600}
                textDecor="underline"
              >
                nesse workshop
              </Link>
              , como tirar os melhores insights dos resultados da Pesquisa de
              Clima’
            </Text>

            <Text lineHeight="24px">
              <b>Prepare sua organização</b> para uma nova pesquisa, que poderá
              ser aplicada daqui a três meses.
            </Text>
            <Link
              href={`/results?cr=${props.props.id}`}
              color="red.100"
              fontWeight={600}
              textDecor="underline"
            >
              Acessar resultados
            </Link>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};

export const NotScheduledCertificate = (props) => {
  return (
    <>
      <Button
        w="100%"
        color="white"
        bg="#002070"
        h="38px"
        borderRadius="8px"
        fontSize={"12px"}
        cursor="none"
        _hover={{
          bg: "#E60000",
        }}
      >
        Pesquisa com prazo de agendamento expirado!
      </Button>
      <Stack mt="24px" spacing="16px">
        <Flex fontSize="13px" gap="9.6px" align="start">
          <Image src={pendingRed} alt="" mt="2px" />
          <Stack spacing="10px">
            <Text>
              <b>
                Infelizmente, não foi realizado o agendamento da pesquisa dentro
                do prazo de 6 meses, conforme cláusula do termo de uso.{" "}
              </b>
              Por isso, o agendamento foi bloqueado e será necessário que
              adquira uma pesquisa adicional ou novo plano para continuar.
            </Text>

            <Link
              href={
                "https://gptwcertification.s3.us-west-2.amazonaws.com/templates/Termos_de_uso.pdf"
              }
              color="red.100"
              fontWeight={600}
              textDecor="underline"
            >
              Para acessar os termos de uso clique aqui
            </Link>

            <Text lineHeight="24px">
              Para iniciar um novo processo de certificação acesse a opção{" "}
              <b>Renovar Certificado</b> localizado no menu Lateral esquerdo.
            </Text>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};

export const ExpiredCertificate = (props) => {
  return (
    <>
      <Button
        w="100%"
        color="white"
        bg="#002070"
        h="38px"
        borderRadius="8px"
        fontSize={"12px"}
        cursor="none"
        _hover={{
          bg: "#E60000",
        }}
      >
        Seu Certificado Expirou!
      </Button>

      {props.props.research && (
        <Selo
          company={props.props.company}
          research={props.props.research}
          cr={props.props.id}
        />
      )}

      <Stack mt="24px" spacing="16px"></Stack>
    </>
  );
};
