import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const Breadcrumbs = ({ breadcumbsItems, ...rest }) => {
  return (
    <Breadcrumb
      spacing="8px"
      separator={<MdOutlineArrowForwardIos color="gray.500" />}
      {...rest}
    >
      {breadcumbsItems.map((b, index) => (
        <BreadcrumbItem key={index}>
          <BreadcrumbLink href={b.link}>
            {b.ativado ? (
              <span style={{ fontWeight: "bold" }}> {b.nome}</span>
            ) : (
              <span>{b.nome}</span>
            )}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
