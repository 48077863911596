export const FORMAT = {
    convertDateUTCToBR(date) {
        const newDate = date.substring(10,8) + '-' + date.substring(7,5) + '-' + date.substring(0,4);
        return newDate;
    },
    formateDateHour(dateHour) {
        // Converter a string de data/hora em um objeto Date
        const dt = new Date(dateHour);
        
        // Extrair os componentes da data e hora
        const dia = dt.getDate();
        const mes = dt.getMonth() + 1;
        const ano = dt.getFullYear();
        const hora = dt.getHours();
        const minuto = dt.getMinutes();
        
        // Formatar a data e hora no formato desejado
        const dataFormatada = `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${ano}`;
        const horaFormatada = `${hora < 10 ? '0' + hora : hora}:${minuto < 10 ? '0' + minuto : minuto}`;
        
        // Criar a string formatada completa
        const resultado = `${dataFormatada} às ${horaFormatada}`;
        
        return resultado;
      }
}

export default FORMAT;