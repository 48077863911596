/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";

import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { researchApi } from "../../../../services/api-research";
import { useResearch } from "../../../../helpers/useResearch";
import iconBar from "../../../../assets/images/icon-bar.svg";
import { queryClient } from "../../../../services/queryCliente";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
  Flex,
  useToast,
  Tag,
} from "@chakra-ui/react";
import { Icon } from "../../../../style";
import CustomSelect from "./CustomSelect";

const ResearchCustomModal = ({ onClose, isOpen, researchId, previousData }) => {
  const toast = useToast();

  const { data } = useResearch(researchId);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [defaultValues, setDefaultValues] = useState(false);

  const { register, handleSubmit } = useForm();
  const updateValues = {
    ...data.research,
  };

  const [selectedOption, setSelectedOption] = useState([""]);

  useEffect(() => {
    if (!!previousData.length) {
      const transformedData = previousData.map((prev) => {
        return {
          id: prev.id,
          value: prev.description,
          label: prev.description,
        };
      });

      setSelectedOption(transformedData);
      setDefaultValues(true);
    }
  }, [previousData]);

  const createAffirmatives = useMutation(
    async (updateOptions) => {
      let response = await Promise.all(
        updateOptions.map(async (m) => {
          if (m.remove === true) {
            if (m.id !== undefined && m.remove === true) {
              const res = await researchApi.delete(
                `/affirmative-research/${m.id}`
              );
              // } else {
              //   const res = await researchApi.put(
              //     `/affirmative-research/${m.id}`,
              //     {
              //       ...m,
              //     }
              //   );
              //
            }
          } else {
            const res = await researchApi.post("/affirmative-research", {
              ...m,
            });
          }
        })
      );

      const res = await researchApi.put(`/research/${researchId}`, {
        ...updateValues,
      });

      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["customaffirmatives"], data);
        queryClient.invalidateQueries("customaffirmatives");
        setSuccess(true)        
      },
    }
  );

  const onSubmit = async (values) => {
    setLoading(true);
    if (selectedOption.length === 0) {
      setError(true);
      setLoading(false);
      return;
    }

    const first = previousData.some((prev) => prev.position === 1)
      ? {
          description: selectedOption[0].value,
          id_research: researchId,
          position: 1,
          id: previousData.find((prev) => prev.position === 1).id,
        }
      : {
          description: selectedOption[0]?.value,
          position: 1,
          id_research: researchId,
        };

    const second = previousData.some((prev) => prev.position === 2)
      ? {
          description: selectedOption[1]?.value,
          id_research: researchId,
          position: 2,
          id: previousData.find((prev) => prev.position === 2).id,
        }
      : {
          description: selectedOption[1]?.value,
          position: 2,
          id_research: researchId,
        };
    const third = previousData.some((prev) => prev.position === 3)
      ? {
          description: selectedOption[2]?.value,
          id_research: researchId,
          position: 3,
          id: previousData.find((prev) => prev.position === 3).id,
        }
      : {
          description: selectedOption[2]?.value,
          position: 3,
          id_research: researchId,
        };

    let errorMessage;

    const options = [first, second, third];

    let update = [];

    options.forEach((option, index) => {
      if (option.description !== undefined) {
        update.push(options[index]);
      } else if (option.description === undefined && option.id !== undefined) {
        let obj = Object.assign(options[index], {
          remove: true,
        });
        update.push(obj);
      } else {
        return;
      }
    });

    try {
      const res = await createAffirmatives.mutateAsync(update);
    } catch (err) {
      console.log(err);
      if (typeof err === "object") {
        errorMessage = err.toString();
      } else if (
        err.response.data?.message ===
        "É possível adicionar até 3 afirmativas pré-determinadas a sua pesquisa de clima."
      ) {
        errorMessage = err.response.data?.message;
      } else {
        errorMessage = "Ocorreu um erro.";
      }

      toast({
        title: "Falha no envio",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  };

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent w={['96vw',"581px"]} p="0px" borderRadius="20px" overflow="hidden">
        <ModalBody p={['32px',"60px"]}>
          <Box display={"flex"} flexDir="column" alignItems="center" gap="24px">
            <Icon type="check-green" width="100px" height="100px" />

            <Heading fontSize={['20px',"24px"]} fontWeight="700" textAlign={'center'}>
              Afirmativas definidas com sucesso
            </Heading>
          </Box>
        </ModalBody>
        <ModalFooter width="100%" p={0}   height={['56px',"69px"]}>
          <Button
            width="100%"
            color="white"
            background="#E60000"
            borderRadius={0}
            height={['56px',"69px"]}
            onClick={handleClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent
        w={["96vw", "1112px"]}
        h={["auto", "552px"]}
        borderRadius="12px"
        py={["34px", "20px"]}
        px={["0px", "5px"]}
      >
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <img src={iconBar} alt="icon" />
            <Text
              fontSize="18px"
              fontWeight="600"
              mt="8px"
              mb="22px"
              color="#292B30"
            >
              Personalização da Pesquisa de Clima
            </Text>

            <Box
              h="365px"
              py={["4px", "22px"]}
              px={["0px", "20px"]}
              border={["0px", "1px solid #CCCCCC"]}
     
              borderRadius="12px"
            >
              <Box mb={["24px", "unset"]}>
                <Tag
                  bg="#e5ebec"
                  color="#002070"
                  fontSize="15px"
                  borderRadius="20px"
                  px="15px"
                  h="19px"
                  fontWeight="600"
                >
                  Afirmativas
                </Tag>
                <Text fontSize="14px" pt={2} lineHeight={"24px"}>
                  É possível adicionar até <b>3 afirmativas </b>{" "}
                  pré-determinadas à sua Pesquisa de Clima.
                </Text>
              </Box>
              <CustomSelect
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />

              <Flex mt="18px" flexDir="column" gap="4px"></Flex>

              {error ? (
                <Text
                  fontSize={"13px"}
                  fontWeight={600}
                  color="red.100"
                  pl="5px"
                >
                  Selecione 3 alternativas
                </Text>
              ) : null}
            </Box>

            <Flex
              h={["48px", "92px"]}
              w="100%"
              gap="17px"
              justifyContent={"space-between"}
              mt="20px"
            >
              <Button
                w={["100%", "265px"]}
                h={["48px", "50px"]}
                bg="white"
                fontWeight="700"
                color="#E60000"
                borderColor="#E60000"
                border="1px"
                borderRadius="10px"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                bg="#E60000"
                w={["100%", "265px"]}
                color="#ffffff"
                borderRadius="10px"
                h={["48px", "50px"]}
                isLoading={loading}
              >
                Salvar
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ResearchCustomModal;
