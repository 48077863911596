import {
  Flex,
  Heading,
  Text,
  Box,
  Button,
  Stack,
  Tag,
  Grid,
  GridItem,
  Container,
  useMediaQuery,
} from "@chakra-ui/react";
import CustomCircularProgressBar from "../../../components/progress-bar/Circular";
import { ProgressLinear } from "../../../components/progress-bar/Linear";

export default function PilarInformationAndStatus({
  data,
  companyId,
  children,
}) {
  const [mobile] = useMediaQuery("(max-width: 48em)");

  return (
    <Container
      maxW={"full"}
      p={0}
      overflowX="hidden"
      bg="white"
      borderBottom="1px solid #E5E5E5"
      mb="24px"
    >
      <Flex
        align="center"
        justify={["center", "space-between"]}
        px={{ base: "20px", xl: "100px", "2xl": "150px" }}
        mx="auto"
      >
        <Grid
          pt={["40px", "35px"]}
          pb={["21px", "35px"]}
          templateColumns={["repeat(1, 1fr)", "repeat(4, 1fr)"]}
          w="100%"
        >
          <GridItem colSpan={1}>
            <Flex direction="column" align="start" pos="relative">
              <Tag
                fontSize={["13px", "14px"]}
                bg="#C7ABF5"
                mb={["8px", "12px"]}
                p="4px 8px"
              >
                Pilares for All
              </Tag>
              <Heading
                as="h3"
                fontSize={["20px", "24px"]}
                lineHeight={["32px", "28.18px"]}
              >
                {data?.namepillar}
              </Heading>
              <Box
                height="1px"
                mt={["16px", "8px"]}
                w={["100%", "330px"]}
                bg="#E5E5E5"
              />

              <Text
                pos={["absolute", "static"]}
                top={0}
                right={0}
                mt={["unset", "8px"]}
                as="span"
                fontSize="sm"
                color="#5C6066"
              >
                1 pergunta
              </Text>
            </Flex>
          </GridItem>

          <GridItem colSpan={[1, 2]} display="flex" justifyContent={"center"}>
            {mobile ? (
              <Stack pt="16px" spacing="8px" w="100%">
                <ProgressLinear label="Respondidas" value={20} color="blue" />
                <ProgressLinear label="Obrigatórias" value={5} color="red" />
                <ProgressLinear label="Concluído" value={70} color="whatsapp" />
              </Stack>
            ) : (
              <Flex w="356px" height="95px" gap="70px" justify={"center"}>
                <CustomCircularProgressBar
                  value={data?.answered ? 100 : 0}
                  label="Respondidas"
                  color="#174BD6"
                  text={data?.answered ? "1/1" : "0/1"}
                />
                <CustomCircularProgressBar
                  value={data?.answered ? 100 : 0}
                  label="Obrigatórias"
                  color="#D50000"
                  text={data?.answered ? "1/1" : "0/1"}
                />
                <CustomCircularProgressBar
                  value={data?.answered ? 100 : 0}
                  label="Concluído"
                  color="#40BF91"
                  text="100%"
                />
              </Flex>
            )}
          </GridItem>
          {!mobile && (
            <GridItem colSpan={1}>
              <Stack spacing="12px" w="265px" ml="auto">
                {children}

                <Button
                  onClick={() =>
                    window.location.replace(
                      `/registration/profile/pilares-for-all?cr=${companyId}`
                    )
                  }
                  variant="secondary"
                  filter="drop-shadow(0px 5px 35px rgba(0, 98, 255, 0.25))"
                >
                  Voltar
                </Button>
              </Stack>
            </GridItem>
          )}
        </Grid>
      </Flex>
    </Container>
  );
}
