import { useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useMediaQuery,
  Box,
  Flex,
  Button,
  Stack,
  Text,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { CustomTextarea } from "../../../components/textarea";
import { SuccessModal } from "../../../components/modal";
import api from "../api";
import { FormAddImage } from "./FormUploadFiles";
import {
  useAllTagsQuery,
  useAnswerFilesQuery,
  useSelectTagsQuery,
} from "./hooks";
import { addDays, isAfter } from "date-fns";
import { findDifferentValues, findMissingValues } from "./helpers";
import { useParticipationDataQuery } from "../hooks";
import { useResearchInfoDataQuery } from "../../../../hooks/useResearchInfo";
import { queryClient } from "../../../../services/queryCliente";
import PilarInformationAndStatus from "./PilarInformationAndStatus";
import { CulturaAuditFeedback, PilaresTags } from "./PilaresTags";

const MAX_WORDS = 5000;

function SubmitButton({ isDirty, isValid, ...rest }) {
  return (
    <Button
      type="button"
      disabled={!isValid || !isDirty}
      variant="primary"
      borderRadius="10px"
      height="40px"
      _hover={{
        transform: "none",
      }}
      {...rest}
    >
      Salvar
    </Button>
  );
}

function TextAreaControl({ control, maxLenght }) {
  const finalAnswer = useWatch({
    control,
    name: "answer",
  });

  return (
    <Flex align="center" justify="start" pt={["16px", "24px"]}>
      <Text as="span" fontWeight={600}>
        {finalAnswer?.length > 0 ? finalAnswer.trim().split(/\s+/).length : "0"}
      </Text>
      <Text as="span">/{maxLenght} palavras</Text>
    </Flex>
  );
}

export default function CadastrosUserQuestionnaire() {
  const toast = useToast();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("cr");
  const pillarId = urlParams.get("pillar");

  const [idAnswer, setIdAnswer] = useState();
  const [maxWords, setMaxWords] = useState(MAX_WORDS);
  const [isThirtyDaysPassed, setThirtyDaysPassed] = useState(false);

  const getValidationSchema = (maxWords) => {
    return yup.object().shape({
      answer: yup
        .string()
        .test(
          "check-word-count",
          `O campo deve ter no máximo ${maxWords} palavras`,
          (value) => {
            if (!value) return true; // Permite campo vazio
            const wordCount = value.trim().split(/\s+/).length;
            return wordCount <= maxWords;
          }
        )
        .test(
          "check-blank",
          "O campo não pode conter apenas espaços em branco",
          (value) => {
            return value.trim().length > 0; // Verifica se o valor após trim não está vazio
          }
        )
        .required("O campo é obrigatório"),
    });
  };

  const validationSchema = getValidationSchema(maxWords);

  const { data, isLoading } = useQuery(
    ["pilar-content", id],
    async () => {
      const { data } = await api.get(`answer/get-answer-ca/${id}`);
      const results = data?.data?.[0]?.result;

      console.log(data.data[0].result)

      if (!results || results.length === 0) {
        return null;
      }

      if (results.length === 1) {
        return results[0];
      }

      const matchingObjects = results.filter(
        (res) => res.idpillar === pillarId
      );
      if (matchingObjects.length === 1) {
        return matchingObjects[0];
      }

      const groupedAnswer = matchingObjects.map((obj) => obj.answer).join("\n");

      const firstObject = { ...matchingObjects[0], answer: groupedAnswer };

      return firstObject;
    },
    { enabled: !!id, refetchOnWindowFocus: false }
  );

  const { data: tagsData } = useAllTagsQuery();
  const { data: selectedTagsData } = useSelectTagsQuery(idAnswer);
  const { data: filesResponse } = useAnswerFilesQuery(idAnswer);
  const { data: participationData } = useParticipationDataQuery(id);
  const { data: researchData } = useResearchInfoDataQuery(id);

  const [mobile] = useMediaQuery("(max-width: 48em)");
  const [submitModal, setSubmittModal] = useState(false);

  const {
    control,
    register,
    formState: { errors, isValid, isSubmitting, isDirty },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      answer: data?.answered ? data.answer : "",
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (researchData && researchData?.applicationEndDate) {
      let date = new Date(researchData?.applicationEndDate);
      //date = new Date(date.getFullYear(), date.getMonth(), date.getUTCDate()); // Formatando pra UTC
      const thirtyDaysLater = addDays(date, 31);
      const currentDate = new Date();
      const hasPassedThirtyDays = isAfter(currentDate, thirtyDaysLater);
      setThirtyDaysPassed(hasPassedThirtyDays);
      // setFinalResearchDate(thirtyDaysLater);

      if (
        participationData?.finished &&
        participationData?.deadline_reopening
      ) {
        const reopeningDate = new Date(participationData?.deadline_reopening);
        const canReopen = isAfter(reopeningDate, new Date());
        const afterThirtyDays = isAfter(reopeningDate, thirtyDaysLater);
        if (canReopen && afterThirtyDays) {
          setThirtyDaysPassed(false);
          // setFinalResearchDate(reopeningDate);
        }
      }
    }
  }, [researchData, participationData]);

  useEffect(() => {
    if (!!selectedTagsData && selectedTagsData?.length > 0) {
      const formatedTags = selectedTagsData.map((seletc) => seletc.idTag);
      setValue("tags", formatedTags);
    }
  }, [selectedTagsData, setValue]);

  const onSubmit = async (values) => {
    try {
      if (values.tags) {
        const removedTags = findMissingValues(selectedTagsData, values.tags);
        await Promise.all(
          removedTags.map(async (tag) => {
            try {
              const response = await api.delete("/answer-tags/deleteById", {
                data: {
                  idQuestionAnswer: idAnswer,
                  idTag: tag,
                },
              });
              console.log(response.data);
            } catch (error) {
              console.error(error);
            }
          })
        );
        const newTags = findDifferentValues(selectedTagsData, values.tags);
        await Promise.all(
          newTags.map(async (tag) => {
            try {
              const response = await api.post("/answer-tags", [
                {
                  idQuestionAnswer: idAnswer,
                  idTag: tag,
                },
              ]);
              console.log(response.data);
            } catch (error) {
              console.error(error);
            }
          })
        );
      }

      const answerData = {
        idAnswer: idAnswer,
        answered: "true",
        answer: values?.answer,
        idCompanyRegister: id,
      };

      const res = await api.put("answer", [answerData]);

      if (res?.data?.hasError === false) {
        setSubmittModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if(data) {
      setMaxWords(data?.maxLenght || 5000);

      if (data?.answered) {      
        setValue("answer", data?.answered ? data.answer : "");
        validationSchema
          .validateAt("answer", { answer: data?.answered ? data.answer : "" })
          .then(() => {
            // No validation errors
          })
          .catch((error) => {
            const errorMessage = error.message;
            // Handle validation error
          });
      }
    }

  }, [data, setValue, setMaxWords]);

  useEffect(() => {
    if (data && !idAnswer) {
      setIdAnswer(data?.idAnswer);
    }
  }, [data, idAnswer]);

  const deleteCurrentResponse = useMutation(
    async (updateAnswerData) => {
      try {
        const response = await api.put("answer", [updateAnswerData]);
        return response?.data;
      } catch (error) {
        throw new Error("Erro ao remover a resposta.");
      }
    },
    {
      onSuccess: () => {
        queryClient?.invalidateQueries(["pilar-content", id]);
      },
    }
  );

  async function handleRemoveResponse() {
    try {
      const updateAnswerData = {
        idAnswer: idAnswer,
        answered: false,
        answer: null,
        idCompanyRegister: id,
      };

      await deleteCurrentResponse.mutateAsync(updateAnswerData);

      toast({
        title: "Resposta removida com sucesso!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setValue("answer", "");
    } catch (error) {
      toast({
        title: "Falha na remoção",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const handleSubmitModal = () => {
    setSubmittModal(false);
    window.location.replace(`/registration/profile/pilares-for-all?cr=${id}`);
  };

  return !isLoading ? (
    <>
      <PilarInformationAndStatus data={data} companyId={id}>
        {isThirtyDaysPassed || participationData?.finished ? (
          <SubmitButton
            control={control}
            isDirty={false}
            isValid={false}
            isLoading={false}
          />
        ) : (
          <SubmitButton
            control={control}
            isDirty={isDirty}
            isValid={isValid}
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
          />
        )}
      </PilarInformationAndStatus>

      <CulturaAuditFeedback
        data={data}
        participationData={participationData}
        isThirtyDaysPassed={isThirtyDaysPassed}
      />

      <Stack
        px={{ base: "0px", xl: "100px", "2xl": "150px" }}
        mb={["unset", "56px"]}
      >
        <Stack bg="white" spacing="24px" p="24px" borderRadius="12px">
          <Text
            as="span"
            color="#D50000"
            fontWeight={500}
            fontSize="13px"
            lineHeight="32px"
            mb={["-20px", "-16px"]}
          >
            Pergunta obrigatória
          </Text>

          <Flex gap={["4px", "10px"]} w="100%" mb="24px" lineHeight="32px">
            <Text fontWeight={700}>01.</Text>
            <Text>{data?.questionText}</Text>
          </Flex>
          <Box px={["0", "30px"]}>
            {participationData?.finished || isThirtyDaysPassed ? (
              <CustomTextarea
                {...register("answer")}
                height="460px"
                disabled={true}
              />
            ) : (
              <CustomTextarea
                {...register("answer")}
                error={errors.answer}
                height="460px"
              />
            )}

            <Flex align="center" justify="space-between">
              <Flex>
                {errors.answer && (
                  <Text
                    as="span"
                    fontSize="14px"
                    fontWeight={500}
                    style={{ color: "red" }}
                  >
                    {errors.answer.message}
                  </Text>
                )}
              </Flex>
              <Flex align="center" gap="40px">
                <TextAreaControl
                  control={control}
                  maxLenght={data?.maxLenght}
                />
                {!participationData?.finished && !isThirtyDaysPassed && (
                  <>
                    {data?.answered && data?.answer && (
                      <Button
                        variant="secondary"
                        mt="24px"
                        isLoading={deleteCurrentResponse?.isLoading}
                        onClick={handleRemoveResponse}
                      >
                        Apagar resposta
                      </Button>
                    )}
                  </>
                )}
              </Flex>
            </Flex>
          </Box>

          <PilaresTags
            register={register}
            tagsData={tagsData}
            participationData={participationData}
            isThirtyDaysPassed={isThirtyDaysPassed}
          />

          <FormAddImage
            idAnswer={idAnswer}
            filesResponse={filesResponse}
            isDisabled={participationData?.finished || isThirtyDaysPassed}
          />
        </Stack>
      </Stack>

      {mobile && (
        <Stack
          bg="white"
          p="24px"
          h="104px"
          mt="31px"
          direction="row"
          spacing="20px"
        >
          <Button variant="secondary" w="100%" h="48px">
            Voltar
          </Button>
          <Button
            variant="primary"
            w="100%"
            h="48px"
            disabled={!isValid || isThirtyDaysPassed}
          >
            Salvar
          </Button>
        </Stack>
      )}
      <SuccessModal
        title="Dados salvos com sucesso!"
        isOpen={submitModal}
        onClose={handleSubmitModal}
      />
    </>
  ) : (
    <Flex height="100vh" justify="center" align="center">
      <Spinner />
    </Flex>
  );
}