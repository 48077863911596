import { useEffect, useState } from "react";
import { downloadAll, downloadCert } from "../../services/downloadCert";
import { format, isPast, parse, subDays } from "date-fns";
import seloCompleto from "../../assets/images/gptwSeloNaoLogo.png";
import seloSemData from "../../assets/images/gptwSeloSemData.png";
import seloDistorcido from "../../assets/images/gptwSeloDistorcido.png";
import seloCores from "../../assets/images/gptwSeloAplicacaoCor.png";
import logo from "../../assets/images/icon-certificacao.svg";
import logoGray from "../../assets/images/icon-certificacao-gray.svg";
import iconPendency from "../../assets/images/icon-pendency.svg";
import iconMore from "../../assets/images/icon-more.svg";
import {
  Button,
  Stack,
  Flex,
  Box,
  Tag,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import {
  InnerContainer,
  Modal,
  ModalContainer,
} from "../certificados/certificacoes/styles";
import axios from "axios";

const Selo = ({ company, research, cr }) => {
  const [isFetching, setIsFetching] = useState(true);
  const [modal, setModal] = useState(null);
  const [currentCertification, setCurrentCertification] = useState();

  useEffect(() => {
    const getCertificates = async () => {
      try {
        const url_certificados =
          window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
          `/participation/valid?dc=${company.cnpj
            .replace(/\./g, "")
            .replace(/-/g, "")
            .replace(/\//, "")}`;

        const certificados = await axios.get(url_certificados);

        if (certificados.data !== undefined && certificados.data.length > 0) {
          // filtrando somente as participações de certificação e as pequisas já finalizadas
          const researchsCertification = certificados.data.filter(
            (item) => item.idSurvey !== "null"
          );
          // Filtrando somente as pesquisas com nota
          const researchs = researchsCertification.filter(
            (item) => item.ti !== null
          );

          if (research?.length > 0) {
            const currentParticipationStartDate = format(
              new Date(research[0].application_date),
              "dd-MM-yyyy"
            );
            //  expirationDate: "28-03-2018"
            const lastResearch = researchs.filter(
              (res) => res.surveyStartDate === currentParticipationStartDate
            )[0];
            if (certificados && lastResearch) {
              const expirationDateTransformed = parse(
                lastResearch.expirationDate.replace(/-/g, "/"),
                "dd/MM/yyyy",
                new Date()
              );
              //const subOneDay = subDays(expirationDateTransformed, 1);

              const cert = {
                participationCode: lastResearch.participationCode,
                ti: lastResearch.ti,
                expired: isPast(expirationDateTransformed),
                expirationDate: format(expirationDateTransformed, "dd/MM/yyyy"),
              };

              setCurrentCertification(cert);
            }
          } else {
            const lastResearch = certificados.data.filter(              
              (item) => item.idCompanyRegister === cr
            )[0];
           
            if (certificados && lastResearch) {
              const expirationDateTransformed = parse(
                lastResearch.expirationDate.replace(/-/g, "/"),
                "dd/MM/yyyy",
                new Date()
              );
              //const subOneDay = subDays(expirationDateTransformed, 1);

              const cert = {
                participationCode: lastResearch.participationCode,
                ti: lastResearch.ti,
                expired: isPast(expirationDateTransformed),
                expirationDate: format(expirationDateTransformed, "dd/MM/yyyy")                
              };

              setCurrentCertification(cert);
            }
          }
        } else {
          return;
        }
      } catch (error) {
        // setError(true);
        return;
      } finally {
        setIsFetching(false);
      }
    };

    if (company) {
      setIsFetching(true);
      getCertificates();
    }
  }, [company, research, cr]);

  const openModal = (method, parameters) => {
    setModal(
      <ModalContainer onClick={() => setModal(null)}>
        <Modal>
          <InnerContainer>
            <h1>Orientações de uso do selo</h1>
            <p>
              Abaixo estão as orientações de uso para o selo da Certificação
              GPTW. Ao aceitar os Termos de Uso da plataforma GPTW Connect, o
              usuário também concorda que irá seguir as orientações fornecidas
              neste documento, sob pena de invalidação de sua certificação, caso
              as regras descritas abaixo não sejam cumpridas.{" "}
            </p>
            <ul>
              <li>
                Não é permitido utilizar o logo do GPTW para divulgar a
                certificação da empresa. A empresa deverá utilizar sempre o selo
                de certificação nesses casos.{" "}
              </li>
              <img
                src={seloCompleto}
                style={{
                  maxHeight: "225px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                alt="Selo gptw completo e selo gptw apenas o logo"
              />
              <li>
                Elementos como período de expiração e/ou país de origem que
                compõem o selo de certificação não devem ser alterados ou
                excluídos
              </li>
              <img
                src={seloSemData}
                style={{
                  maxHeight: "225px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                alt="Selo gptw completo e selo gptw apenas o logo"
              />
              <li>Não é permitido redimensionar o selo fora de escala. </li>
              <img
                src={seloDistorcido}
                style={{
                  maxHeight: "225px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                alt="Selo gptw completo e selo gptw apenas o logo"
              />
              <li>
                Não é permitido alterar as cores do selo de certificação.{" "}
              </li>
              <img
                src={seloCores}
                style={{
                  maxHeight: "225px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                alt="Selo gptw completo e selo gptw apenas o logo"
              />
              <li>
                Não é permitido utilizar o selo após sua data de expiração.{" "}
              </li>
              <li>
                Caso haja alguma mudança destes termos de uso, o cliente terá
                que fazer novamente a validação deste documento por meio da
                plataforma GPTW Connect.{" "}
              </li>
              <li>
                A Certificação é apenas o primeiro nível dos reconhecimentos que
                uma organização pode obter do GPTW. Portanto, se sua organização
                apenas possui este selo de certificação, ao divulgar o
                reconhecimento, utilize o termo “certificada”, e não “premiado”.
                Organizações premiadas são apenas aquelas que deram
                prosseguimento ao processo de certificação e obtiveram também o
                selo de premiação em algum ranking das Melhores Empresas para
                Trabalhar.
              </li>
            </ul>
            <h2>Para prosseguir e utilizar o selo, clique no botão abaixo:</h2>
            <Button
              onClick={() =>
                method(parameters[0], parameters[1], parameters[2])
              }
              style={{ padding: "10px 20px" }}
            >
              Eu aceito
            </Button>
          </InnerContainer>
        </Modal>
      </ModalContainer>
    );
  };

  return (
    <>
      {isFetching && !currentCertification ? (
        <Flex h="400px" pt="40px">
          <Text fontSize="14px" fontWeight={600}>
            Carregando...
          </Text>
        </Flex>
      ) : currentCertification === undefined ? (
        <Flex h="80px">
          <Text fontSize="14px" fontWeight={600}>
            Nenhuma participação com certificado disponível encontrada.
          </Text>
        </Flex>
      ) : (
        <>
          {modal}
          <Stack
            w="100%"
            bg="white"
            border="1px"
            borderColor="#CCCCCC"
            borderRadius="12px"
            overflow="hidden"
          >
            <Box p={4} display="flex" flexDirection={"column"}>
              <Flex justify={"space-between"} height={"20px"}>
                <img src={iconPendency} alt="icon pendency" />
                <span
                  style={{
                    fontSize: "14px",
                    color: "#2e2e2e",
                    fontWeight: "500",
                    position: "relative",
                  }}
                >
                  {currentCertification.participationCode}
                </span>
                {/* {currentCertification.expired ? (
                  <Menu>
                    <MenuButton>
                      <img src={iconMore} alt="more" />
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={() =>
                          openModal(downloadCert, [currentCertification, "png"])
                        }
                      >
                        Baixar selo em <b>&nbsp;.PNG</b>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          openModal(downloadCert, [
                            currentCertification,
                            "jpeg",
                          ])
                        }
                      >
                        Baixar selo em <b>&nbsp;.JPEG</b>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          openModal(downloadCert, [
                            currentCertification,
                            "png",
                            "svg",
                          ])
                        }
                      >
                        Baixar selo em <b>&nbsp;.SVG</b>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          openModal(downloadCert, [
                            currentCertification,
                            "png",
                            "pdf",
                          ])
                        }
                      >
                        Baixar selo em <b>&nbsp;.EPS</b>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          openModal(downloadAll, [currentCertification])
                        }
                      >
                        Baixar<b>&nbsp;todos&nbsp;</b>os selos
                      </MenuItem>
                    </MenuList>
                  </Menu>
                ) : (
                  // <img src={iconMore} alt="more" onClick={() => asPng(selos[key])} />
                  <div style={{ width: "20px", height: "20px" }} />
                )} */}
                <div style={{ width: "20px", height: "20px" }} />
              </Flex>
              <img
                src={!currentCertification.expired ? logo : logoGray}
                alt="gptw logo de certificação"
                style={{
                  margin: "0 auto",
                }}
              />

              <Tag
                fontWeight="600"
                bg="#e9e8ef"
                w="65px"
                fontSize="12px"
                alignSelf={"end"}
              >
                Nota:{" "}
                {currentCertification.ti.length > 2
                  ? currentCertification.ti.replace(/\..+/, "")
                  : currentCertification.ti}
              </Tag>
            </Box>
            {!currentCertification.expired ? (
              <Box bg="#e9e8ef" h="auto" w="100%" p={4}>
                <Flex justify="space-between" align="center">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#002070",
                      fontWeight: "500",
                    }}
                  >
                 Selo de certificação expira em:
                  </span>
                  <span
                    style={{
                      color: "#E60000",
                      fontSize: "14px",
                      fontWeight: "bold",
                      lineHeight: "22px",
                    }}
                  >
                    {currentCertification.expirationDate}
                  </span>
                </Flex>
              </Box>
            ) : (
              <Box bg="#fce6e6" h="auto" w="100%" p={4}>
                <Flex justify="space-between" align="center">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#E60000",
                      fontWeight: "500",
                    }}
                  >
                    Certificado expirado em:
                  </span>
                  <span
                    style={{
                      color: "#E60000",
                      fontSize: "14px",
                      fontWeight: "bold",
                      lineHeight: "22px",
                    }}
                  >
                    {currentCertification.expirationDate}
                  </span>
                </Flex>
              </Box>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default Selo;
