import React from "react";
import styled from "styled-components";
import { Links, Icon } from "../../../style";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import background from "../../../style/modal/dialog/background.png";

import { Text, Flex, Stack, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import format from "../../../helpers/formatDateHelper"


const Color = styled.div`
  width: 20px;
  height: 5px;
  background-color: ${(props) => props.cor};
  border-radius: 6px;
  top: 32px;
  left: 32px;
`;

const ContainerCircular = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 58px;
  right: 32px;
`;
const ContainerStatus = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  top: 78px;
  left: 33px;
  display: flex;
  flex-direction: row;
`;

const MiddlePlay = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;

  bottom: 52px;
  left: 32px;

  :hover {
    cursor: pointer;
  }
`;

const MidddleLink = styled.div`
  vertical-align: middle;
  bottom: 52px;
  left: 32px;
`;

const MiddleLink = styled.div`
  vertical-align: middle;
  bottom: 32px;
  left: 32px;
`;

const Alert = ({ ready, text }) => {
  return (
    <Flex
      w="100%"
      h="auto"
      p="8.5px"
      bg={ready ? "#008000" : "red.100"}
      color="#ffffff"
      justify="center"
      align="center"
      gap={["4px"]}
      textAlign="center"
    >
      {!ready && <Icon type="alert-white" />}

      <Text fontSize={["12px", "13px"]} lineHeight={["14px"]}>
        {" "}
        {text}
      </Text>
    </Flex>
  );
};

const getInfoThematicSurvey = (props) => {
  let today = new Date();
  let dateToExpiresSurvey = new Date(props.expires);
  let isExpired = today > dateToExpiresSurvey;
  dateToExpiresSurvey = dateToExpiresSurvey.toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"});
  let dateResponseEnd = props.responseEnd !== null ? format.formateDateHour(props.responseEnd) : null;
  
  return { dateToExpiresSurvey, isExpired, dateResponseEnd};
}

const Questionarios = (props) => {
  let thematicResearch = props['type'] == 'thematic'? true : false;
  let { isExpired,  dateToExpiresSurvey,  dateResponseEnd } = getInfoThematicSurvey(props)

  return (
    <Stack
      width={["100%", props.width ? props.width : "100%"]}
      backgroundImage={background}
      backgroundSize="cover"
      borderRadius="12px"
      backgroundRepeat="no-repeat"
      overflow={"hidden"}
      h="203px"
      position="relative"
      justify={"space-between"}
      style={{ order: props.order }}
    >
      <Stack py="20px" px="24px" spacing={"10px"}>
        <Color cor={props.cor} />
        <Heading
          as="h3"
          fontSize={["18px"]}
          color="white"
          fontWeight={600}
          pb="0px"
        >
          {props.titulo}
        </Heading>
        {
            props['type'] == 'thematic' ? (
                <Heading
                    as="h6"
                    fontSize={["12px"]}
                    color="white"
                    fontWeight={600}
                    pb="0px"
                >
                    {`O questionário deve ser respondido até ${dateToExpiresSurvey}`}
                </Heading>
            ) : <></>
        }
        {props.status === true && (
          <ContainerStatus>
            <>
              <Icon type={props.inscrito ? "check" : "alert-orange"} />
              &nbsp;{props.inscrito ? "Inscrito" : "Não inscrito"}
            </>
          </ContainerStatus>
        )}
        {props.message === "false" && (
          <ContainerStatus>
            <Icon type="alert-orange" /> {`Prazo de preenchimento encerrado!`}
          </ContainerStatus>
        )}
        {/* // TODO: REATIVAR quando tiver os dados no banco de cá pra testar  */}
        {props.method === "redirect" ? (
          <ContainerCircular>
            <CircularProgressbar
              value={props.inicial}
              minValue={0}
              maxValue={props.final}
              text={props.inicial + "/" + props.final}
              styles={buildStyles({
                textSize: "font-size: 16px",
                width: "71px",
                height: "71px",
                pathColor: `#40BF91`,
                textColor: "#ffff",
                trailColor: "#E5E5E5",
              })}
            />
          </ContainerCircular>
        ) : (
          <></>
        )}
        {
          thematicResearch ? (
            isExpired ? 
              (
                <ContainerStatus>
                  <Icon type="alert-orange" /> &nbsp;{`Prazo de preenchimento encerrado!`}
                </ContainerStatus>
              ) : (
                dateResponseEnd == null ? (
                  <MiddlePlay
                    onClick={
                      props.method === "redirect"
                        ? () => props.action(props.surveyLink)
                        : () =>
                            props.action(
                              props.surveyCode,
                              props.surveyId,
                              props.surveyLink
                            )
                    }
                  >
                    <Icon type="icone-play" />
                    &nbsp; &nbsp;
                    <Text
                      color="#fff"
                      fontWeight={"bold"}
                      fontFamily={"Raleway"}
                      fontStyle={"normal"}
                      fontSize={"14px"}
                      lineHeight={"16px"}
                      textDecorationLine={"underline"}
                    >
                      {props.textoUrl}
                    </Text>
                  </MiddlePlay>
                ) : (
                  <ContainerStatus>
                    <>
                      <Icon type="check" />
                      <b>
                        &nbsp;{`Questionário respondido em ${dateResponseEnd}!`}
                      </b>
                    </>
                  </ContainerStatus>
                )
              )
          ) : (
            props.url ? (
              <MiddlePlay>
                <Link to={props.url}>
                  <Icon type="icone-play" />
                </Link>
                &nbsp; &nbsp;
                <Links
                  to={props.url}
                  label={props.textoUrl}
                  color="#fff"
                  underline
                />
              </MiddlePlay>
            ) : (
              <MiddlePlay
                onClick={
                  props.method === "redirect"
                    ? () => props.action(props.surveyLink)
                    : () =>
                        props.action(
                          props.surveyCode,
                          props.surveyId,
                          props.surveyLink
                        )
                }
              >
                <Icon type="icone-play" />
                &nbsp; &nbsp;
                <Text
                  color="#fff"
                  fontWeight={"bold"}
                  fontFamily={"Raleway"}
                  fontStyle={"normal"}
                  fontSize={"14px"}
                  lineHeight={"16px"}
                  textDecorationLine={"underline"}
                >
                  {props.textoUrl}
                </Text>
              </MiddlePlay>
            )
          )
        }
        {/* {props.iconePlay} */}
      </Stack>
      {props.estado && props.todasRespondidas === false && (
        <Alert ready={false} text={props.textoAlerta} />
      )}
      {props.estado && props.todasRespondidas === true && (
        <Alert ready={true} text={props.textoAlerta} />
      )}
    </Stack>
  );
};

export default Questionarios;
