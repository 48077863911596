import axios from "axios";
// import fs from "fs"
import JSZip from "jszip";
import { useEffect } from "react";

const dateFormatter = ({ startCert, endCert }) => {
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  startCert = startCert.split("-");
  const startMonth = months[parseInt(startCert[1]) - 1];

  endCert = endCert.split("/");
  const endMonth = months[parseInt(endCert[1]) - 1];

  return `${startMonth}/${startCert[2]} - ${endMonth}/${endCert[2]}`;
};

export const downloadCert = async (date, imgFormat, canvasFormat, zip) => {
  const startCert = date.surveyEndDate;
  const endCert = date.formattedEndDate;
  const texts = dateFormatter({ startCert, endCert });

  try {
    const url_selo_certificacao =
      window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
      `/selo-certificacao?text=${texts}&type=${imgFormat}${
        canvasFormat ? `&canvas=${canvasFormat}` : ""
      }&survey=${date.idCompanyRegister}`;

    const selo = await axios.get(url_selo_certificacao);

    const base64 = canvasFormat
      ? canvasFormat === "pdf"
        ? selo.data.replace(
            "data:image/png;base64",
            "data:application/postscript;base64"
          )
        : selo.data.replace(
            "data:image/png;base64",
            "data:image/svg+xml;base64"
          )
      : selo.data;

    if (zip) {
      return base64;
    }
    if (date.download) {
      const a = document.createElement("a");

      a.download = `selo-gptw.${
        canvasFormat
          ? canvasFormat === "pdf"
            ? "eps"
            : canvasFormat
          : imgFormat
      }`;
      a.href = base64;
      a.click();
    }

    return selo.data;
  } catch (error) {
    return new Error("erro");
  }
};

export const downloadAll = async (date) => {
  const pngFile = getBase64String(await downloadCert(date, "png", null, true));
  const jpegFile = getBase64String(
    await downloadCert(date, "jpeg", null, true)
  );
  const svgFile = getBase64String(await downloadCert(date, "png", "svg", true));
  const epsFile = getBase64String(await downloadCert(date, "png", "pdf", true));

  const zip = new JSZip();
  const folder = zip.folder("selos");

  folder.file("selo.png", pngFile, { base64: true });
  folder.file("selo.jpg", jpegFile, { base64: true });
  folder.file("selo.svg", svgFile, { base64: true });
  folder.file("selo.eps", epsFile, { base64: true });

  const selos = await zip.generateAsync({ type: "base64", streamFiles: true });

  const a = document.createElement("a");
  a.download = `selos.zip`;
  a.href = `data:application/zip;base64,${selos}`;
  a.click();

  return selos;
};

const getBase64String = (dataURL) => {
  const idx = dataURL.indexOf("base64,") + "base64,".length;
  return dataURL.substring(idx);
};

export const generateThumb = (data) => {
  return <div>data.certificate_badge_color</div>;
};
