import React, { FC, useState } from "react"

import iconVerified from "../../../assets/images/icon-verified.svg"
import iconDelete from "../../../assets/images/icon-delete.svg"
import iconEdit from "../../../assets/images/icon-edit.svg"
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Flex,
  Button,
  useToast,
} from "@chakra-ui/react"
import ModalEdit from "../../colaboradores/Modals/ModalEdit"
import { researchApi } from "../../../services/api-research"
import ModalConfirmDelete from "../../colaboradores/Modals/ModalConfirmDelete"

const TableModel = ({
  currentPage,
  currentCollaborators,
  handleCount,
  total,
  active,
  handleRefresh,
}) => {
  const [editModalCollaborator, setEditModalCollaborator] = useState(false)
  const [deleteModalCollaborator, setDeleteModalCollaborator] = useState(false)
  const [currentEmployee, setCurrentEmployee] = useState()
  const toast = useToast()

  const editEmployeeModal = (employee) => {
    setCurrentEmployee(employee)
    setEditModalCollaborator(true)
    // open modal(employee, 1)
  }

  const handleCloseEditModalColaborator = () => {
    setEditModalCollaborator(false)
  }

  const deleteEmployeeModal = async (employee) => {
    setCurrentEmployee(employee)
    setDeleteModalCollaborator(true)
    // open delete modal(employee)
  }

  const handleCloseDeleteModalColaborator = () => {
    setDeleteModalCollaborator(false)
  }

  handleCount(
    currentCollaborators && currentCollaborators !== 0 ? Object.keys(currentCollaborators).length : 0
  )

  return (
    <Table variant="simple">
      <Thead bg="#e2e1e7">
        <Tr color="#292B30" fontSize="12px">
          <Th
            textTransform="none"
            color="##292B30"
            fontFamily="Raleway"
            px="24px"
            w="80px"
          >
            #
          </Th>
          <Th
            textTransform="none"
            color="#292B30"
            fontFamily="Raleway"
            px={0}
            w="210px"
          >
            Descrição
          </Th>
          <Th
            textTransform="none"
            color="#292B30"
            fontFamily="Raleway"
            px={0}
            w="252px"
          >
            Login
          </Th>
          {/* <Th
            textTransform="none"
            color="#292B30"
            fontFamily="Raleway"
            px={0}
            w="160px"
          >
            Setor
          </Th> */}
          <Th
            textTransform="none"
            color="#292B30"
            fontFamily="Raleway"
            px={0}
            w="138px"
          >
            Status
          </Th>
          <Th
            textTransform="none"
            color="#292B30"
            fontFamily="Raleway"
            px={0}
            w="176px"
          >
            Selecionado
          </Th>
          <Th
            textTransform="none"
            color="#292B30"
            fontFamily="Raleway"
            px={0}
            w="100px"
          >
            Ações
          </Th>
        </Tr>
      </Thead>
      <Tbody bg="white">
        {!currentCollaborators || currentCollaborators === 0 ? (
          <></>
        ) : (
          <>
            <ModalEdit
              isOpen={editModalCollaborator}
              onClose={handleCloseEditModalColaborator}
              researchId={currentEmployee?.id_research}
              total={total}
              active={active}
              refresh={handleRefresh}
              employee={currentEmployee}
            />

            <ModalConfirmDelete 
              isOpen={deleteModalCollaborator}
              onClose={handleCloseDeleteModalColaborator}
              researchId={currentEmployee?.id_research}
              total={total}
              active={active}
              refresh={handleRefresh}
              employee={currentEmployee}
            />

            {currentCollaborators.map((employee, idx) => (
              <Tr fontSize="12px" index={idx}>
                <Td px="24px">{employee.place}</Td>
                <Td px={0}>
                  {employee.name
                    ? employee.name
                    : employee.email
                    ? employee.email
                    : "-"}
                </Td>
                <Td px={0}>{employee.login}</Td>
                {/* <Td px={0}>{user.setor}</Td> */}
                <Td px={0}>
                  {employee.status === "Ativo" ? (
                    <Tag
                      bg="#2CD9DD"
                      fontSize="12px"
                      color="white"
                      borderRadius="6px"
                    >
                      Ativo
                    </Tag>
                  ) : (
                    <Tag
                      bg="#FF6700"
                      fontSize="12px"
                      color="white"
                      borderRadius="6px"
                    >
                      Inativo
                    </Tag>
                  )}
                </Td>
                <Td textAlign="start" px={0}>
                  {employee.status === "Ativo" ? (
                    <img
                      src={iconVerified}
                      alt="icon selected"
                      style={{ marginLeft: "37px" }}
                    />
                  ) : (
                    <></>
                  )}
                </Td>
                <Td px={0}>
                  <Flex gap="20px">
                    <Button
                      background={"white"}
                      onClick={() => editEmployeeModal(employee)}
                    >
                      <img src={iconEdit} hover alt="icon edit" />
                    </Button>
                    <Button
                      background={"white"}
                      onClick={() => deleteEmployeeModal(employee)}
                    >
                      <img src={iconDelete} alt="icon delete" />
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </>
        )}
      </Tbody>
    </Table>
  )
}

export default TableModel
