import {
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import danger from "../../assets/images/danger.svg";
import {
  Add,
  AddCircle,
  Award,
  Cup,
  Document,
  Like,
  Monitor,
  Ranking,
  Shop,
} from "iconsax-react";
import Highlight from "../components/highlight";

function ModalCancellationHold({
  title,
  isOpen,
  onClose,
  handleSubmit,
  children,
  titleSubmit,
  isLoading,
  plan,
}) {
  const initialRef = useRef(null);

  const MOCK_CARD_MODAL = [
    {
      text: "Selo de empresa certificada",
      textHighlight: ["Selo"],
      icon: <Award color="#E60000" />,
      help: "Após o cancelamento, a empresa não terá mais o direito de utilizar o selo, caso esteja ativo.",
      helpHighlight: ["a empresa não terá mais o direito de utilizar o selo"],
    },
    {
      text: "Participação nos Rankings GPTW",
      textHighlight: ["Rankings GPTW"],
      icon: <Ranking color="#E60000" />,
      help: "Após o cancelamento, a empresa perderá o direito de ser considerada para premiações futuras.",
      helpHighlight: [
        "perderá o direito de ser considerada para premiações futuras",
      ],
    },
    {
      text: "Página de Empresa Certificada",
      textHighlight: ["Empresa Certificada"],
      icon: <Cup color="#E60000" />,
      help: "Divulgamos em nosso site todas as Empresas Certificadas, e cada uma delas tem uma página exclusiva com fotos e dados sobre a organização.",
      helpHighlight: ["Empresas Certificadas", "página exclusiva"],
    },
    {
      text: "Comunidade Great Place To Work",
      textHighlight: ["Comunidade Great Place To Work"],
      icon: <Like color="#E60000" />,
      help: "A comunidade para clientes GPTW, com acesso a conteúdos e eventos online, não estará mais disponível após o cancelamento.",
      helpHighlight: ["acesso a conteúdos e eventos online"],
    },
    {
      text: "GPTW Store",
      textHighlight: ["GPTW Store"],
      icon: <Shop color="#E60000" />,
      help: "Empresas Certificadas poderão adquirir produtos exclusivos, o que não será possível após o cancelamento.",
      helpHighlight: ["produtos exclusivos"],
    },
    {
      text: "Sistema Connect",
      textHighlight: ["Sistema Connect"],
      icon: <Monitor color="#E60000" />,
      help: "Após o cancelamento, o acesso ao sistema Connect ficará inativo.",
      helpHighlight: ["acesso ao sistema Connect ficará inativo"],
    },
    {
      text: "Histórico de preços",
      textHighlight: ["Histórico"],
      help: "Nas assinaturas dos planos da Jornada de Certificação & Ranking GPTW, o reajuste anual está vinculado ao IGPM acumulado, enquanto a tabela oficial GPTW é ajustada anualmente conforme diretriz global.",
      icon: <Document color="#E60000" />,
      helpHighlight: [
        "reajuste anual",
        "tabela oficial GPTW é ajustada anualmente conforme diretriz global",
      ],
    },
  ];

  const clickToTalk = () => {
    console.log("🚀 ~ clickToTalk:", clickToTalk);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef}>
      <ModalOverlay />

      <ModalContent borderRadius="20px" maxWidth={["956px"]}>
        <ModalBody
          p={["24px", "24px", "48px"]}
          gap="32px"
          display="flex"
          flexDirection="column"
        >
          <Flex gap={"24px"} direction={"column"} align={"center"}>
            <Image src={danger} alt="Alerta" w="60px" h="60px" />
            <Flex gap="16px" direction={"column"} align={"center"}>
              <Heading
                as="h1"
                fontSize="20px"
                fontWeight={700}
                lineHeight="23px"
              >
                Você está prestes a cancelar sua assinatura!
              </Heading>
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="19px"
                color="#011F3B"
              >
                Ao cancelar o plano da{" "}
                <Text as="span" fontWeight={600}>
                  {plan},{" "}
                </Text>
                você{" "}
                <Text as="span" fontWeight={600} color="#E60000">
                  perderá benefícios{" "}
                </Text>
                como:
              </Text>
            </Flex>
            <Flex
              w="100%"
              gap="24px"
              direction={["column", "row"]}
              wrap={["wrap", "wrap", "nowrap"]}
              justify="center"
            >
              {MOCK_CARD_MODAL.map((item, index) => {
                return index < 3 ? <CardModal key={index} item={item} /> : null;
              })}
            </Flex>
          </Flex>

          <Flex gap="24px" direction={"column"} align={"center"}>
            <Text
              fontSize="16px"
              fontWeight={400}
              lineHeight="19px"
              color="#011F3B"
              w="fit-content"
            >
              Você também{" "}
              <Text as="span" fontWeight={600}>
                perderá o acesso{" "}
              </Text>
              a:
            </Text>

            <Flex
              w="100%"
              gap="24px"
              direction={["column", "row"]}
              wrap={["wrap", "wrap", "nowrap"]}
              justify="center"
            >
              {MOCK_CARD_MODAL.map((item, index) => {
                return index > 2 ? <CardModal key={index} item={item} /> : null;
              })}
            </Flex>
          </Flex>

          <Flex
            bg="#2F3030"
            p="32px"
            borderRadius={"12px"}
            direction={["column", "column", "row"]}
            align={"center"}
            justify="space-between"
            gap="24px"
          >
            <Flex direction={"column"} align={["center", "center", "start"]}>
              <Text
                fontSize="16px"
                fontWeight={700}
                lineHeight="24px"
                color="white"
                textAlign={["center", "center", "start"]}
              >
                Precisando de ajuda? Fale com um de nossos consultores.
              </Text>
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="24px"
                color="white"
                textAlign={["center", "center", "start"]}
              >
                Será um prazer conversar com você e tirar suas dúvidas.
              </Text>
            </Flex>
            <Button
              variant="red"
              onClick={clickToTalk}
              width={["100%"]}
              maxW={"266px"}
              h="56px"
              borderRadius="10px"
              as={Link}
              href="https://jornada-certificacao.gptw.com.br/contatos"
              target="_blank"
            >
              Falar com consultor
            </Button>
          </Flex>

          <Flex
            gap={["16px", "32px"]}
            justify="space-between"
            direction={["column", "row"]}
          >
            <Button
              variant="outlineRed"
              ref={initialRef}
              onClick={onClose}
              width={["100%"]}
              maxW={"266px"}
              h="56px"
              borderRadius="10px"
            >
              Voltar
            </Button>
            <Button
              variant="outlineRed"
              onClick={handleSubmit}
              isLoading={isLoading}
              width={["100%"]}
              maxWidth={"266px"}
              h="56px"
              borderRadius="10px"
            >
              {titleSubmit}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ModalCancellationHold;

const CardModal = ({ item }) => {
  console.log("🚀 ~ item:", item);

  const [openTooltip, setOpenTooltip] = useState(false);
  return (
    <Flex
      rounded={"8px"}
      p="24px"
      gap="8px"
      position={"relative"}
      bg="#F7F9FC"
      direction="column"
      align="center"
      w={["100%"]}
      minW={["226px", "270px", "auto"]}
      maxW={["100%", "fit-content", "271px"]}
    >
      <Tooltip
        label={
          <Highlight text={item.help} highlightWords={item.helpHighlight} />
        }
        isOpen={openTooltip}
        onOpen={() => setOpenTooltip(true)}
        onClose={() => setOpenTooltip(false)}
        hasArrow
        bg="#FEFEFE"
        borderRadius="8px"
        sx={{ ".highlight-container": { whiteSpace: "unset" } }}
      >
        <Flex
          position={"absolute"}
          top="8px"
          right={"8px"}
          bg={openTooltip ? "#E60000" : "#D6D6D6"}
          rounded={"full"}
          w="20px"
          h="20px"
          fontSize={"20px"}
        >
          <Add size="20px" color={openTooltip ? "white" : "black"} />
        </Flex>
      </Tooltip>
      {item.icon}
      <Highlight text={item.text} highlightWords={item.textHighlight} />
    </Flex>
  );
};
