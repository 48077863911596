import { Icon, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { IconClose } from "../../../assets";

const ButtonClose = ({ isOpen, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <IconButton
      variant={"unstyled"}
      size={"sm"}
      icon={
        <IconClose
          style={{
            transition: "fill 0.3s",
            opacity: isHovered ? "1" : "0.6",
            fill: "#22262D",
          }}
        />
      }
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    />
  );
};

export default ButtonClose;
