import axios from 'axios'

export const IBGE = {
    getUFs() {
        const UFs = axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(response => { return response.data })
    
        return UFs
    },

    getCities(UFId) {
        const cities = axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${UFId}/municipios`)
        .then(response => { return response.data })

        return cities;
    }
}

export default IBGE;