import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const InputText = styled.input`

 	font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    border: none;
    border: 1px solid #B8CAE8;
    box-sizing: border-box;
    border-radius: 10px;
    outline:  none;
    padding: 16px;
    width: ${props=>props.width ? props.width : '443px'};
    height: ${props=>props.height ? props.height : '56px'};




`

const StyledContainer =styled.div`
  display: flex;
  flex-direction: column;

`

const StyleLabel = styled.label`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;

color: #292B30;
`

function InputEmail(props){
  const [valor, setValor] = useState('');

  useEffect(() => {
    setValor(props.valor)
  })

	return  <StyledContainer>
            <StyleLabel>{props.label}</StyleLabel>
            <InputText type="text" {...props} value={valor} onChange={(e)=>setValor(e.target.value)} />
         </StyledContainer>
}

export default InputEmail
