import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Error from "./Error";
import RankingCard from "./RankingCard";
import { useFormsInfo } from "../../helpers/useFormsInfo";
import moment from 'moment';
import axios from "axios"

import {
  Stack,
  Heading,
  Grid,
  GridItem,
  Box,
  Tag,
  Text,
  Image,
  Flex,
  Spinner,
  Link,
  useMediaQuery,
  TabPanels,
  Tab,
  Tabs,
  TabPanel,
  TabList
} from "@chakra-ui/react";
import SimpleSidebar from "../components/nav/Sidebar";
import bg from "../../assets/images/rankings-bg-custom.svg";
import arrow from "../../assets/images/arrow-rankings.svg";
import etapas from "../../assets/images/etapas.svg";
import trophy from "../../assets/images/ranking-icone.svg";
import cant from "../../assets/images/icon-cant.svg";
import iconReady from "../../assets/images/icon-ready.svg";
import { Pagination } from "../components/pagination";
import { elegibility } from "./use-eligibility";
import useCertification from "./use-research-status";
import useResearchInfo from "./use-research-info";
import useRankingsList from "./use-rankings-list";
import queryParamsPresent from "./get-params";
import { getCookie } from "../common/cookieManager";
import { checkSizeCompany } from "./use-checkSizeCompany";
import { useParticipationDataQuery } from "../cadastro/pilares-for-all/hooks";

const Rankings = () => {
  const [plan, setPlan] = useState(getCookie("PLANO_FULL"));  

  const [st, id, token] = queryParamsPresent();

  const [page, setPage] = useState(1);
  const [pageControl, setPageControl] = useState(0);
  const [isLast, setIslast] = useState(false);

  const [isMobile] = useMediaQuery("(max-width: 1090px)");

  const { data: forms } = useFormsInfo(id);
  const questionnaires = elegibility(forms);
  const questionnairesLink = !!questionnaires
    ? ""
    : `/registration/profile?cr=${id}&st=${st}&token=${token}`;

  const [certificaded, isCertificationLoading, isError] = useCertification(id);
  const [researchInfo, isResearchLoading] = useResearchInfo(id);
  const [current, currentRankings, isLoading] = useRankingsList(
    id,
    pageControl
  );
  const { data: participationData, isLoading: participationDataIsLoading } =
    useParticipationDataQuery(id);

  // const [pilaresForAll, setPilaresForAll] = useState(null);
  const [loading, setIsLoading] = useState(null);
  const [diversityRankingsData, setDiversityRankingsData] = useState([])
  const sizeCompany = checkSizeCompany(plan, researchInfo.employeesQuantity)

  
  const handleSetPage = (num) => {
    num === 1 ? setPageControl(0) : setPageControl(pageControl + 16);
  };

  const handleSetPreviousPage = (num) => {
    num === 1 ? setPageControl(0) : setPageControl(pageControl - 16);
  };

  const getEarliestEndLimitDate = (items) => {
    const activeItems = items.filter(item => item.status === 'ACTIVE');
    if (activeItems.length === 0) {
      return null;
    }
    
    const earliestDate = activeItems
      .map(item => {
        const date = moment.utc(item.end_limit_date);
        return date;
      })
      .reduce((earliest, current) => {
        return current.isBefore(earliest) ? current : earliest;
      }, moment.utc(activeItems[0].end_limit_date));
  
    return earliestDate;
  };
  
  const earliestDateRule = window["runConfig"].REACT_APP_EARLIER_DATE_RULE;

  const deadline = (researchInfo) => {
  moment.locale('pt-br');

  if (earliestDateRule === "active") {
    const earliestDate = getEarliestEndLimitDate(current);
    if (!earliestDate) {
      return { msg1: "Não há listas disponíveis", finalDeadline: null };
    }

    const limitDate = earliestDate.format('DD/MM/YYYY');
    const msg1 = checkLimitDeadLine(researchInfo) === '#FFCCCC' || checkLimitDeadLine(researchInfo) === '#F5F5DC' ? `Você teve até ` : `Você tem até `;

    return { msg1: msg1, finalDeadline: limitDate };
  } else {
    const limitDate = moment(researchInfo.applicationEndDate).add(30, 'days').format('DD/MM/YYYY');
    const msg1 = checkLimitDeadLine(researchInfo) === '#FFCCCC' || checkLimitDeadLine(researchInfo) === '#F5F5DC' ? `Você teve até ` : `Você tem até `;

    return { msg1: msg1, finalDeadline: limitDate };
  }
};

const checkStatus = () => {  
  const status = questionnaires && certificaded && (participationData?.finished || researchInfo.employeesQuantity < 100) ? 'Tudo certo! Você preencheu todos os requisitos.' : 'Ainda faltam requisitos para serem preenchidos'
  const color = questionnaires && certificaded && (participationData?.finished || researchInfo.employeesQuantity < 100) ? '#98FB98' : '#FFCCCC'
  return {color: color, status: status}  
};

const checkLimitDeadLine = (researchInfo) => {
  const limitDate = moment(researchInfo.applicationEndDate).add(30, 'days').format('MM/DD/YYYY'); 
  const dateToday = moment().format('MM/DD/YYYY');
  let color = moment(dateToday).locale('pt-br').isAfter(limitDate) ? '#FFCCCC' : '#98FB98';
  color = (questionnaires && certificaded) && color === '#FFCCCC' ? '#F5F5DC' : color;

  return color;  
};

const url_results_check_for_all =
window["runConfig"].REACT_APP_URL_RESULT_SERVICE + `/participation/check-pilares-for-all?dc=${id}`

const url_results_ranking_diversidade_all = 
window["runConfig"].REACT_APP_URL_RESULT_SERVICE + `/ranking-diversidade?dc=${id}`

  useEffect(() => {
    if (isLast === true && page !== 1)
      setPageControl(currentRankings.length - 16); 
          
    const checkRankingsDiversidade = async () => {
      try {
        // const statusPilaresForAll = await axios.get(url_results_check_for_all)

        // setPilaresForAll(statusPilaresForAll.data)

        await axios.get(url_results_ranking_diversidade_all)
        .then((response) => {
          response.data.map(async (item) => {
            await axios.get(
              window["runConfig"].REACT_APP_URL_RESULT_SERVICE + 
              `/ranking-diversidade/getEach?companyRegisterId=${id}&externalSurveyId=${item.id}`
            )
            .then(response => {
              const data = response.data
              item = {...item, formData: data}
              diversityRankingsData.push(item)
            })
          })
        })

        setIsLoading(false);
      } catch (err) {
        console.log('Problema na busca status pilares for all ' + err)
      }

    }
    setIsLoading(true);
    checkRankingsDiversidade();

  }, [isLast, currentRankings, page]);

  useEffect(() => {
    if (!id) {
      window.location.replace("/login");
    }
  }, [id]);

  return (
    <SimpleSidebar>
      {isLoading && isResearchLoading && isCertificationLoading && loading && participationDataIsLoading ? (
        <Flex h="40vh" justify="center" align="center">
          <Spinner />
        </Flex>
      ) : /*isError ? (
        <Error />
      ) :*/ (
        <Stack spacing="20px" px={["0", "20px"]}>
          <Heading fontSize={"18px"}>Rankings</Heading>
          <Grid
            templateColumns={["repeat(1, 1fr)", "repeat(4, 1fr)"]}
            gap={[0, 6]}
            border="1px solid #CCCCCC"
            borderRadius="12px"
            bg="white"
            overflow={"hidden"}
          >
            <GridItem
              w="100%"
              minW={["100%", "340px"]}
              h={["153px", "auto"]}
              colSpan={1}
              backgroundImage={bg}
              backgroundRepeat="no-repeat"
              backgroundSize={["90% 100%", "cover"]}
              borderRadius="12px"
              position={"relative"}
            >
              <Stack
                p={["22px", "26px"]}
                spacing={"8px"}
                alignItems={"flex-start"}
                color="white"
              >
                <Tag
                  color="white"
                  fontSize={"13px"}
                  fontWeight={600}
                  bg="#2CD9DD"
                  h="24px"
                >
                  Pré-requisitos
                </Tag>
                <Flex h={["unset", "125px"]} gap="20px" alignItems={"flex-end"}>
                  <Text
                    fontSize={["18px", "20px"]}
                    lineHeight={["33.6px", "28px"]}
                    fontWeight={700}
                  >
                    Para ser apto a se
                    <br />
                    inscrever em um Ranking
                  </Text>

                  <Image
                    src={arrow}
                    alt="arrow"
                    display={["none", "flex"]}
                    bottom={"26px"}
                    right={8}
                  />
                </Flex>
              </Stack>
              <Image
                src={trophy}
                alt="trophy"
                position={"absolute"}
                display={["flex", "none"]}
                bottom={0}
                right="-10px"
              />
            </GridItem>
            <GridItem w="100%" h={["auto", "128px", "148px"]} colSpan={2}>
              <Stack spacing={["22px", "8px"]} py={["22px", "14px"]}>
                <Flex
                  p={["0px 18px 0 18px", "12px 16px 12px 16px"]}
                  align="center"
                  maxH={["unset", "56px"]}
                  gap={["12px", "16px"]}
                  borderRadius="12px"
                  border={["none", "1px solid #CCCCCC"]}
                >
                  {certificaded ? (
                    <Image src={iconReady} alt="etapas" />
                  ) : (
                    <Image src={etapas} alt="etapas" />
                  )}

                  <Text
                    fontSize={"14px"}
                    color="#292B30"
                    fontWeight={600}
                    whiteSpace="nowrap"
                  >
                    Certificado na pesquisa de Clima
                  </Text>
                  <Box fontSize={"12px"} ml="auto">
                    {certificaded ? (
                      <Text
                        color="#40BF91"
                        fontWeight={500}
                        lineHeight="19.6px"
                      >
                        Concluído
                      </Text>
                    ) : (
                      <Link
                        as={RouterLink}
                        to={`/registration/profile?cr=${id}&st=${st}&token=${token}`}
                        justifySelf={"flex-end"}
                        fontSize={"12px"}
                        fontWeight={[600, 400]}
                        variant={"link"}
                        textDecor="underline"
                        color="red.100"
                      >
                        {isMobile ? "Não certificado" : "Não certificado"}
                      </Link>
                    )}
                  </Box>
                </Flex>

                <Flex
                  p={["0px 18px 0 18px", "12px 16px 12px 16px"]}
                  align="center"
                  maxH={["unset", "56px"]}
                  gap={["12px", "16px"]}
                  borderRadius="12px"
                  border={["none", "1px solid #CCCCCC"]}
                >
                  {questionnaires ? (
                    <Image src={iconReady} alt="etapas" />
                  ) : (
                    <Image src={etapas} alt="etapas" />
                  )}
                  <Text fontSize={"14px"} color="#292B30" fontWeight={600}>
                    Questionários de Certificação e Ranking....
                  </Text>
                  <Box fontSize={"12px"} ml="auto">
                    {questionnaires ? (
                      <Text
                        color="#40BF91"
                        fontWeight={500}
                        lineHeight="19.6px"
                      >
                        Concluído
                      </Text>
                    ) : (
                      <Link
                        as={RouterLink}
                        to={questionnairesLink}
                        justifySelf={"flex-end"}
                        fontSize={"12px"}
                        fontWeight={[600, 400]}
                        variant={"link"}
                        textDecor="underline"
                        color="red.100"
                      >
                        {isMobile ? "Responder" : "Responder cadastro"}
                      </Link>
                    )}
                  </Box>
                </Flex>

                <Flex
                  p={["0px 18px 0 18px", "12px 16px 12px 16px"]}
                  align="center"
                  maxH={["unset", "56px"]}
                  gap={["12px", "16px"]}
                  borderRadius="12px"
                  border={["none", "1px solid #CCCCCC"]}
                >
                  {console.log('sizeCompany', sizeCompany)}

                  {participationData?.finished || sizeCompany == 'SMALL'  ? (
                    <Image src={iconReady} alt="etapas" />
                  ) : (
                    <Image src={etapas} alt="etapas" />
                  )}
                  <Text fontSize={"14px"} color="#292B30" fontWeight={600}>
                    Pilares For All
                  </Text>
                  <Box fontSize={"12px"} ml="auto">
                    {participationData?.finished || sizeCompany == 'SMALL' ? (
                      <Text
                        color="#40BF91"
                        fontWeight={500}
                        lineHeight="19.6px"
                      >
                        Concluído
                      </Text>
                    ) : (
                    <Text
                      color="red.100"
                      fontWeight={500}
                      lineHeight="19.6px"
                    >
                      Não Concluído.
                    </Text>

                    )}
                  </Box>
                </Flex>
              </Stack>
            </GridItem>
 
            <GridItem
              w="100%"
              h="178px"
              colSpan={1}
              display={["none", "flex"]}
              justifyContent={"center"}
            >
              <Image src={trophy} alt="trophy" h="100%" />
            </GridItem>
          </Grid>
          {!certificaded && (
            <Flex
              bg="#FFCCCC"
              py="12px"
              borderRadius={"8px"}
              align="center"
              justify={"center"}
              gap="8px"
              px="15px"
              textAlign={"center"}
            >
              <Image src={cant} alt="" />

              <Text
                fontSize={["13px", "14px"]}
                lineHeight="20px"
                color="red.100"
              >
                <b>Atenção!</b> Participação não certificada. Não é possível
                concorrer aos rankings!
              </Text>
            </Flex>
          )}
          {certificaded && (researchInfo) && (
            <Flex
              bg={checkStatus().color}
              py="12px"
              borderRadius={"8px"}
              align="center"
              justify={"center"}
              gap="8px"
              px="15px"
              textAlign={"center"}
            >
              <Image src={cant} alt="" />

              <Text
                fontSize={["13px", "14px"]}
                lineHeight="20px"
                color="#292B30"
              >
                Status dos Requisitos :
                <b> {checkStatus().status} </b>                
              </Text>
            </Flex>
          )}
          
          {researchInfo.applicationEndDate && certificaded &&(
            <Flex
                bg={checkLimitDeadLine(researchInfo)}
                py="12px"
                borderRadius={"8px"}
                align="center"
                justify={"center"}
                gap="8px"
                px="15px"
                textAlign={"center"}
              >
                <Image src={cant} alt="" />

                <Text
                  fontSize={["13px", "14px"]}
                  lineHeight="20px"
                  color="#292B30"
                >
                  <b> Atenção! </b>
                  {deadline(researchInfo).msg1}
                  <b> {deadline(researchInfo).finalDeadline} </b>
                  para preencher os Pré-Requisitos para estar apto a participar dos Rankings
                  
                </Text>
              </Flex>
          )}
        
        <Tabs variant="unstyled" mt="20px">
            <Flex justifyContent="space-between" mt="25px" h="27px" mb="35px">
              <TabList color="#292B30">
                <Tab
                  fontSize={["14px", "16px"]}
                  lineHeight={["21px",'18.78px']}
                  _focus={{
                    border: "none",
                  }}
                  _selected={{
                    fontWeight: "700",
                    borderBottom: "3px solid #E60000",
                    bg: "none",
                  }}
                >
                  Nacional
                </Tab>
                <Tab
                  fontSize={["14px", "16px"]}
                  lineHeight={["21px",'18.78px']}
                  _focus={{
                    border: "none",
                  }}
                  _selected={{
                    fontWeight: "700",
                    borderBottom: "3px solid #E60000",
                    bg: "none",
                  }}
                >
                  Multinacional
                </Tab>
                <Tab
                  fontSize={["14px", "16px"]}
                  lineHeight={["21px",'18.78px']}
                  _focus={{
                    border: "none",
                  }}
                  _selected={{
                    fontWeight: "700",
                    borderBottom: "3px solid #E60000",
                    bg: "none",
                  }}
                >
                  Regional
                </Tab>
                <Tab
                  fontSize={["14px", "16px"]}
                  lineHeight={["21px",'18.78px']}
                  _focus={{
                    border: "none",
                  }}
                  _selected={{
                    fontWeight: "700",
                    borderBottom: "3px solid #E60000",
                    bg: "none",
                  }}
                >
                  Diversidade
                </Tab>
                <Tab
                  fontSize={["14px", "16px"]}
                  lineHeight={["21px",'18.78px']}
                  _focus={{
                    border: "none",
                  }}
                  _selected={{
                    fontWeight: "700",
                    borderBottom: "3px solid #E60000",
                    bg: "none",
                  }}
                >
                  Destaque
                </Tab>
                <Tab
                  fontSize={["14px", "16px"]}
                  lineHeight={["21px",'18.78px']}
                  _focus={{
                    border: "none",
                  }}
                  _selected={{
                    fontWeight: "700",
                    borderBottom: "3px solid #E60000",
                    bg: "none",
                  }}
                >
                  Setorial
                </Tab>
              </TabList>
            </Flex>
            <TabPanels>
              <TabPanel p={0} pt="28px">
                <Grid
                  pt="14px"
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(4, 1fr)",
                  }}
                  gap={["16px", "16px", "16px", "8px", "16px"]}
                >
                  {currentRankings?.filter && currentRankings?.filter(data => data.type === "Nacional").length > 0 ? 
                  currentRankings?.filter(data => data.type === "Nacional").map((data, index) => {
                    return (
                    <RankingCard
                      key={data.id}
                      certificaded={certificaded}
                      canSignUp={certificaded}
                      ranking={data}
                      page={page}
                      companyId={id}
                      researchInfo={researchInfo}
                    />
                  )}) : "Não há rankings dessa categoria para você participar!"}
                </Grid>
              </TabPanel>
              <TabPanel p={0} pt="28px">
                <Grid
                  pt="14px"
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(4, 1fr)",
                  }}
                  gap={["16px", "16px", "16px", "8px", "16px"]}
                >
                  {currentRankings?.filter && currentRankings?.filter(data => data.type === "Multinacional").length > 0 ? 
                  currentRankings?.filter(data => data.type === "Multinacional").map((data, index) => {
                    return (
                    <RankingCard
                      key={data.id}
                      certificaded={certificaded}
                      canSignUp={certificaded}
                      ranking={data}
                      page={page}
                      companyId={id}
                      researchInfo={researchInfo}
                    />
                  )}) : "Não há rankings dessa categoria para você participar!"}
                </Grid>
              </TabPanel>
              <TabPanel p={0} pt="28px">
                <Grid
                  pt="14px"
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(4, 1fr)",
                  }}
                  gap={["16px", "16px", "16px", "8px", "16px"]}
                >
                  {currentRankings?.filter && currentRankings?.filter(data => data.type === "Regional").length > 0 ? 
                  currentRankings?.filter(data => data.type === "Regional").map((data, index) => {
                    return (
                    <RankingCard
                      key={data.id}
                      certificaded={certificaded}
                      canSignUp={certificaded}
                      ranking={data}
                      page={page}
                      companyId={id}
                      researchInfo={researchInfo}
                    />
                  )}) : "Não há rankings dessa categoria para você participar!"}
                </Grid>
              </TabPanel>
              <TabPanel p={0} pt="28px">
                <Grid
                  pt="14px"
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(4, 1fr)",
                  }}
                  gap={["16px", "16px", "16px", "8px", "16px"]}
                >
                  {currentRankings?.filter && currentRankings?.filter(data => data.type === "Diversidade").length > 0 ? 
                  currentRankings?.filter(data => data.type === "Diversidade").map((data, index) => {
                    const getRanking = diversityRankingsData.find((item) => {
                      return data.survey_code === item.id
                    })

                    return (
                    <RankingCard
                      key={data.id}
                      certificaded={certificaded}
                      canSignUp={certificaded}
                      ranking={data}
                      page={page}
                      companyId={id}
                      researchInfo={researchInfo}
                      rankingInfo={getRanking}
                    />
                  )}) : "Não há rankings dessa categoria para você participar!"}
                </Grid>
              </TabPanel>
              <TabPanel p={0} pt="28px">
                <Grid
                  pt="14px"
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(4, 1fr)",
                  }}
                  gap={["16px", "16px", "16px", "8px", "16px"]}
                >
                  {currentRankings?.filter && currentRankings?.filter(data => data.type === "Destaque").length > 0 ? 
                  currentRankings?.filter(data => data.type === "Destaque").map((data, index) => {
                    return (
                    <RankingCard
                      key={data.id}
                      certificaded={certificaded}
                      canSignUp={certificaded}
                      ranking={data}
                      page={page}
                      companyId={id}
                      researchInfo={researchInfo}
                    />
                  )}) : "Não há rankings dessa categoria para você participar!"}
                </Grid>
              </TabPanel>
              <TabPanel p={0} pt="28px">
                <Grid
                  pt="14px"
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(4, 1fr)",
                  }}
                  gap={["16px", "16px", "16px", "8px", "16px"]}
                >
                  {currentRankings?.filter && currentRankings?.filter(data => data.type === "Setorial").length > 0 ? 
                  currentRankings?.filter(data => data.type === "Setorial").map((data, index) => {
                    return (
                    <RankingCard
                      key={data.id}
                      certificaded={certificaded}
                      canSignUp={certificaded}
                      ranking={data}
                      page={page}
                      companyId={id}
                      researchInfo={researchInfo}
                    />
                  )}) : "Não há rankings dessa categoria para você participar!"}
                </Grid>
              </TabPanel>
            </TabPanels>
          </Tabs>

          {/* <Grid
            pt="14px"
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(4, 1fr)",
            }}
            gap={["16px", "16px", "16px", "8px", "16px"]}
          >
            {currentRankings?.map((data, index) => (
              <RankingCard
                key={data.id}
                certificaded={certificaded}
                canSignUp={certificaded}
                ranking={data}
                page={page}
                companyId={id}
                researchInfo={researchInfo}
              />
            ))}
          </Grid> */}
          {/* <Box ml={["auto"]}>
            <Pagination
              currentPage={page}
              setIslast={setIslast}
              handleSetPreviousPage={handleSetPreviousPage}
              handleSetPage={handleSetPage}
              onPageChange={setPage}
              totalCountOfRegisters={currentRankings?.length}
            />
          </Box> */}
        </Stack>
      )}
    </SimpleSidebar>
  );
};

export default Rankings;

