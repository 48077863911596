import axios from "axios"

export const downloadAffirmatives = async (research, setIsOpen) => {

  // Se a pesquisa não rodou no sistema de pesquisa não acessamos o relatorio
  if(research.idSurvey.length > 6) {
    return alert('Relatório não disponível')
  }

  console.log(research);

  if (!(research.plan.search('PREMIUM') > -1)) {
    try {
      const url_result =
      window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
        `/report/affirmatives/${research.idSurvey}`

      const report = await axios.get(url_result, {method: "GET", responseType: 'blob'})    

      const url = URL.createObjectURL(report.data)

      const a = document.createElement('a')
      a.download = `afirmativas${research.idSurvey}.xls`
      a.href = url
      a.click()    

      return report
    } catch (e) {
      return new Error('erro' + JSON.stringify(e))
    }
  } else {
    setIsOpen(true);
    // const index = research.plan.indexOf('_');
    // const plan = research.plan.substring(index + 1);
    // const a = document.createElement('a')
    // a.target="_blank"
    // a.href = `https://produtos.gptw.com.br/produtos/trustindex/?product=${plan}`
    // a.click() 
  }
}