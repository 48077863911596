import axios from "axios";

const username =
  window["runConfig"].REACT_APP_RESEARCH_API_USER || "connect@gptw.com.br";
const password = window["runConfig"].REACT_APP_RESEARCH_API_PASS || "1234";

export const researchApi = axios.create({
  baseURL:
    window["runConfig"].REACT_APP_URL_RESEARCH_API ||
    "http://localhost:30680/api/certification/",

  auth: {
    username: username,
    password: password,
  },
});

const usernameRank =
  window["runConfig"].REACT_APP_RANKING_API_USER || "connect@gptw.com.br";
const passwordRank = window["runConfig"].REACT_APP_RANKING_API_PASS || "1234";

export const rankingApi = axios.create({
  baseURL:
    window["runConfig"].REACT_APP_URL_RANKING_API ||
    "http://localhost:30680/api/rankings/",
  auth: {
    username: usernameRank,
    password: passwordRank,
  },
});

const usernameDash =
  window["runConfig"].REACT_APP_DASH_API_USER || "connect@gptw.com.br";
const passwordDash = window["runConfig"].REACT_APP_DASH_API_PASS || "1234";

export const dashboardApi = axios.create({
  baseURL:
    window["runConfig"].REACT_APP_URL_DASH_API ||
    "http://localhost:30680/api/dashboard/",
  auth: {
    username: usernameDash,
    password: passwordDash,
  },
});

const urlExtractApi = window["runConfig"].REACT_APP_URL_EXTRACTOR_API || "";

export const extractApi = (token) =>
  axios.create({
    baseURL: urlExtractApi,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
