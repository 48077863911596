const transformObjectsToString = (objects) => {
    let result = 'CNPJ;Conteúdo do Registro;Data;\n';

    objects.forEach(obj => {
        const {
            company_document,
            log_type,
            log,
            creation_date_time
        } = obj;

        // Verifica se o log_type contém a palavra 'erro'
        if (!log_type.toLowerCase().includes('erro')) {
            const formattedDateTime = new Date(creation_date_time).toLocaleDateString();
            result += `${company_document};${JSON.stringify(log)};${formattedDateTime};\n`;
        }
    });

    return result;
}

export {
    transformObjectsToString,
};