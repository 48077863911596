/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";

import { queryClient } from "../../../../services/queryCliente";
import { useResearch } from "../../../../helpers/useResearch";

import iconBar from "../../../../assets/images/icon-bar.svg";
import iconRemove from "../../../../assets/images/icon-remove-red.svg";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Box,
  Flex,
  Tag,
  Tabs,
  Stack,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import { Input } from "../../../components/input";
import { researchApi } from "../../../../services/api-research";
import SuccessModal from "../../../components/modal/SuccessModal";

const schema = Yup.object().shape({
  customTheme: Yup.string().max(
    100,
    "A opção deve ter no máximo 100 caracteres"
  ),
  workalternative: Yup.string().max(
    100,
    "A opção deve ter no máximo 100 caracteres"
  ),
  customOption: Yup.string().max(
    150,
    "A opção deve ter no máximo 150 caracteres"
  ),
  positionalternative: Yup.string().max(
    100,
    "A opção deve ter no máximo 100 caracteres"
  ),
});

const defaultPositionOptions = [
  "Vendedor",
  "Operacional",
  "Gerente e Supervisor",
  "Administrativo",
  "Estagiário",
  "Executivo e Gestor Sênior",
  "Técnico",
];

const defaultWorkOptions = [
  "Vendas/Comercial",
  "Logística/Supply Chain",
  "Tecnologia da Informação",
  "Jurídico/Auditoria",
  "Produção/Operação",
  "Recursos Humanos",
  "Qualidade",
  "Administrativo/Financeiro",
  "Marketing",
  "Regionais/Agências",
  "Manutenção",
];

const SpecificQuestionsModal = ({
  onClose,
  isOpen,
  researchId,
  info,
  defaultTheme,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const toast = useToast();
  const { data } = useResearch(researchId);

  const [mobile] = useMediaQuery("(min-width: 1024px)");

  const [success, setSuccess] = useState(false);
  const [hasPreviousData, setPreviousData] = useState(false);
  const [workOptions, setworkOptions] = useState(defaultWorkOptions);
  const [positionOptions, setPositionOptions] = useState(
    defaultPositionOptions
  );
  const [customOptions, setCustomOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmitOptions = useMutation(
    async (model) => {
      let response;
      setLoading(true);
      for await (let num of model) {
        if (num.type === "Custom") {
          response = await researchApi.post(`/specific-questions`, {
            ...num,
          });
        } else {
          response = await researchApi.put(`/specific-questions/${num.id}`, {
            ...num,
          });
        }
      }
      const updateValues = {
        ...data.research,
      };

      const res = await researchApi.put(`/research/${researchId}`, {
        ...updateValues,
      });

      console.log("response", response);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("specific");
        setSuccess(true);
        setLoading(false);
      },
    }
  );

  const onSubmit = async (values) => {
    if (customOptions.length > 0 && watch("customTheme").length === 0) {
      toast({
        title: "Falha no envio",
        description: "Defina o tema da pergunta customizável",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const work = hasPreviousData
      ? {
          type: "Desktop",
          alternatives: workOptions.join(";"),
          teme: "Área de Trabalho",
          research: researchId,
          id: info[0].id,
        }
      : {
          type: "Desktop",
          alternatives: workOptions.join(";"),
          teme: "Área de Trabalho",
          research: researchId,
        };

    const position = hasPreviousData
      ? {
          type: "Positions",
          alternatives: positionOptions.join(";"),
          teme: "Cargo",
          research: researchId,
          id: info[1].id,
        }
      : {
          type: "Positions",
          alternatives: positionOptions.join(";"),
          teme: "Cargo",
          research: researchId,
        };

    const custom =
      hasPreviousData && info.length > 2
        ? {
            type: "Custom",
            alternatives: customOptions.join(";"),
            teme: values.customTheme,
            research: researchId,
            id: info[2].id,
          }
        : {
            type: "Custom",
            alternatives: customOptions.join(";"),
            teme: values.customTheme,
            research: researchId,
          };
    const options = [work, position, custom];

    let update = [];

    options.forEach((option, index) => {
      if (option.alternatives !== "") {
        update.push(option);
      } else {
        return;
      }
    });

    try {
      const res = await handleSubmitOptions.mutateAsync(update);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddOption = (type) => {
    if (watch("workalternative").length > 100) return;
    if (watch("workalternative").replace(/^\s+|\s+$/g, "").length === 0) return;

    if (workOptions.length < 11) {
      const newOptions = [...workOptions];
      newOptions.push(watch("workalternative"));
      setworkOptions(newOptions);
      resetField("workalternative");
    }
  };

  const handlePositionAlternative = () => {
    if (watch("positionalternative").length > 100) return;
    if (watch("positionalternative").replace(/^\s+|\s+$/g, "").length === 0)
      return;

    if (positionOptions.length < 11) {
      const newOptions = [...positionOptions];
      newOptions.push(watch("positionalternative"));
      setPositionOptions(newOptions);
      resetField("positionalternative");
    }
  };

  const handleCustomAlternative = () => {
    // if (watch("customOption").replace(/^\s+|\s+$/g, "").length === 0) return;

    if (customOptions.length < 12) {
      const newOptions = [...customOptions];
      newOptions.push(watch("customOption"));
      setCustomOptions(newOptions);
      resetField("customOption");
    }
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  };

  const removeOption = (ref) => {
    const newOptions = [...workOptions].filter((o, index) => index !== ref);
    setworkOptions(newOptions);
  };

  const removePositionOption = (ref) => {
    const newOptions = [...positionOptions].filter((o, index) => index !== ref);
    setPositionOptions(newOptions);
  };

  const removeCustomOption = (ref) => {
    const newOptions = [...customOptions].filter((o, index) => index !== ref);
    setCustomOptions(newOptions);
  };

  useEffect(() => {
    if (info instanceof Object && info.length > 1) {
      setPreviousData(true);
      const work = info.find((w) => w.type === "Desktop");
      const positions = info.find((w) => w.type === "Positions");
      const custom = info.find((w) => w.type === "Custom");
      setworkOptions(work.alternatives.split(";"));
      setPositionOptions(positions.alternatives.split(";"));
      if (custom) {
        setCustomOptions(custom.alternatives.split(";"));
      }
    }
  }, [info]);

  return success ? (
    <SuccessModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Perguntas específicas definidas com sucesso"
    />
  ) : (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="6xl"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        h={["100vh", "auto"]}
        p={["22px 0", "20px 10px"]}
        w={["100vw", "1112px"]}
        borderRadius={"12px"}
      >
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Image src={iconBar} alt="icon" mb="10px" />
            <Heading
              as="h3"
              fontSize="18px"
              fontWeight="600"
              mb="24px"
              color="#292B30"
            >
              Personalização da Pesquisa de Clima
            </Heading>

            <Stack
              p={[0, 5]}
              spacing={["10px", "20px"]}
              color="#292B30"
              border={["0", "1px solid #CCCCCC"]}
              borderRadius="12px"
            >
              <Tag
                w="fit-content"
                bg="gray.400"
                color="#002070"
                fontSize={["15px", "16px"]}
                fontWeight={"600"}
                h="23px"
                borderRadius="20px"
                px={["12px", "15px"]}
              >
                Perguntas específicas
              </Tag>

              <Tabs variant="unstyled">
                <TabList
                  justifyContent={["space-between", "unset"]}
                  gap={["unset", "40px"]}
                >
                  <Tab
                    fontSize={["13px", "15px"]}
                    px={0}
                    textAlign={["left"]}
                    _focus={{
                      border: "none",
                    }}
                    _selected={{
                      fontWeight: "bold",
                      borderBottom: mobile
                        ? "3px solid #E60000"
                        : "4px solid #E60000",
                    }}
                  >
                    {mobile ? "Sobre área de trabalho" : "Área de Trabalho"}
                  </Tab>
                  <Tab
                    px={0}
                    _focus={{
                      border: "none",
                    }}
                    textAlign={["left"]}
                    fontSize={["13px", "15px"]}
                    _selected={{
                      fontWeight: "bold",
                      borderBottom: mobile
                        ? "3px solid #E60000"
                        : "4px solid #E60000",
                    }}
                  >
                    Sobre cargos
                  </Tab>
                  <Tab
                    px={0}
                    textAlign={["left"]}
                    _focus={{
                      border: "none",
                    }}
                    fontSize={["13px", "15px"]}
                    _selected={{
                      fontWeight: "bold",
                      borderBottom: mobile
                        ? "3px solid #E60000"
                        : "4px solid #E60000",
                    }}
                  >
                    Pergunta customizável
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel pl={0} pr={[0, "unset"]}>
                    <DefaultForm
                      handleAdd={handleAddOption}
                      topic="Área de Trabalho"
                      options={workOptions}
                      remove={removeOption}
                    >
                      <Input
                        name="workalternative"
                        {...register("workalternative")}
                        error={errors.p}
                        placeholder="Digite uma alternativa"
                        w={["100%", "413px"]}
                        h="56px"
                        fontSize="14px"
                        borderColor="#B8CAE8"
                        borderRadius="10px"
                      />
                    </DefaultForm>
                  </TabPanel>
                  <TabPanel pl={0} pr={[0, "unset"]}>
                    <DefaultForm
                      handleAdd={handlePositionAlternative}
                      topic="Cargos da empresa"
                      options={positionOptions}
                      remove={removePositionOption}
                    >
                      <Input
                        name="positionalternative"
                        {...register("positionalternative")}
                        error={errors.positionalternative}
                        placeholder="Digite uma alternativa"
                        w={["100%", "413px"]}
                        h="56px"
                        fontSize="14px"
                        borderColor="#B8CAE8"
                        borderRadius="10px"
                      />
                    </DefaultForm>
                  </TabPanel>

                  <TabPanel pl={0} pr={[0, "unset"]}>
                    <DefaultForm
                      description={
                        "Para a pergunta customizável você pode adicionar até 11 alternativas."
                      }
                      handleAdd={handleCustomAlternative}
                      topic="customizável"
                      options={customOptions}
                      remove={removeCustomOption}
                      custom={true}
                    >
                      <Flex
                        flexDir={["column", "row"]}
                        align="flex-start"
                        mb={["20px"]}
                      >
                        <Text
                          fontSize={"12px"}
                          mt={["unset", "24px"]}
                          mr="20px"
                          align="end"
                        >
                          Digite o <b>tema</b> da sua pergunta
                        </Text>

                        {hasPreviousData ? (
                          <input
                            name="customTheme"
                            {...register("customTheme")}
                            defaultValue={defaultTheme}
                            placeholder="Ex: Gênero que se identifica"
                            className="custom-input"
                            style={{
                              height: "56px",
                              marginTop: "8px",
                              fontSize: "14px",
                              border: "1px solid #B8CAE8",
                              borderRadius: "10px",
                              padding: "0 16px",
                              fontWeight: "600",
                            }}
                          />
                        ) : (
                          <Input
                            type="text"
                            name="customTheme"
                            {...register("customTheme")}
                            placeholder="Ex: Gênero que se identifica"
                            w={["100%", "413px"]}
                            h="56px"
                            mt="8px"
                            fontSize="14px"
                            borderColor="#B8CAE8"
                            borderRadius="10px"
                          />
                        )}
                      </Flex>

                      <Box w={["100%", "413px"]}>
                        <Text
                          fontSize="12px"
                          color="#292B30"
                          fontWeight="600"
                          mb="5px"
                        >
                          Alternativas
                        </Text>

                        <Input
                          name="customOption"
                          {...register("customOption")}
                          error={errors.customOption}
                          placeholder="Digite uma alternativa"
                          w="413px"
                          h="54px"
                          fontSize="14px"
                          borderColor="#B8CAE8"
                          borderRadius="10px"
                        />
                      </Box>
                    </DefaultForm>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>

            <Flex
              h={["48px", "74px"]}
              w="100%"
              gap="16px"
              justifyContent={"space-between"}
              mt="40px"
            >
              <Button
                w={["100%", "265px"]}
                h={["48px", "50px"]}
                variant="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant={"primary"}
                w={["100%", "265px"]}
                h={["48px", "50px"]}
                isLoading={loading}
              >
                Salvar
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const DefaultForm = ({
  topic,
  handleAdd,
  options,
  children,
  remove,
  custom,
}) => {
  return (
    <>
      <Text fontSize={"14px"} lineHeight={["24px", "20px"]}>
        Para a pergunta
        <Text as="span" px="4px" fontWeight={700}>
          {topic}
        </Text>
        você pode adicionar até <b>11</b> alternativas.
      </Text>

      <Flex
        mt="24px"
        h={["auto", custom ? "auto" : "78px"]}
        gap="22px"
        align={"flex-end"}
        flexDir={["column", "row"]}
        w="100%"
      >
        <Box w={["100%", "unset"]}>
          {!custom && (
            <Text fontSize="12px" color="#292B30" fontWeight="600" mb="5px">
              Alternativas
            </Text>
          )}

          {children}
        </Box>

        <Button
          mt="auto"
          ml={["unset", custom ? "-170px" : "unset"]}
          alignSelf={"baseline"}
          justifySelf="baseline"
          bg="none"
          color="red.100"
          border="1px"
          onClick={() => handleAdd()}
          mb="0px"
          borderRadius={"10px"}
          w={["100%", "265px"]}
          h={["48px", "56px"]}
        >
          Adicionar alternativa
        </Button>
      </Flex>

      <Text mt="24px" fontSize={"12px"}>
        Você já selecionou <b>{options.length} alternativas</b>.
      </Text>

      <Flex w="100%" gap="8px" mt="16px" flexWrap="wrap">
        {options.map((list, index) => (
          <Flex
            key={index}
            gap={["8px", "10px"]}
            alignItems="center"
            borderRadius="20px"
            px="12px"
            py={["8px", "2.5px"]}
            bg="rgba(41, 43, 48, 0.1)"
          >
            <Text fontSize="12px" fontWeight="600">
              {list}
            </Text>

            <Image
              src={iconRemove}
              alt="icon remove"
              h="16px"
              w="16px"
              cursor="pointer"
              onClick={() => remove(index)}
            />
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default SpecificQuestionsModal;
