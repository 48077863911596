import React from "react";
import { Box, Heading, Link, Stack, Text } from "@chakra-ui/react";

function QuestionnairesOverview() {
  return (
    <Box bg="rgba(41, 43, 48, 0.1)" p="38px 24px" borderRadius="12px" mb='32px'>
      <Heading fontSize="24px" mb="16px">
        Questionários de Certificação e Ranking.
      </Heading>
      <Stack fontSize="sm">
        <Text>
          Confira, a seguir, os questionários necessários para sua organização
          concorrer à <b>Certificação GPTW</b> e depois aos <b>Rankings GPTW</b>
        </Text>
        <Text>
          <b>Cadastro Inicial:</b> 9 campos para inserir os contatos da
          organização que receberão todas as importantes notificações das etapas
          da Jornada GPTW. Preenchimento obrigatório para todas as empresas, que
          deve ser concluído antes do agendamento da Pesquisa de Clima.
        </Text>
        <Text>
          <b>Questionário de Certificação:</b> 86 questões sobre os dados da
          organização no Brasil e no mundo, representatividade de
          funcionário(a)s e sua identificação nas redes sociais. Preenchimento
          necessário para as empresas obterem o selo de certificação, que deve
          ser concluído até a data-fim da Pesquisa de Clima.
        </Text>

        <Text>
        <b>Questionário de Ranking (Setorial, Regional e Nacional):</b> 72 questões sobre os 
          dados das organizações quanto aos benefícios, ações de clima e cultura, e modelos de trabalho. 
          Preenchimento necessário apenas para as empresas que desejam concorrer aos Rankings 
          GPTW nas categorias Setorial, Regional ou Nacional. 
          O prazo será a data limite da lista com o menor período ao qual a empresa é elegível. 
          Confira o <Link
            href="https://gptw.com.br/ranking/calendario-de-rankings/"
            target="_blank"
            rel="noreferrer"
            color="blue"
            fontWeight="bold"
          >Calendário de Rankings.</Link>
        </Text>

        <Text>
          Caso tenha dúvidas sobre esse processo, consulte a{" "}
          <Link
            href="https://gptw-brasil.zendesk.com/hc/pt-br"
            target="_blank"
            rel="noreferrer"
            color="blue"
            fontWeight="bold"
          >
            Central de Ajuda GPTW{" "}
          </Link>
          ou acesse o&nbsp;
          <Link
            href="https://conteudo.gptw.com.br/plantao-de-duvidas-gptw"
            target="_blank"
            rel="noreferrer"
            color="blue"
            fontWeight="bold"
          >
            Plantão de Dúvidas GPTW{" "}
          </Link>
          ou então entre em contato com o nosso Suporte pelo{" "}
          <Link
            href="mailto:atendimento@gptwbrasil.com.br"
            target="_blank"
            rel="noreferrer"
            color="blue"
            fontWeight="bold"
          >
            atendimento@gptwbrasil.com.br
          </Link>
        </Text>
      </Stack>
    </Box>
  );
}

export default QuestionnairesOverview;
