import apiNotification from "../../services/api-notification";

const getAllCancelamentLogs = async () => {
    try {
        const { hasError, statusCode, data } = await apiNotification.get('/log-cancel-journey');

        if (hasError || statusCode ==! 200) {
            throw "A rota está apresentando erro."
        }

        return data;
    } catch(erro) {
        throw erro;
    }
};

export {
    getAllCancelamentLogs,
}