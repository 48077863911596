import {
  Image,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Stack,
  Flex,
} from "@chakra-ui/react";
import { attentionModal } from "../../../assets/images/admin";
import { useEffect, useState } from "react";
import { SuccessModal } from "../../components/modal";

export default function ConfirmationModal({
  isOpen,
  onClose,
  setSubmitAfterConfirmation,
  isSubmitting,
  researchData,
  success, setSuccess
}) {

  const [title, setTitle] = useState("Pilares For All enviado com sucesso.");

  const handleButton = () => {
    setSubmitAfterConfirmation(true);
  };

  const handleClose = () => {
    setSubmitAfterConfirmation(false)
    onClose()
    setSuccess(false);
  };

  useEffect(() => {
    if (researchData?.salesforceParticipationCode) {
      setTitle(`Pilares For All enviado com sucesso - ${researchData.salesforceParticipationCode}`);
    }
  }, [researchData]);

  // if (success && !isOpen) {
  //   setSuccess(false);
  // }

  

  return success ? (
    <SuccessModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      text="Obrigada por compartilhar suas práticas conosco"
    />
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="3xl">
      <ModalOverlay />
      <ModalContent w="766px" borderRadius="20px" overflow={"hidden"}>
        <ModalBody p="48px 40px">
          <Flex align="center">
            <Stack spacing="12px" w="55%">
              <Text
                fontSize="20px"
                lineHeight="23.48px"
                color="#FF6700"
                fontWeight={600}
              >
                Atenção!
              </Text>

              <Text fontSize="28px" lineHeight="42px" fontWeight={700}>
                Deseja mesmo enviar o Pilares For All para avaliação GPTW?
              </Text>
              <Text fontSize="18px" lineHeight="32px">
                Só confirme se tiver 100% certeza que preencheu todos os dados
                pertinentes a essa avaliação.
              </Text>
            </Stack>

            <Flex height="175px" justify="center" w="45%">
              <Image src={attentionModal} alt="" />
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter p={0} height={"69px"}>
          <Button
            w="50%"
            onClick={onClose}
            h="100%"
            borderRadius={0}
            variant="secondary"
            fontWeight={500}
          >
            Não, cancelar
          </Button>
          <Button
            w="50%"
            h="100%"
            borderRadius={0}
            variant="primary"
            fontWeight={500}
            onClick={handleButton}
            isLoading={isSubmitting}
          >
            Sim, enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
