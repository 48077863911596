import { useState, useEffect } from "react";
import { addDays, isPast, parseISO } from "date-fns";
import { useMutation } from "react-query";
import { queryClient } from "../../services/queryCliente";
import { useQuery } from "react-query";
import {
  Flex,
  Box,
  GridItem,
  Button,
  Stack,
  Text,
  Tag,
  Image,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  ModalHeader,
  useMediaQuery,
  Link
} from "@chakra-ui/react";
import { format } from "date-fns";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import etapas from "../../assets/images/etapas.svg";
import pendency from "../../assets/images/icon-pendency.svg";
import check from "../../assets/images/icon-ready.svg";
import cancel from "../../assets/images/calcel-subs-rankings.svg";

import { TbDotsVertical } from "react-icons/tb";
import SuccessModal from "../components/modal/SuccessModal";
import { rankingApi as api } from "../../services/api-research";
import { apiFinancial } from "../../services/api-financial";
import de from "date-fns/esm/locale/de/index.js";
registerLocale("ptBr", ptBR);

const RankingCard = ({ canSignUp, ranking, companyId, page, researchInfo, rankingInfo = null}) => {
  const toast = useToast();
  const [participation, setParticipation] = useState({
    isSubscribe: false,
    participationId: null,
    display: null,
    rankingSF: ''
  });

  const [expired, setExpired] = useState(false);
  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const [unsubscribedModalOpen, setUnsubscribedModalOpen] = useState(false);

  const limitDate = ranking.initial_limit_date
    ? format(new Date(ranking.end_limit_date), "dd/MM/yyyy", {
        locale: ptBR,
      })
    : null;

  const apply = useMutation(
    async () => {

      // Adicionando participação à empresa no serviço interno
      const res = await api.post("/participation", {
        company: companyId,
        ranking: ranking.id,
      });

      // Adicionando inscrição do ranking no salesforce
      const rankingCompany = await fetchData();
      
      let rankingsSalesforce = '';
      for (const item in rankingCompany) {
        rankingsSalesforce = rankingsSalesforce + rankingCompany[item].ranking.name + ' - ' + rankingCompany[item].ranking.version + ';'
      }

      addRankingSF(rankingsSalesforce.slice(0, -1));

      return res;
    },
    {
      onSuccess: () => {
        setApplyModalOpen(true);
        queryClient.invalidateQueries(["participation", ranking.id]);
      },
    }
  );

  async function addRankingSF(nameRankings) {
    console.log('Atualizando ranking... ' + nameRankings)
    await apiFinancial.post(`/salesforce/add-ranking-info?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}`, {
      salesforceId: researchInfo.salesforceId,
      namesRanking: nameRankings
    });
  }

  const verifyApplication = async () => {
    try {
      await apply.mutateAsync();
    } catch (err) {
      const errorMessage = err.response.data?.message;
      console.log("Err", err);
      toast({
        title: "Falha no cadastro",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleModal = () => {
    setApplyModalOpen(!applyModalOpen);
  };

  const handleUnsubscribedModal = () => {
    setUnsubscribedModalOpen(!unsubscribedModalOpen);
  };

  const [modalDetail, setModalDetail] = useState(false);

  const handleDetailsModal = () => {
    setModalDetail(!modalDetail);
  };

  const fetchData = async () => {
    const res = await api.get(`/participation/company/${companyId}`);

    return res.data;
  };

  const { data, isFetching, isRefetching } = useQuery(
    ["participation", ranking.id],
    () => fetchData()
  );

  const inactive = ranking.status === 'INACTIVE'

  useEffect(() => {
    function checkSubscription() {     
      // ver se data esta atualizado / length
      if (data) {
        const findParticipation = data.filter(
          (d) => d.ranking?.id === ranking.id
        );

        if (findParticipation.length > 0) {
          setParticipation({
            isSubscribe: true,
            participationId: findParticipation[0].id,
            display: true          });
        } else {
          setParticipation({
            isSubscribe: false,
            participationId: null,
            display: inactive ? false : true
          });
        }
      }
    }

    ranking && checkSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranking, page, isFetching, isRefetching]);

  useEffect(() => {
    if (!!ranking.end_limit_date) {
      
      const date = parseISO(ranking.end_limit_date);
      const dateLimit = addDays(date, 31) // Estamos deixando o ranking aberto 31 dias após o prazo final dele
      setExpired(isPast(dateLimit));
    }
  }, [ranking]);

  return (
    <Box
      bg="white"
      borderRadius={"12px"}
      border="1px solid #CCCCCC"
      overflow={"hidden"}
      minH={["229px", "210px", "220px"]}
      position="relative"
      as={GridItem}
      colSpan={1}
      display={participation.display === true ? 'grid' : 'none'}
    >
      <ModalDetail
        ranking={ranking}
        isOpen={modalDetail}
        onClose={handleDetailsModal}
      />
      <SuccessModal
        isOpen={applyModalOpen}
        onClose={handleModal}
        title={`Parabéns! Sua empresa já está inscrita para participar do Ranking ${ranking.name}!`}
        text="Fique na torcida! O resultado será divulgado em breve."
      />
      <UnsubscribedModal
        rankingId={ranking.id}
        rankingName={ranking.name}
        isOpen={unsubscribedModalOpen}
        onClose={handleUnsubscribedModal}
        participation={participation}
        researchInfo={researchInfo}
        rankingVersion={ranking.version}
        companyId={companyId}
      />
      <Stack
        spacing={["12px", "12px", "12px", "12px", "14px"]}
        p="16px 10px 16px 16px"
        align="start"
        pb={["12px", "16px"]}
      >
        <Flex h="20px" w="100%" align="center" justify={"space-between"}>
          <Image src={pendency} alt="pendency" />
          <DropdownButton openModal={handleDetailsModal} />
        </Flex>

        <Tag
          bg="rgba(0, 32, 112, 0.1)"
          color="#002070"
          fontSize={"12px"}
          fontWeight={600}
          h={{ base: "22px", lg: "20px" }}
        >
          {ranking.type}
        </Tag>
        <Heading
          as="h4"
          color="#292B30"
          fontWeight={700}
          fontSize={{ base: "18px", lg: "17px" }}
          lineHeight="26px"
          display="block"
          mt='10px !important'
        >
          {ranking.name ? ranking.name : "--"}
          <Text as="span" fontWeight={700} ml="6px">
            {ranking.version}
          </Text>
        </Heading>

        <Flex h="20px" align={"center"} gap="8px">
          <Image
            src={participation.isSubscribe ? check : etapas}
            alt=""
            h="20px"
          />
          {expired && !participation.isSubscribe ? (
            <Text fontSize={"12px"} color="red.100" fontWeight={600}>
              Inscrições encerradas
            </Text>
          ) : (
            <>
              {participation.isSubscribe ? (
                <Text fontSize={"12px"} color="#40BF91" fontWeight={600}>
                  Você já está inscrito
                </Text>
              ) : (
                canSignUp  /* && rankingInfo !== null && rankingInfo.formData.totalQuestions === 0 && !rankingInfo.formData.completedOn */ ? 
                <Link
                  fontSize="12px"
                  fontWeight="400"
                  w="auto"
                  href={`https://connect.gptw.info/registration/profile?cr=${companyId}&showDiversity=true`}
                  color="#E60000"
                  isExternal={true}
                  variant="ghost"
                >Responda o Questionário para Participar</Link> 
                :
                <Text fontSize={"12px"} color="#002070">
                  {/* Inscrições até{" "} */}
                  <Text as="span" fontWeight={600}>
                    {/* {limitDate ? limitDate : "--"} */}
                  </Text>
                </Text>
              )}
            </>
          )}
        </Flex>
      </Stack>
      {participation.isSubscribe ? (
        <UnsubscribedButton
          canSignUp={canSignUp}
          onClick={() => setUnsubscribedModalOpen(true)}
          rankingInfo={rankingInfo}
        />
      ) : (
        <SubscribeButton
          isLoading={apply.isLoading}
          canSignUp={canSignUp && !expired}
          onClick={verifyApplication}
          rankingInfo={rankingInfo}
        />
      )}
    </Box>
  );
};

const UnsubscribedModal = ({ isOpen, onClose, participation, rankingId, rankingName, researchInfo, rankingVersion, companyId }) => {
  const [mobile] = useMediaQuery("(min-width: 1024px)");
  const [success, setSuccess] = useState(false);

  const handleClose = () => {
    onClose();
    setSuccess(false);
  };

  const unsubscribe = useMutation(
    async () => {
      const res = await api.delete(
        `participation/${participation.participationId}`
      );

      // Retirando participação do salesforce
      const rankingCompany = await api.get(`/participation/company/${companyId}`);
      const infoRanking = rankingCompany.data;
      const rankingRemove = `${rankingName} - ${rankingVersion};`;

      let rankingsSalesforce = '';
      if (infoRanking.length > 0) {
        for (const item in infoRanking) {
          rankingsSalesforce = rankingsSalesforce + infoRanking[item].ranking.name 
            + ' - ' + infoRanking[item].ranking.version + ';'
        }
      }

      const position = rankingsSalesforce.search(rankingRemove);
      
      const updateRankingSF = position === -1 ?
      rankingsSalesforce.replace(rankingRemove.slice(0,-1), '') :
      rankingsSalesforce.replace(rankingRemove, '');


      // Atualizando informação no salesforce
      console.log('Atualizando retirada de salesforce ...')
      await apiFinancial.post(`/salesforce/add-ranking-info?secret=${window['runConfig'].REACT_APP_FINANCIAL_SECRET}`, {
        salesforceId: researchInfo.salesforceId,
        namesRanking: updateRankingSF.trim() === '' ? 'SEM INSCRIÇÕES' : updateRankingSF
      });

      return res.data;
    },
    {
      onSuccess: (data) => {
        setSuccess(true);
        queryClient.invalidateQueries(["participation", rankingId]);
        queryClient.setQueryData(["participation", data]);
      },
    }
  );

  const handleCancelParticipation = async () => {
    try {
      unsubscribe.mutateAsync();
    } catch (err) {
      console.log("Err", err);
      setSuccess(true);
    }
  };

  return success ? (
    <SuccessModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Inscrição cancelada com sucesso."
      text=""
    />
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent
        w="766px"
        maxW="766px"
        h={["437px", "401px"]}
        p="0px"
        borderRadius="20px"
        overflow="hidden"
      >
        <ModalBody p={["24px", "40px 40px 50px 40px"]}>
          <Flex
            align={["flex-start", "center"]}
            gap={["24px", "20px"]}
            flexDir={["column-reverse", "row"]}
          >
            <Stack
              alignItems={"flex-start"}
              flex={[3, 1]}
              spacing="12px"
              w="100%"
            >
              <Text
                fontSize={"18px"}
                fontWeight={600}
                lineHeight={["30px", "24px"]}
                color="orange.100"
              >
                Atenção!
              </Text>

              <Heading
                fontSize={["20px", "24px"]}
                lineHeight="33.6px"
                fontWeight="700"
                as="h4"
                color="black.100"
              >
                Deseja mesmo cancelar sua inscrição no Ranking {rankingName}?
              </Heading>
            </Stack>
            <Flex flex={[1]} w="100%" justify={"center"} h={["182px", "235px"]}>
              <Image src={cancel} alt="" h={["182px", "235px"]} />
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter width="100%" p={0} height={["56px", "69px"]}>
          <Button
            variant={"naked"}
            w="100%"
            height={["56px", "69px"]}
            fontWeight={[700, 500]}
            borderRadius={"0px 0px 0px 20px"}
            border="1px solid #E60000"
            onClick={handleClose}
            _focus={{
              boxShadow: "none",
            }}
          >
            Não, voltar
          </Button>
          <Button
            width="100%"
            color="white"
            background="#E60000"
            borderRadius={0}
            height={["56px", "69px"]}
            fontWeight={[700, 400]}
            onClick={handleCancelParticipation}
          >
            {mobile ? "Sim, cancelar inscrição" : "Sim, cancelar"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SubscribeButton = ({ canSignUp, rankingInfo, ...rest }) => {
  let checkIfCanSignUp = canSignUp
  // if (rankingInfo !== null) {
  //   checkIfCanSignUp = (canSignUp && rankingInfo.formData.totalQuestions !== 0 &&
  //     rankingInfo.formData.completedOn)
  // }

  return (
    <Button
      w="100%"
      position={"absolute"}
      bottom={0}
      bg={[
        !checkIfCanSignUp ? "#E5E5E5" : "red.100",
        !checkIfCanSignUp ? "gray.300" : "red.100",
      ]}
      h={["54px", "38px"]}
      borderRadius={0}
      align={"center"}
      justify={"center"}
      disabled={!checkIfCanSignUp}
      _disabled={{
        opacity: 0.8,
      }}
      _hover={!checkIfCanSignUp ? { bg: 'gray.300', cursor: 'not-allowed' } : ""}
      {...rest}
    >
      <Text
        fontSize={["15x", "12px"]}
        color={[!checkIfCanSignUp ? "#5C6066" : "white", "white"]}
        fontWeight={700}
      >
        Quero me inscrever
      </Text>
    </Button>
  );
};

const UnsubscribedButton = ({ canSignUp, ...rest }) => {

  return (
    <Button
      position={"absolute"}
      bottom={0}
      h={["54px", "38px"]}
      w="100%"
      bg={"white"}
      borderRadius={0}
      align={"center"}
      justify={"center"}
      borderTop="1px solid #CCCCCC"
      _focus={{
        boxShadow: "none",
      }}
      {...rest}
    >
      <Text fontSize={["16px", "14px"]} color="red.100" fontWeight={[600, 500]}>
        Cancelar inscrição
      </Text>
    </Button>
  );
};

const DropdownButton = ({ ranking, openModal, ...rest }) => {
  const handleDetails = () => {
    openModal();
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        py="3.75px"
        bg="#E5E5E5"
        w="20px"
        minW={0}
        maxW="20px"
        h="20px"
        p={0}
        _active={{
          bg: "#E5E5E5",
        }}
      >
        <TbDotsVertical color="black" />
      </MenuButton>
      <MenuList
        w="95px"
        maxW="95px"
        h="70px"
        borderRadius={"8px"}
        background="white"
        border="1px solid #F4F4F4"
      >
        <MenuItem py="10px" fontSize={"12px"} onClick={handleDetails}>
          Detalhes
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const ModalDetail = ({ ranking, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />

      <ModalContent
        w={["100vw", "60vw"]}
        maxW={["100vw", "60vw"]}
        p={0}
        borderRadius="12px"
        overflow="hidden"
      >
        <ModalHeader bg="#F5F5FB" h={["64px"]}>
          <ModalCloseButton bg="white" rounded="full" />
          <Text fontSize={"18px"} fontWeight={600}>
            Informações
          </Text>
        </ModalHeader>
        <ModalBody color="black.100" p="20px 24px 32px 24px">
          <Stack fontSize={"14px"} spacing="12px" fontWeight={500}>
            <Text>Nome: {ranking.name}</Text>
            <Text>Cidade: {ranking.city?.name}</Text>
            <Text>
              Quantidade mínima de colaboradores: {ranking.min_employees}
            </Text>
           {/* <Text>
              Quantidade máxima de colaboradores: {ranking.max_employees}
            </Text> */}
            {/* <Text>
              Data limite de inscrição:{" "}
              {ranking.end_limit_date !== null
                ? format(new Date(ranking.end_limit_date), "dd/MM/yyyy", {
                    locale: ptBR,
                  })
                : "--"}
            </Text> */}
            {/* <Text>
              Publicação:{" "}
              {ranking.publication_date !== null
                ? format(new Date(ranking.publication_date), "dd/MM/yyyy", {
                    locale: ptBR,
                  })
                : "--"}
            </Text> */}
            <Text>
              Premiação - (Mês/Ano):{" "}
              {ranking.premiation_date !== null
                ? format(new Date(ranking.premiation_date), "MM/yyyy", {
                    locale: ptBR,
                  })
                : "--"}
            </Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default RankingCard;
