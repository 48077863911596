import apiForms from "../../services/api-forms";
import { useQuery } from "react-query";
import { queryClient } from "../../services/queryCliente";

const fetchFormsStatus = async (id) => {
  const res = await apiForms.get(`/api/answer/create-and-get/${id}`);
  return res.data;
};

export const useFormsStatus = (id) => {
  return useQuery(
    "formsStatus",
    () => fetchFormsStatus(id),
    {
      enabled: id !== undefined,
    },
    {
      staleTime: 30 * 1000,
      initialData: () => queryClient.getQueryData("formsStatus"),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState("formsStatus")?.dataUpdatedAt,
    }
  );
};