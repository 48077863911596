import {  useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { queryClient } from "../../../../services/queryCliente";

import iconBar from "../../../../assets/images/icon-bar.svg";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Box,
  Flex,
  Tag,
  Grid,
  GridItem,
  Stack,
  InputGroup,
  FormLabel,
} from "@chakra-ui/react";
import { Input } from "./Input";
import { researchApi } from "../../../../services/api-research";
import { useResearch } from "../../../../helpers/useResearch";
import SuccessModal from "../../../components/modal/SuccessModal";

const schema = Yup.object().shape({
  manager: Yup.string().required("Campo obrigatório"),
  people: Yup.string().required("Campo obrigatório"),
});

const LanguageModal = ({ onClose, isOpen, researchId, data }) => {
  const {
    register,
    handleSubmit,
    watch
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: researchData } = useResearch(researchId);

  const [success, setSuccess] = useState(false);
  const [updateAction, setUpdateAction] = useState(false);
  const [loading, setLoading] = useState(false);

  const createLanguage = useMutation(
    async (values) => {
      const response = await researchApi.post(`/organization-language`, {
        ...values,
      });

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("language");
      },
    }
  );

  const updateLanguage = useMutation(
    async (values) => {
      const response = await researchApi.put(
        `/organization-language/${data[0].id}`,
        {
          ...values,
        }
      );

      const updateValues = {
        ...researchData.research,
      };

      const res = await researchApi.put(`/research/${researchId}`, {
        ...updateValues,
      });

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("language");
      },
    }
  );

  const onSubmit = async (values) => {
    setLoading(true);
    const model = {
      manager: values.manager,
      company: values.company,
      collaborator: values.people,
      research: researchId,
    };
    // retorna um id
    try {
      let res;

      updateAction
        ? (res = await updateLanguage.mutateAsync(model))
        : (res = await createLanguage.mutateAsync(model));

      if (res.status === 200 || res.status === 201) {
        setSuccess(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setUpdateAction(true);
    }
  }, [data]);

  return success ? (
    <SuccessModal
      title="Personalização salva com sucesso!"
      isOpen={isOpen}
      onClose={handleClose}
    />
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent
        w={["96vw", "1112px"]}
        h={["auto", "552px"]}
        borderRadius="12px"
        p={["24px 0 24px 0", "10px"]}
      >
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <img src={iconBar} alt="icon" />
            <Heading fontSize="18px" fontWeight="600" mt="8px" mb="24px">
              Personalização da Pesquisa de Clima
            </Heading>

            <Box
              h={["auto", "365px"]}
              p={[0, 5]}
              border={["0", "1px"]}
              borderColor="#CCCCCC"
              borderRadius="12px"
            >
              <Box>
                <Tag
                  bg="#e5ebec"
                  color="#002070"
                  borderRadius="20px"
                  h="23px"
                  px="15px"
                  fontWeight="600"
                >
                  Linguagem organizacional
                </Tag>
                <Text
                  as="h4"
                  fontSize={["13px", "14px"]}
                  lineHeight={"20px"}
                  pt={2}
                >
                  Selecione as <b>terminologias </b> utilizadas em sua empresa.
                </Text>
              </Box>

              <Grid
                templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
                gap={"20px"}
                mt="20px"
              >
                <GridItem
                  w={["100%", "312px"]}
                  h={["172px", "240px"]}
                  border="1px"
                  borderColor={["#E5E5E5", "#CCCCCC"]}
                  fontSize="14px"
                  borderRadius="12px"
                  p="20px"
                >
                  <Text fontSize={["13px", "14px"]} mb="20px">
                    Como são chamados os <b>Gestores?</b>
                  </Text>
                  <Stack
                    spacing={["0", "12px"]}
                    flexDir={["row", "column"]}
                    flexWrap={["wrap"]}
                  >
                    <InputGroup
                      mr={["16px", "0"]}
                      mb={["16px", "0"]}
                      bg={watch("manager") === "Chefia" ? "red.100" : "#E5E5E5"}
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("manager")}
                        value="Chefia"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize={["13px", "14px"]}
                        color={
                          watch("manager") === "Chefia" ? "white" : "#292B30"
                        }
                      >
                        Chefia
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={watch("manager") === "Gestão" ? "red.100" : "#E5E5E5"}
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      mr={["16px", "0"]}
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("manager")}
                        value="Gestão"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize={["13px", "14px"]}
                        color={
                          watch("manager") === "Gestão" ? "white" : "#292B30"
                        }
                      >
                        Gestão
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={
                        watch("manager") === "Liderança" ? "red.100" : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("manager")}
                        value="Liderança"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize={["13px", "14px"]}
                        color={
                          watch("manager") === "Liderança" ? "white" : "#292B30"
                        }
                      >
                        Liderança
                      </FormLabel>
                    </InputGroup>
                  </Stack>
                </GridItem>
                <GridItem
                  w={["100%", "312px"]}
                  h={["172px", "240px"]}
                  border="1px"
                  borderColor={["#E5E5E5", "#CCCCCC"]}
                  fontSize="14px"
                  borderRadius="12px"
                  p="20px"
                >
                  <Text fontSize={["13px", "14px"]} mb="20px">
                    Como são chamadas as <b>Pessoas?</b>
                  </Text>

                  <Stack
                    spacing={["0", "12px"]}
                    flexDir={["row", "column"]}
                    flexWrap={["wrap"]}
                  >
                    <InputGroup
                      mr={["14px", "0"]}
                      mb={["14px", "0"]}
                      bg={
                        watch("people") === "Colaboradores"
                          ? "red.100"
                          : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("people")}
                        value="Colaboradores"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize={["13px", "14px"]}
                        color={
                          watch("people") === "Colaboradores"
                            ? "white"
                            : "#292B30"
                        }
                      >
                        Colaboradores
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      mr={["14px", "0"]}
                      mb={["14px", "0"]}
                      bg={
                        watch("people") === "Empregados" ? "red.100" : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("people")}
                        value="Empregados"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize={["13px", "14px"]}
                        color={
                          watch("people") === "Empregados" ? "white" : "#292B30"
                        }
                      >
                        Empregados
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      mr={["16px", "0"]}
                      bg={
                        watch("people") === "Funcionarios"
                          ? "red.100"
                          : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("people")}
                        value="Funcionarios"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize={["13px", "14px"]}
                        color={
                          watch("people") === "Funcionarios"
                            ? "white"
                            : "#292B30"
                        }
                      >
                        Funcionários
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={
                        watch("people") === "Integrantes"
                          ? "red.100"
                          : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("people")}
                        value="Integrantes"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize={["13px", "14px"]}
                        color={
                          watch("people") === "Integrantes"
                            ? "white"
                            : "#292B30"
                        }
                      >
                        Integrantes
                      </FormLabel>
                    </InputGroup>
                  </Stack>
                </GridItem>
                <GridItem
                  w={["100%", "312px"]}
                  h={["172px", "240px"]}
                  border="1px"
                  borderColor={["#E5E5E5", "#CCCCCC"]}
                  fontSize="14px"
                  borderRadius="12px"
                  p="20px"
                >
                  <Text fontSize={["13px", "14px"]} mb="18px">
                    Como é chamada a <b>Empresa?</b>
                  </Text>

                  <Stack
                    spacing={["0", "12px"]}
                    flexDir={["row", "column"]}
                    flexWrap={["wrap"]}
                  >
                    <InputGroup
                      mr={["14px", "0"]}
                      mb={["14px", "0"]}
                      bg={
                        watch("company") === "Companhia" ? "red.100" : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("company")}
                        value="Companhia"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize={["13px", "14px"]}
                        color={
                          watch("company") === "Companhia" ? "white" : "#292B30"
                        }
                      >
                        Companhia
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={
                        watch("company") === "Empresa" ? "red.100" : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("company")}
                        value="Empresa"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize={["13px", "14px"]}
                        color={
                          watch("company") === "Empresa" ? "white" : "#292B30"
                        }
                      >
                        Empresa
                      </FormLabel>
                    </InputGroup>

                    <InputGroup
                      bg={
                        watch("company") === "Organização"
                          ? "red.100"
                          : "#E5E5E5"
                      }
                      borderRadius="40px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      px="12px"
                      width="fit-content"
                      gap="8px"
                      className="remove-margin"
                    >
                      <Input
                        type="radio"
                        {...register("company")}
                        value="Organização"
                        p={0}
                        w="16.67px"
                        h="16.67px"
                        borderRadius="50px"
                      />
                      <FormLabel
                        fontSize={["13px", "14px"]}
                        color={
                          watch("company") === "Organização"
                            ? "white"
                            : "#292B30"
                        }
                      >
                        Organização
                      </FormLabel>
                    </InputGroup>
                  </Stack>
                </GridItem>
              </Grid>
            </Box>

            <Flex
              h={["48px", "92px"]}
              w="100%"
              gap="17px"
              mt="24px"
              justifyContent={"space-between"}
            >
              <Button
                w={["100%", "265px"]}
                h={["48px", "50px"]}
                variant="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                w={["100%", "265px"]}
                variant={"primary"}
                h={["48px", "50px"]}
                isLoading={loading}
              >
                Salvar
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LanguageModal;
