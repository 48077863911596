import { Button, IconButton, Tooltip, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";
import { MdSwitchAccessShortcutAdd } from "react-icons/md";
import { getCookie } from "../../../common/cookieManager";

const ButtonRedirectLegado = ({ ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [mobile] = useMediaQuery("(max-width: 992px)");

  const authLegacy = window["runConfig"].REACT_APP_CONNECT_BASE_URL_LEGACY
    ? window["runConfig"].REACT_APP_CONNECT_BASE_URL_LEGACY
    : "https://certificacao.gptw.info";

  const url = `${authLegacy}/gptw`;

  const authorizationViewButton = [
    "GPTW_VALUER_ROLE",
    "GPTW_REVIEWER_ROLE",
    "GPTW_VALUERNEWCA_ROLE",
    "GPTW_RANKING",
    "GPTW_ADMIN",
  ];

  const authUser = getCookie("roles").some((item) =>
    authorizationViewButton.includes(item)
  );

  if (authUser) {
    return mobile ? (
      <Button
        as={"a"}
        target="_blank"
        href={url}
        leftIcon={
          <MdSwitchAccessShortcutAdd
            style={{
              transition: "fill 0.3s",
              fill: isHovered ? "#FF1628" : "#444C54",
            }}
          />
        }
        variant={"unstyled"}
        _hover={{
          color: "telegram.700",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        display={"flex"}
        gap="16px"
        justifyContent="start"
        fontSize={"18px"}
        lineHeight={"18px"}
        fontWeight={500}
        w="fit-content"
        color={isHovered ? "telegram.900" : "telegram.700"}
        opacity={isHovered ? "1" : "0.6"}
        sx={{
          span: {
            margin: "0",
          },
          svg: { boxSize: "24px", opacity: isHovered ? "1" : "0.6" },
        }}
        {...props}
      >
        Acessar a versão antiga
      </Button>
    ) : (
      <Tooltip label="Acessar a versão antiga">
        <IconButton
          as={"a"}
          target="_blank"
          href={url}
          variant={"unstyled"}
          size={"sm"}
          icon={
            <MdSwitchAccessShortcutAdd
              style={{
                transition: "fill 0.3s",
                fill: isHovered ? "#FF4553" : "#444C54",
              }}
            />
          }
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          {...props}
          sx={{ svg: { boxSize: "32px" } }}
        />
      </Tooltip>
    );
  } else {
    return null;
  }
};

export default ButtonRedirectLegado;
