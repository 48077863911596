import { useQuery } from "react-query";
import api from "./api";

// Data fetching functions
const fetchTemplateData = async (id) => {
  const { data } = await api.get(`answer/create-and-get/${id}`);
  const formCA = data?.data?.find(([obj]) => obj.form === "CA");
  const extractedObj = formCA ? formCA[0] : null;
  return extractedObj;
};

const fetchParticipationData = async (id) => {
  const { data } = await api.get(`/pillarsForAll/by-participation/${id}`);
  return data?.data[0];
};

const getData = async (id) => {
  const { data } = await api.get(`answer/get-answer-ca/${id}`);
  return data?.data?.[0]?.result;
};

// useQuery hooks
export const useTemplateDataQuery = (id) => {
  return useQuery(["pilares-template", id], () => fetchTemplateData(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};

export const useParticipationDataQuery = (id) => {
  return useQuery(
    ["pilares-participation", id],
    () => fetchParticipationData(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );
};

export const useDataQuery = (id) => {
  return useQuery(["pilares-questionaries", id], () => getData(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};
