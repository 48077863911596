import {
  Flex,
  Image,
  Text,
  Box,
  Button,
  Stack,
  CircularProgress,
  CircularProgressLabel,
  FormLabel,
  Tooltip,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { iconAnchor, iconDelete } from "../../../../assets/images";
import { formatFileSize, isValidFileSize } from "./helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import queryParamsPresent from "../../../rankings/get-params";
import api from "../api";
import * as yup from "yup";

export function FormAddImage({ idAnswer, filesResponse, isDisabled }) {
  const toast = useToast();
  const [st, id] = queryParamsPresent();
  const [fileIdentifier, setFileIdentifier] = useState("");
  const [formattedFilesResponse, setFormattedFilesResponse] = useState([]);
  let currentFiles = filesResponse?.length;

  const [isSending, setIsSending] = useState(false);
  const [progress, setProgress] = useState(0);

  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const schema = yup.object().shape({
    files: yup
      .mixed()
      .required("VALIDATION_FIELD_REQUIRED")
      .test(
        "is-valid-max",
        "Você pode selecionar no máximo 10 arquivos",
        (value) => value && Array.from(value)?.length + currentFiles <= 10
      )
      .test(
        "is-valid-size",
        "O tamanho máximo permitido é de 10GB",
        (value) => value && isValidFileSize(value)
      ),
  });

  const {
    setError,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    defaultValues: { files: [] },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  async function save(file, companyRegister) {
    const AWS = require("aws-sdk");

    AWS.config.update({
      accessKeyId: window["runConfig"].AWS_ACCESS_KEY_ID,
      secretAccessKey: window["runConfig"].AWS_SECRET_ACCESS_KEY,
    });

    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      region: window["runConfig"].AWS_REGION,
    });

    const params = {
      Bucket: `${window["runConfig"].AWS_S3_BUCKET}/CompanyPics/${companyRegister}`,
      Key: file.name,
      Body: file,
      ACL: "public-read",
    };

    try {
      const s3Response = await s3.upload(params).promise();
      return s3Response; // Retorna a resposta do S3 somente se o upload for bem-sucedido
    } catch (error) {
      // Tratar erros aqui
      console.error("Erro ao fazer o upload do arquivo:", error);
      return null;
    }
  }

  const uploadFiles = useMutation(
    async (files) => {
      setIsSending(true);
      setLoading(true);
      try {
        const promises = Array.from(files).map(async (file) => {
          // Realiza o upload do arquivo para o S3
          const s3Response = await save(file, id);

          if (s3Response) {
            // Se o upload foi bem-sucedido, chama o POST para '/files'
            const fileInformation = [
              {
                idCompanyRegister: id,
                name: file.name,
                type: file.type,
                key: s3Response.Location, // Utiliza a chave do S3 da resposta
                status: "OK",
                error: "",
                s3Version: "1.1",
              },
            ];

            const responseInformation = await api.post(
              "files",
              fileInformation
            );

            // Se a requisição de POST der certo, definir o fileIdentifier
            if (responseInformation) {
              setFileIdentifier(s3Response.Location);
            }
            return responseInformation;
          }

          return null; // Retorna null caso o upload tenha falhado
        });

        await Promise.all(promises);
      } catch (error) {
        console.error("Erro ao fazer o upload dos arquivos:", error);
      } finally {
        setIsSending(false);
        setLoading(false);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("answer-files");
      },
    }
  );

  const onSubmit = (data) => {
    // Aqui você pode realizar o envio dos arquivos ou executar outras ações com os dados do formulário
    console.log(data);
  };

  const uploadFile = useMutation(
    async (identifier) => {
      const { data } = await api.get(`/files/${id}`);

      if (!data?.hasError) {
        const lastUpload = data?.data.find((d) => d.key === identifier);

        if (lastUpload) {
          const filesWithId = [
            {
              idQuestionAnswer: idAnswer,
              idFile: lastUpload.idFile,
            },
          ];
          await api.post(`answer-file`, filesWithId);
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("answer-files");
      },
    }
  );

  const deleteFile = useMutation(
    async (fileId) => {
      setLoading(true);
      try {
        const response = await api.delete(`answer-file/deleteById`, {
          data: {
            idQuestionAnswer: idAnswer,
            idFile: fileId,
          },
        });

        if (response.data.hasError === false) {
          const fileToDelete = formattedFilesResponse.find(
            (file) => file.idFile === fileId
          );
          if (fileToDelete) {
            await deleteFileFromS3(fileToDelete.key);
          }
        }
        return response;
      } catch (error) {
        console.error("Erro ao deletar o arquivo", error);
      } finally {
        setLoading(false);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("answer-files");
      },
    }
  );

  async function deleteFileFromS3(key) {
    const AWS = require("aws-sdk");

    AWS.config.update({
      accessKeyId: window["runConfig"].AWS_ACCESS_KEY_ID,
      secretAccessKey: window["runConfig"].AWS_SECRET_ACCESS_KEY,
    });

    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      region: window["runConfig"].AWS_REGION,
    });

    const params = {
      Bucket: `${window["runConfig"].AWS_S3_BUCKET}`,
      Key: key,
    };

    try {
      await s3.deleteObject(params).promise();
    } catch (error) {
      console.log("Error deleting file from S3:", error);
    }
  }

  async function fetchFiles(identifier) {
    await uploadFile.mutateAsync(identifier);
  }

  const handleFileDelete = async (fileId) => {
    try {
      await deleteFile.mutateAsync(fileId);
      toast({
        title: "Arquivo deletado com sucesso!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erro ao deletar o arquivo", error);
    }
  };

  const handleFileDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileUrl.substr(fileUrl.lastIndexOf("/") + 1);
    link.target = "_blank";
    link.click();
    link.parentNode?.removeChild(link);
  };

  useEffect(() => {
    if (fileIdentifier.length > 1) {
      fetchFiles(fileIdentifier);
    }
  }, [fileIdentifier]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = filesResponse.map(async (object) => {
          const response = await fetch(object.key);
          const contentLength = response.headers.get("Content-Length");
          return { ...object, size: contentLength };
        });

        const results = await Promise.all(promises);

        // setValue("files", results);
        setFormattedFilesResponse(results);
      } catch (error) {
        console.log("Error while retrieving data:", error);
      }
    };

    if (filesResponse && filesResponse?.length > 0) {
      fetchData();
    }
  }, [filesResponse]);

  const handleFileChange = async (event) => {
    setError("files", null);
    const selectedFiles = Array.from(event.target.files);
    setValue("files", selectedFiles); // Atualiza o valor do campo de arquivos no React Hook Form

    // Verifica se não há erros de validação antes de enviar os arquivos
    const isValid = await trigger(); // Executa a validação manualmente

    if (isValid) {
      // Faz o upload dos arquivos para o S3 e envia informações para /files
      uploadFiles.mutate(selectedFiles);
    }
  };

  return (
    <>
      <Text fontSize={["sm", "md"]} fontWeight={700} lineHeight="32px">
        Arquivos
      </Text>
      <Stack spacing={["0", "6px"]} fontSize={["sm", "md"]} lineHeight="32px">
        <Text>
          Anexe arquivos com <b>limite máximo de 10mb</b> por upload.
        </Text>
        <Text>
          Arquivos permitidos: <b>imagem, vídeo, excel, word e powerpoint.</b>
        </Text>

        <Text>
          Você pode anexar até <b>10 arquivos </b>.
        </Text>
      </Stack>

      <Box as="form" width="100%" onSubmit={handleSubmit(onSubmit)}>
        {loading && (
          <Flex
            w="100vw"
            height="full"
            pos="absolute"
            bg="rgba(255, 255, 255, 0.5)"
            left={0}
            top="0"
            align="center"
            justify="center"
            zIndex={10}
          >
            <Spinner />
          </Flex>
        )}
        {formattedFilesResponse?.length > 0 && (
          <Stack spacing={["16px", "12px"]} mb="28px">
            {formattedFilesResponse.map((file, i) => (
              <Flex
                key={i}
                align="center"
                px="16px"
                justify="space-between"
                border="1px solid #40BF91"
                borderRadius={"12px"}
                height="52px"
              >
                <Button
                  onClick={() => handleFileDownload(file.key)}
                  fontWeight={500}
                  fontSize={["12px", "sm"]}
                  textDecor="underline"
                  maxW={["70%"]}
                  h="20px"
                  bg="none"
                  border="none"
                  p={0}
                  _hover={{
                    bg: "none",
                  }}
                  _active={{
                    bg: "none",
                    border: "none",
                  }}
                >
                  {file.name}
                </Button>

                <Flex align="center" gap="12px">
                  <Text fontSize={["12px", "14px"]}>
                    {formatFileSize(file?.size)?.toLowerCase()}
                  </Text>

                  <Button
                    onClick={() => handleFileDelete(file.idFile)}
                    minW="20px"
                    w="20px"
                    maxW="20px"
                    h="20px"
                    bg="none"
                    borderRadius="full"
                    p={0}
                    _hover={{
                      bg: "none",
                    }}
                    isDisabled={isDisabled}
                  >
                    <Image src={iconDelete} alt="" />
                  </Button>
                </Flex>
              </Flex>
            ))}
          </Stack>
        )}

        <FormLabel
          htmlFor="files"
          cursor={
            isSending || currentFiles === 10 || isDisabled
              ? "not-allowed"
              : "pointer"
          }
          opacity={isSending ? 0.5 : 1}
        >
          <Flex w="full" h="full" alignItems="center">
            {isSending && (
              <>
                <CircularProgress
                  trackColor="pGray.200"
                  value={progress}
                  color="orange.500"
                >
                  <CircularProgressLabel>{progress}%</CircularProgressLabel>
                </CircularProgress>
                <Text as="span" pt={2} textAlign="center">
                  Enviando...
                </Text>
              </>
            )}

            <Tooltip
              label="Limite de 10 arquivos atingido"
              isDisabled={currentFiles === 10 || isDisabled ? false : true}
            >
              <Flex
                align="center"
                w={["90%", "300px"]}
                h="32px"
                gap={["8px", "12px"]}
                opacity={currentFiles === 10 || isDisabled ? 0.5 : 1}
              >
                <Image src={iconAnchor} alt="" />
                <Text as="span" color="red.100" fontWeight={600}>
                  Anexar arquivos
                </Text>

                <Text> (10gb disponível) </Text>
              </Flex>
            </Tooltip>
          </Flex>

          <input
            type="file"
            id="files"
            data-testid="files"
            multiple
            disabled={currentFiles === 10 || isDisabled}
            onChange={handleFileChange}
            style={{
              display: "none",
            }}
          />
          {errors.files && (
            <Text as="span" fontSize="sm" fontWeight={600} color="red">
              {errors.files.message}
            </Text>
          )}
        </FormLabel>
      </Box>
    </>
  );
}
