import React, { useState } from "react";
import { Input, ModalWithoutClose, Select } from "../../../components";
import { Flex, useToast } from "@chakra-ui/react";
import { MOCK_LIST_FILTERS_PRODUCTS } from "../../../mock";
import { fetchReportStatusConnect } from "../../../../../hooks/Admin/useCompanyRegisterList";

const ModalStatus = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [statusValues, setStatusValues] = useState({
    produto: "",
    nroFuncionariosIni: 0,
    nroFuncionariosFim: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const dataFiltersProducts = MOCK_LIST_FILTERS_PRODUCTS;

  const handleClose = () => {
    setStatusValues((prev) => ({
      ...prev,
      produto: "",
      nroFuncionariosIni: "",
      nroFuncionariosFim: "",
    }));
    onClose();
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(!isLoading);
      const data = await fetchReportStatusConnect(statusValues);
      if (!!data.xssfWorkbookByte) {
        const bytes = new Uint8Array(data.xssfWorkbookByte);
        let d = new Date();
        const dateToFile = d.getTime();
        const filename = `StatusCertificacao_${dateToFile}.xlsx`;

        let blob = new Blob([bytes], { type: "application/vnd.ms-excel" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        toast({
          title: "Geração de status ocorreu com sucesso!",
          description: "O Download já foi iniciado.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Não ha dados para esse tipo de status",
          description:
            "Não foi possivel fazer download, por não existir os dados solicitados.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Ocorreu um erro inesperado!",
        description:
          "Tente novamente mais tarde ou entre em contato com o suporte",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      handleClose();
    }
  };

  return (
    <ModalWithoutClose
      title={"Gerar status certificação"}
      isOpen={isOpen}
      onClose={handleClose}
      titleSubmit={"Gerar Relatório"}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
    >
      <Flex gap="16px" direction={"column"}>
        <Flex gap="16px" direction={["column", "column", "column", "row"]}>
          <Select
            placeholder={"Produto"}
            name={"Produto"}
            value={statusValues.produto}
            setValue={(value) =>
              setStatusValues((prev) => ({
                ...prev,
                produto: value,
              }))
            }
          >
            {dataFiltersProducts?.map((opt, index) => {
              return (
                <option value={opt.value} key={index}>
                  {opt.label}
                </option>
              );
            })}
          </Select>
        </Flex>
        <Input
          placeholder={"Quantidade mínima de funcionários elegíveis"}
          name={"nroFuncionariosIni"}
          value={statusValues.nroFuncionariosIni}
          onChange={(value) =>
            setStatusValues((prev) => ({
              ...prev,
              nroFuncionariosIni: value.target.value,
            }))
          }
        />
        <Input
          placeholder={"Quantidade máxima de funcionários elegíveis"}
          name={"nroFuncionariosFim"}
          value={statusValues.nroFuncionariosFim}
          onChange={(value) =>
            setStatusValues((prev) => ({
              ...prev,
              nroFuncionariosFim: value.target.value,
            }))
          }
        />
      </Flex>
    </ModalWithoutClose>
  );
};

export default ModalStatus;
