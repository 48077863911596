import { Flex, Box } from "@chakra-ui/react";

export function Badge({ children, ...rest }) {
  return (
    <Flex
      align="center"
      justify="center"
      bg="#FFCCCC"
      borderRadius={["0", "20px"]}
      height={["auto", "43px"]}
      {...rest}
    >
      <Flex
        py={["8px", "12px"]}
        px={["9px", "24px"]}
        fontSize={["13px", "16px"]}
        color="red.100"
        w='inherit'
      >
        {children}
      </Flex>
    </Flex>
  );
}
