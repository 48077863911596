import React from 'react'
import styled from 'styled-components'
import email from './email.svg'

const InputText = styled.input.attrs({type: 'email'})`

 	font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    border: none;
    border: 1px solid #B8CAE8;
    box-sizing: border-box;
    border-radius: 10px;
    outline:  none;
    padding: 16px;
	width: ${props=>props.width ? props.width : '443px'};
    height: ${props=>props.height ? props.height : '56px'};
	
	@media screen and (max-width: 960px) {
		width: 100%;
	}

	${props=>{
		if(props.icon === false){
			return `

			`
		}
		else {
			return `
			 	background-image: url(${email}) ;
			    background-repeat: no-repeat;
			    background-position: 16px 16px;
			    text-indent: 36px;
			    vertical-algn: middle;
			    background-size: 20px;
			`
		}
	}}
`

const StyledContainer =styled.div`
  display: flex;
  flex-direction: column;

`

const StyleLabel = styled.label`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;

color: #292B30;
`

function InputEmail(props){
	return  <StyledContainer>
            <StyleLabel>{props.label}</StyleLabel>
            <InputText  type="email" {...props} />
         </StyledContainer>
}

export default InputEmail
