import React, { useState } from "react";
import { Input, ModalWithoutClose } from "../../../components";
import { fetchExportQuestions } from "../../../../../hooks/Admin/useCompanyRegisterList";
import { useToast } from "@chakra-ui/react";

const ModalQuestions = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [questionsValues, setQuestionsValues] = useState({
    code: "",
  });

  const handleClose = () => {
    setQuestionsValues((prev) => ({
      ...prev,
      code: "",
    }));
    onClose();
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(!isLoading);

      await fetchExportQuestions(questionsValues.code,false);

      toast({
        title: "Geração das respostas dos quetionários ocorreu com sucesso!",
        description: "O Download já foi iniciado.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Ocorreu um erro inesperado!",
        description:
          "Tente novamente mais tarde ou entre em contato com o suporte",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      handleClose();
    }
  };

  return (
    <ModalWithoutClose
      title={"Gerar respostas dos questionários"}
      isOpen={isOpen}
      onClose={handleClose}
      titleSubmit={"Gerar Respostas"}
      handleSubmit={handleSubmit}
      widht="614px"
      isLoading={isLoading}
    >
      <Input
        placeholder={"Digite o código de participação"}
        name={"CodigoDeParticipacao"}
        value={questionsValues.code}
        onChange={(value) =>
          setQuestionsValues((prev) => ({
            ...prev,
            code: value.target.value,
          }))
        }
      />
    </ModalWithoutClose>
  );
};

export default ModalQuestions;
