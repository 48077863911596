export const notificationsMock = [
  {
    id: 1,
    type: "Custom",
    topic: "Pesquisa de certificação",
    color: "#002070",
    tagBg: "rgba(0, 32, 112, 0.10)",
    message: "<strong>Parabéns!</strong> Sua empresa foi certificada!",
    hasButton: true,
    buttonText: "Ver resultados",
    buttonLink: "#",
  },
  {
    id: 2,
    type: "Attention",
    topic: "Ranking",
    color: "#FF6700",
    tagBg: "rgba(255, 103, 0, 0.10)",
    message:
      "<strong>Atenção!</strong> Período para participação em Rankings termina em 2 dias!",
    hasButton: true,
    buttonText: "Acessar rankings",
    buttonLink: "#",
  },
  {
    id: 3,
    type: "Complete",
    topic: "Pesquisa de certificação",
    color: "#002070",
    tagBg: "rgba(0, 32, 112, 0.10)",
    message:
      "Você concluíu com sucesso o <strong>cadastro complementar</strong>",
    hasButton: false,
    buttonText: "",
    buttonLink: "",
  },
  {
    id: 4,
    type: "Complete",
    topic: "Pesquisa de certificação",
    color: "#002070",
    tagBg: "rgba(0, 32, 112, 0.10)",
    message:
      "Você concluíu com sucesso o <strong>cadastro complementar</strong>",
    hasButton: false,
    buttonText: "",
    buttonLink: "",
  },
  {
    id: 5,
    type: "Attention",
    topic: "Cadastros",
    color: "#FF6700",
    tagBg: "rgba(255, 103, 0, 0.10)",
    message:
      "Há cadastros que precisam ser preenchidos em <strong>Perfil Organizacional</strong>",
    hasButton: true,
    buttonText: "Acessar cadastros",
    buttonLink: "#",
  },
];

export const empresas5AnosMock = [
  '09227188000180',
  '08257844000205',
  '10316083000186',
  '10327213000186',
  '61377073000191',
  '01814989000130',
  '09326499000104',
  '13927934000115',
  '13573332000107',
  '42591651000143',
  '03823704000152',
  '60746948000112',
  '33055146000193',
  '03753088000100',
  '58113812000123',
  '12888241000106',
  '49319411000133',
  '00117351000187',
  '03361252000134',
  '47866934000174',
  '12528708000107',
  '20680798000177',
  '07698801000111',
  '35029057000106',
  '09487141000155',
  '58113812002258',
  '02038405000144',
  '04242942000137',
  '02732968000138',
  '12478186000178',
  '41587502000148',
  '04238951000154',
  '07406242000129',
  '07508172000110',
  '72104144000107',
  '07272825000457',
  '63310411000101',
  '10395358000114',
  '05868278000107',
  '00974915000106',
  '10515785000199',
  '00323063000189',
  '36756997000151',
  '01984199000100',
  '02741679000103',
  '01540533000129',
  '04496152000188',
  '58113812004706',
  '42278473000103',
  '00718528000109',
  '08007677000163',
  '01534080000128',
  '05437233000170',
  '01224134000310',
  '04388688000180',
  '09461647000195',
  '15375991000164',
  '08415806000152',
  '21246699000144',
  '71208516000174',
  '12131444000145',
  '17184037000110',
  '00276225000175',
  '08415806000152',
  '17467515000107',
  '04413729000140',
  '10399001000104',
  '26404731000196',
  '14110585000107',
  '26314062000161',
  '03823704000152',
  '56998982000107',
  '02732968000138',
  '33372251000156',
  '60872504000123',
  '00718528000109',
  '26314062000161',
  '05740125000171',
  '45985371000108',
  '78016003000100',
  '07229021000122',
  '96534094000158',
  '61854147000133',
  '00974915000106',
  '88031539000159',
  '12528708000107',
  '10515785000199',
  '21246699000144',
  '71208516000174',
  '07526557000100',
  '09591056000132',
  '42591651000143',
  '03823704000152',
  '07768134000287',
  '60318797000100',
  '11509676000121',
  '12131444000145',
  '07707650000110',
  '60746948000112',
  '17184037000110',
  '62307848000115',
  '90400888000142',
  '87315099000107',
  '09042817000105',
  '00276225000175',
  '33055146000193',
  '84229889000173',
  '00323063000189',
  '36756997000151',
  '56998982000107',
  '08257844000205',
  '81904948000173',
  '15048754012286',
  '03753088000100',
  '07698801000111',
  '35029057000106',
  '00609634000146',
  '84683481000177',
  '93578813000144',
  '00028666000158',
  '03802115000198',
  '42183442000160',
  '02741679000103',
  '01540533000129',
  '06023259000134',
  '02100999000176',
  '00543968000164',
  '05938780000139',
  '82639451000138',
  '03459850000140',
  '05582619000175',
  '43637214000186',
  '62895230000113',
  '04413729000140',
  '08583723000172',
  '04098210000115',
  '06980081000110',
  '09487141000155',
  '82653726000198',
  '04204018000166',
  '72381189000110',
  '04496152000188',
  '05437734000156',
  '03720882001200',
  '03182155000184',
  '58062779000150',
  '08777465000165',
  '76487032000125',
  '26404731000196',
  '09053134000226',
  '80896194000194',
  '58113812000123',
  '09095183000140',
  '03672254000225',
  '00474973000162',
  '61190096000192',
  '02038405000144',
  '04242942000137',
  '02732968000138',
  '42278473000103',
  '13927934000115',
  '07032076000148',
  '77941490000155',
  '01533865000186',
  '01880344000103',
  '09967852000127',
  '75290122000169',
  '33372251000156',
  '42283770000139',
  '05050074000156',
  '06189629000108',
  '82901000000127',
  '60872504000123',
  '02833372000124',
  '09688218000155',
  '13573332000107',
  '12478186000178',
  '00718528000109',
  '55885321000102',
  '13427325000105',
  '61069373000103',
  '01438784000105',
  '82015652000164',
  '33200056000149',
  '60250776000191',
  '47960950056852',
  '49319411000133',
  '57040040000184',
  '00117351000187',
  '03361252000134',
  '90719238000164',
  '60316817000103',
  '81442014000167',
  '33608308000173',
  '08007677000163',
  '05656399000187',
  '78614229000103',
  '27915735000100',
  '02955426000124',
  '05337875000105',
  '01534080000128',
  '82277955000236',
  '16921603000166',
  '03902252001095',
  '59456277000176',
  '06214736000149',
  '01814989000130',
  '81639023001890',
  '02343359000278',
  '11677441000149',
  '13021784000186',
  '03845717002176',
  '64089824000324',
  '75642256000100',
  '10279522000128',
  '44914992000138',
  '18650225000159',
  '87163234000138',
  '26314062000161',
  '74544297000788',
  '74544297000192',
  '03575238000133',
  '89096457000155',
  '82845322000104',
  '09461647000195',
  '09326499000104',
  '02966317000102',
  '16825255000123',
  '06975199000150',
  '02429732000127',
  '47866934000174',
  '15375991000164',
  '33164021000100',
  '02949160000107',
  '63310411000101',
  '46124624000111',
  '10395358000114',
  '78339439000130',
  '05868278000107',
  '87701249000102',
  '28714533000154',
  '42163881000101',
  '01902835000108',
  '05740125000171',
  '03347828000109',
  '87376109000106',
  '33122466000704',
  '03128979000176',
  '00718528006565',
  '78016003000100',
  '03644084000194',
  '62307848000115',
  '81904948000173',
  '04592014000100',
  '02100999000176',
  '03459850000140',
  '05582619000175',
  '82653726000198',
  '04204018000166',
  '76487032000125',
  '01691041000134',
  '80896194000194',
  '77941490000155',
  '10327213000186',
  '84978485000182',
  '82015652000164',
  '61377073000191',
  '81442014000167',
  '02955426000124',
  '81639023001890',
  '75642256000100',
  '08187168000160',
  '78339439000130',
  '11509676000121',
  '07698801000383',
  '04098210000115',
  '58113812005192',
  '22724746000180',
  '02472105000179',
  '06214736000149',
  '02429732000127',
  '01902835000108',
  '62895230000113',
  '61854147000133',
  '55928014000161',
  '00541642000106',
  '09688218000155',
  '60250776000191',
  '44914992000138',
  '02949160000107',
  '09591056000132',
  '09042817000105',
  '07698801000626',
  '42183442000160',
  '05209279000131',
  '03672254000225',
  '00474973000162',
  '42283770000139',
  '06189629000108',
  '04472647000177',
  '33608308000173',
  '27915735000100',
  '13701645000101',
  '11677441000149',
  '28714533000154',
  '42163881000101',
  '05740125000171',
  '88031539000159',
  '05040481000182',
  '87315099000107',
  '09227188000180',
  '08257844000205',
  '93578813000144',
  '06023259000134',
  '90159799000156',
  '05305671000184',
  '00752214000114',
  '72381189000110',
  '03182155000184',
  '08777465000165',
  '01691041000568',
  '07032076000148',
  '05050074000156',
  '92695691000103',
  '90719238000164',
  '01814989000130',
  '87163234000138',
  '74544297000788',
  '03575238000133',
  '89096457000155',
  '87235172000122',
  '93070159000163',
  '87701249000102',
  '86900925000104',
  '07229021000122',
  '11087105000146',
  '17160849000125',
  '84229889000173',
  '15048754012286',
  '19384141000183',
  '84683481000177',
  '05120289000104',
  '16779741000152',
  '82639451000138',
  '15754475000140',
  '12218713000105',
  '01533865000186',
  '75290122000169',
  '82901000000127',
  '10903643000107',
  '82640558000104',
  '78614229000103',
  '05337875000105',
  '13021784000186',
  '18650225000159',
  '82845322000104',
  '04892991000115',
  '07143006000167',
  '04563634000103',
  '55928014000161',
  '09168425000189',
  '07768134000287',
  '60318797000100',
  '56998982000107',
  '84683481000177',
  '09053134000226',
  '61190096000192',
  '01880344000103',
  '12478186000178',
  '00718528000109',
  '04238951000154',
  '82277955000236',
  '10416729000105',
  '08726829000188',
  '46124624000111',
  '10395358000114',
  '78339439000130',
  '05868278000107',
  '87701249000102',
  '42163881000101',
  '03644084000194',
  '04204018000166',
  '80896194000194',
  '08187168000160',
  '07229021000122',
  '96534094000158',
  '03644084000194',
  '88031539000159',
  '21246699000144',
  '71208516000174',
  '11087105000146',
  '17160849000125',
  '12131444000145',
  '09042817000105',
  '00276225000175',
  '04563634000103',
  '09227188000180',
  '08415806000152',
  '03753088000100',
  '19384141000183',
  '00609634000146',
  '93578813000144',
  '00028666000158',
  '03802115000198',
  '08323041000120',
  '04413729000140',
  '05305671000184',
  '08583723000172',
  '06980081000110',
  '00541642000106',
  '82653726000198',
  '04204018000166',
  '00752214000114',
  '72381189000110',
  '10316083000186',
  '04496152000188',
  '05437734000156',
  '10399001000104',
  '03182155000184',
  '80896194000194',
  '15754475000140',
  '03672254000225',
  '22724746000180',
  '33372251000156',
  '01534930000198',
  '05050074000156',
  '06189629000108',
  '82901000000127',
  '10903643000107',
  '06337969000139',
  '41587502000148',
  '13427325000105',
  '12888241000106',
  '04472647000177',
  '57040040000184',
  '14110585000107',
  '00117351000187',
  '03361252000134',
  '90719238000164',
  '60316817000103',
  '27915735000100',
  '09168425000189',
  '05337875000105',
  '59456277000176',
  '13701645000101',
  '06214736000149',
  '01814989000130',
  '11677441000149',
  '13021784000186',
  '10416729000105',
  '26314062000161',
  '74544297000788',
  '74544297000192',
  '82845322000104',
  '09461647000195',
  '93070159000163',
  '07272825000457',
  '08187168000160',
  '06975199000150',
  '04892991000115',
  '05740125000171',
  '07143006000167',
  '03347828000109',
  '09591056000132',
  '42591651000143',
  '84229889000173',
  '15048754012286',
  '03720882001200',
  '58062779000150',
  '08777465000165',
  '26404731000196',
  '77941490000155',
  '12954744000124',
  '02472105000179',
  '61069373000103',
  '01438784000105',
  '33200056000149',
  '47960950056852',
  '01534080000128',
  '03845717002176',
  '75642256000100',
  '07272825000457',
  '15375991000164',
  '23115119000105',
  '10440482000154',
  '16844641000162',
  '28651959000106',
  '53113791000122',
  '47960950000121',
  '10766206000180',
  '00117842000632'
]
