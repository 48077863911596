import { FC, useEffect, useState } from "react"

import * as Yup from "yup"
import { useForm, SubmitHandler } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import iconBar from "../../../assets/images/icon-bar.svg"
import iconSelected from "../../../assets/images/icon-arrow-down.svg"

import { MdOutlineEmail } from "react-icons/md"
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
  Grid,
  GridItem,
  Flex,
  FormLabel,
  Select,
  RadioGroup,
  Stack,
  Radio,
  Input,
  useToast,
} from "@chakra-ui/react"
import { researchApi } from "../../../services/api-research"
// import { Input } from "../../../components/ui/form/input/Input";
// import { Icon } from "../../../components/ui";

const ModalEdit = ({
  onClose,
  isOpen,
  researchId,
  employee,
  total,
  active,
  refresh,
}) => {
  const [collaborator, setCollaborator] = useState({...employee, status: employee?.status === 'Ativo' ? 'sim' : 'não'})
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setCollaborator({...employee, status: employee?.status === 'Ativo' ? 'sim' : 'não'});
  }, [employee])
  

  const toast = useToast();

  const handleSendData = async () => {
    if (collaborator.login || collaborator.status) {

      try {
        await researchApi.put(
          `/employees?researchId=${researchId}&total=${total}&active=${active}`,
          {
            id: collaborator.id,
            name: collaborator.name,
            login: collaborator.login,
            status: collaborator.status,
          }
        )

        refresh();
        setSuccess(true)
      } catch (err) {
        if (err.response) {
          toast({
            title: "Falha no envio",
            description: err.response.data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        console.log(err.message)
      }

    } else {
      toast({
        title: "Falha no envio",
        description: 'Campos Login e Status são obrigatórios',
        status: "error",
        duration: 5000,
        isClosable: true,
      });

    }
  }

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setSuccess(false)
    }, 1000)
  }

  const handleLoginChange = (event) => {
    setCollaborator({...collaborator, login: event.target.value})
  }

  const handleDescriptionChange = (event) => {
    setCollaborator({...collaborator, name: event.target.value})
  }

  const handleRadioChange = (event) => {
    setCollaborator({...collaborator, status: event === '1' ? 'sim' : 'não'})
  }

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          height: "341px",
          width: "552px",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box display={"flex"} flexDir="column" pt="60px">
          <div
            style={{
              margin: "0 auto",
              marginBottom: "20px",
            }}
          >
            {/* <Icon type="check-green" width="100px" height="100px" /> */}
          </div>
          <Heading fontSize="24px" fontWeight="700">
            Alteração realizada com sucesso
          </Heading>
        </Box>
        <ModalFooter
          width="100%"
          position={"absolute"}
          p={0}
          bottom={0}
          height="69px"
        >
          <Button
            width="100%"
            color="white"
            background="#E60000"
            height="100%"
            fontWeight="300"
            onClick={handleClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "12px",
          width: "800px",
          position: "relative",
          height: "396px",
          overflow: "hidden",
        }}
      >
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody px="33px">
          <img src={iconBar} alt="icon bar" />
          <Heading fontSize="18px" fontWeight="600" mb="10px" mt="8px">
            Editar Colaborador
          </Heading>

          <Grid templateColumns="repeat(2, 1fr)" gap={4} mt="20px">
            <GridItem colSpan={1} h="77px">
              <FormLabel
                fontSize="xs"
                color="#292B30"
                fontWeight="normal"
                mb="8px"
              >
                Descrição&nbsp;<label style={{color: 'white', weight: 'bold', fontSize: 16}}>*</label>
              </FormLabel>
              <Input
                id="Descrição"
                label="Descrição"
                w="100%"
                h="52px"
                fontSize="14px"
                border={"1px solid #5C6066"}
                borderRadius="10px"
                resize="none"
                p="8px"
                placeholder="descrição"
                type="text"
                onChange={handleDescriptionChange}
                value={collaborator.name}
              />
              {/* <Input
                id="Descrição"
                label="Descrição"
                error={errors.email}
                placeholder="Digite seu e-mail"

                // {...register("Descrição", { required: true })}
              /> */}
              {/* {errors.email && <p>{errors.email.message}</p>} */}
            </GridItem>
            <GridItem colSpan={1} h="77px">
              <FormLabel
                fontSize="xs"
                color="#292B30"
                fontWeight="normal"
                mb="8px"
              >
                Login&nbsp;<label style={{color: 'red', weight: 'bold', fontSize: 16}}>*</label>
              </FormLabel>
              <Input
                id="Login"
                label="Login"
                w="100%"
                h="52px"
                fontSize="14px"
                border={"1px solid #5C6066"}
                borderRadius="10px"
                resize="none"
                p="8px"
                placeholder="Login"
                type="text"
                onChange={handleLoginChange}
                value={collaborator.login}
              />
              {/* <Input
                id="Descrição"
                label="Login"
                error={errors.email}
                placeholder="Digite seu e-mail"

                // {...register("Descrição", { required: true })}
              /> */}
            </GridItem>
            <GridItem colSpan={1} h="77px">
              <FormLabel
                fontSize="xs"
                color="#292B30"
                fontWeight="normal"
                mb="8px"
              >
                Status&nbsp;<label style={{color: 'red', weight: 'bold', fontSize: 16}}>*</label>
              </FormLabel>

              <RadioGroup onChange={handleRadioChange} defaultValue={collaborator && collaborator.status === 'sim' ? '1' : '2'}>
                <Stack spacing={5} direction="row">
                  <Box
                    bg="#E5E5E5"
                    w="90px"
                    h="32px"
                    borderRadius={"20px"}
                    display="flex"
                    alignItems={"center"}
                    justifyContent="center"
                  >
                    <Radio colorScheme="seal" value="1" fontSize={"14px"}>
                      Ativo
                    </Radio>
                  </Box>
                  <Box
                    bg="#E5E5E5"
                    w="90px"
                    h="32px"
                    borderRadius={"20px"}
                    display="flex"
                    alignItems={"center"}
                    justifyContent="center"
                  >
                    <Radio colorScheme="seal" value="2" fontSize={"14px"}>
                      Inativo
                    </Radio>
                  </Box>
                </Stack>
              </RadioGroup>
            </GridItem>
          </Grid>

          {/* <form onSubmit={handleSubmit(handleSendData)}>
            <Input
              id="email"
              // label="E-mail"
              error={errors.email}
              placeholder="Digite seu e-mail"
              icon={<MdOutlineEmail color="#9ea3ad" fontSize="22px" />}
              {...register("email", { required: true })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </form> */}
        </ModalBody>
        <ModalFooter w="100%" mb="15px">
          <Flex justify={"space-between"} w="100%">
            <Button
              w="265px"
              variant="outline"
              size="lg"
              color="#E60000"
              fontSize="16px"
              fontWeight="600"
              borderColor="#E60000"
              borderRadius="10px"
              border="1px"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              w="265px"
              onClick={handleSendData}
              // h="100%"
              type="submit"
              bg="#E60000"
              color="white"
              borderRadius="10px"
              size="lg"
              fontSize="16px"
            >
              Salvar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalEdit
