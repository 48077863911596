import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from "@chakra-ui/react";

const WaitImportRespondentModal = ({
    isOpen, onClose, text
  }) => {

    return (
    <AlertDialog
    isOpen={isOpen}
    >
    <AlertDialogOverlay>
      <AlertDialogContent>

        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
          Ação indisponível no momento
        </AlertDialogHeader>
    
        <AlertDialogBody>
            {text=='' ? (
                <>
                    Por favor, aguarde enquanto processamos os dados. Neste momento, estamos cadastrando os colaboradores na pesquisa. Esse processo pode levar algum tempo, dependendo do número de colaboradores convidados. 
                    IMPORTANTE: Durante esse processo, não é possível realizar outras configurações na pesquisa.
                </>
            ) : (
                <>{text}</>
            )}
        </AlertDialogBody>
    
        <AlertDialogFooter>
          <Button onClick={onClose}>
            Fechar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
    </AlertDialog>)
};

export default WaitImportRespondentModal;