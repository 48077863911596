import { useQuery } from "react-query";
import { queryClient } from "../../services/queryCliente";
import axios from "axios";

export const fetchCertificates = async (company) => {
  const url_certificados =
    window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
    `/participation?dc=${company.cnpj
      .replace(/\./g, "")
      .replace(/-/g, "")
      .replace(/\//, "")}`;

  const res = await axios.get(url_certificados);

  return res.data;
};

export const useCertificados = (company) => {
  return useQuery(
    "company-certificates",
    () => fetchCertificates(company),
    {
      enabled: company !== undefined,
    },
    {
      refetchOnWindowFocus: false, // default: true
      staleTime: 30 * 1000,
      initialData: () => queryClient.getQueryData("company-certificates"),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState("company-certificates")?.dataUpdatedAt,
    }
  );
};
