import React from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { SearchBar, Select } from "../../../components";
import {
  MOCK_LIST_FILTERS_STATES,
  MOCK_LIST_FILTERS_STATUS,
} from "../../../mock";
import { useFilters } from "../../../../../hooks/Admin/useCompanyRegisterList";

const StatusFilters = ({ filtersValues, setFiltersValues }) => {
  const { data: dataFiltersListCities } = useFilters("listCities");
  const { data: dataFiltersListStates } = MOCK_LIST_FILTERS_STATES;
  const { data: dataFiltersListSectors } = useFilters("listSectors");
  const { data: dataFiltersListStatus } = MOCK_LIST_FILTERS_STATUS;
  const { data: dataFiltersListAccountOwners } =
    useFilters("listAccountOwners");

  return (
    <SimpleGrid columns={[3, 3, 3, 3, 3, 6]} spacingX="24px" spacingY="16px">
      <SearchBar
        textSearch={filtersValues.search}
        setTextSearch={(value) => {
          setFiltersValues((prev) => ({
            ...prev,
            search: value,
          }));
        }}
        placeholder={"Digite o nome da empresa"}
      />
      <Select
        placeholder={"Todos os Estados"}
        name={"ufFilter"}
        value={filtersValues.ufFilter}
        setValue={(value) =>
          setFiltersValues((prev) => ({
            ...prev,
            ufFilter: value,
          }))
        }
      >
        {dataFiltersListStates?.map((opt, index) => {
          return (
            <option value={opt.value} key={index}>
              {opt.label}
            </option>
          );
        })}
      </Select>
      <Select
        placeholder={"Todas as cidades"}
        name={"cityFilter"}
        value={filtersValues.cityFilter}
        setValue={(value) =>
          setFiltersValues((prev) => ({
            ...prev,
            cityFilter: value,
          }))
        }
      >
        {dataFiltersListCities?.map((opt, index) => {
          return (
            <option value={opt.cidade} key={index}>
              {opt.cidade}
            </option>
          );
        })}
      </Select>
      <Select
        placeholder={"Todos os Setores"}
        name={"setorFilter"}
        value={filtersValues.setorFilter}
        setValue={(value) =>
          setFiltersValues((prev) => ({
            ...prev,
            setorFilter: value,
          }))
        }
      >
        {dataFiltersListSectors?.map((opt, index) => {
          return (
            <option value={opt.setor} key={index}>
              {opt.setor}
            </option>
          );
        })}
      </Select>
      <Select
        placeholder={"Todos os Status"}
        name={"filter"}
        value={filtersValues.filter}
        setValue={(value) =>
          setFiltersValues((prev) => ({
            ...prev,
            filter: value,
          }))
        }
      >
        {dataFiltersListStatus?.map((opt, index) => {
          return (
            <option value={opt.status} key={index}>
              {opt.status}
            </option>
          );
        })}
      </Select>
      <Select
        placeholder={"Todas as contas"}
        name={"accountOwnerFilter"}
        value={filtersValues.accountOwnerFilter}
        setValue={(value) =>
          setFiltersValues((prev) => ({
            ...prev,
            accountOwnerFilter: value,
          }))
        }
      >
        {dataFiltersListAccountOwners?.map((opt, index) => {
          return (
            <option value={opt.donoConta} key={index}>
              {opt.donoConta}
            </option>
          );
        })}
      </Select>
    </SimpleGrid>
  );
};

export default StatusFilters;
