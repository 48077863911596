import * as React from "react";
import { isEmpty } from "lodash";
import { useRankings } from "../../hooks/useRankings";

const useRankingsList = (id, pageControl) => {
  const [current, setCurrent] = React.useState([]);
  const { data, isLoading, isError } = useRankings(id);

  const currentRankings = data;

  React.useEffect(() => {
    if (!isEmpty(data)) {
      const current = currentRankings.slice(pageControl, pageControl + 16);
      setCurrent(current);
    }
  }, [data, currentRankings, pageControl]);

  return [current, currentRankings, isLoading, isError];
};

export default useRankingsList;
