import React from "react"
import styled from "styled-components"

import SimpleSidebar from "../components/nav/Sidebar"
import TermosLista from "./termos"
import iconDownload from "../../assets/images/icon-download.svg"

import {
  Heading,
  Container,
  Link,
  Flex,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react"


const Termos = () => {

  return (
    <SimpleSidebar>
      <Container maxW="container.xl">
        {/* <Card hasCloseButton /> */}
        <Heading as="h3" fontSize={"18px"} mt="30px">
          Termos de Uso e Compromisso
        </Heading>

        <Tabs variant="unstyled">
          <Flex justifyContent="space-between" mt="25px" h="27px" mb="35px">
            <TabList>
              <Tab
                _selected={{
                  fontWeight: "bold",
                  borderBottom: "4px solid #E60000",
                }}
              >
                Termos
              </Tab>
            </TabList>
          </Flex>
          <TabPanels>
            <TabPanel>
              <TermosLista />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </SimpleSidebar>
  )
}

export default Termos