/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext } from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Tag,
  Text,
  useMediaQuery,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useSpecificQuestions } from "../../../helpers/useSpecificQuestions";
import SpecificQuestionsModal from "./Modals/SpecificQuestionsModal";
import WaitImportRespondentModal from "./Modals/WaitImportRespondentModal";

import { CustomContext } from "../../../context/CustomContext";

const SpecificQuestions = ({ researchId, importRespondentIsRunning, totalRespondents, extern_researchId }) => {
  const { custom } = useContext(CustomContext);
  const [mobile] = useMediaQuery('(min-width: 1024px)')

  const [modalOpen, setModalOpen] = useState(false);
  const [defaultTheme, setDefaultTheme] = useState("");
  const [customOptions, setCustomOptions] = useState([]);
  const [workOptions, setWorkOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [info, setInfo] = useState([]);
  const [isOpenWaitImportModal, setIsOpenWaitImportModal] = useState(false);

  const { data, isLoading } = useSpecificQuestions(researchId);

  useEffect(() => {
    if (data instanceof Array && data.length > 0) {
      setInfo(data);
      const customData = data.find((i) => i.type === "Custom")?.alternatives;
      if (customData) {
        setDefaultTheme(data.find((i) => i.type === "Custom")?.teme);
        setCustomOptions(customData.split(";"));
      }

      const work = data.find((i) => i.type === "Desktop").alternatives;
      setWorkOptions(work.split(";"));

      const positions = data.find((i) => i.type === "Positions").alternatives;
      setPositionOptions(positions.split(";"));
    }
  }, [data]);

  const handleSpecificModal = () => {
    if (custom) {

      if(importRespondentIsRunning && extern_researchId!=null){
        setIsOpenWaitImportModal(true);
      } else {
        setModalOpen(true);
      }

    } else return;
  };

  const handleCloseSpecificModal = () => {
    setModalOpen(false);
  };

  const handleCloseModalWaitImport = () => {
    setIsOpenWaitImportModal(false);
  };

  return !mobile ? (
    <Accordion
      defaultIndex={[1]}
      allowMultiple
      maxW="100%"
      p={["2px 22px 32px 22px", 6]}
    >
      <SpecificQuestionsModal
        info={info}
        defaultTheme={defaultTheme}
        researchId={researchId}
        isOpen={modalOpen}
        onClose={handleCloseSpecificModal}
      />
      <WaitImportRespondentModal isOpen={isOpenWaitImportModal} onClose={handleCloseModalWaitImport}  text=''/>
      <AccordionItem border="none" position={"relative"}>
        <Tag
          bg="#e5ebec"
          color="#002070"
          borderRadius="20px"
          h="23px"
          fontSize="16px"
          px="12px"
          fontWeight="600"
        >
          Perguntas específicas
        </Tag>
        <Text fontSize="13px" lineHeight={"24px"} pt="8px" maxW="86%">
          Aqui você poderá gerenciar as alternativas em
          <b> duas perguntas específicas </b> sobre <b> Áreas de trabalho</b> e
          <b> Cargos</b> e poderá <b>criar uma nova pergunta</b> também com
          alternativas customizadas por você.
        </Text>
        <AccordionButton
          bg="gray.400"
          w="32px"
          right={0}
          top="52px"
          p={0}
          justifyContent="center"
          h="32px"
          rounded={"full"}
          position={"absolute"}
        >
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p={0}>
          <Items
            customOptions={customOptions}
            workOptions={workOptions}
            positionOptions={positionOptions}
            handleSpecificModal={handleSpecificModal}
          />
        </AccordionPanel>
      </AccordionItem>
      <Button
        w="100%"
        mt="24px"
        h="48px"
        variant="secondary"
        color={!custom ? "#292B30" : "red.100"}
        disabled={!custom}
        borderColor={!custom ? "#292B30" : "red.100"}
        onClick={handleSpecificModal}
      >
        {customOptions.length > 0 ? "Editar" : "Configurar"}
      </Button>
    </Accordion>
  ) : (
    <GridItem
      w="100%"
      border="1px"
      borderColor="#CCCCCC"
      borderRadius="20px"
      p="20px"
    >
      <SpecificQuestionsModal
        info={info}
        defaultTheme={defaultTheme}
        researchId={researchId}
        isOpen={modalOpen}
        onClose={handleCloseSpecificModal}
      />
      <WaitImportRespondentModal isOpen={isOpenWaitImportModal} onClose={handleCloseModalWaitImport} text=''/>
      <Flex justify={"space-between"} align="center">
        <Box>
          <Tag
            bg="#e5ebec"
            color="#002070"
            fontSize="16px"
            borderRadius="20px"
            h="23px"
            px="15px"
            fontWeight="600"
          >
            Perguntas específicas
          </Tag>
          <Text fontSize="14px" pt={1} mt="8px">
            Aqui você poderá gerenciar as alternativas em
            <b> duas perguntas específicas </b> sobre <b> Área de Trabalho</b>{" "}
            e
            <br />
            <b> Cargos</b> e poderá <b>criar uma nova pergunta</b> também com
            alternativas customizadas por você.
          </Text>
        </Box>
        <Button
          w="175px"
          variant="secondary"
          color={!custom ? "#292B30" : "red.100"}
          disabled={!custom}
          borderColor={!custom ? "#292B30" : "red.100"}
          onClick={handleSpecificModal}
        >
          {customOptions.length > 0 ? "Editar" : "Configurar"}
        </Button>
      </Flex>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt="25px">
        <GridItem
          w="100%"
          h="95px"
          border="1px"
          borderColor="#40BF91"
          fontSize="14px"
          borderRadius="10px"
          p="20px"
        >
          <Text fontSize={"16px"} fontWeight="bold">
            Área de Trabalho
          </Text>
          <div
            style={{
              backgroundColor: "#E60000",
              width: "25px",
              height: "2px",
            }}
          ></div>
          {workOptions.length > 0 ? (
            <Text fontSize={"13px"} fontWeight="600" mt="5px">
              Foram selecionadas {workOptions.length} alternativas
            </Text>
          ) : (
            <Text fontSize={"13px"} fontWeight="400" mt="5px">
              Customize as alternativas
            </Text>
          )}
        </GridItem>
        <GridItem
          w="100%"
          h="95px"
          border="1px"
          borderColor="#40BF91"
          fontSize="14px"
          borderRadius="10px"
          p="20px"
        >
          <Text fontSize={"16px"} fontWeight="bold">
            Cargos
          </Text>
          <div
            style={{
              backgroundColor: "#E60000",
              width: "25px",
              height: "2px",
            }}
          ></div>

          {positionOptions.length > 0 ? (
            <Text fontSize={"13px"} fontWeight="600" mt="5px">
              Foram selecionadas {positionOptions.length} alternativas
            </Text>
          ) : (
            <Text fontSize={"13px"} fontWeight="400" mt="5px">
              Customize as alternativas
            </Text>
          )}
        </GridItem>

        {customOptions.length > 0 ? (
          <GridItem
            w="100%"
            h="95px"
            border="1px"
            borderColor="#40BF91"
            fontSize="14px"
            borderRadius="10px"
            p="20px"
          >
            <Text fontSize={"16px"} fontWeight="bold">
              Pergunta customizável
            </Text>
            <div
              style={{
                backgroundColor: "#E60000",
                width: "25px",
                height: "2px",
              }}
            ></div>
            <Text fontSize={"13px"} fontWeight="600" mt="5px">
              Foram selecionadas {customOptions.length} alternativas
            </Text>
          </GridItem>
        ) : (
          <GridItem w="100%" h="95px">
            <Box
              cursor="pointer"
              display="flex"
              alignItems="center"
              justifyContent="center"
              w="100%"
              h="100%"
              border="1px dashed #40BF91"
              borderRadius="10px"
              onClick={handleSpecificModal}
            >
              <Text fontSize="14px"> Crie e customize mais uma pergunta</Text>
            </Box>
          </GridItem>
        )}
      </Grid>
    </GridItem>
  );
};

const Items = ({
  customOptions,
  workOptions,
  positionOptions,
  handleSpecificModal,
}) => {
  return (
    <Grid
      templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(3, 1fr)"]}
      gap={["12px", 6]}
      mt="25px"
    >
      <GridItem
        w="100%"
        h="95px"
        border="1px solid #40BF91"
        fontSize="14px"
        borderRadius={["12px", "10px"]}
        p="20px"
      >
        <Text fontSize={"16px"} fontWeight="bold">
          Áreas de trabalho
        </Text>
        <div
          style={{
            backgroundColor: "#E60000",
            width: "25px",
            height: "2px",
          }}
        ></div>
        {workOptions.length > 0 ? (
          <Text fontSize={"13px"} fontWeight="600" mt="5px">
            Foram selecionadas {workOptions.length} alternativas
          </Text>
        ) : (
          <Text fontSize={"13px"} fontWeight="400" mt="5px">
            Customize as alternativas
          </Text>
        )}
      </GridItem>
      <GridItem
        w="100%"
        h="95px"
        border="1px"
        borderColor="#40BF91"
        fontSize="14px"
        borderRadius="10px"
        p="20px"
      >
        <Text fontSize={"16px"} fontWeight="bold">
          Cargos
        </Text>
        <div
          style={{
            backgroundColor: "#E60000",
            width: "25px",
            height: "2px",
          }}
        ></div>

        {positionOptions.length > 0 ? (
          <Text fontSize={"13px"} fontWeight="600" mt="5px">
            Foram selecionadas {positionOptions.length} alternativas
          </Text>
        ) : (
          <Text fontSize={"13px"} fontWeight="400" mt="5px">
            Customize as alternativas
          </Text>
        )}
      </GridItem>

      {customOptions.length > 0 ? (
        <GridItem
          w="100%"
          h="95px"
          border="1px"
          borderColor="#40BF91"
          fontSize="14px"
          borderRadius="10px"
          p="20px"
        >
          <Text fontSize={"16px"} fontWeight="bold">
            Pergunta customizável
          </Text>
          <div
            style={{
              backgroundColor: "#E60000",
              width: "25px",
              height: "2px",
            }}
          ></div>
          <Text fontSize={"13px"} fontWeight="600" mt="5px">
            Foram selecionadas {customOptions.length} alternativas
          </Text>
        </GridItem>
      ) : (
        <GridItem w="100%" h="95px">
          <Box
            cursor="pointer"
            display="flex"
            alignItems="center"
            justifyContent="center"
            w="100%"
            h="100%"
            border="1px dashed #40BF91"
            borderRadius="10px"
            onClick={handleSpecificModal}
          >
            <Text fontSize="14px"> Crie e customize mais uma pergunta</Text>
          </Box>
        </GridItem>
      )}
    </Grid>
  );
};

export default SpecificQuestions;
