import { useQuery } from "react-query";
import apiForms from "../../../services/api-forms";

// Data fetching functions
const fetchFormsListData = async () => {
  const { data } = await apiForms.get(`/api/form-questions`);
  return data?.data
};

const fetchQuestionnaire = async (companyId) => {
  const { data } = await apiForms.get(
    `/api/answer/create-and-get/${companyId}`
  );
  return data?.data;
};

export const useFormsList = (id) => {
  return useQuery(["form-list", id], fetchFormsListData, {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};

export const useQuestionnaireDetails = (companyId) => {
  return useQuery(
    ["form-questions", companyId],
    () => fetchQuestionnaire(companyId),
    {
      enabled: !!companyId,
      refetchOnWindowFocus: false,
    }
  );
};
