import React from "react";
import styled from "styled-components";
import { Grid, Icon } from "../../../../style";

const ContainerStyled = styled.div`
  padding-top: 25px;
  padding-left: 16px;
  padding-bottom: 23px;
  padding-right: 16px;

  border-radius: 12px;
  border: 1px solid #ccc;

  margin-right: 17px;
  margin-bottom: 24px;
  margin-top: 32px;

  background-color: #fff;
`;

const Title = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #011f3b;
`;

const Subtitle = styled.h2`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  color: #011f3b;
`;

const Tag = styled.div`    
    background-color: '#fff'};
    color: ${(props) => (props.texto ? props.texto : "#000")};

    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 16px;
    padding-right: 16px;

    border-radius: 8px;
    margin-left: 9px;

`;

function HeaderColaboradores(props) {
  return (
    <ContainerStyled>
      <Grid row>
        <Icon type={props.icon} />
        <Grid column>
          <Title>{props.title}</Title>
          <Subtitle>{props.text}</Subtitle>
        </Grid>
        <Tag fundo={props.background} texto={props.color}>
          {/* {props.number} */}
        </Tag>
      </Grid>
    </ContainerStyled>
  );
}

export default HeaderColaboradores;
