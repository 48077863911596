/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import ptBR from "date-fns/locale/pt-BR";
import { useMutation } from "react-query";

import "react-datepicker/dist/react-datepicker.css";
import iconBar from "../../../../assets/images/icon-bar.svg";
import pending from "../../../../assets/images/icon-pending-red.svg";
import iconCalendarSecond from "../../../../assets/images/icon-calendar-white.svg";
import apiNotification from "../../../../services/api-notification";
import { addDays, format, parseISO, formatISO, isBefore } from "date-fns";

import { queryClient } from "../../../../services/queryCliente";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  Box,
  Flex,
  Image,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  useMediaQuery,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { researchApi } from "../../../../services/api-research";
import { useResearch } from "../../../../helpers/useResearch";
import SuccessModal from "../../../components/modal/SuccessModal";

const schema = Yup.object().shape({
  from: Yup.string().required("Campo obrigatório"),
});

registerLocale("ptBr", ptBR);

const InitialDateModal = ({
  onClose,
  isOpen,
  researchId,
  initialDate,
  endDate,
  data,
  onSubmitChangeDate
}) => {
  const [mobile] = useMediaQuery("(max-width: 1090px)");

  const [success, setSuccess] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [conectionError, setConectionError] = useState(false);
  const { data: researchsData } = useResearch(researchId);
  const [loading, setLoading] = useState(false);

  const updateDate = useMutation(
    async (researchDate) => {
      const response = await researchApi.put(`/research/${researchId}`, {
        ...researchDate,
      });
      console.log("response", response);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("research");
      },
    }
  );

  const sendNotificationResearchScheduled = (data) => {
    let crId = data.research.id_company_register;

    apiNotification
    .get(`/notification/pesquisaAgendada/${crId}`)
    .then((res) => {
      console.log("FOI")
    })
    .catch((err) => {
      console.error("Erro no getFormsData, problema: ", err);
    });

    const dataToCreateNotificationQueue = {
      notificationCode: 'PESQUISA_AGENDADA',
      companyRegister: crId
    }

    apiNotification.post(`/notification-center/queue-notification`, dataToCreateNotificationQueue)
    .then((res) => {
      console.log("Notificação criada na fila")
    })
    .catch((err) => {
      console.error("Erro no getFormsData, problema: ", err);
    });
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [endDateInput, setEndDateInput] = useState(endDate);

  useEffect(() => {
    if (endDate !== null && typeof endDate === "string") {
      setEndDateInput(endDate);
    }
  }, [endDate]);

  const onSubmit = async (values) => {
    setLoading(true);
    if (errorDate) return;
    const startDate = formatISO(new Date(values.from));
    const endDate = addDays(new Date(values.from), 14);

    const updateValues = {
      ...researchsData.research,
      application_date: startDate,
      application_date_end: formatISO(new Date(endDate)),
    };

    try {
      const res = await updateDate.mutateAsync(updateValues);
      sendNotificationResearchScheduled(data);
      onSubmitChangeDate();

      setLoading(false);
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setConectionError(true);
      setLoading(false);
    }
    // setSuccess(true);
  };

  const handleChange = (date) => {
    const today = new Date();
    if (isBefore(date, today)) {
      setErrorDate(true);
    } else {
      setErrorDate(false);
    }

    const formattedDate = addDays(new Date(date), 14);

    const endDate = format(new Date(formattedDate), "dd/MM/yyyy", {
      locale: ptBR,
    });
    setEndDateInput(endDate);
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
      setConectionError(false);
    }, 1000);
  };

  return success ? (
    <SuccessModal
      title="Início da pesquisa configurado com sucesso!"
      text="Agora é só aguardar o início da pesquisa. Não esqueça de avisar seus colaboradores e, caso precise, mude a data em até 2 dias antes do início."
      isOpen={isOpen}
      onClose={handleClose}
    />
  ) : (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size={["full", "6xl"]}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={["6px", "12px"]}
        w={["98%", "853px"]}
        h={["100vh", "auto"]}
        py="26px"
        px={["0", "10px"]}
        position='relative'
      >
        <ModalCloseButton />

        <ModalBody
     
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box h={["auto", "300px"]}>
              <Box>
                <img src={iconBar} alt="icon" />
                <Text color="#292B30" fontWeight="600" mt="10px">
                  Início da Pesquisa de Clima
                </Text>

                <Text
                  as="h4"
                  fontSize={["13px", "14px"]}
                  lineHeight={"20px"}
                  pt={["20px", 2]}
                >
                  Selecione a <b>previsão de início</b> da pesquisa. A{" "}
                  <b> previsão de encerramento </b>será ajustada{" "}
                  <b>automaticamente</b> para{" "}
                  <b>15 dias após a data de início. </b>
                </Text>
              </Box>

              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={"18px"}
                mt="20px"
                h={["auto", "78px"]}
                w={["100%", "379px"]}
              >
                <GridItem w="100%" h="100%">
                  <Text fontSize="12px" color="#292B30" mb="5px">
                    Previsão de início
                  </Text>
                  <InputGroup>
                    <Controller
                      control={control}
                      name="from"
                      defaultValue={
                        initialDate !== null
                          ? parseISO(data.period.from)
                          : undefined
                      }
                      render={({ field }) => (
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          locale="ptBr"
                          dateFormatCalendar="MMMM | yyyy"
                          placeholderText="--/--/--"
                          onChange={(date) => {
                            field.onChange(date);
                            handleChange(date);
                          }}
                          selected={field.value}
                        />
                      )}
                    />
                  </InputGroup>
                </GridItem>
                <GridItem w="100%" h="100%" fontSize="14px" borderRadius="12px">
                  <Text fontSize="12px" color="#292B30" mb="5px">
                    {mobile ? "Previsão de fim" : "Previsão de encerramento"}
                  </Text>

                  <InputGroup>
                    <Input
                      bg="gray.400"
                      color="#B8CAE8"
                      value={endDateInput}
                      w="100%"
                      h="50px"
                      fontSize="14px"
                      border="none"
                      borderRadius="10px"
                      fontWeight="600"
                    />
                    <InputRightElement h="100%" mr="10px" pointerEvents="none">
                      <Image src={iconCalendarSecond} alt="icon" h="23px" />
                    </InputRightElement>
                  </InputGroup>
                </GridItem>
              </Grid>

              {errorDate && (
                <Text
                  fontSize="12px"
                  fontWeight="600"
                  pt="5px"
                  pl="5px"
                  color="red.100"
                >
                  A data selecionada é inválida
                </Text>
              )}

              {conectionError && (
                <Text
                  fontSize="12px"
                  fontWeight="600"
                  pt="5px"
                  pl="5px"
                  color="red.100"
                >
                  Aconteceu um erro. Espere alguns minutos para tentar
                  novamente.
                </Text>
              )}
              <Flex
                mt={["24px", "20px"]}
                gap="10px"
                h={["auto", "36px"]}
                alignItems={["start", "center"]}
              >
                <Image
                  src={pending}
                  alt="icon pending"
                  h="16px"
                  mt={["5px", "0"]}
                />
                <Text fontSize={"12px"} lineHeight={["24px", "18px"]}>
                  Escolha uma data com no <b>mínimo 1 dia </b> útil a partir da
                  data atual,
                  <b> período mínimo que recomendamos </b>
                  <br />
                  para você ter tempo de
                  <Text as="span" fontWeight="700" mx="4px">
                    comunicar seus colaboradores
                  </Text>
                  comunicar seus colaboradores sobre a Pesquisa de Clima.
                </Text>
              </Flex>
              <Flex
                mt="10px"
                gap="10px"
                h={["auto", "36px"]}
                alignItems={["start", "center"]}
              >
                <Image
                  src={pending}
                  alt="icon pending"
                  mt={["5px", "0"]}
                  h="16px"
                />
                <Text fontSize={"12px"} lineHeight={["24px", "18px"]}>
                  Você pode <b>reagendar </b>a data até dois (2){" "}
                  <b>dias antes do Início da Pesquisa de Clima.</b>
                </Text>
              </Flex>
            </Box>

            <Flex
              w="100%"
              maxW={['86vw', '100%']}
              gap="16px"
              justifyContent={"space-between"}
              mt={['unset',"16px"]}
              position={['absolute', 'static']}
              bottom='32px'
            >
              <Button
                w={["50%", "265px"]}
                h={["48px", "50px"]}
                variant={"secondary"}
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant={"primary"}
                disabled={!!conectionError || loading}
                w={["50%", "265px"]}
                h={["48px", "50px"]}
                isLoading={loading}
              >
                Salvar
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InitialDateModal;
