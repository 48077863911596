import { useState } from "react";

import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { recover } from "../../../services/authServices";

import successImage from "../../../assets/images/success.jpg";

import { MdOutlineEmail } from "react-icons/md";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
  Image,
} from "@chakra-ui/react";
import { Input } from "../../components/input/index";

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Campo obrigatório")
    .email("Insira um email válido"),
});

const ForgotPassword = ({ onClose, isOpen, fechar }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  async function handleSendEmail(data) {
    const { email } = data;
    try {
      const response = await recover(email);

      if (response) {
        setSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  };

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent
        borderRadius={["20px", "30px"]}
        style={{
          width: "100%",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box display={"flex"} flexDir="column" p={["32px", "40px"]}>
         
            <Image src={successImage} alt="success" w={["60px", "auto"]}
            mx='auto'
            mb='24px'
             />
       
          <Heading fontSize={['20px',"28px"]}>Link enviado com sucesso</Heading>
          <Text fontSize={"16px"} mt="30px">
            Clique no link enviado ao seu e-mail para
            <br />
            prosseguir com a alteração de sua senha.
            <br />
            <b>Importante:</b> O e-mail chegará em até 24 horas.
          </Text>
        </Box>
        <ModalFooter width="100%" p={0}>
          <Button
            width="100%"
            color="white"
            background="#E60000"
            height={['56px',"69px"]}
            bottom={0}
            borderRadius={0}
            onClick={handleClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="9xl">
      <ModalOverlay />
      <ModalContent
        justifySelf={"center"}
        alignSelf="center"
        w={["100%", "500px"]}
        p={["0px", "40px"]}
        borderRadius={["20px 20px 0px 0px", "30px"]}
        overflow="hidden"
      >
        <ModalHeader bg={["#F5F5FB", "none"]} py={["17px"]} h={["64px"]}>
          <Heading fontSize={["16px", "28px"]}>Esqueci minha senha</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={["24px"]}>
          <Text fontSize={["16px", "18px"]} lineHeight={["28px"]} mb="30px">
            Digite seu e-mail de cadastro para que possamos enviar um link para
            recuperação de sua senha.
          </Text>
          <form onSubmit={handleSubmit(handleSendEmail)}>
            <Input
              {...register("email")}
              label="E-mail"
              errors={errors}
              // type='email'
              id="emailRecupera"
              placeholder="Digite seu e-mail"
              icon={<MdOutlineEmail color="#9ea3ad" fontSize="22px" />}
            />
            {errors.email && <p>{errors.email.message}</p>}
            <Box mt="32px" pb="28px">
              <Button
                type="submit"
                isLoading={isSubmitting}
                bg="#E60000"
                color="white"
                borderRadius={"10px"}
                w="100%"
                // onClick={(e) => {recuperarSenha(getEmailRecupera()); setSuccess(true);}}
                h="56px"
                fontSize="16px"
              >
                Enviar link
              </Button>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ForgotPassword;
