import { Flex, Text } from "@chakra-ui/react";
import Pagination from "../Pagination";

const Results = ({ meta, currentPage, setCurrentPage }) => {
  return (
    <Flex
      direction={["column", "column", "column", "row"]}
      gap={"24px"}
      justify={!!meta.actual && !!meta.total ? "space-between" : "end"}
      align="center"
    >
      {!!meta.actual && !!meta.total ? (
        <Flex align="center">
          {!!meta.actual && !!meta.total && meta.total > 0 ? (
            <Text
              fontSize={["14px", "14px", "14px", "18px"]}
              lineHeight={["14px", "14px", "14px", "18px"]}
              fontWeight={500}
              sx={{ b: { fontWeight: 600 } }}
            >
              Mostrando <b>{meta.actual < 10 ? `0${meta.actual}` : 10}</b> de{" "}
              <b>{meta.total < 10 ? `0${meta.total}` : meta.total}</b>
            </Text>
          ) : (
            <Text
              fontSize={["14px", "14px", "14px", "18px"]}
              lineHeight={["14px", "14px", "14px", "18px"]}
              fontWeight={500}
            >
              Nenhum registro encontrado
            </Text>
          )}
        </Flex>
      ) : null}

      <Pagination
        meta={meta}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Flex>
  );
};
export default Results;
