import { isEmpty } from "lodash";

export const elegibility = (forms) => {
  let completed = false;
  if (!isEmpty(forms)) {
    if (
      !!forms.cultureBrief.allRequiredAnswered &&
      !!forms.rankingForm.allRequiredAnswered
    ) {
      completed = true;
    }
    return completed;
  }
};
