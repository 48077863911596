import {
  Box,
  Button,
  Text,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Image
} from "@chakra-ui/react";

const SimpleModal = ({ isOpen, onClose, title, text, image, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        w={["100%", "552px"]}
        p="0px"
        borderRadius={["12px", "20px"]}
        overflow="hidden"
      >
        <ModalBody p={["32px", "60px 40px 60px 40px"]}>
          <Box display={"flex"} flexDir="column" alignItems="center" gap="24px">

            <Heading
              fontSize={["20px", "24px"]}
              lineHeight="33.6px"
              fontWeight="700"
              textAlign="center"
            >
              {title}
            </Heading>
            <Text color="#5C6066" lineHeight={"28px"} textAlign="center">
              {image}
              {text}
            </Text>
            {children}
          </Box>
        </ModalBody>
        <ModalFooter width="100%" p={0} height={["56px", "69px"]}>
          <Button
            width="100%"
            color="white"
            background="#E60000"
            borderRadius={0}
            height={["56px", "69px"]}
            fontWeight={[600, 400]}
            onClick={onClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SimpleModal;
