import axios from "axios"

export const downloadComments = async (research) => {
    // Se a pesquisa não rodou no sistema de pesquisa não acessamos o relatorio
    if(research.idSurvey.length > 6) {
      return alert('Relatório não disponível')
    }
  
  if (research.plan.search('LIGHT') === -1 || (research.hasExtraNoteComments) ) {
    // Somente planos PLUS e PREMIUM tem acesso (ou compra avulsa)
    try {
      const url_result =
      window["runConfig"].REACT_APP_URL_RESULT_SERVICE +
        `/report/comments/${research.idSurvey}`

      const report = await axios.get(url_result, {method: "GET", responseType: 'blob'})    

      const url = URL.createObjectURL(report.data)

      const a = document.createElement('a')
      a.download = `comments${research.idSurvey}.pdf`
      a.href = url
      a.click()    

      return report;
    } catch (e) {
      return new Error('erro' + JSON.stringify(e))
    }
  } else {
    const index = research.plan.indexOf('_');
    const plan = research.plan.substring(index + 1);
    const a = document.createElement('a')
    a.target="_blank"
    a.href = `https://produtos.gptw.com.br/produtos/cadernocomentarios/?product=${plan}`
    a.click() 
  }
}