import axios from "axios";
import { Buffer } from "buffer";

const username = window["runConfig"].REACT_APP_AUTH_API_USER;
const password = window["runConfig"].REACT_APP_AUTH_API_PASS;

const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");

const apiAuth = axios.create({
  baseURL:
    window["runConfig"].REACT_APP_URL_AUTH_API || "http://auth.domain.tld/api",
  headers: {
    Authorization: `Basic ${token}`,
    "Content-Type": "application/json;charset=UTF-8",
  },
});

export default apiAuth;
