
import { FC, useState } from "react";

import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import iconBar from "../../../assets/images/icon-bar.svg";

import { MdOutlineEmail } from "react-icons/md";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  ModalFooter,
  Box,
} from "@chakra-ui/react";
// import { Input } from "../../../components/ui/form/input/Input";
// import { Icon } from "../../../components/ui";

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Campo obrigatório")
    .email("Insira um email válido"),
});

const UploadModal = ({ onClose, isOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const handleSendData = () => {
    setSuccess(true);
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  };

  return success ? (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          height: "341px",
          width: "552px",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box display={"flex"} flexDir="column" pt="60px">
          <div
            style={{
              margin: "0 auto",
              marginBottom: "20px",
            }}
          >
            {/* <Icon type="check-green" width="100px" height="100px" /> */}
          </div>
          <Heading fontSize="24px" fontWeight='700'>Planilha enviada com sucesso</Heading>
        </Box>
        <ModalFooter
          width="100%"
          position={"absolute"}
          p={0}
          bottom={0}
          height="69px"
        >
          <Button
            width="100%"
            color="white"
            background="#E60000"
            height="100%"
            fontWeight='100'
            onClick={handleClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "30px",
          width: "581px",
          position: "relative",
          height: "325px",
          overflow: "hidden",
        }}
      >
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody px="33px">
          <img src={iconBar} alt="icon bar" />
          <Heading fontSize="18px" fontWeight="600" mb="10px" mt="8px">
            Enviar planilha de colaboradores
          </Heading>
          <Text as="p" fontSize={"14px"} mb="30px">
            Após <b> baixar </b> o nosso <b>template </b> e <b>preencher</b> as
            informações envie para nós.
          </Text>
          <form onSubmit={handleSubmit(handleSendData)}>
            {/* <Input
              id="email"
              // label="E-mail"
              error={errors.email}
              placeholder="Digite seu e-mail"
              icon={<MdOutlineEmail color="#9ea3ad" fontSize="22px" />}
              {...register("email", { required: true })}
            /> */}
            {errors.email && <p>{errors.email.message}</p>}
          </form>

          <Button
            mt="10px"
            h="20px"
            // type="submit"

            variant="link"
            // colorScheme="red"
            w="100%"
            size="lg"
            color="#E60000"
            fontSize="16px"
            fontWeight="600"
          >
            Atualizar planilha
          </Button>
        </ModalBody>
        <ModalFooter position={"absolute"} bottom={0} w="100%" h="69px" p={0}>
          <Button
            onClick={handleSendData}
            h="100%"
            type="submit"
            bg="#E60000"
            color="white"
            w="100%"
            size="lg"
            fontSize="16px"
            fontWeight="200"
          >
            Enviar planilha
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadModal;
