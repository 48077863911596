import * as React from "react";
import { take } from "lodash";
import { useQuery } from "react-query";
import { isPast, parseISO } from "date-fns";
import { researchApi as api } from "../../services/api-research";
import { jsx } from "@emotion/react";

const useCertification = (id) => {
  const [certificationLoading, setCertificationLoading] = React.useState(true);
  const [researchInfo, setResearchInfo] = React.useState(false);

  const fetchResearchInfo = async () => {
    const resp = await api.get(`/research/researchInfo/${id}`);    
    if (resp && resp.data) return resp.data;
  };

  const { data } = useQuery("resp", () => fetchResearchInfo());  


  React.useEffect(() => {
    if (data) { 
      setResearchInfo(data)
      setCertificationLoading(false);
    }
  }, [data]);

  return [researchInfo, certificationLoading];
};

export default useCertification;