/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Stack, Spinner, Button } from "@chakra-ui/react";
import moment from 'moment';
import getUrlParams from "../../../services/getUrlParams";
import SimpleSidebar from "../../components/nav/Sidebar";
import Questionarios from "../../components/card/Questionario";
import { researchApi } from "../../../services/api-research";
import {
  useParticipationDataQuery,
  useTemplateDataQuery,
} from "../pilares-for-all/hooks";
import { addDays, isAfter } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useFormsList, useQuestionnaireDetails } from "./hooks";
import QuestionnairesOverview from "./QuestionnairesOverview";
import apiForms from "../../../services/api-forms";
import QuestionnaireCard from "../../components/questionnaire/QuestionnaireCard";
import { useResearchInfoDataQuery } from "../../../hooks/useResearchInfo";
import { getCookie } from "../../common/cookieManager";
import { useLocation, useHistory } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function Cadastro() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const cr = urlParams.get("cr");
  const st = urlParams.get("st");
  const { document, key } = getUrlParams("questions");
  const companyPlan = getCookie("selected-company-register");

  const { data: researchData } = useResearchInfoDataQuery(cr);
  const { data: questionnaireListData } = useFormsList(cr);
  const { data: questionnaireDetails } = useQuestionnaireDetails(cr);
  const { data: templateData } = useTemplateDataQuery(cr);
  const { data: participationData, isLoading: participationDataIsLoading } =
    useParticipationDataQuery(cr);

  const [currentRankings, setCurrentRankings] = useState([]);
  const [formattedForms, setFormattedForms] = useState();
  const [isThirtyDaysPassed, setThirtyDaysPassed] = useState(false);
  const [productLimit, setProductLimit] = useState();
  const [thematics, setThematics] = useState(0);
  const tokenURL = btoa(cr);
  const [canUpdateForm, setCanUpdateForm] = useState(false);

  const [dataMessages, setDataMessages] = useState({}); // Novo estado para armazenar as mensagens de data

  function backListQuestion() {
    const url = new URL(window.location);
    const searchParams = url.searchParams;
    searchParams.set('showDiversity', false);
    window.history.replaceState({}, '', `${url.pathname}?${searchParams.toString()}`);
    window.location.reload();
  };

  const query = useQuery();
  const showDiversity = query.get('showDiversity') === 'true';

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  params.set('showDiversity', 'true');
  const newUrl = `${location.pathname}?${params.toString()}`;

  function redirectFormUrl(type, linkForm) {
    let link;
    if (type === "QT") {
      link = `${linkForm}/${cr}/thematic-survey-ext`;
    } else if (type === "CA") {
      link =
        productLimit < 100
          ? `https://portal-ca.gptw.com.br/?company-register=${cr}`
          : `/registration/profile/pilares-for-all?cr=${cr}`;
    } else {
      link = `/registration/profile/info?form=${type}&cr=${document}&st=${key}`;
    }
    return link;
  }

  useEffect(() => {
    if (
      questionnaireListData &&
      questionnaireDetails &&
      !formattedForms &&
      productLimit
    ) {
      const groupedArray = {};

      questionnaireDetails.forEach((obj2) => {
        const formValue = obj2[0].form;
        groupedArray[formValue] = {};
      });
      questionnaireListData.forEach((obj1) => {
        const typeValue = obj1.type;
        const formValue = groupedArray[typeValue];

        if (formValue) {
          formValue["order"] = obj1.order;
          formValue["type"] = obj1.type;
          formValue["nameVersion"] = obj1.nameVersion;
          formValue["linkForm"] = obj1.linkForm;
          formValue["formUrl"] = redirectFormUrl(obj1.type, obj1.linkForm);
          formValue["responseEnableOnAt"] = obj1.responseEnableOnAt;
          formValue["isDiversity"] = obj1.isDiversity;
        }
      });
      questionnaireDetails.forEach((obj2) => {
        const formValue = obj2[0].form;
        const formInfo = groupedArray[formValue];

        if (formInfo) {
          formInfo["totalQuestions"] = obj2[0].totalQuestions;
          formInfo["totalAnswered"] = obj2[0].totalAnswered;
          formInfo["allRequiredAnswered"] = obj2[0].allRequiredAnswered;
        }
      });

      const resultArray = Object.values(groupedArray);
      setFormattedForms(resultArray);
      setCanUpdateForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    questionnaireDetails,
    questionnaireListData,
    formattedForms,
    productLimit,
  ]);
  const getEarliestEndLimitDate = (items) => {
    const activeItems = items.filter(item => item.status === 'ACTIVE');
    if (activeItems.length === 0) {
      console.log("Nenhum item ativo encontrado");
      return null;
    }
    const earliestDate = activeItems
      .map(item => moment.utc(item.end_limit_date))
      .reduce((earliest, current) => {
        return current.isBefore(earliest) ? current : earliest;
      }, moment.utc(activeItems[0].end_limit_date));
  
    return earliestDate;
  };

  const earliestDateRule = window["runConfig"].REACT_APP_EARLIER_DATE_RULE;

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const { data } = await axios.get(`https://connect.gptw.info/api/rankings/rankings?company_id=${cr}`);
        setCurrentRankings(data);
      } catch (error) {
        console.error("Erro ao buscar rankings: ", error);
      }
    };

    fetchRankings();
  }, [cr]);

  useEffect(() => {
    if (researchData && researchData?.applicationEndDate && currentRankings) {
      let limitDate;
      if (earliestDateRule === "active") {
        const earliestDate = getEarliestEndLimitDate(currentRankings);
        if (!earliestDate) {
          setThirtyDaysPassed(false);
          return;
        }
        limitDate = earliestDate;
      } else {
        let date = new Date(researchData?.applicationEndDate);
        limitDate = addDays(date, 31);
      }
  
      const currentDate = new Date();
      const hasPassedThirtyDays = isAfter(currentDate, limitDate);
      setThirtyDaysPassed(hasPassedThirtyDays);
    }
  }, [researchData, currentRankings, earliestDateRule]);

  useEffect(() => {
    let mounted = true;
    const handleGetThematics = async () => {
      try {
        const response = await researchApi.get(`/thematic-research`);
        setThematics(response.data.length);
      } catch (error) {
        console.log("ResearchApi ERROR > ", error);
      }
    };

    handleGetThematics();
    return () => (mounted = false)
  }, [researchData]);

  useEffect(() => {
    async function fetch() {
      // verificando o plano para abertura dos pilares
      if (document !== false) {
        // verificando o plano para abertura dos pilares
        const urlConnect =
          window["runConfig"].REACT_APP_URL_FINANCIAL_API +
          `/connect/connect-product?secret=${window["runConfig"].REACT_APP_FINANCIAL_SECRET}&cr=${document}`;
        try {
          await axios.get(urlConnect).then((response) => {
            if (response?.data?.data?.maxEmployee) {
              setProductLimit(response.data.data?.maxEmployee);
            } else if (researchData?.employeesQuantity) {
              // nao tem produto, tenta ver a qtd no registro da pesquisa
              setProductLimit(researchData?.employeesQuantity);
            } else {
              setProductLimit(100)
            }
          });
          
        } catch (error) {
          console.error("Erro no getResearchInfo, problema: ", error);
          setProductLimit(100);
        }
      }
    }
    fetch();
  }, [productLimit, document, researchData]);

  useEffect(() => {
    if (templateData && canUpdateForm) {
      const transformedData = [...formattedForms];
      const lastIndex = transformedData.length - 1;

      // Trecho comentado pois estava colocando os totais em Cards Incorretos
      // Update the last object in formsInfo with templateData
      // transformedData[lastIndex] = {
      //   ...transformedData[lastIndex],
      //   totalQuestions: templateData.totalQuestions,
      //   totalAnswered: templateData.totalAnswered,
      //   allRequiredAnswered: templateData.allRequiredAnswered,
      // };

      setFormattedForms(transformedData);
      setCanUpdateForm(false);
    }
  }, [templateData, canUpdateForm, formattedForms]);

  const fetchDataMessage = async (idCompanyRegister, idFormQuestion) => {
    try {
      const response = await apiForms.get(`/api/answer/last-update/${idCompanyRegister}/${idFormQuestion}`);
      const data = response.data.data;
      return `Questionário enviado em ${data.finalizedAt ? moment(data.finalizedAt).format('DD/MM/YYYY HH:mm') : 'xx/xx/xxxx xx:xx'}, por ${data.createdBy || 'usuário'}`;
    } catch (error) {
      console.error("Erro ao obter última atualização: ", error);
      return 'Sem data de finalização';
    }
  };
  useEffect(() => {
    const fetchAllDataMessages = async () => {
      const messages = {};
      for (const form of questionnaireListData || []) {
        messages[form.type] = await fetchDataMessage(document, form.idFormQuestion);
      }
      setDataMessages(messages);
    };
    if (questionnaireListData && Object.keys(dataMessages).length === 0) {
      fetchAllDataMessages();
    }
  }, [questionnaireListData, document, dataMessages]);

  if (
    !questionnaireListData ||
    !questionnaireDetails ||
    participationDataIsLoading
  ) {
    return (
      <Stack align="center" justify="center" h="40vh">
        <Spinner />
      </Stack>
    );
  }

  return (
    <SimpleSidebar>
      {!!formattedForms && (cr !== 'undefined' && cr !== 'null' ) ? (
        <>

          {showDiversity && (  
              <Button variant="red" borderRadius="10px" bottom={2} h="26px" onClick={backListQuestion}>
                Voltar
              </Button>
          )}

          {!showDiversity && (
            <QuestionnairesOverview />
          )}

          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            {window["runConfig"].REACT_APP_QUESTIONARIOS_TEMATICOS ===
              "active" ? (
              <Questionarios
                cor={"#ffff55"}
                titulo={"Questionários Temáticos"}
                inicial={0}
                final={thematics}
                url={`/tematicos?cr=${cr}&st=${st}&token=${tokenURL}`}
                textoUrl={"Visualizar todos"}
                textoAlerta={"Texto Alerta"}
                message={"Mensagem"}
                status={false}
              />
            ) : null}

            {formattedForms.filter((form) =>
              showDiversity
              ? form.isDiversity === true
              : form.isDiversity === null || !form.isDiversity
            )
            .map((form) => (
              <QuestionnaireCard
                key={form.type}
                title={form.nameVersion}
                dataMessage={form.type !== "CA" ? dataMessages[form.type] : undefined}
                form={form}
                progressLabel={`${form.totalAnswered}/${form.totalQuestions}`}
                isThirtyDaysPassed={isThirtyDaysPassed}
                hideProgressBar={
                  form.type === "CA" && productLimit < 100 
                }
                pillarsFinished={participationData?.finished}
                isDiversity={form.isDiversity}
                responseEnableOnAt={form.responseEnableOnAt}
              />
            ))}
            {!showDiversity && (
              <Questionarios
                cor={"#ffff55"}
                titulo={"Questionário Diversidade"}
                inicial={0}
                final={thematics}
                url={newUrl}
                textoUrl={"Visualizar todos"}
                textoAlerta={"Texto Alerta"}
                message={"Mensagem"}
                status={false}
              />
            )}
          </Grid>
        </>
      ) : (
        <Stack align="center" justify="center" h="40vh">
          <Spinner />
        </Stack>
      )}
    </SimpleSidebar>
  );
}

export default Cadastro;
