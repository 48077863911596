import { get } from "react-hook-form";
import { getCookie } from "../../common/cookieManager";

const NPS = ({companyRegister, email}) => {
  // adicionar depois o objeto tags pra utilizar na geração do tracksale
  const tags = {
    cnpj: companyRegister.cnpj,
    email,
    empresa: companyRegister.name,
    nomeFantasia: companyRegister.fantasyName,
    tamanho: getCookie("PLANO_FULL"),
    plano: getCookie("PLANO"),
    certificado: "sim",
  };

  const isRunAs = window.location.pathname.includes('run-as');

  const isProject = companyRegister.isProject;

  const isGptw = getCookie("roles").find((role) => role === "GPTW");

  const runScript = (tags) => {
    (function (doc, script, scriptName) {
      const tracksaleParams = {
        key: "02d3f100abe91727",
        campaign: "acd31d5c",
        email: tags.email,
        tags,
        salute:
          "Olá, por favor avalie sua experiência no processo de certificação no GPTW Connect.",
        thanks:
          "Muito obrigado! Seu feedback é muito importante para melhorarmos nossos serviços!",
      };

      let scriptElement;
      const firstScriptTag = doc.getElementsByTagName(script)[0];
      if (doc.getElementById(scriptName)) {
        return;
      }

      scriptElement = doc.createElement(script);
      scriptElement.id = scriptName;
      scriptElement.src = "https://cdn.tracksale.co/tracksale-js/tracksale.js";
      scriptElement.type = "text/javascript";
      scriptElement.async = true;
      scriptElement.onload = scriptElement.onreadystatechange = function () {
        const h = this.readyState;
        if (h && h !== "complete" && h !== "loaded") {
          return;
        }
        try { 
          console.log(getCookie("tracksale-widget") ? true : false);
          const tracksale = new window.Tracksale();
          // TODO: Arrumar uma forma de validar se a pesquisa de satisfação foi respondida, para evitar que o widget apareça novamente apenas se ela já foi respondida
          tracksale.init(tracksaleParams);
        } catch (i) {
          console.log("Erro ao carregar o script do Tracksale");
          console.log(i);
        }
      };
      firstScriptTag.parentNode?.insertBefore(scriptElement, firstScriptTag);
    })(document, "script", "tracksale-js");
  };

  !isProject && !isRunAs && !isGptw && runScript(tags);

  return null;
};

export default NPS;
