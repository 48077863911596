const queryParamsPresent = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const st = urlParams.get("st");
  const companyId = urlParams.get("cr");
  const token = urlParams.get("token");
  return [st, companyId, token];
};

export default queryParamsPresent;
