import React from "react";
import { ModalWithClose, TagStatus } from "../../../components";
import { Divider, Flex, Text } from "@chakra-ui/react";
import { CNPJconverter } from "../../../../../helpers/CNPJconverter";

const ModalInfo = ({ isOpen, onClose, data }) => {
  console.log("🚀 ~ data:", data);
  console.log("🚀 ~ data:", data?.statusConnect);

  const handleClose = () => {
    onClose();
  };

  const detailsStatus = () => {
    const status = data?.statusConnect || "";
    const diasParaExpirarCertificado = data?.diasParaExpirarCertificado || 0;

    switch (true) {
      case diasParaExpirarCertificado < 100 && diasParaExpirarCertificado > 0:
        return `${diasParaExpirarCertificado}`;
      case status.includes("andamento"):
      case status.includes("processo"):
      case status.includes("Reprovado"):
      case status.includes("Ainda"):
        return status;
      default:
        return "----";
    }
  };
  return (
    <ModalWithClose
      title="Mais Informações"
      isOpen={isOpen}
      onClose={handleClose}
      minH="100vh"
    >
      <Flex direction="column" gap="18px" p="24px 24px 52px 24px">
        <TitleText title="Nome da empresa" text={data?.razaoSocial} />
        <Divider borderColor="#EDF1EF" />
        <TitleText title="Nome fantasia" text={data?.fantasia} />
        <Divider borderColor="#EDF1EF" />
        <TitleText
          title="CNPJ"
          text={!!data?.cnpj ? CNPJconverter(data?.cnpj) : data?.cnpj}
        />
        <Divider borderColor="#EDF1EF" />
        <TitleText
          title="Status da Certificação"
          tag={true}
          text={data?.statusConnect}
        />
        <Divider borderColor="#EDF1EF" />
        <TitleText title="Detalhes do Status" text={detailsStatus()} />
        <Divider borderColor="#EDF1EF" />
        <TitleText title="Produto" text={data?.Plano} />
        <Divider borderColor="#EDF1EF" />
        <TitleText title="Informações do Produto" text={data?.produto} />
        <Divider borderColor="#EDF1EF" />
        <TitleText title="Estado" text={`${data?.cidade} - ${data?.estado} `} />
        <Divider borderColor="#EDF1EF" />
        <TitleText title="Responsável" text={data?.nomeContato} />
        <Divider borderColor="#EDF1EF" />
        <TitleText title="E-mail responsável" text={data?.telefoneContato} />

        <Divider borderColor="#EDF1EF" />
        <TitleText title="Contato responsável" text={data?.eMailContato} />
        <Divider borderColor="#EDF1EF" />
        <TitleText title="Setor" text={data?.setor} />
        <Divider borderColor="#EDF1EF" />
        <TitleText title="Dono da Conta" text={data?.donoConta} />
        <Divider borderColor="#EDF1EF" />
      </Flex>
    </ModalWithClose>
  );
};

const TitleText = ({ title, text, tag }) => {
  return (
    <Flex gap={"24px"} w="100%" align="center" pr="20px">
      <Text
        fontWeight={500}
        fontSize={"14px"}
        lineHeight={"17px"}
        color="#555E66"
        maxW="126px"
        w="100%"
      >
        {title}
      </Text>
      {!!tag ? (
        <TagStatus name={text} transparent={true} />
      ) : (
        <Text
          fontWeight={600}
          fontSize={"16px"}
          lineHeight={"16px"}
          color={"#22262D"}
          whiteSpace={"wrap"}
          wordBreak="break-word"
        >
          {!!text && text !== "null" ? text : "----"}
        </Text>
      )}
    </Flex>
  );
};
export default ModalInfo;
