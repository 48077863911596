import { Icon, IconButton } from "@chakra-ui/react";
import { useState } from "react";

const ButtonArrow = ({ isActive, isLeft, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <IconButton
      variant={"unstyled"}
      size={"sm"}
      w="38px"
      h="38px"
      minW="unset"
      disabled={!isActive}
      _disabled={{ opacity: 1, cursor: "not-allowed" }}
      icon={
        <IconArrow
          style={{
            transform: isLeft ? "unset" : "rotate(180deg)",
            transition: "fill 0.3s",
            opacity: isHovered ? "0.6" : "1",
            fill: isActive ? "#444C54" : "#C4CCCC",
          }}
        />
      }
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    />
  );
};

const IconArrow = (props) => {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" {...props}>
      <path d="M18.0739 8.36443L14.7138 12.0342L12.6516 14.2749C11.7828 15.2238 11.7828 16.7672 12.6516 17.7161L18.0739 23.638C18.7857 24.4154 20 23.8552 20 22.7691V16.3556L20 9.23329C20 8.13579 18.7857 7.58703 18.0739 8.36443Z" />
    </Icon>
  );
};

export default ButtonArrow;
