import { Text, Progress, Grid, GridItem } from "@chakra-ui/react";

export function ProgressLinear({ label, value, color }) {
  return (
    <Grid templateColumns={"repeat(5, 1fr)"} gap="12px" alignItems='center'>
      <GridItem colSpan={1} w='86px' height="16px">
        <Text fontSize="sm" lineHeight="16.44px">
          {label}
        </Text>
      </GridItem>
      <GridItem colSpan={3} display="grid" minW='200px' alignItems="center">
        <Progress
          value={value}
          minW='200px'
          maxW='233px'
          height="6px"
          colorScheme={color}
          bg="gray.400"
          borderRadius="20px"
        />
      </GridItem>
      <GridItem colSpan={1} width='37px'>
        <Text
        fontSize={'sm'}
        color='#5C6066'
        fontWeight={700}
        >0/1</Text>
      </GridItem>
    </Grid>
  );
}
