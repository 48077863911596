const suggestions = [
  {
    value:
      "Entendo como meu trabalho contribui para o alcance das metas e objetivos estabelecidos",
    label:
      "Entendo como meu trabalho contribui para o alcance das metas e objetivos estabelecidos",
  },

  {
    value: "Entendo como meus, os problemas da empresa",
    label: "Entendo como meus, os problemas da empresa",
  },
  {
    value: "A gestão incentiva o trabalho em equipe",
    label: "A gestão incentiva o trabalho em equipe",
  },
  {
    value:
      "Minha área recebe serviços de excelente qualidade de outras áreas internas das quais depende",
    label:
      "Minha área recebe serviços de excelente qualidade de outras áreas internas das quais depende",
  },
  {
    value: "Percebo o trabalho em equipe como uma prática da empresa",
    label: "Percebo o trabalho em equipe como uma prática da empresa",
  },
  {
    value:
      "Conheço meus objetivos e entendo como eles contribuem para os alcance das metas e objetivos da empresa",
    label:
      "Conheço meus objetivos e entendo como eles contribuem para os alcance das metas e objetivos da empresa",
  },
  {
    value: "Entendo bem os objetivos da empresa a curto, médio e longo prazos",
    label: "Entendo bem os objetivos da empresa a curto, médio e longo prazos",
  },
  {
    value: "Eu conheço os produtos e serviços da empresa",
    label: "Eu conheço os produtos e serviços da empresa",
  },
  {
    value:
      "É fácil conseguir colaboração de outras áreas da empresa com as quais me relaciono.",
    label:
      "É fácil conseguir colaboração de outras áreas da empresa com as quais me relaciono.",
  },
  {
    value:
      "Os objetivos e metas mais importantes para o negócio são compartilhados entre todos",
    label:
      "Os objetivos e metas mais importantes para o negócio são compartilhados entre todos",
  },
  {
    value:
      "Percebo que a empresa está buscando a sustentabilidade do negócio diante das turbulências do mercado",
    label:
      "Percebo que a empresa está buscando a sustentabilidade do negócio diante das turbulências do mercado",
  },
  {
    value:
      "Senti que houve transparência e respeito por parte da empresa nesse momento de mudanças e reestruturação",
    label:
      "Senti que houve transparência e respeito por parte da empresa nesse momento de mudanças e reestruturação",
  },
  {
    value:
      "A empresa tem critérios e processos definidos para avaliação de desempenho",
    label:
      "A empresa tem critérios e processos definidos para avaliação de desempenho",
  },
  {
    value: "Eu me desenvolvo profissionalmente trabalhando aqui",
    label: "Eu me desenvolvo profissionalmente trabalhando aqui",
  },
  {
    value: "Eu satisfaço minhas aspirações da carreira trabalhando aqui",
    label: "Eu satisfaço minhas aspirações da carreira trabalhando aqui",
  },
  {
    value:
      "As pessoas aqui são bem tratadas independentemente de serem pessoas com deficiência",
    label:
      "As pessoas aqui são bem tratadas independentemente de serem pessoas com deficiência",
  },
  {
    value:
      "A empresa promove ações de bem estar e qualidade de vida para os seus colaboradores",
    label:
      "A empresa promove ações de bem estar e qualidade de vida para os seus colaboradores",
  },
  {
    value: "É possível equilibrar bem o tempo entre trabalho e família",
    label: "É possível equilibrar bem o tempo entre trabalho e família",
  },
  {
    value: "É possível realizar as tarefas dentro do horário de trabalho",
    label: "É possível realizar as tarefas dentro do horário de trabalho",
  },
  {
    value:
      "O tempo que dedico ao meu trabalho é aquele que foi combinado com a empresa",
    label:
      "O tempo que dedico ao meu trabalho é aquele que foi combinado com a empresa",
  },
  {
    value:
      "Sinto que a gestão se preocupa e incentiva a qualidade de vida e o equilíbrio entre vida profissional e pessoal",

    label:
      "Sinto que a gestão se preocupa e incentiva a qualidade de vida e o equilíbrio entre vida profissional e pessoal",
  },
  {
    value:
      "Esta empresa entrega aos seus clientes aquilo que promete com alta qualidade.",
    label:
      "Esta empresa entrega aos seus clientes aquilo que promete com alta qualidade.",
  },
  {
    value:
      "Um compromisso assumido com um cliente torna-se prioridade para todos",
    label:
      "Um compromisso assumido com um cliente torna-se prioridade para todos",
  },
  {
    value: "As pessoas fazem o que é certo, mesmo assumindo riscos",
    label: "As pessoas fazem o que é certo, mesmo assumindo riscos",
  },
  {
    value:
      "As pessoas que trabalham na empresa estão sempre procurando inovar aquilo que fazem",
    label:
      "As pessoas que trabalham na empresa estão sempre procurando inovar aquilo que fazem",
  },
  {
    value:
      "Eu participo da definição dos meus objetivos com o meu superior imediato",
    label:
      "Eu participo da definição dos meus objetivos com o meu superior imediato",
  },
  {
    value:
      "A gestão discute comigo meus pontos fortes e necessidade de desenvolvimento visando o meu aprimoramento profissional",

    label:
      "A gestão discute comigo meus pontos fortes e necessidade de desenvolvimento visando o meu aprimoramento profissional",
  },
  {
    value:
      "Meu desempenho é avaliado de forma justa pelo meu superior imediato",
    label:
      "Meu desempenho é avaliado de forma justa pelo meu superior imediato",
  },
  {
    value: "Minha gestão me oferece oportunidades e desafios no meu dia a dia",
    label: "Minha gestão me oferece oportunidades e desafios no meu dia a dia",
  },
  {
    value:
      "A gestão atua de forma decisiva com colaboradores que apresentam problemas de desempenho repetidamente.",

    label:
      "A gestão atua de forma decisiva com colaboradores que apresentam problemas de desempenho repetidamente.",
  },
  {
    value: "Meu superior imediato monitora o atingimento dos meus objetivos",
    label: "Meu superior imediato monitora o atingimento dos meus objetivos",
  },
  {
    value: 'Recebo "feedback" sobre o meu desempenho',
    label: 'Recebo "feedback" sobre o meu desempenho',
  },
  {
    value:
      "Se precisar falar com a minha gestão ou a gestão acima da minha, sei que serei recebido.",

    label:
      "Se precisar falar com a minha gestão ou a gestão acima da minha, sei que serei recebido.",
  },
  {
    value:
      "Acredito que os resultados desta pesquisa serão utilizados de maneira positiva pela gestão.",

    label:
      "Acredito que os resultados desta pesquisa serão utilizados de maneira positiva pela gestão.",
  },
  {
    value: "Percebi melhorias a partir da última pesquisa",
    label: "Percebi melhorias a partir da última pesquisa",
  },
  {
    value:
      "A empresa me dá oportunidade de participar em atividades de ação social ou comunitária",
    label:
      "A empresa me dá oportunidade de participar em atividades de ação social ou comunitária",
  },
  {
    value:
      "A sensação mais frequente que sinto ao deixar o trabalho é de realização profissional pelos desafios",

    label:
      "A sensação mais frequente que sinto ao deixar o trabalho é de realização profissional pelos desafios",
  },
  {
    value: "Conheço a missão,  visão e valores da empresa",
    label: "Conheço a missão,  visão e valores da empresa",
  },
  {
    value: "Sinto que o que fazemos contribui para o alcance de nossa Visão.",
    label: "Sinto que o que fazemos contribui para o alcance de nossa Visão.",
  },
];

export default suggestions;
