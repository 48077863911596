import React from "react";
import { Avatar, Flex, Text } from "@chakra-ui/react";
import { getCookie } from "../../../common/cookieManager";

const Profile = () => {
  const name = getCookie("user_name");
  const email = getCookie("user_email");
  const avatar = !!getCookie("user_avatar") ? getCookie("user_avatar") : "";
  return (
    <Flex
      align={"center"}
      gap="8px"
      justify={"end"}
      w={[
        "calc(100% - 56px)",
        "calc(100% - 56px)",
        "calc(100% - 56px)",
        "fit-content",
      ]}
    >
      <Flex
        gap={"4px"}
        direction={"column"}
        h={"fit-content"}
        w={[
          "calc(100% - 46px)",
          "calc(100% - 46px)",
          "calc(100% - 46px)",
          "fit-content",
        ]}
      >
        <Text
          fontSize={["16px", "16px", "16px", "18px"]}
          lineHeight={["16px", "16px", "16px", "18px"]}
          fontWeight={600}
          color={"#32383F"}
          maxW={["100%", "100%", "100%", "255px"]}
          w="100%"
          textAlign={"end"}
          whiteSpace={"nowrap"}
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {name}
        </Text>
        <Text
          fontSize={["12px", "12px", "12px", "14px"]}
          lineHeight={["12px", "12px", "12px", "14px"]}
          fontWeight={400}
          color="#555E66"
          maxW={["100%", "100%", "100%", "255px"]}
          w="100%"
          textAlign={"end"}
          whiteSpace={"nowrap"}
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {email}
        </Text>
      </Flex>
      <Flex flexShrink={0}>
        <Avatar
          maxW={["38px", "38px", "38px", "48px"]}
          maxH={["38px", "38px", "38px", "48px"]}
          minW={["38px", "38px", "38px", "48px"]}
          minH={["38px", "38px", "38px", "48px"]}
          src={avatar}
          name={name}
          bg="#FF1628"
          color="white"
        />
      </Flex>
    </Flex>
  );
};

export default Profile;
