import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
} from "@chakra-ui/react";

export function TermsModal({ isOpen, onClose, content }) {

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
      <ModalOverlay />
      <ModalContent color="#292B30" w={"80vw"} mt="8vh">
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p="40px 60px"
          h="140px"
          maxH="140px"
        >
          <Heading as="h2" fontSize="24px" textAlign="left">
            Regras de participação
          </Heading>
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody
          bg="#f6f5fb"
          p="40px 60px"
          color="#000000"
          lineHeight="19.6px"
        >
          <div id="testId" dangerouslySetInnerHTML={{ __html: content }} />
         
        </ModalBody>

        <ModalFooter>
            <Button
              variant="primary"
              w="242px"
              h="52px"
              boxShadow="0px 5px 20px rgba(230, 0, 0, 0.1)"
              onClick={onClose}
            >
              Fechar
            </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}