import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { logoSidebar } from "../../../../assets/images";
import { useLocation, Link } from "react-router-dom";

import ButtonLogout from "./ButtonLogout";
import ButtonHamburguer from "./ButtonHamburguer";
import Profile from "./Profile";
import SideDrawer from "./SideDrawer";
import { IconDashboardAdmin, IconStatusAdmin } from "../../assets";
import { getCookie } from "../../../common/cookieManager";
import ButtonRedirectLegado from "./ButtonRedirectLegado";

function NavbarAdmin({ children }) {
  const location = useLocation();
  const [mobile] = useMediaQuery("(max-width: 992px)");
  const { isOpen, onToggle } = useDisclosure(false);
  const authUser = getCookie("roles").find(
    (item) => item === "PARTNER" || item === "GPTW"
  );
  const cr = getCookie("selected-company-register").id;

  if (!authUser) {
    window.location.replace(`/dashboard?cr=${cr}`);
  }

  const MOCK_LIST_LINKS = [
    {
      name: "Empresas",
      icon: IconDashboardAdmin,
      path: `/admin/dashboard`,
      ref: "dashboard",
    },
    {
      name: "Status das Empresas",
      icon: IconStatusAdmin,
      path: `/admin/status-dos-clientes`,
      ref: "status",
    },
    // {
    //   name: "Gerenciar",
    //   path: `/gerenciar`,
    //   ref: "gerenciar",
    // },
  ];

  return authUser ? (
    <Box
      minH="100%"
      minW="100%"
      bg={[
        "linear-gradient(180deg, rgba(255,255,255,1) 426px, rgba(245,248,246,1) 426px)",
        "linear-gradient(180deg, rgba(255,255,255,1) 360px, rgba(245,248,246,1) 360px)",
      ]}
      id="card-dashboard"
    >
      <Flex
        borderBottom={[
          "1px solid #F5F8F6",
          "1px solid #F5F8F6",
          "1px solid #F5F8F6",
          "2px solid #F5F8F6",
        ]}
        p={[
          "42px 24px 9px",
          "42px 24px 9px",
          "42px 24px 9px",
          "0 40px 0 0",
          "0 80px 0 0",
        ]}
        gap={["16px", "16px", "16px", "0"]}
        maxW="100%"
        justify={"space-between"}
      >
        <Image
          src={logoSidebar}
          alt="sidebar logo"
          minW={["48px", "48px", "48px", "80px"]}
          minH={["48px", "48px", "48px", "80px"]}
          maxW={["48px", "48px", "48px", "80px"]}
          maxH={["48px", "48px", "48px", "80px"]}
        />

        <Flex
          p={["none", "none", "none", "0 0 0 48px"]}
          justify={["end", "end", "end", "space-between"]}
          w={[
            "calc(100% - 64px)",
            "calc(100% - 64px)",
            "calc(100% - 64px)",
            "100%",
          ]}
          gap="24px"
        >
          {!mobile ? (
            <Flex gap="16px" w="fit-content">
              {MOCK_LIST_LINKS.map((link, index) => {
                const isCurrent = location.pathname.includes(link.path);
                return (
                  <Link
                    className="link-active"
                    to={link.path}
                    style={{ textDecoration: "none" }}
                    key={index}
                  >
                    <Flex
                      p="0 16px"
                      align="center"
                      h="100%"
                      borderTop={"3px solid trasnparent"}
                      borderBottom={
                        isCurrent
                          ? "3px solid #FF1628"
                          : "3px solid transparent"
                      }
                      color={isCurrent ? "#FF1628" : "#22262D"}
                      _hover={{
                        color: "#FF1628",
                        opacity: "0.8",
                      }}
                    >
                      <Text
                        fontSize={"18px"}
                        lineHeight={"18px"}
                        fontWeight={600}
                        whiteSpace={"nowrap"}
                        h="fit-content"
                      >
                        {link.name}
                      </Text>
                    </Flex>
                  </Link>
                );
              })}
            </Flex>
          ) : null}
          <Flex
            p={["unset", "unset", "unset", "12px 0"]}
            align={"center"}
            gap={"24px"}
            justify={"end"}
            w={["100%", "100%", "100%", "fit-content"]}
          >
            {mobile ? null : <ButtonRedirectLegado />}
            <Profile
              name={"nome do lorem impsonsasdasd asdasdasdass"}
              email={"email@doditocujo.com"}
              avatar={"https://bit.ly/dan-abramov"}
            />

            {mobile ? (
              <ButtonHamburguer
                onClick={() => {
                  onToggle();
                  console.log("fechou");
                }}
                isOpen={isOpen}
                title={isOpen ? "Fechar" : "Abrir"}
              />
            ) : (
              <ButtonLogout title={"Sair"} />
            )}
          </Flex>
        </Flex>
      </Flex>

      <SideDrawer isOpen={isOpen} links={MOCK_LIST_LINKS} onClose={onToggle} />
      <Box p={["24px", "24px", "24px", "40px 40px", "40px 80px"]}>
        {children}
      </Box>
    </Box>
  ) : (
    <Flex
      h="100vh"
      direction={"column"}
      gap="24px"
      align="center"
      justify="center"
    >
      <Text
        fontSize={"40px"}
        lineHeight={"50px"}
        fontWeight={600}
        h="fit-content"
      >
        Pagina não existe.
      </Text>
      <Text
        fontSize={"40px"}
        lineHeight={"50px"}
        fontWeight={600}
        h="fit-content"
      >
        você esta sendo redirecionado.
      </Text>
    </Flex>
  );
}
export default NavbarAdmin;
