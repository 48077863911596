import React, { useState } from "react";
import { Flex, Spinner, useMediaQuery } from "@chakra-ui/react";
import {
  CardDashboard,
  Header,
  NavbarAdmin,
  Results,
  SearchBar,
} from "../../components";
import { useListCompanies } from "../../../../hooks/Admin/useCompanyRegisterList";
import { getCookie } from "../../../common/cookieManager";

function DashboardAdmin() {
  const userGPTW = getCookie("user_email");
  const emailPartner = getCookie("roles").find((item) => item === "PARTNER")
    ? userGPTW
    : "";

  const [meta, setMeta] = useState({
    pageSize: 10,
    skip: 0,
    emailPartner: emailPartner,
    textSearch: "",
  });

  const { data: dataListCompanies, isLoading } = useListCompanies(meta);

  const setCurrentPage = (value) => {
    setMeta((prev) => ({ ...prev, skip: value - 1 }));
  };

  const setTextSearch = (value) => {
    setMeta((prev) => ({ ...prev, textSearch: value }));
  };

  return (
    <NavbarAdmin>
      {isLoading ? (
        <Flex height="400px" justify={"center"} align="center">
          <Spinner />
        </Flex>
      ) : (
        <Flex gap={4} direction={"column"}>
          <Flex
            direction={["column", "column", "column", "row"]}
            gap={"24px"}
            justify="space-between"
            align={"center"}
            w="100%"
          >
            <Header
              title={"Empresas"}
              total={dataListCompanies?.count}
              textSearch={meta.textSearch}
              setTextSearch={setTextSearch}
            />
            <SearchBar
              textSearch={meta.textSearch}
              setTextSearch={setTextSearch}
              placeholder={"Pesquisar por CNPJ, Nome ou Código de participação"}
            />
          </Flex>
          {dataListCompanies?.result.map((companie, index) => {
            return (
              <CardDashboard data={companie} key={`${companie.id}${index}`} />
            );
          })}
          <Results
            meta={{
              last_page: Math.ceil(dataListCompanies?.count / meta.pageSize),
              actual: dataListCompanies?.result?.length,
              total: dataListCompanies?.count,
            }}
            currentPage={meta.skip + 1}
            setCurrentPage={setCurrentPage}
          />
        </Flex>
      )}
    </NavbarAdmin>
  );
}
export default DashboardAdmin;
