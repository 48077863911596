import axios from "axios";

const api = axios.create({
  baseURL: `${window["runConfig"].REACT_APP_URL_FORMS_API}/api`,
  headers: {
    auth: window["runConfig"].REACT_APP_URL_FORMS_API_AUTH,
  },
});

export default api;
