import { Flex } from "@chakra-ui/react";
import { PaginationItem } from "./PaginationItem";
import ButtonArrow from "./ButtonArrow";

const Pagination = ({ meta, currentPage, setCurrentPage }) => {
  const lastPage = meta.last_page;
  const siblingsCount = 2;

  function generatePagesArray() {
    return Array.from({ length: lastPage }, (_, i) => 1 + i);
  }

  const leftArrowPage = currentPage - 1;
  const rightArrowPage = currentPage + 1;

  let startPage = Math.max(1, currentPage - siblingsCount);
  let endPage = Math.min(lastPage, currentPage + siblingsCount);

  if (endPage - startPage + 1 < 2 * siblingsCount + 1) {
    if (currentPage <= siblingsCount) {
      endPage = Math.min(endPage + siblingsCount - currentPage + 1, lastPage);
    } else {
      startPage = Math.max(
        startPage - (currentPage + siblingsCount - endPage),
        1
      );
    }
  }

  return (
    <Flex gap="12px" align="center" justify="center">
      {lastPage > 1 && (
        <ButtonArrow
          isLeft={true}
          isActive={currentPage !== 1}
          onClick={() => setCurrentPage(leftArrowPage)}
        />
      )}

      {generatePagesArray()
        .filter((page) => page >= startPage && page <= endPage)
        .map((page) => (
          <PaginationItem
            key={page}
            number={page}
            isCurrent={page === currentPage}
            onClick={() => setCurrentPage(page)}
          />
        ))}

      {lastPage > 1 && (
        <ButtonArrow
          isLeft={false}
          isActive={currentPage !== lastPage}
          onClick={() => setCurrentPage(rightArrowPage)}
        />
      )}
    </Flex>
  );
};

export default Pagination;
