import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Stack,
} from "@chakra-ui/react";
import { MdKeyboardArrowDown } from "react-icons/md";

export function AboutModal({ handleDownload }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        onClick={onOpen}
        bg="gray.400"
        h="48px"
        borderRadius="12px"
        w="100%"
        px="16px"
        justifyContent="space-between"
      >
        Sobre os Pilares for All
        <MdKeyboardArrowDown fontSize="20px" />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent
          mt="20px"
          borderRadius="20px 20px 0px 0px"
          overflow="hidden"
          filter="drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))"
        >
          <ModalHeader bg="gray.100" fontSize="sm" fontWeight={700} py="17px">
            Sobre os Pilares for All
          </ModalHeader>

          <ModalCloseButton bg="white" borderRadius="full" />
          <ModalBody p="24px">
            <Stack spacing="22px">
              <Text lineHeight="28px">
                O Pilares For All inclui uma série de questões abertas,
                elaboradas para nos auxiliar a entender quais são as práticas
                diferenciais na cultura da sua organização. Estamos
                particularmente interessados no que é especial, único ou não
                usual.
              </Text>
              <Text lineHeight="28px">
                As questões estão agrupadas de acordo com as 9 Práticas
                Culturais necessárias para criar um Great Workplace.
              </Text>
              <Text lineHeight="28px">
                Importante: não há respostas certas ou erradas. Avaliaremos a
                qualidade e o conteúdo das respostas, não o tamanho ou estilo,
                portanto, forneça somente as informações necessárias para que
                possamos entender o propósito e o conteúdo das práticas ou
                políticas, assim como sua relação com a cultura da empresa.
              </Text>
              <Text lineHeight="28px">
                Se sua empresa já preencheu o Pilares For All em anos anteriores,
                por favor indique com “NOVO!” as novas práticas que está
                acrescentando em seu documento.
              </Text>

              <Text lineHeight="28px">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://youtu.be/ISg0MgLGl6s?lumUserSessionId=null&certEvolving=true&externCompanyRegisterId=18894bdd-1ed3-40f5-89bb-b81a580366a9"
                >
                  <Text
                    as="span"
                    color="red.100"
                    textDecor="underline"
                    fontWeight={600}
                  >
                    Clique aqui
                  </Text>{" "}
                </a>{" "}
                para conhecer os 5 critérios de avaliação do Pilares For All.
              </Text>

              <Text>
                <Text
                  as="button"
                  onClick={handleDownload}
                  color="red.100"
                  textDecor="underline"
                  fontWeight={600}
                >
                  Clique aqui
                </Text>{" "}
                para baixar o manual de preenchimento das Práticas Culturais.
              </Text>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
