import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  Text,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Input,
  Image,
  Checkbox,
  Flex,
} from "@chakra-ui/react";
import FinishRenovationModal from "../../components/modal/FinishRenovationModal";
import axios from "axios";
import iconCheck from "../../../assets/images/icon-check.svg";
import pending from "../../../assets/images/pending-gray.svg";
import { getCookie, removeAllCookie } from "../../common/cookieManager";
import getUrlParams from "../../../services/getUrlParams";
import SuccessModal from "../../components/modal/SuccessModal";

const RenovationModal = ({ isOpen, onClose }) => {
  const [finishRenovationModal, setFinishRenovationModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isVoucherValid, setIsVoucherValid] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [isConsultancy, setIsConsultancy] = useState(false);
  const [productId, setProductId] = useState("");
  const [renovationSuccess, setRenovationSucess] = useState(false);

  const handleChange = (e) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setIsTermsChecked(isChecked);
    } else {
      setIsTermsChecked(false);
    }
  };

  const finishRenovation = useCallback(async () => {
    const url_renovation =
      window["runConfig"].REACT_APP_URL_FINANCIAL_API +
      `/connect/renew?secret=${window["runConfig"].REACT_APP_FINANCIAL_SECRET}`;

    const email = getCookie("user_email");
    const { document: doc } = getUrlParams("");
    console.log(doc);

    const body = {
      voucher: voucher,
      responsible: {
        confirmPassword: "naoinformado",
        password: "naoinformado",
        phone: "(11) 999999999",
        email: email,
        lastName: "",
        firstName: "",
        name: "",
        isSubscribed: true,
      },
      user: {
        confirmPassword: "naoinformado",
        password: "naoinformado",
        phone: "(11) 999999999",
        email: email,
        lastName: "",
        firstName: "",
        name: "",
        isSubscribed: true,
      },
      companyRegisterId: doc[0],
      productId: productId,
    };

    const res = await axios
      .post(url_renovation, body)
      .then((response) => {
        setRenovationSucess(true);
      })
      .catch((err) => {
        alert(
          "Não foi possível realizar a renovação. Por favor, entre em contato com o atendimento@gptwbrasil.com.br e informe o voucher utilizado."
        );
        setRenovationSucess(false);
      });
  }, [voucher, productId, isConsultancy]);

  const handleModalFinishRenovation = useCallback(
    async (e) => {
      const voucher = document.getElementById("voucher").value;

      if (voucher.trim().length === 21) {
        const typeVoucher = voucher.trim().substring(0, 5);

        if (typeVoucher === "GPTW1" || typeVoucher === "GPTW2") {
          const url_check_voucher =
            window["runConfig"].REACT_APP_URL_FINANCIAL_API +
            `/connect/check-voucher?secret=${window["runConfig"].REACT_APP_FINANCIAL_SECRET}&voucher=${voucher}`;

          if (isSending) return;

          setIsSending(true);
          try {
            const resultVoucher = await axios.get(url_check_voucher);

            if (resultVoucher.data) {
              setVoucher(voucher);
              setProductId(resultVoucher.data.data.productId);
              setIsConsultancy(resultVoucher.data.data.consultancy);
              setIsVoucherValid(true);
            } else {
              setIsVoucherValid(false);
            }
          } catch (error) {
            setIsVoucherValid(false);
          }
          setIsSending(false);
        } else {
          setIsVoucherValid(false);
        }

        setIsSending(false);
      } else {
        setIsVoucherValid(false);
      }
    },
    [isSending]
  ); // update the callback if the state changes

  const handleCloseModalFinishRenovation = () => {
    setFinishRenovationModal(false);
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setRenovationSucess(false);
    }, 1000);
    removeAllCookie();
    window.location.href = "/";
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        w={["100%", "852px"]}
        p="0px"
        borderRadius={["12px", "20px"]}
        overflow="hidden"
      >
        <ModalBody p={["32px", "60px 40px 60px 40px"]}>
          <Box display={"flex"} flexDir="column" alignItems="center" gap="24px">
            <Heading
              fontSize={["20px", "24px"]}
              lineHeight="33.6px"
              fontWeight="700"
              textAlign="center"
            >
              {"Rumo à Certificação!"}
            </Heading>
            <Text mb="8px">Informe o código para renovação (Voucher)</Text>
            <Flex gap="5px">
              <Input
                style={{ justifyContent: "flex-start" }}
                id="voucher"
                placeholder="Código de Renovação"
                onChange={(e) => handleModalFinishRenovation(e)}
              />
              <Image
                style={{ justifyContent: "flex-end" }}
                src={isVoucherValid ? iconCheck : pending}
                alt="Status icon"
              />
            </Flex>

            {isVoucherValid && (
              <Checkbox
                id="aceite"
                colorScheme="red"
                size="sm"
                mt="auto"
                onChange={(e) => handleChange(e)}
              >
                LI E ACEITO OS{" "}
                <b>
                  <a
                    href={`https://gptwcertification.s3.us-west-2.amazonaws.com/templates/Termos_de_uso.pdf`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "blue" }}
                  >
                    TERMOS DE COMPROMISSO E REGRAS DE PARTICIPAÇÃO
                  </a>
                </b>{" "}
                DA PLATAFORMA GPTW CONNECT
              </Checkbox>
            )}
            {isTermsChecked && (
              <Button
                h="48px"
                w="241px"
                variant="outline"
                colorScheme="red"
                borderRadius="20px"
                fontWeight="600"
                fontSize="18px"
                onClick={finishRenovation}
              >
                Renovar
              </Button>
            )}
          </Box>
          <FinishRenovationModal
            isOpen={finishRenovationModal}
            onClose={handleCloseModalFinishRenovation}
          />
        </ModalBody>
        <ModalFooter width="100%" p={0} height={["56px", "69px"]}>
          <Button
            width="100%"
            color="white"
            background="#E60000"
            borderRadius={0}
            height={["56px", "69px"]}
            fontWeight={[600, 400]}
            onClick={onClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
      {renovationSuccess && (
        <SuccessModal
          title="Renovação realizada com sucesso!"
          text="Para finalizar o processo de renovação é necessário realizar o logout e login do sistema."
          isOpen={isOpen}
          onClose={handleClose}
        />
      )}
    </Modal>
  );
};

export default RenovationModal;
